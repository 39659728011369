import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import QuestionContainer from './QuestionContainer';
import { QUESTION_TIMEOUT_DELAY_IN_MILLISECONDS } from '../../config/appConfig';
import { InputButton } from '@simply-fin-services/astronomix3';
import * as R from 'ramda';
const INITIAL_ANSWER = '';

const replaceAll = (value, search, replacement) =>
  value.replace(new RegExp(search, 'g'), replacement);

class InputQuestion extends PureComponent {
  constructor() {
    super();
    this.submitQuestion = this.submitQuestion.bind(this);
    this.onChange = this.onChange.bind(this);
    this.startSubmitTimer = this.startSubmitTimer.bind(this);
    this.clearSubmitTimer = this.clearSubmitTimer.bind(this);
    this.typingTimeout = null;
    this.submittedValue = INITIAL_ANSWER;
  }

  componentWillMount() {
    this.setState({ value: this.props.answer || INITIAL_ANSWER });
  }

  submitQuestion() {
    this.clearSubmitTimer();
    const { value } = this.state;
    if (value !== this.submittedValue) {
      const { answerQuestion } = this.props;
      answerQuestion(value);
    }
    this.submittedValue = value;
  }

  onChange(e) {
    let value = e.target.value;
    const { hasDateMask } = this.props;
    if (!hasDateMask) {
      this.setState({
        value,
      });
      return;
    }
    if (value.length < this.state.value.length) {
      this.setState({
        value,
      });
      return;
    }
    if (value.length === 2 || value.length === 5) {
      value += '/';
    }
    this.setState({
      value: replaceAll(value, '//', '/'),
    });
  }

  startSubmitTimer() {
    this.clearSubmitTimer();
    this.typingTimeout = window.setTimeout(() => {
      this.submitQuestion();
    }, QUESTION_TIMEOUT_DELAY_IN_MILLISECONDS);
  }

  clearSubmitTimer() {
    window.clearTimeout(this.typingTimeout);
  }

  render() {
    const {
      placeholder,
      disable,
      questionId,
      errors,
      icon,
      buttonProps,
      children,
      isValid,
    } = this.props;
    const { value } = this.state;
    const disabled = disable || false;
    const hasError = !R.isNil(errors) && !R.isEmpty(errors);
    const inputProps = {
      hasError,
      validates: isValid,
      placeholder,
      onBlur: this.submitQuestion,
      onChange: this.onChange,
      onKeyUp: this.startSubmitTimer,
      onKeyDown: this.clearSubmitTimer,
      value,
      disabled,
      name: questionId,
      icon,
    };

    return (
      <QuestionContainer {...this.props}>
        <InputButton input={inputProps} button={buttonProps}>
          {children}
        </InputButton>
      </QuestionContainer>
    );
  }
}

InputQuestion.propTypes = {
  answerQuestion: PropTypes.func,
  placeholder: PropTypes.string,
  answer: PropTypes.string,
  initialValue: PropTypes.string,
  disable: PropTypes.bool,
  hasDateMask: PropTypes.bool,
  labelIcon: PropTypes.string,
};

export default InputQuestion;

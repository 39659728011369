import React, { PureComponent } from 'react';
import PaymentDetailsExtraTermsContainer from './PaymentDetailsExtraTermsContainer';

import QuestionContainer from '../../questions/QuestionContainer';
import { Radio, Radios } from '@simply-fin-services/astronomix3';

class PersalInceptionOptions extends PureComponent {

  render() {
    // Component knows how to feature toggle itself
    if (!this.props.isReplaceAndSaveEnabled) return <React.Fragment />;

    return (
      <React.Fragment>
        <h5>Policy cancellation</h5>

        {this.props.isAffordable && (
          <p>
            <strong>
              We note that you have capacity to deduct this premium from your
              salary
            </strong>
            , but we will delay the start date of the collection until you have
            cancelled the policies you are replacing (unless you select an
            immediate start date below).
          </p>
        )}

        {!this.props.isAffordable && (
          <p>
            <strong>
              We note that you do not currently have capacity to deduct this
              premium from your salary
            </strong>
            . We will delay the first collection on this policy to give you
            enough time to cancel your existing policies and free up capacity to
            deduct this premium from your salary.
          </p>
        )}

        <PaymentDetailsExtraTermsContainer paymentType="persal" />

        <QuestionContainer title="Choose the appropriate inception date">
          <Radios>
            {this.props.inceptionOptions.map((inceptionOption) => {
              const inceptionDate = inceptionOption['inception-date'];
              const salaryMonth = inceptionOption['salary-month'];
              const key = inceptionDate + salaryMonth;
              return (
                <Radio
                  key={key}
                  id={key}
                  label={inceptionOption.description}
                  value={inceptionDate}
                  checked={inceptionDate === this.props.inceptionDate}
                  onChange={() =>
                    this.props.setCollectionDates({
                      salaryMonth,
                      inceptionDate,
                    })
                  }
                />
              );
            })}
          </Radios>
        </QuestionContainer>
      </React.Fragment>
    );
  }
}

export default PersalInceptionOptions;

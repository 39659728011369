"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPackageDiscountRate = exports.isBrokerSale = exports.NO_DISCOUNT_SENDER_REFERENCES = exports.getRopReturn = exports.totalPremiumOverFiveYears = exports.PREMIUM_INCREASE_PER_YEAR = exports.ROP_YEARS = exports.ROP_PERCENTAGE = exports.getRopPremiumFromContract = exports.getDiscountedPremiumWithRop = exports.getDiscountedRopPremium = exports.getRopPremium = exports.getRopCost = exports.getPremium = exports.isNotAffordable = exports.getDiscount = exports.calculateExtrasCost = exports.selectedProductFilterImmutable = void 0;

var R = _interopRequireWildcard(require("ramda"));

var _immutable = require("immutable");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var premiumReducerImmutable = function premiumReducerImmutable(t, p) {
  return t + p.get('premium');
};

var premiumReducer = function premiumReducer(t, p) {
  return t + p.premium;
};

var selectedProductFilter = function selectedProductFilter(product) {
  return product.selected === true;
};

var selectedProductFilterImmutable = function selectedProductFilterImmutable(product) {
  return product.get('selected') === true;
};

exports.selectedProductFilterImmutable = selectedProductFilterImmutable;

var calculateProductCost = function calculateProductCost(productSelection) {
  if (_immutable.Iterable.isIterable(productSelection.first())) {
    return productSelection.filter(selectedProductFilterImmutable).reduce(premiumReducerImmutable, 0);
  }

  return productSelection.filter(selectedProductFilter).reduce(premiumReducer, 0);
};

var calculateExtrasCost = function calculateExtrasCost(extrasSelection) {
  if (_immutable.Iterable.isIterable(extrasSelection)) {
    return extrasSelection.reduce(function (v, extra) {
      return v + extra.get('price');
    }, 0);
  }

  if (!extrasSelection || R.isEmpty(extrasSelection)) {
    return 0;
  }

  return R.pipe(R.map(R.prop('price')), R.reduce(R.add, 0))(extrasSelection);
};

exports.calculateExtrasCost = calculateExtrasCost;

var getDiscount = function getDiscount(cost, discountRate) {
  return cost * (discountRate / 100);
};

exports.getDiscount = getDiscount;

var isNotAffordable = function isNotAffordable(income, premium) {
  return premium / income * 100 > 6;
};

exports.isNotAffordable = isNotAffordable;

var getPremium = function getPremium(productSelection, extrasSelection) {
  return R.add(calculateProductCost(productSelection), calculateExtrasCost(extrasSelection));
};

exports.getPremium = getPremium;

var getRopCost = function getRopCost(productSelection, productRopPriceMap) {
  return productSelection.map(function (p, k) {
    var premium = p.get('isExtendedFamily') ? p.get('insureds').map(function (i) {
      return {
        // the false clause of the ternary is for obelix, because the prices are not structured the same way
        premium: i.hasIn(['prices', p.get('productId'), "".concat(i.get('insuredAmount')), 'premiumWithRop']) ? i.getIn(['prices', p.get('productId'), "".concat(i.get('insuredAmount')), 'premiumWithRop']) : i.getIn(['prices', 'premiumWithRop'])
      };
    }).reduce(premiumReducer, 0) : productRopPriceMap.getIn([k, "".concat(p.get('insuredAmount'))]);
    return {
      premium: premium
    };
  }).reduce(premiumReducer, 0);
};

exports.getRopCost = getRopCost;

var getRopPremium = function getRopPremium(selectedProducts, productRopPriceMap, packageExtras) {
  var premium = getPremium(selectedProducts, packageExtras);
  var premiumWithRop = getRopCost(selectedProducts, productRopPriceMap);
  return premiumWithRop - premium;
};

exports.getRopPremium = getRopPremium;

var getDiscountedRopPremium = function getDiscountedRopPremium(selectedProducts, productRopPriceMap, packageExtras, discountRate) {
  var ropPremium = getRopPremium(selectedProducts, productRopPriceMap, packageExtras);
  var ropDiscount = getDiscount(ropPremium, discountRate);
  return ropPremium - ropDiscount;
};

exports.getDiscountedRopPremium = getDiscountedRopPremium;

var getDiscountedPremiumWithRop = function getDiscountedPremiumWithRop(selectedProducts, productRopPriceMap, packageExtras, discountRate) {
  var premium = getPremium(selectedProducts, packageExtras);
  var discountedPremium = premium - getDiscount(premium, discountRate);
  var discountedRopPremium = getDiscountedRopPremium(selectedProducts, productRopPriceMap, packageExtras, discountRate);
  return discountedPremium + discountedRopPremium;
};

exports.getDiscountedPremiumWithRop = getDiscountedPremiumWithRop;

var getRopPremiumFromContract = function getRopPremiumFromContract(contract, productOptionNameSuffix) {
  return R.pipe(R.prop('Policies'), R.filter(function (p) {
    return p.ProductOptionName.endsWith(productOptionNameSuffix);
  }), R.map(R.prop('Premium')), R.reduce(R.add, 0))(contract);
};

exports.getRopPremiumFromContract = getRopPremiumFromContract;
var ROP_PERCENTAGE = 50;
exports.ROP_PERCENTAGE = ROP_PERCENTAGE;
var ROP_YEARS = 5;
exports.ROP_YEARS = ROP_YEARS;
var PREMIUM_INCREASE_PER_YEAR = 1.1; // -ru packages premiums increase at 1.1 per year, and cover at 1.05 per year.

exports.PREMIUM_INCREASE_PER_YEAR = PREMIUM_INCREASE_PER_YEAR;
var MONTHS_IN_YEAR = 12;

var totalPremiumOverFiveYears = function totalPremiumOverFiveYears(premium) {
  return premium * MONTHS_IN_YEAR + premium * PREMIUM_INCREASE_PER_YEAR * MONTHS_IN_YEAR + premium * Math.pow(PREMIUM_INCREASE_PER_YEAR, 2) * MONTHS_IN_YEAR + premium * Math.pow(PREMIUM_INCREASE_PER_YEAR, 3) * MONTHS_IN_YEAR + premium * Math.pow(PREMIUM_INCREASE_PER_YEAR, 4) * MONTHS_IN_YEAR;
};

exports.totalPremiumOverFiveYears = totalPremiumOverFiveYears;

var getRopReturn = function getRopReturn(premium, ropPercentage) {
  return totalPremiumOverFiveYears(premium) * (ropPercentage / 100);
};

exports.getRopReturn = getRopReturn;
var NO_DISCOUNT_SENDER_REFERENCES = ['OOBA']; // refactor this and put it in a common place in dogmatix

exports.NO_DISCOUNT_SENDER_REFERENCES = NO_DISCOUNT_SENDER_REFERENCES;

var isNoDiscountSenderReference = function isNoDiscountSenderReference(_ref) {
  var senderReference = _ref.senderReference;
  return R.is(String, senderReference) && R.includes(R.toUpper(senderReference), NO_DISCOUNT_SENDER_REFERENCES);
};

var isBrokerSale = function isBrokerSale(_ref2) {
  var brokerCode = _ref2.brokerCode;
  return R.not(R.or(R.isEmpty(brokerCode), R.isNil(brokerCode)));
};

exports.isBrokerSale = isBrokerSale;
var NO_DISCOUNT_SCENARIOS = [isBrokerSale, isNoDiscountSenderReference];

var shouldNotHaveDiscount = function shouldNotHaveDiscount(saleDetails) {
  return R.pipe(R.map(function (f) {
    return f(saleDetails);
  }), R.reduce(R.or, false))(NO_DISCOUNT_SCENARIOS);
};

var allProductDiscount = function allProductDiscount(selectedPackageConfig, discounts, productSelection) {
  var selectedCount = _immutable.Iterable.isIterable(productSelection.valueSeq()) ? productSelection.valueSeq().filter(function (p) {
    return selectedProductFilterImmutable(p) && !p.get('isExtendedFamily');
  }).count() : R.filter(function (p) {
    return selectedProductFilterImmutable(p) && !p.get('isExtendedFamily');
  }, productSelection.valueSeq()).length();

  if (selectedPackageConfig.get('products').count() === selectedCount) {
    return discounts.getIn([selectedPackageConfig.get('packageDiscount'), 'discountRate']);
  }

  return 0;
};

var PACKAGE_DISCOUNT_FUNCTIONS = {
  'all-products-discount': allProductDiscount
};

var getPackageDiscountFunction = function getPackageDiscountFunction(discountType) {
  return R.prop(discountType, PACKAGE_DISCOUNT_FUNCTIONS) || R.always(0);
};

var getPackageDiscountRate = function getPackageDiscountRate(saleDetails) {
  var productSelection = saleDetails.productSelection,
      selectedPackageConfig = saleDetails.selectedPackageConfig,
      discounts = saleDetails.discounts;

  if (!productSelection || shouldNotHaveDiscount(saleDetails)) {
    return 0;
  }

  var discountFunction = getPackageDiscountFunction(selectedPackageConfig.get('packageDiscount'));
  return discountFunction(selectedPackageConfig, discounts, productSelection);
};

exports.getPackageDiscountRate = getPackageDiscountRate;
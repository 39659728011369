import { fromJS } from 'immutable';
import {
  LINK_BROKER_CODE_SUCCESS,
  SET_BROKER_PORTAL_FLAG,
  SET_OTP_SMS_TEMPLATE,
  SET_BROKER_CONFIG,
  WILL_SEND_RECORD_OF_ADVICE_OTP,
} from '../actions/types';

const initialState = fromJS({
  brokerCode: '',
});

export default (state = initialState, action) => {
  if (action.type === LINK_BROKER_CODE_SUCCESS) {
    return state.merge(action.payload);
  }
  if (action.type === SET_BROKER_PORTAL_FLAG) {
    return state.set('fromBrokerPortal', action.payload.fromBrokerPortal);
  }
  if (action.type === SET_OTP_SMS_TEMPLATE) {
    return state.set('otpSmsTemplate', action.payload.template);
  }
  if (action.type === SET_BROKER_CONFIG) {
    return state.merge(action.payload);
  }
  if (action.type === WILL_SEND_RECORD_OF_ADVICE_OTP) {
    return state.set('willSendRecordOfAdviceWithOtp', true);
  }
  return state;
};

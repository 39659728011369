import { connect } from 'react-redux';
import { isReplaceAndSaveEnabled } from '../../../stateStore/replaceAndSave';
import { hasTermsConfig } from '../../../stateStore/terms';

import PaymentDetailsExtraTerms from './PaymentDetailsExtraTerms';

export const mapStateToProps = (state, ownProps) => {
  const termsId = `replace-and-save-policy-payment-terms-${ownProps.paymentType}`;

  const hasConfig = hasTermsConfig(state, termsId);

  return {
    isReplaceAndSaveEnabled: isReplaceAndSaveEnabled(state),
    hasPaymentTermsConfig: hasConfig,
    showGeneralPaymentTerms: !hasConfig,
    termsId,
  };
};

export const mapDispatchToProps = () => ({});

const PaymentDetailsExtraTermsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDetailsExtraTerms);

export default PaymentDetailsExtraTermsContainer;

import React, { PureComponent } from 'react';

const encodeUri = t => {
  if (typeof window === 'undefined') {
    return t;
  }
  return window.encodeURI(t);
};

export default class mailTo extends PureComponent {
  constructor() {
    super();
    this.next = this.next.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = { body: '' };
  }

  next() {
    const { subject, next, to } = this.props;
    const { body } = this.state;
    const stext = encodeUri(subject);
    const text = encodeUri(body);
    const addresses = encodeURI(to.toString());
    const mailToLink = `mailto:?to=${addresses}&subject=${stext}&cc=referrals@simply.co.za&body=${text}`;
    window.location = mailToLink;
    next();
  }

  onChange(e) {
    const body = e.target.value;
    this.setState({ body });
  }

  componentWillMount() {
    const { placeholder } = this.props;
    this.setState({ body: placeholder });
  }

  render() {
    const { to } = this.props;
    const friendCountMsg =
      to.length > 1
        ? `invites to ${to.length} friends`
        : 'invite to your friend';
    return (
      <div className="row mail-to">
        <div className="col-md-6 col-md-offset-3" id="form_container">
          <h3>Sending {friendCountMsg} </h3>
          <br />
          <p>What would you like your message to be ?</p>
          <br />
          <form role="form" method="post" id="reused_form">
            <div className="row">
              <div className="col-sm-12 form-group">
                <textarea
                  className="form-control"
                  type="textarea"
                  name="message"
                  maxLength="6000"
                  rows="7"
                  onChange={this.onChange}
                  value={this.state.body}
                />
              </div>
            </div>
            <div className="row hidden">
              <div className="col-sm-6 form-group">
                <label htmlFor="name">Your Name:</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  required
                />
              </div>
              <div className="col-sm-6 form-group hidden">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-xs-12 form-group">
                <button
                  type="submit"
                  className="btn col-xs-12 col-xs-12 btn-lg btn-finish"
                  onClick={this.next}
                >
                  Complete with email client →
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IPropTypes from 'react-immutable-proptypes';
import { QuestionPageQuestions } from '../questionnaire/QuestionPage';
import { connect } from 'react-redux';
import { saveReferral, deleteReferral } from '../../actions/referral';
import processAnswer from '../../.shared/processAnswer';
import {
  walkQuestionPageGivenAnswers as walkPage,
  isPageValidGivenAnswers as isPageValid,
} from '../questionnaire/toQuestionPageProps';
import { fromJS } from 'immutable';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import scroll, { scroller, Element as ScrollToElement } from 'react-scroll';
import { isMobile } from 'react-device-detect';
import { SecondaryButton, Buttons } from '@simply-fin-services/astronomix3';

const scrollTo = (id) =>
  scroller.scrollTo(id, {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
  });

const scrollMore = (d) => scroll.animateScroll.scrollMore(d);

const getIdRowIndex = (id, items) => R.findIndex(R.propEq('id', id))(items);

class Referral extends PureComponent {
  constructor(props) {
    super(props);
    this.reset = this.reset.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.buildQuestionPage = this.buildQuestionPage.bind(this);
    this.generateQuestionPageConfig =
      this.generateQuestionPageConfig.bind(this);
    this.addQuestionPage = this.addQuestionPage.bind(this);
    this.updateSubmitState = this.updateSubmitState.bind(this);
    this.generateAnswerFunction = this.generateAnswerFunction.bind(this);
    this.getAnswers = this.getAnswers.bind(this);
    this.saveAnswerToState = this.saveAnswerToState.bind(this);
    this.saveAnswersToAppState = this.saveAnswersToAppState.bind(this);
    this.state = {
      isRefereshing: false,
      questionPages: [],
      savedAnswers: {},
      hasSubmittedQuestionPage: R.F(),
      canSubmitPage: false,
    };
  }

  getAnswers(id) {
    const { savedAnswers } = this.state;
    return R.isNil(savedAnswers[id]) ? fromJS({}) : fromJS(savedAnswers[id]);
  }

  saveAnswersToAppState(questionPageId, answer) {
    const { saveToAppState } = this.props;
    return new Promise((resolve) =>
      resolve(saveToAppState(R.assoc(questionPageId, answer, {})))
    );
  }

  saveAnswerToState(questionPageId, answer) {
    const noAnswersSavedYet = R.isNil(this.state.savedAnswers);
    const currentAnswers = noAnswersSavedYet ? {} : this.state.savedAnswers;
    this.setState({
      savedAnswers: R.assoc(questionPageId, answer, currentAnswers),
    });
    this.saveAnswersToAppState(questionPageId, answer);
  }

  generateAnswerFunction(questionPageId) {
    return (questionId) => (answer) => {
      const { questions } = this.props;
      const { savedAnswers } = this.state;
      const validatedAnswer = processAnswer(questions, questionId, answer);
      const isFirstSave = R.isNil(R.prop(questionPageId, savedAnswers));
      const currentAnswer = isFirstSave
        ? {}
        : R.prop(questionPageId, savedAnswers);
      const { isValid, errors } = validatedAnswer;
      this.saveAnswerToState(
        questionPageId,
        R.assoc(questionId, { answer, isValid, errors }, currentAnswer)
      );
    };
  }

  generateQuestionPageConfig(id, pageQuestions) {
    const validateable = { answer: '', isValid: R.F(), errors: [] };
    const valdateableArray = R.repeat(validateable, pageQuestions.size);
    const initialAnswers = R.zipObj(pageQuestions.toJS(), valdateableArray);
    const questionPageConfigs = R.append(
      { id, pageQuestions },
      this.state.questionPageConfigs
    );
    this.setState({ questionPageConfigs });
    return new Promise((resolve) => {
      this.saveAnswersToAppState(id, initialAnswers).then(() => resolve());
    });
  }

  buildQuestionPage(id, questions, answers, pageQuestions) {
    return (
      <div key={id}>
        <QuestionPageQuestions
          questions={questions}
          answers={answers}
          pageQuestions={pageQuestions}
          answerQuestion={this.generateAnswerFunction(id)}
          fieldContainerClass="col-xs-12 inner-space-around-none"
          fieldClass="no-max-width"
        />
      </div>
    );
  }

  addQuestionPage() {
    const id = uuidv4();
    const { pageQuestions } = this.props;
    this.generateQuestionPageConfig(id, pageQuestions).then(() => {
      const { questionPageConfigs } = this.state;
      const hasAtleastFiveRows = questionPageConfigs.length >= 5;
      const rowIndex = getIdRowIndex(id, questionPageConfigs);
      const isFirstPage = rowIndex < 1;
      if (!isFirstPage && isMobile) {
        scrollTo(id);
      }
      const scrollFactor = questionPageConfigs.length > 10 ? 7.5 : 15;
      if (hasAtleastFiveRows && !isMobile) {
        scrollMore(questionPageConfigs.length * scrollFactor);
      }
    });
  }

  onSubmit() {
    const { submit, answers, type } = this.props;
    const submitAnswers = R.prop('onSubmit', submit);
    const pageAnswers = answers.get(type);
    submitAnswers(pageAnswers, type);
    this.setState({ hasSubmittedQuestionPage: R.T() });
  }

  reset() {
    this.setState({ hasSubmittedQuestionPage: R.F() });
    this.setState({ savedAnswers: {}, canSubmitPage: false });
  }

  updateSubmitState() {
    const { canSubmitPage } = this.props;
    this.setState({ canSubmitPage });
  }

  componentWillMount() {
    this.addQuestionPage();
  }

  componentDidUpdate() {
    if (this.state.hasSubmittedQuestionPage) {
      this.updateSubmitState();
    }
  }

  render() {
    const { questions, type, answers, submit } = this.props;
    const {
      isRefereshing,
      questionPageConfigs,
      hasSubmittedQuestionPage,
      savedAnswers,
    } = this.state;
    const pageAnswers = answers.get(type).toJS();
    const { next } = submit;
    const submitSuccessComponent = <div> {next(pageAnswers, this.reset)} </div>;
    const canSubmit = R.isEmpty(savedAnswers)
      ? this.state.canSubmitPage
      : this.props.canSubmitPage;
    return (
      <div className="referral-selector">
        {!isRefereshing && !hasSubmittedQuestionPage && (
          <div>
            {questionPageConfigs.map((pageConfig) => {
              const { id, pageQuestions } = pageConfig;
              const buildAnswers = this.getAnswers(id);
              return (
                <ScrollToElement className={id} name={id}>
                  <div id="questiopage">
                    {this.buildQuestionPage(
                      id,
                      questions,
                      buildAnswers,
                      pageQuestions
                    )}
                  </div>
                </ScrollToElement>
              );
            })}
            <Buttons>
              <SecondaryButton onClick={this.onSubmit} disabled={!canSubmit}>
                {submit.button.text}
              </SecondaryButton>
            </Buttons>
          </div>
        )}
        {hasSubmittedQuestionPage && <div>{submitSuccessComponent}</div>}
      </div>
    );
  }
}

Referral.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  submit: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
  }).isRequired,
  referralCode: PropTypes.string.isRequired,
  answers: IPropTypes.map.isRequired,
  packageReferrals: PropTypes.arrayOf(
    PropTypes.shape({
      packageId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  type: PropTypes.string.isRequired,
  questions: IPropTypes.map.isRequired,
  pageQuestions: IPropTypes.listOf(PropTypes.string).isRequired,
};

export const mapStateToProps = (state, ownProps) => {
  const type = `referrals-${ownProps.type}`;
  const questionPage = state.getIn(['questionPages', type]);
  const pageQuestions = questionPage.get('questions');
  const optionalPageQuestions = questionPage.get('optionalQuestions');
  const answers = state.getIn(['referral', 'answers']);
  const isValid = (validation) => validation === true;
  const pageValidation = answers.get(ownProps.type).map((page) =>
    R.head(
      page
        .get('answers')
        .map((ans) =>
          isPageValid(
            walkPage(state, pageQuestions, optionalPageQuestions, ans),
            ans
          )
        )
        .toJS()
    )
  );
  const canSubmitPage = R.reduceWhile(
    isValid,
    (prev, cur) => cur === true,
    true,
    R.values(pageValidation.toJS())
  );

  return {
    referralCode: state.getIn(['referralSignup', 'referralCode']),
    questions: state.get('questions'),
    type: ownProps.type,
    pageQuestions,
    canSubmitPage,
    answers,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  saveToAppState: (referrals) =>
    dispatch(saveReferral(ownProps.type, referrals)),
  deletePageEntryFromReferralAnswers: (entryId) =>
    dispatch(deleteReferral(ownProps.type, entryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Referral);

import React from 'react';
import { Spinner } from '@simply-fin-services/astronomix3';
import { 
  toShortPremium
} from '../common/numberFormats';
import styled from 'styled-components';

export const centeredSpinner = () => (
  <div className="text-center">
    <Spinner width="40px" />
  </div>
);

export const RightAlignTablewHeader = styled.th`
  text-align: right !important;
`;

export const RightAlignTableCell = styled.td`
  text-align: right;
`;

export const pluralisePolicyWording = (totalReplacementPolicies) =>
  totalReplacementPolicies === 1 ? 'policy' : 'policies';

export const pluraliseCostWording = (totalReplacementPolicies) =>
  totalReplacementPolicies === 1 ? 'costs' : 'cost';

export const replacementPolicySummarySentence = ({
  totalReplacementPolicies,
  totalMonthlyPremium,
}) => (
  <div className="text-center">
    This quote is for replacing {totalReplacementPolicies} funeral{' '}
    {pluralisePolicyWording(totalReplacementPolicies)} that currently{' '}
    {pluraliseCostWording(totalReplacementPolicies)} a total of{' '}
    {toShortPremium(totalMonthlyPremium)} per month.
  </div>
);

export const replacementPolicyCustomisationPageSentence = ({ initialPremiumSaving }) => {
  const premiumToShow = initialPremiumSaving < 0 ? 0 : initialPremiumSaving;

  return (
    <div className="text-center">
      <strong>PREMIUM SAVING:</strong> The initial premium saving for this
      replacement policy quote is {toShortPremium(premiumToShow)} per month.
    </div>
  );
};

export const replacementPolicyCoverExceedsMaxCover = () => (
  <div className="text-center">
    <strong>NOTE:</strong> One or more of the insured lives will have less cover
    than the total amount they had under the current policies, due to the cover
    limits on this policy.
  </div>
);

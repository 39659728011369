import { connect } from 'react-redux';
import FeaturesPage from './FeaturesPage';
// import getExtrasForPackage
//   from '../../../server/.dogmatix/domain/productCatalogue/getExtrasForPackage';

const toggleFeature = isSelected => console.log(isSelected); // eslint-disable-line no-console

export const mapStateToProps = state => {
  // const extras = getExtrasForPackage(state.get('selectedPackageSlug')).toJS();
  const packageSlug = state.get('selectedPackageSlug');
  return {
    extras: [],
    packageSlug,
  };
};

export const mapDispatchToProps = () => ({
  toggleFeature,
});

const Features = connect(mapStateToProps)(FeaturesPage);

export default Features;

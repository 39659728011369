import * as types from './types';
import newId from '../common/newId';
import { setInteractionIdOnRequest } from '../common/request';
import {
  linktoCampaign,
  handleSenderReferenceAndLinkCampaign,
  saveUTMProps,
  UTM_PROPS_LIST,
} from './campaign';
import { linkExistingReferrerCode } from './referrerLinking';
import {
  setFromBrokerPortal,
  linkExistingBrokerCode,
  linkBroker,
  setBrokerConfig,
} from './brokerLinking';
import {setReplaceAndSaveEnabled} from './replaceAndSave';
import * as R from 'ramda';
import { extractQueryParams } from '../common/propsToLocationQueryParams';
import { setContactDetailsPopupFlag } from './contactDetailsPopup';
import { setSenderReference } from './senderReference';
import {
  setPaymentType,
  setIemasSupport,
  supportsIemasSalaryDeductionPaymentType,
  onlyDebitOrderPaymentTypeSupported,
} from './paymentType';
import { willSendRecordOfAdviceWithOtp } from './Otp';

import { getBrokerConfig } from '../common/brokers';
import { firstAvailablePaymentMethodType, isAllowedPaymentMethod } from '../common/saleOptions';

export const setInteractionId = (interactionId) => {
  const type = types.SET_INTERACTION_ID;
  const payload = interactionId;
  return {
    type,
    payload,
  };
};

export const dispatchNewInteractionId = (dispatch, interactionId) => {
  setInteractionIdOnRequest(interactionId);
  dispatch(setInteractionId(interactionId));
};

const startUpActions = (dispatch, getState) => {
  const queryParams = extractQueryParams();
  const interactionId =
    queryParams.interactionId || getState().get('interactionId') || newId();
  const hasUTMProps = R.anyPass(R.map(R.has, UTM_PROPS_LIST));
  dispatchNewInteractionId(dispatch, interactionId);

  if (queryParams.senderReference) {
    handleSenderReferenceAndLinkCampaign(dispatch, queryParams);
    dispatch(setSenderReference(queryParams.senderReference));
  } else {
    dispatch(linktoCampaign());
  }

  if (!queryParams.senderReference && hasUTMProps(queryParams)) {
    dispatch(saveUTMProps(queryParams));
  }

  if (queryParams.fromBrokerPortal) {
    dispatch(setFromBrokerPortal(queryParams));
  }

  // If this querystring is present it means the "replace & save" feature is enabled (feature toggled on) and will show replace and save as the intial page / home page (otherwise the initial quote questions page will show)
  if (queryParams.replaceAndSave) {
    dispatch(setReplaceAndSaveEnabled(interactionId));
  }

  if (queryParams.brokerCode) {
    dispatch(linkBroker(queryParams.brokerCode, queryParams.senderReference));
  } else {
    dispatch(linkExistingBrokerCode());
  }

  if (queryParams.recordOfAdvice) {
    dispatch(willSendRecordOfAdviceWithOtp());
  }

  dispatch(linkExistingReferrerCode());
  setContactDetailsPopupFlag(queryParams, dispatch);

  if (queryParams.supportsIemasSalaryDeduction === 'true') {
    dispatch(supportsIemasSalaryDeductionPaymentType());
  } else {
    dispatch(onlyDebitOrderPaymentTypeSupported());
  }

  if (queryParams.fromBrokerPortal === 'true') {
    const state = getState();
    const brokerConfig = getBrokerConfig(state);
    if (!R.isNil(brokerConfig)) {
      const { supportsIemasSalaryDeduction } = brokerConfig;
      const paymentType = isAllowedPaymentMethod(state, brokerConfig.paymentType) ? brokerConfig.paymentType : firstAvailablePaymentMethodType(state);
      dispatch(setIemasSupport(supportsIemasSalaryDeduction));
      dispatch(setPaymentType(paymentType));
      dispatch(
        setBrokerConfig(
          R.omit(['paymentType', 'supportsIemasSalaryDeduction'], brokerConfig)
        )
      );
    }
  }
};

export const startup = () => {
  const type = types.STARTUP;
  const payload = startUpActions;
  return {
    type,
    payload,
  };
};

const dispatchResetActions = (dispatch) => {
  dispatchNewInteractionId(dispatch, newId());
};

export const reset = () => {
  const type = types.RESET;
  return {
    type,
    payload: dispatchResetActions,
  };
};

// This reducer is required to make react-router-redux work with immutable.js
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  SHOW_CALL_ME_BACK,
  SAVE_CALL_ME_BACK_DETAILS,
  SAVE_CALL_ME_BACK_DETAILS_FAILURE,
  SAVE_CALL_ME_BACK_DETAILS_SUCCESS,
  OPEN_CALL_ME_BACK_MENU,
  CLOSE_CALL_ME_BACK_MENU,
} from '../actions/types';
import { fromJS } from 'immutable';
import * as R from 'ramda';
import createActionHandler from './createActionHandler';

const initialState = fromJS({
  callMeBackVisible: false,
  isSaving: false,
  success: false,
  menuOpen: false,
});

const actionHandlers = {};

const setLoading = R.curry((isSaving, state) =>
  state.set('isSaving', isSaving)
);

const resetShowCallMeBack = state =>
  state.merge({
    callMeBackVisible: false,
    isSaving: false,
    success: false,
  });

const showCallMeBack = state => state.set('callMeBackVisible', true);

const openMenu = state => state.set('menuOpen', true);

const closeMenu = state => state.set('menuOpen', false);

const success = state =>
  state.merge({
    callMeBackVisible: false,
    isSaving: false,
    success: true,
  });

const failure = state =>
  state.merge({
    callMeBackVisible: false,
    isSaving: false,
    success: false,
  });

actionHandlers[LOCATION_CHANGE] = resetShowCallMeBack;
actionHandlers[SHOW_CALL_ME_BACK] = showCallMeBack;
actionHandlers[SAVE_CALL_ME_BACK_DETAILS] = setLoading(true);
actionHandlers[SAVE_CALL_ME_BACK_DETAILS_FAILURE] = failure;
actionHandlers[SAVE_CALL_ME_BACK_DETAILS_SUCCESS] = success;
actionHandlers[OPEN_CALL_ME_BACK_MENU] = openMenu;
actionHandlers[CLOSE_CALL_ME_BACK_MENU] = closeMenu;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import IPropTypes from 'react-immutable-proptypes';
import AccountNumber from './AccountNumber';
import Otp from './Otp';
import thirdPartyOtp from './thirdPartyOtp';
import BranchCode from './BranchCode';
import AccountType from './AccountType';
import SelectDebitDay from './SelectDebitDay';
import SelectDebitMonth from './SelectDebitMonth';
import PaymentDetailsExtraTermsContainer from '../../views/replaceAndSave/integration/PaymentDetailsExtraTermsContainer';
import {
  validateDebitDay,
  validateAccountType,
  validateBranchCode,
  validateAccountNumber,
  validateInceptionMonth,
  getAccountValidatorForBranch,
} from '../../.shared/paymentDetails/bankingDetails';
import {
  Form,
  FieldContainer,
  Icon,
  Block as AstroBlock,
  Spinner,
} from '@simply-fin-services/astronomix3';
import {
  isEmpty,
  isNil,
  mapObjIndexed,
  whereEq,
  pick,
  reduceWhile,
  values,
} from 'ramda';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  getAccountVerfification,
  pollAccountVerfification,
} from '../../actions/avsr';
import BrokerCompliance from './brokerCompliance';
const Block = styled(AstroBlock)`
  &,
  li {
    color: #888;
  }
  ul {
    margin-bottom: 0 !important;
    &.undecorated {
      margin-left: 0 !important;
      list-style: none;
      li {
        padding: 0 !important;
      }
    }
  }
`;

const toControl = (key, Control, details, answerQuestion) => {
  const props = details.get(key) ? details.get(key).toJS() : {};
  return (
    <FieldContainer>
      <Control
        answerQuestion={answerQuestion(key)}
        {...props}
        answer={details.getIn([key, 'value'])}
        details={details}
        validateAndAnswerQuestion={answerQuestion}
        questionId={key}
        form="debit_order"
      />
    </FieldContainer>
  );
};

const isNotNilOrEmpty = (i) => !isNil(i) && !isEmpty(i);
const currentValidationIsTrue = (v) => v === true;
const getNextDetailValidation = (v, detail) => detail.isValid === true;
const checkIfBankingDetailsAreValid = (details) =>
  reduceWhile(currentValidationIsTrue, getNextDetailValidation, true, details);

const accountVerfificationMessages = ({ state, retry, poll }) => {
  const {
    busy,
    bankingDetailsVerified,
    shouldFixIssues,
    shouldRetry,
    messages,
    hasWarnings,
    isTakingTooLong,
  } = state.toJS();
  return (
    <div>
      {!busy && bankingDetailsVerified && (
        <Block display="flex" alignItems="center" gap="0.25rem">
          <small>
            <span className="text-secondary">Account verified. </span>{' '}
          </small>
          <Icon className="text-secondary" icon="check" size="20px" />
        </Block>
      )}

      {busy && (
        <Block display="flex" alignItems="center" gap="0.25rem">
          <small>Verifying your account details, please be patient.</small>
          <Spinner width="20px" margin="5px" />
        </Block>
      )}

      {isTakingTooLong && (
        <Block
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="0.25rem"
        >
          <small>
            {messages.map((m) => (
              <span>{m}</span>
            ))}
            <br />
            <a onClick={() => poll()}>Retry verification</a>
          </small>
        </Block>
      )}

      {isNotNilOrEmpty(messages) && !busy && hasWarnings && (
        <Block
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="0.25rem"
        >
          <small>
            {shouldRetry && <a onClick={() => retry()}>Retry verification</a>}
          </small>
          <small className="alert alert-warning">
            You may continue as is, but should address these issues if you can:
            <ul>
              {messages.map((m) => (
                <li style={{ "line-height": "2em", "padding-left": "0em" }}>
                  <small style={{ "color": "#664d03" }}>{m}</small>
                </li>
              ))}
            </ul>
          </small>
        </Block>
      )}

      {isNotNilOrEmpty(messages) && !busy && !hasWarnings && (
        <Block
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="0.25rem"
        >
          {shouldRetry && !shouldFixIssues && (
            <small>
              {messages.map((m) => (
                <span style={{ "color": "#E67E22" }}>{m}</span>
              ))}
              <br />
              <a onClick={() => retry()}>Retry verification</a>
            </small>
          )}

          {shouldFixIssues && (
            <Fragment>
              <small className="alert alert-danger">
                Please address these issues before continuing:
                <ul>
                  {messages.map((m) => (
                    <li style={{ "line-height": "2em", "padding-left": "0em" }}>
                      <small style={{ "color": "#842029" }}>{m}</small>
                    </li>
                  ))}
                </ul>
              </small>
            </Fragment>
          )}
        </Block>
      )}
    </div>
  );
};

class PaymentDetails extends PureComponent {
  constructor() {
    super();
    this.toggleAcceptance = this.toggleAcceptance.bind(this);
    this.verifyBankingDetails = this.verifyBankingDetails.bind(this);
    this.pollVerification = this.pollVerification.bind(this);
    this.state = {
      isAccepted: false,
    };
  }

  toggleAcceptance() {
    this.setState({ isAccepted: !this.state.isAccepted });
  }

  verifyBankingDetails(props) {
    const accountNumber = props.details.getIn(['accountNumber', 'value']);
    const branchCode = props.details.getIn(['branchCode', 'value']);
    const accountType = props.details.getIn(['accountType', 'value']);
    this.props.verifyAccount({ branchCode, accountNumber, accountType });
  }

  pollVerification() {
    const jobId = this.props.verificationState.get('jobId');
    this.props.pollVerification({ jobId });
  }

  componentWillReceiveProps(nextProps) {
    const newDetails = mapObjIndexed((v) => v.value, nextProps.details.toJS());
    const currentDetails = mapObjIndexed(
      (v) => v.value,
      this.props.details.toJS()
    );
    const detailsHaveChanged = !whereEq(currentDetails, newDetails);
    const keysThatGetVerified = ['branchCode', 'accountNumber', 'accountType'];
    const detailsNeededForVerification = values(
      pick(keysThatGetVerified, nextProps.details.toJS())
    );
    const newDetailsAreValid = checkIfBankingDetailsAreValid(
      detailsNeededForVerification
    );

    if (detailsHaveChanged && newDetailsAreValid) {
      this.verifyBankingDetails(nextProps);
    }
  }

  render() {
    const {
      answerQuestion,
      details,
      requiresOTP,
      isBrokerSale,
      requiresThirdPartyOTP,
    } = this.props;
    const validator = getAccountValidatorForBranch(
      this.props.details.getIn(['branchCode', 'value'])
    );
    const answerDebitDay = answerQuestion(validateDebitDay);
    const answerInceptionMonth = answerQuestion(
      validateInceptionMonth(new Date())
    );
    const answerAccountNumber = answerQuestion(
      validateAccountNumber(validator)
    );
    const answerAccountType = answerQuestion(validateAccountType);
    const answerBranchCode = answerQuestion(validateBranchCode);
    return (
      <Form>
        {isBrokerSale &&
          toControl(
            'inceptionMonth',
            SelectDebitMonth,
            details,
            answerInceptionMonth
          )}
        {toControl('debitDay', SelectDebitDay, details, answerDebitDay)}
        {toControl('branchCode', BranchCode, details, answerBranchCode)}
        {toControl('accountType', AccountType, details, answerAccountType)}
        {toControl(
          'accountNumber',
          AccountNumber,
          details,
          answerAccountNumber
        )}
        {accountVerfificationMessages({
          state: this.props.verificationState,
          retry: () => this.verifyBankingDetails(this.props),
          poll: () => this.pollVerification(),
        })}

        <PaymentDetailsExtraTermsContainer paymentType="debit-order" />

        <BrokerCompliance type="debit-order" />
        {requiresOTP && toControl('otp', Otp, details, answerQuestion)}
        {requiresThirdPartyOTP &&
          toControl('thirdPartyOtp', thirdPartyOtp, details, answerQuestion)}
      </Form>
    );
  }
}

PaymentDetails.propTypes = {
  isThirdPartyPackage: PropTypes.bool,
  isBrokerSale: PropTypes.bool,
  isQaSale: PropTypes.bool,
  answerQuestion: PropTypes.func.isRequired,
  withVerification: PropTypes.bool,
  details: IPropTypes.mapContains({
    debitDay: PropTypes.object.isRequired,
    accountNumber: PropTypes.object.isRequired,
    branchCode: PropTypes.object.isRequired,
    accountType: PropTypes.object.isRequired,
    inceptionMonth: PropTypes.object.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  verificationState: state.get('avsr'),
});

const mapDispatchToProps = (dispatch) => ({
  verifyAccount: (details) => dispatch(getAccountVerfification(details)),
  pollVerification: (details) => dispatch(pollAccountVerfification(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);

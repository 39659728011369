import {
  SHOW_CALL_ME_BACK,
  SAVE_CALL_ME_BACK_DETAILS,
  SAVE_CALL_ME_BACK_DETAILS_SUCCESS,
  SAVE_CALL_ME_BACK_DETAILS_FAILURE,
  OPEN_CALL_ME_BACK_MENU,
  CLOSE_CALL_ME_BACK_MENU,
} from './types';
import { getSelectedPackageIdOrDefault } from '../common/getPackageIdFromSlug';
import { API_ROUTES, ROUTES } from '../config/constants';
import newId from '../common/newId';
import * as R from 'ramda';
import request from 'axios';
import { push } from 'connected-react-router';

export const showCallMeBack = () => {
  const type = SHOW_CALL_ME_BACK;
  return {
    type,
  };
};

export const saveCallMeBackDetailsSuccess = (state, details) => {
  const type = SAVE_CALL_ME_BACK_DETAILS_SUCCESS;
  const packageId = getSelectedPackageIdOrDefault(state);
  const payload = {
    packageId,
  };
  return {
    type,
    payload,
    meta: details,
  };
};
export const saveCallMeBackDetailsFailure = err => {
  const type = SAVE_CALL_ME_BACK_DETAILS_FAILURE;
  const payload = err;
  return {
    type,
    payload,
  };
};

const formatAnswers = answer => ({
  questionId: answer.get('questionId'),
  answer: answer.get('answer'),
});

const getValidOrNewInteractionId = interactionId => {
  if (R.includes('Website', interactionId)) {
    return newId();
  }
  return interactionId;
};

const toInteractionData = state => ({
  answers: state
    .get('answers')
    .map(formatAnswers)
    .toArray(),
  interactionId: getValidOrNewInteractionId(state.get('interactionId')),
  referrer: { referrerCode: state.get('referrerCode') },
});

export const openCallmeBackMenu = () => {
  const type = OPEN_CALL_ME_BACK_MENU;
  return {
    type,
  };
};

export const closeCallmeBackMenu = () => {
  const type = CLOSE_CALL_ME_BACK_MENU;
  return {
    type,
  };
};

const customCallMeBackRouteAction = (dispatch, currentRoute) => {
  const customRoutes = {
    '/banking-details/simply-family-combo': 'SAVE_CALL_ME_BACK_DETAILS_BANK',
    '/banking-details/simply-employer-combo': 'SAVE_CALL_ME_BACK_DETAILS_BANK',
  };
  const type = customRoutes[currentRoute];
  if (type) {
    dispatch({ type });
  }
};

const postCallMeBackDetails = details => (dispatch, getState) => {
  const state = getState();
  const currentRoute = state.getIn([
    'routing',
    'locationBeforeTransitions',
    'pathname',
  ]);
  const campaign = state.get('campaign').toJS();
  const interactionData = toInteractionData(state);
  request
    .post(API_ROUTES.CALL_ME_BACK_DETAILS, {
      details,
      currentRoute,
      campaign,
      interactionData,
    })
    .then(response => {
      dispatch(saveCallMeBackDetailsSuccess(state, details));
      customCallMeBackRouteAction(dispatch, currentRoute);
      dispatch(closeCallmeBackMenu());
      return response;
    })
    .catch(err => {
      dispatch(saveCallMeBackDetailsFailure(err));
      dispatch(closeCallmeBackMenu());
      dispatch(push(ROUTES.ERROR));
    });
};

export const saveCallMeBackDetails = details => {
  const type = SAVE_CALL_ME_BACK_DETAILS;
  const meta = details;
  const payload = postCallMeBackDetails(details);
  return {
    type,
    meta,
    payload,
  };
};

import * as types from './types';
import request from 'axios';
import { push } from 'connected-react-router';
import { API_ROUTES, ROUTES } from '../config/constants';
import { savePeeps } from './peeps';

const bankingDetailValidationFailure = details => {
  const type = types.SAVE_BANKING_DETAILS_NOT_VALID;
  const payload = details;
  return {
    type,
    payload,
  };
};

export const saveBankingDetailSuccessFailure = () => {
  const type = types.SAVE_BANKING_DETAILS_FAILURE;
  return {
    type,
  };
};

const submitBankingDetails = details => (dispatch, getState) => {
  const state = getState();
  const insureds = state.getIn(['peeps', 'insureds']).toJS();
  const beneficiaries = state.getIn(['peeps', 'beneficiaries']).toJS();

  request
    .post(API_ROUTES.BANKING_DETAILS, { details })
    .then(() => {
      dispatch(savePeeps(insureds, beneficiaries));
    })
    .catch(error => {
      if (error.response && error.response.status < 500) {
        dispatch(bankingDetailValidationFailure(error.response.data));
        dispatch(push(ROUTES.ERROR));
      } else {
        dispatch(saveBankingDetailSuccessFailure());
        dispatch(push(ROUTES.ERROR));
      }
    });
};

export const saveBankingDetails = details => {
  const type = types.SAVE_BANKING_DETAILS;
  const meta = details;
  const payload = submitBankingDetails(meta);
  return {
    type,
    payload,
    meta,
  };
};

export const setBankingDetails = details => {
  const type = types.SET_BANKING_DETAILS;
  const payload = details;
  return {
    payload,
    type
  };
};

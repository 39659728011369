import { Map, fromJS } from 'immutable';
import { GET_BANK_ACCOUNT_VERIFICATION, SET_BANK_ACCOUNT_VERIFICATION, RESET } from '../actions/types';
import createActionHandler from './createActionHandler';

const VERIFICATION_STATES_THAT_BLOCK_SALE = ['not-valid'];

const initialState = Map({
  busy: false,
  shouldRetry: false,
  messages: [],
  bankingDetailsVerified: false,
  hasWarnings: false,
  shouldFixIssues: false,
  status: null,
  canContinueToCheckout: false
});

const actionHandlers = {};

const toInitialState = () => initialState;

const setBusy = (state) => state.set('busy', true);

const setStatus = (state, action) => {
  const { status, data } = action.payload;
  const newState = {
    bankingDetailsVerified: status === 'valid',
    busy: false,
    messages: data,
    shouldRetry: status === 'retry-required',
    shouldFixIssues: status === 'not-valid',
    shouldVerifyBankingDetails: false,
    hasWarnings: status === 'warning',
    canContinueToCheckout: !VERIFICATION_STATES_THAT_BLOCK_SALE.includes(status),
    isTakingTooLong: status === 'timeout',
    jobId: action.payload.jobId
  };
  return fromJS(newState);
};

actionHandlers[RESET] = toInitialState;
actionHandlers[GET_BANK_ACCOUNT_VERIFICATION] = setBusy;
actionHandlers[SET_BANK_ACCOUNT_VERIFICATION] = setStatus;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

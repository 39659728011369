import { ENTER_COMPETITION, OPT_OUT_OF_COMPETITION } from './types';
import request from 'axios';
import { API_ROUTES } from '../config/constants';

export const competitionOptOut = () => ({
  type: OPT_OUT_OF_COMPETITION,
  payload: {},
});

const enterCompetitionAction = packageId => ({
  type: ENTER_COMPETITION,
  payload: { packageId },
});

export const enterCompetition = () => ({
  type: '',
  payload: (dispatch, getState) => {
    const state = getState();
    const packageId = state.getIn([
      'packages',
      state.get('selectedPackageSlug'),
      'packageId',
    ]);
    request.post(API_ROUTES.LOG_EVENT, {
      type: ENTER_COMPETITION,
      payload: { packageId },
    });
    return dispatch(enterCompetitionAction(packageId));
  },
});

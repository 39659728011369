import React, { PureComponent } from 'react';

export default class ErrorPage extends PureComponent {
  render() {
    return (
      <div className="row info-page">
        <div className="space-below-3"></div>
        <div className="col-xs-12 text-center space-below-3">
          <p className="space-below-3"></p>
          <p className="space-below-3"></p>
          <h2 className="space-above-3">Oh No! Something went wrong.</h2>
          <h4 className="space-below-3">We apologise for the inconvenience</h4>
          <p className="space-below-3"></p>
          <p className="space-below-3"></p>
          <p className="space-below-3"></p>
          <p className="space-below-3"></p>
        </div>
      </div>
    );
  }
}

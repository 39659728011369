import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';

const Input = styled.input`
  display: none;
`;

const LI = styled.li`
  list-style-position: outside;
  margin-left: 16px;
`;
class ToggleTerm extends PureComponent {
  constructor() {
    super();
    this.toggleShowContent = this.toggleShowContent.bind(this);
    this.state = { showContent: false };
  }

  toggleShowContent() {
    const { showContent } = this.state;
    this.setState({ showContent: !showContent });
  }

  render() {
    const { term } = this.props;
    const { showContent } = this.state;
    return (
      <div className="toggle">
        <Input
          type="checkbox"
          value="selected"
          id="title"
          className="toggle-input hidden"
        />
        <p>
          <span
            htmlFor="title"
            onClick={this.toggleShowContent}
            dangerouslySetInnerHTML={{ __html: term.link.title }} // eslint-disable-line
          />
        </p>
        {showContent && (
          <ul className="indent">
            {term.detail.map((d, i) => {
              if (R.head(d) === '-') {
                return <LI key={i}>{R.tail(d)}</LI>;
              }
              return <LI key={i}>{d} </LI>;
            })}
          </ul>
        )}
      </div>
    );
  }
}

ToggleTerm.propTypes = {
  term: PropTypes.shape({
    link: PropTypes.shape({
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
    detail: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default ToggleTerm;

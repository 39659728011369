import { SET_REFERRER_CODE } from '../actions/types';

const initialState = '';

export default (state = initialState, action) => {
  if (action.type === SET_REFERRER_CODE) {
    return action.payload;
  }
  return state;
};

import * as R from 'ramda';

const parseParams = (querystring) =>
      [...new URLSearchParams(querystring)]
      .reduce((obj,[key,val]) => R.assoc(key, val, obj), {});

export default props => parseParams(props.location.search);

export const extractQueryParams = () =>
  parseParams(window.location.search);

import { Map } from 'immutable';
import { SET_FORM_ENTITY } from '../actions/types';
import createActionHandler from './createActionHandler';

const actionHandlers = {};

const setFormEntity = (state, action) => {
  const { entityType, entity } = action.payload;
  return state.set(entityType, entity);
};

actionHandlers[SET_FORM_ENTITY] = setFormEntity;

const handle = createActionHandler(actionHandlers);

export default (state = Map(), action) => handle(state, action);

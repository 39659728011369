import { connect } from 'react-redux';
import Home from './Home';
import { showCallMeBack } from '../../actions/callMeBack';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  showCallMeBackFrom: () => dispatch(showCallMeBack()),
});

const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);

export default HomeContainer;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateCallMeBack = void 0;

var _index = require("./index");

var _immutable = require("immutable");

var validationReducer = function validationReducer(reduction, value) {
  return reduction.set('isValid', reduction.get('isValid') && value.get('isValid'));
};

var callMeBackValid = function callMeBackValid(details) {
  return (0, _immutable.fromJS)({
    'full-name': (0, _index.validateRequiredText)(details.get('full-name')),
    'cellphone-number': (0, _index.validateRequiredCellPhone)(details.get('cellphone-number'))
  });
};

var validateCallMeBack = function validateCallMeBack(details) {
  return callMeBackValid(details).reduce(validationReducer, new _immutable.Map({
    isValid: true
  }));
};

exports.validateCallMeBack = validateCallMeBack;
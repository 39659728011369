import { connect } from 'react-redux';
import BankingDetailsPage from './BankingDetailsPage';
import { saveBankingDetails } from '../../actions/bankingDetails';
import * as R from 'ramda';
import { getSelectedPackageIdFromState } from '../../common/getPackageIdFromSlug';
import brokerQaActions from '../../actions/brokerQA';

const getBankingDetailsInfoFromState = state => {
  const packageId = getSelectedPackageIdFromState(state);
  return state.getIn(['packageDetails', packageId, 'bankingDetailsPageInfo']);
};

export const mapStateToProps = state => ({
  isSaving:
    state.getIn(['bankingDetails', 'isSaving']) ||
    state.getIn(['peeps', 'isSaving']) ||
    state.getIn(['completeSale', 'isSaving']),
  packageId: getSelectedPackageIdFromState(state),
  bankingDetailsInfo: getBankingDetailsInfoFromState(state),
});

const next = R.curry((dispatch, details) =>
  dispatch(saveBankingDetails(details.toJS()))
);

const updateCorrectionComments = R.curry((dispatch, comments) => {
  dispatch(brokerQaActions.updateCorrectionComments(comments));
});


export const mapDispatchToProps = dispatch => ({
  next: next(dispatch),
  updateCorrectionComments: updateCorrectionComments(dispatch),
  sendForCorrections: () => dispatch(brokerQaActions.sendForCorrections()),
  rejectSale: () => dispatch(brokerQaActions.rejectSale())
});

const mergeProps = (stateProps, dispatchProps) =>
  Object.assign({}, stateProps, dispatchProps, {
    next: dispatchProps.next,
    sendForCorrections: dispatchProps.sendForCorrections,
    rejectSale: dispatchProps.rejectSale
  });

const BankingDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(BankingDetailsPage);

export default BankingDetail;

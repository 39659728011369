import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Referrals from './Referrals';
import { referMultipleContacts } from '../../actions/referrals';
import ShareReferralLink from './ShareReferralLink';
import PageSeo from '../common/PageSeo';
import Immutable from 'immutable';
import * as R from 'ramda';
import PageDescription from '../common/PageDescription';
import { WallOfTextContainer } from '@simply-fin-services/astronomix3';

const isImmutable = (ob) => ob instanceof Immutable.Map;
const extractFirstName = (name) => {
  const isEmpty = isImmutable(name) ? name.size === 0 : name === '';
  if (isEmpty) {
    throw new Error('Referrer Name cannot be empty');
  }
  return isImmutable(name)
    ? R.head(R.split(' ', name.get('answer')))
    : R.head(R.split(' ', name.answer));
};

const toReferrerStringName = (name) => {
  if (isImmutable(name)) {
    return name.get('answer');
  }
  return name.answer;
};

class ReferralHome extends PureComponent {
  render() {
    const { referrerName, referralCode, submitReferral, referralUrl } =
      this.props;
    const firstname = extractFirstName(referrerName);
    const callButton = { text: 'Send Referral' };
    const stringReferrerName = toReferrerStringName(referrerName);
    return (
      <div>
        <PageSeo
          image="https://storage.googleapis.com/simply-public/simply-referral-programme.png"
          description="You like what Simply does. You refer a friend to Simply.
        Your friend gets a discount. You get cash – for yourself or a charity of your choice.
        It’s a win-win!"
          title="Simply referral programme"
        />
        <PageDescription description={`Hi ${firstname}`} />
        <WallOfTextContainer>
          <p>
            This is your very own referrals page! You can come to your page any
            time – either by saving this web address as a favourite or by
            signing in on our home page.
          </p>
          <p className="space-below">
            You can refer friends in 2 ways - either share a link they can
            follow to our website or give us their details so we can contact
            them. Remember, if your friend signs up, they get a discount and you
            get a fee for yourself or a charity of your choice.
          </p>
          <br />
          <h5 className="bold">1. Share my link</h5>
          <ShareReferralLink
            referralUrl={referralUrl}
            referrerName={stringReferrerName}
            referralCode={referralCode}
          />
          <br />
          <h5>2. Give details</h5>
          <Referrals
            referralUrl={referralUrl}
            referrerName={stringReferrerName}
            referralCode={referralCode}
            submitReferral={submitReferral}
            button={callButton}
            type="call"
          />
          <h5>To qualify for Simply Family Cover:</h5>
          <ul className="indent space-below">
            <li>Ages between 23-64</li>
            <li>Employed (self-employed)</li>
            <li>A valid SA ID number</li>
          </ul>
          <h5>To qualify for Simply Domestic Cover:</h5>
          <ul className="indent space-below">
            <li>Ages between 18-64</li>
            <li>SA citizens or foreigners working legally in SA</li>
            <li>
              Work for you for at least 24 hours a month for 6 months or more
            </li>
          </ul>
          <h5>To qualify for Simply Group Cover:</h5>
          <ul className="indent space-below">
            <li>Permanently employed</li>
            <li>
              Actively working for the organisation for at least 20 hours per
              week in SA
            </li>
          </ul>
        </WallOfTextContainer>
      </div>
    );
  }
}

ReferralHome.propTypes = {
  referralUrl: PropTypes.string.isRequired,
  referralCode: PropTypes.string.isRequired,
  submitReferral: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => ({
  referralUrl: state.getIn(['referralSignup', 'referralUrl']),
  referralCode: state.getIn(['referralSignup', 'referralCode']),
  referrerName: state.getIn(['referralSignup', 'referrerName']),
  appState: state,
});

export const mapDispatchToProps = (dispatch) => ({
  submitReferral: referMultipleContacts(dispatch),
});

export const mergeProps = (stateProps, dispatchProps) =>
  Object.assign({}, stateProps, dispatchProps, {
    submitReferral: dispatchProps.submitReferral(
      stateProps.appState,
      toReferrerStringName(stateProps.referrerName),
      stateProps.referralCode
    ),
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ReferralHome);

import { Map, fromJS } from 'immutable';
import {
  SELECT_PRODUCTS,
  UPDATE_PRODUCT_SELECTION,
  SAVE_PRODUCT_SELECTION,
  SAVE_SELECTIONS,
  TOGGLE_ROP,
  TOGGLE_EFF_SUCCESS,
  RESET,
  OVERWRITE_PRODUCT_SELECTION,
  RELOAD_OLD_PACKAGE_SELECTED_PRODUCTS,
  SAVE_PRODUCT_SELECTION_SUCCESS,
  SAVE_PRODUCT_SELECTION_FAILURE,
  SET_DETAILS_CAPTURE_PRODUCTS,
  SET_EXTENDED_FAMILY_PRICING,
} from '../actions/types';
import createActionHandler from './createActionHandler';
import * as R from 'ramda';

const initialState = fromJS({
  withRop: false,
  isPushingRop: false,
  withExtendedFamilyFuneral: false,
});

const actionHandlers = {};

const selectProducts = (state, action) => {
  const products = fromJS(action.payload)
    .map((p) => p.set('selected', R.defaultTo(false, p.get('selected'))))
    .reduce(
      (productMap, product) =>
        productMap.set(
          product.get('productId'),
          fromJS({
            insuredAmount: product.get('insuredAmount'),
            selected: product.get('selected'),
            premium: product.get('premium'),
            productId: product.get('productId'),
            title: product.get('title'),
            insureds: product.get('insureds'),
            isExtendedFamily: product.get('isExtendedFamily'),
          })
        ),
      new Map()
    );
  return state.merge({
    products,
    isSaving: false,
  });
};

const updateProductSelection = (state, action) => { 

  const products = fromJS(action.payload);
  
  const stateProducts = state.get('products');

  const effProduct = state.get('extendedFamilyProductAndPricing');

  const effProductId = effProduct ? effProduct.get('productId') : null;

  const payloadhasEffProduct = products.has(effProductId);

  const stateHasEffProduct = stateProducts.has(effProductId);
  if (stateHasEffProduct && !payloadhasEffProduct) {
    return state.merge({
      products: products.set(effProductId, stateProducts.get(effProductId)),
      isSaving: false,
    });
  }
  return state.merge({
    products,
    isSaving: false,
  });
};

const overwriteProductSelection = (state, action) =>
  state.merge({
    products: fromJS(action.payload),
    isSaving: false,
  });

const reloadProductSelection = (state, action) =>
  state.merge({
    products: fromJS(action.payload.products),
    isSaving: false,
  });

const saveSelections = (state, action) =>
  state.merge({ selections: fromJS(action.payload) });

const setDetailsCaptureProducts = (state, action) =>
  state.merge({ products: fromJS(action.payload) });

const toggleRop = (state, action) => state.set('withRop', action.payload);
const toggleExtendedFamilyFuneral = (state, { payload }) =>
  state.set('withExtendedFamilyFuneral', payload);
const setExtendedFamilyPricing = (state, { payload }) =>
  state.set('extendedFamilyProductAndPricing', fromJS(payload));
const saveProductsSelection = (state) => state.set('isSaving', true);
const saveProductsSelectionComplete = (state) => state.set('isSaving', false);
const toInitialState = () => initialState;

actionHandlers[RESET] = toInitialState;
actionHandlers[SELECT_PRODUCTS] = selectProducts;
actionHandlers[UPDATE_PRODUCT_SELECTION] = updateProductSelection;
actionHandlers[OVERWRITE_PRODUCT_SELECTION] = overwriteProductSelection;
actionHandlers[RELOAD_OLD_PACKAGE_SELECTED_PRODUCTS] = reloadProductSelection;
actionHandlers[SAVE_PRODUCT_SELECTION] = saveProductsSelection;
actionHandlers[SAVE_PRODUCT_SELECTION_SUCCESS] = saveProductsSelectionComplete;
actionHandlers[SAVE_PRODUCT_SELECTION_FAILURE] = saveProductsSelectionComplete;
actionHandlers[TOGGLE_ROP] = toggleRop;
actionHandlers[TOGGLE_EFF_SUCCESS] = toggleExtendedFamilyFuneral;
actionHandlers[SET_DETAILS_CAPTURE_PRODUCTS] = setDetailsCaptureProducts;
actionHandlers[SAVE_SELECTIONS] = saveSelections;
actionHandlers[SET_EXTENDED_FAMILY_PRICING] = setExtendedFamilyPricing;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { savePeeps } from '../../actions/peeps';
import LoadingRow from '../common/LoadingRow';
import PageActionButtons from '../common/PageActionButtons';
import * as R from 'ramda';
import Beneficiaries from './Beneficiaries';
import Insureds from './Insureds';
import getSelectedProductIds from './getSelectedProductIds';
import CompleteSaleErrors from '../thankYou/CompleteSaleErrors';

class PeepsPage extends PureComponent {
  render() {
    const { isSaving, submit } = this.props;
    return (
      <div>
        {isSaving && <LoadingRow />}
        {!isSaving && (
          <PageActionButtons text="Finish" onClick={submit} canGoBack />
        )}
        <Insureds />
        <Beneficiaries />
        {isSaving && <LoadingRow />}
        <CompleteSaleErrors />
        {!isSaving && (
          <PageActionButtons text="Finish" onClick={submit} canGoBack />
        )}
      </div>
    );
  }
}

PeepsPage.propTypes = {
  submit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

const submitPeeps = R.curry((dispatch, selectedPeeps) => {
  dispatch(savePeeps(selectedPeeps.insureds, selectedPeeps.beneficiaries));
});

export const getSelectedPeeps = state => {
  const selectedProductIds = getSelectedProductIds(state);
  const peeps = state.get('peeps');
  return {
    insureds: peeps
      .get('insureds')
      .filter((v, k) => R.includes(k, selectedProductIds))
      .toJS(),
    beneficiaries: peeps
      .get('beneficiaries')
      .filter((v, k) => R.includes(k, selectedProductIds))
      .toJS(),
  };
};

const mapStateToProps = state => ({
  isSaving:
    state.getIn(['peeps', 'isSaving']) ||
    state.getIn(['completeSale', 'isSaving']),
  selectedPeeps: getSelectedPeeps(state),
});

const mapDispatchToProps = dispatch => ({
  submit: submitPeeps(dispatch),
});

const mergeProps = (stateProps, dispatchProps) =>
  Object.assign({}, stateProps, dispatchProps, {
    submit: () => dispatchProps.submit(stateProps.selectedPeeps),
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PeepsPage);

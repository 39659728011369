import { fromJS } from 'immutable';

const stateKey = 'replaceAndSave';

export const getAppState = (key, state) => state.getIn([stateKey, key]);

export const replacementPolicies = (state) =>
  getAppState('replacementPolicies', state) || fromJS([]);

export const cancellationLetters = (state) =>
  getAppState('cancellationLetters', state) || fromJS([]);

export const totalReplacementPolicies = (state) =>
  replacementPolicies(state).size;

export const hasReplacementPolicies = (state) =>
  totalReplacementPolicies(state) > 0;

export const currentPolicy = (state) => getAppState('currentPolicy', state);

export const currentPolicyId = (state) => currentPolicy(state).get('id');

export const initialInsuredPerson = (state) =>
  getAppState('initialInsuredPerson', state);

export const isReplaceAndSaveEnabled = (state) =>
  getAppState('enabled', state) === true;

export const isAddingInsuredPerson = (state) =>
  getAppState('isAddingInsuredPerson', state);

export const isAddingReplacementPolicy = (state) =>
  getAppState('isAddingReplacementPolicy', state);

export const totalMonthlyPremium = (state) =>
  getAppState('totalMonthlyPremium', state);

export const totalCover = (state) => getAppState('totalCover', state);

export const savingsInformation = (state) =>
  getAppState('savingsInformation', state) || fromJS({});

export const hasPremiumSaving = (state) =>
  savingsInformation(state).get('hasPremiumSaving');

export const monthlyPremiumSavings = (state) =>
  savingsInformation(state).get('monthlyPremiumSaving');

export const annualPremiumSavings = (state) =>
  savingsInformation(state).get('annualPremiumSaving');

export const initialPremiumSavings = (state) =>
  savingsInformation(state).get('initialPremiumSaving');

export const shouldShowPolicyListing = (state) =>
  !isAddingReplacementPolicy(state) &&
  !isAddingInsuredPerson(state) &&
  hasReplacementPolicies(state);

export const replaceAndSaveEnabledPackage = (state, packageConfig) => {
  if (isReplaceAndSaveEnabled(state))
    return packageConfig.get('replaceAndSaveEnabled') === true;

  return true;
};

// This interaction ID is kept in replace and save state since it gets reset
// when the sale completes. This keeps the original which is required for HTTP API calls
export const replaceAndSaveInteractionId = (state) =>
  getAppState('interactionId', state);

import * as R from 'ramda';
import { List } from 'immutable';

const selectQuestions = state => state.get('questions');
const selectQuestionNodes = state => state.get('questionNodes');
const selectAllQuestions = state =>
  selectQuestions(state).merge(selectQuestionNodes(state));

export default selectAllQuestions;

const getChildQuestions = R.curry((allQuestions, question) => {
  if (question.has('nodeId')) {
    const children = question
      .get('children')
      .valueSeq()
      .reduce((r, v) => r.concat(v), new List())
      .map(q => allQuestions.get(q))
      .map(getChildQuestions(allQuestions));
    return children.push(question);
  }
  return question.get('questionId');
});

export const allPossibleQuestionsForPage = (questionPageId, state) => {
  const questions = state.getIn(['questionPages', questionPageId, 'questions']);
  const allQuestions = selectAllQuestions(state);
  return questions
    .map(q => allQuestions.get(q))
    .map(getChildQuestions(allQuestions))
    .flatten()
    .toJS();
};

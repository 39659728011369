import { DEFAULT_PACKAGES } from '../config/appConfig';
import * as R from 'ramda';

const getPackageIdFromSlug = (state, packageUrlSlug) => {
  if (state.getIn(['packages', packageUrlSlug])) {
    return state.getIn(['packages', packageUrlSlug]).get('packageId');
  }
  return R.prop('sim-family', DEFAULT_PACKAGES);
};

export default getPackageIdFromSlug;

export const getSelectedPackageIdOrDefault = state => {
  if (state.get('selectedPackageSlug')) {
    return getPackageIdFromSlug(state, state.get('selectedPackageSlug'));
  }
  return R.prop('sim-family', DEFAULT_PACKAGES);
};

export const getSelectedPackageIdFromState = state =>
  getPackageIdFromSlug(state, state.get('selectedPackageSlug'));

export const getPackageSlugFromId = (state, packageId) => {
  if (!packageId) {
    return '';
  }
  const selectedPackage = state
    .get('packages')
    .filter(v => v.get('packageId') === packageId)
    .first();

  if (!selectedPackage) {
    return '';
  }
  return selectedPackage.get('urlSlug');
};

import React from 'react';
import styled from 'styled-components';
import AcceptCheckboxQuestion from '../../questions/AcceptCheckboxQuestion';
import { connect } from 'react-redux';
import { answerQuestion as answerQuestionAction } from '../../../actions/answers';
import { curry, isEmpty, isNil } from 'ramda';
import { WallOfTextContainer } from '@simply-fin-services/astronomix3';

// const OMART_SMS_SHORT_CODE = '30994';
// const SIMPLY_SMS_SHORT_CODE = '45794';

const YES = 'Yes';
const NO = 'No';
const exists = (i) => !isEmpty(i) && !isNil(i);
const getOpposite = (answer) => {
  if (!exists(answer)) {
    return NO;
  }

  if (answer === YES) {
    return NO;
  }

  return YES;
};

const getTrueOrFalse = (text) => text === NO;

const OL = styled.ol`
  list-style: none;
  li {
    p {
      margin-bottom: 0.5rem !important;
    }
  }
`;

const OmartCheckboxLabel = (
  <p>
    Please check this box or email your ID number along with the word OPT-OUT to customerservice@hollard.co.za if you would prefer not to receive such information and/or financial services.
  </p>
);

const SimplyCheckboxLabel = (
  <p>
    Please check this box or email your ID number along with the word OPT-OUT to admin@simply.co.za if you would prefer not to receive such information and/or financial services.
  </p>
);

const isNilOrEmpty = (i) => isNil(i) || isEmpty(i);
class PIP extends React.Component {
  componentDidMount() {
    const {
      isSimplyChecked,
      isOmartChecked,
      complianceQuestions,
      answerQuestion,
    } = this.props;
    if (isNilOrEmpty(isSimplyChecked)) {
      answerQuestion(complianceQuestions.simply, YES);
    }

    if (isNilOrEmpty(isOmartChecked)) {
      answerQuestion(complianceQuestions.omart, YES);
    }
  }

  render() {
    const {
      isSimplyChecked,
      isOmartChecked,
      complianceQuestions,
      answerQuestion,
      WEB_SITE_BASE,
    } = this.props;
    return (
      <WallOfTextContainer className="space-above">
        <p>
          Simply is collecting personal information that will be passed to Hollard, 
          the underwriter of the policy, to be used for purposes such as underwriting, 
          assessments, processing of claims, and so forth. Simply may also use the 
          collected personal information for purposes such as regulatory record 
          keeping and marketing analytics. Simply and Hollard would like to
          provide you with information about products and services that may be
          suitable to meet your financial needs on an ongoing basis.
        </p>
        <OL className="sans-indentation">
          <li className="sans-indentation">
            <AcceptCheckboxQuestion
              text={OmartCheckboxLabel}
              checked={getTrueOrFalse(isOmartChecked)}
              id="omart-compliance"
              labelIsBold={false}
              onChange={() =>
                answerQuestion(
                  complianceQuestions.omart,
                  getOpposite(isOmartChecked)
                )
              }
            />
          </li>
          <li className="sans-indentation">
            <AcceptCheckboxQuestion
              text={SimplyCheckboxLabel}
              checked={getTrueOrFalse(isSimplyChecked)}
              id="simply-compliance"
              labelIsBold={false}
              onChange={() =>
                answerQuestion(
                  complianceQuestions.simply,
                  getOpposite(isSimplyChecked)
                )
              }
            />
          </li>
        </OL>
        <p>
          Simply and Hollard may use information from credit bureaus or other data
          sources to help prevent fraud and to improve the products and services we
          offer. By completing this application you give us permission to retrieve your
          consumer credit information as defined in section 70(1) of the National
          Credit Act.
        </p>
        <p>
          A full description of how personal information is used will be
          provided to you in writing alongside your policy document. Simply’s
          Privacy policy is available {" "}
          <a
            href={`${WEB_SITE_BASE}/privacy-policy`}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>.
          Hollard’s Privacy Policy can be viewed at {" "}
          <a
            href="https://www.hollard.co.za/our-world/company-overview/hollard-privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.hollard.co.za/our-world/company-overview/hollard-privacy
          </a>
          .
        </p>
      </WallOfTextContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    isSimplyChecked: stateProps.state.getIn([
      'answers',
      ownProps.complianceQuestions.simply,
      'answer',
    ]),
    isOmartChecked: stateProps.state.getIn([
      'answers',
      ownProps.complianceQuestions.omart,
      'answer',
    ]),
    WEB_SITE_BASE: stateProps.state.getIn(['config', 'WEB_SITE_BASE']),
    answerQuestion: curry((questionId, answer) => {
      const { packageId } = ownProps;
      dispatchProps.dispatch(
        answerQuestionAction(packageId, questionId, answer)
      );
    }),
  });

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PIP);

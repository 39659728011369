import {
  toInput,
  toOptionalInput,
  toProperCaseInput,
  toPhoneNumberInput,
} from './inputHelpers';
import * as shared from '../../.shared/validators';
import { answer } from '../../actions/referralSignup';
import * as R from 'ramda';

export const CELLPHONE_NUMBER = 'CELLPHONE_NUMBER';
export const ID_NUMBER = 'ID_NUMBER';
export const NUMBER_TEXT = 'NUMBER_TEXT';
export const TEXT = 'TEXT';
export const EMAIL = 'EMAIL';
export const DATE = 'DATE';

export const REQUIRED_CELLPHONE_NUMBER = 'REQUIRED_CELLPHONE_NUMBER';
export const REQUIRED_ID_NUMBER = 'REQUIRED_ID_NUMBER';
export const REQUIRED_NUMBER_TEXT = 'REQUIRED_NUMBER_TEXT';
export const REQUIRED_TEXT = 'REQUIRED_TEXT';
export const REQUIRED_EMAIL = 'REQUIRED_EMAIL';
export const REQUIRED_DATE = 'REQUIRED_DATE';
export const REQUIRED_DOB = 'REQUIRED_DOB';

const typeMap = {};
typeMap[TEXT] = {
  typeValidator: shared.validateRequiredText,
  isOptional: false,
};
typeMap[EMAIL] = {
  typeValidator: shared.validateOptionalEmail,
  isOptional: true,
};
typeMap[DATE] = {
  typeValidator: shared.validateRequiredDate,
  isOptional: false,
};
typeMap[ID_NUMBER] = {
  typeValidator: shared.validateOptionalIdNumber,
  isOptional: true,
};
typeMap[NUMBER_TEXT] = {
  typeValidator: shared.validateOptionalNumberText,
  isOptional: true,
};
typeMap[CELLPHONE_NUMBER] = {
  typeValidator: shared.validateOptionalCellphone,
  isOptional: true,
};
typeMap[REQUIRED_ID_NUMBER] = {
  typeValidator: shared.validateRequiredIdNumber,
  isOptional: false,
};
typeMap[REQUIRED_EMAIL] = {
  typeValidator: shared.validateRequiredEmail,
  isOptional: false,
};
typeMap[REQUIRED_TEXT] = {
  typeValidator: shared.validateRequiredText,
  isOptional: false,
};
typeMap[REQUIRED_NUMBER_TEXT] = {
  typeValidator: shared.validateRequiredNumberText,
  isOptional: false,
};
typeMap[REQUIRED_CELLPHONE_NUMBER] = {
  typeValidator: shared.validateRequiredCellPhone,
  isOptional: false,
};
typeMap[REQUIRED_DATE] = {
  typeValidator: shared.validateRequiredDate,
  isOptional: false,
};
typeMap[REQUIRED_DOB] = {
  typeValidator: shared.validateRequiredDateOfBirth,
  isOptional: false,
};

const getValidator = (query, validator) => {
  const simpleValidator = value => validator(value, query);
  const rangeValidator = value => validator(value, query.max, query.min);
  return R.is(Object, query) ? rangeValidator : simpleValidator;
};

const getInputConstructor = (type, isOptional) => {
  const stringType = type.toString();
  const isText = R.includes('TEXT', stringType.split('_'));
  const isPhoneNumber =
    stringType === CELLPHONE_NUMBER || stringType === REQUIRED_CELLPHONE_NUMBER;
  const toTextInput = isOptional ? toOptionalInput : toInput;
  const otherInputField = isPhoneNumber ? toPhoneNumberInput : toTextInput;
  return isText ? toProperCaseInput : otherInputField;
};

export const getFieldUtilities = () =>
  R.mapObjIndexed((options, type) => {
    const { typeValidator, isOptional } = options;
    const fieldConstructor = getInputConstructor(type, isOptional);
    return {
      setValidation: query => getValidator(query, typeValidator),
      saveToState: (dispatch, fieldname, owner) =>
        answer(type, dispatch, fieldname, owner),
      constructField: fieldConstructor,
    };
  }, typeMap);

import {
  PERSAL_START_AFFORDABILITY_CHECK,
  PERSAL_FINISH_AFFORDABILITY_CHECK,
  PERSAL_START_MANDATE_UPLOAD,
  PERSAL_FINISH_MANDATE_UPLOAD,
  PERSAL_START_MANDATE_DOWNLOAD,
  PERSAL_FINISH_MANDATE_DOWNLOAD,
  PERSAL_START_ELECTRONIC_MANDATE_REQUEST,
  PERSAL_FINISH_ELECTRONIC_MANDATE_REQUEST,
  PERSAL_SELECT_MANDATE_TYPE,
  PERSAL_START_CONFIRM_ELECTRONIC_MANDATE_SIGNED,
  PERSAL_FINISH_CONFIRM_ELECTRONIC_MANDATE_SIGNED,
  PERSAL_RESTART,
  PERSAL_ONLOAD_RESTART,
  PERSAL_QA_RESTART,
  PERSAL_ERROR,
  TOGGLE_VOICE_MANDATE_TERM,
  PERSAL_SET_COLLECTION_DATES,
} from './types';
import request from 'axios';
import { API_ROUTES } from '../config/constants';
import { fileFromBase64 } from '../common/downloadfile';
import { isQaSaleInQaStage } from '../common/brokers';
import { calculatePricingFromState } from '../views/customization/calculatePricing';


export const restart = () => ({
  type: PERSAL_RESTART,
  payload: {},
});

export const qaRestart = () => ({
  type: PERSAL_QA_RESTART,
  payload: {},
});

export const onloadRestart = () => ({
  type: PERSAL_ONLOAD_RESTART,
  payload: (dispatch, getState) => {
    if(isQaSaleInQaStage(getState())) {
      dispatch(qaRestart());
      return;
    }
    dispatch(restart());
  },
});

export const error = () => ({
  type: PERSAL_ERROR,
  payload: {},
});

export const finishAffordability = (data) => ({
  type: PERSAL_FINISH_AFFORDABILITY_CHECK,
  payload: data,
});

export const finishMandateUpload = (data) => ({
  type: PERSAL_FINISH_MANDATE_UPLOAD,
  payload: data,
});


export const finishMandateDownload = (data) => ({
  type: PERSAL_FINISH_MANDATE_DOWNLOAD,
  payload: data,
});

export const checkAffordability = () => {
  const payload = (dispatch, getState) => {
    const state = getState();
    const slug = state.get('selectedPackageSlug');
    const pricing = calculatePricingFromState(state, slug);
    const premiumInCents = Math.ceil(pricing.finalCost * 100);
    const replaceAndSaveEnabled = state.getIn(['replaceAndSave', 'enabled']);
    request
      .get(API_ROUTES.PERSAL_CHECK_AFFORDABILITY, { params: { premiumInCents, replaceAndSaveEnabled } })
      .then(res => dispatch(finishAffordability(res.data)))
      .catch(() => dispatch(error()));
  };
  return {
    type: PERSAL_START_AFFORDABILITY_CHECK,
    payload,
  };
};

export const downloadMandate = () => {
  const payload = (dispatch, getState) => {
    const state = getState();
    const inputs = state.getIn(['persal', 'affordability']);
    request
      .post(API_ROUTES.PERSAL_MANDATE_DOWNLOAD, { params: inputs})
      .then(res => {
        fileFromBase64(`${inputs.transactionNumber}-PERSAL-MANDATE.pdf`, res.data.base64);
        dispatch(finishMandateDownload(res.data));
      })
      .catch(() => dispatch(error()));
  };
  return {
    type: PERSAL_START_MANDATE_DOWNLOAD,
    payload,
  };
};

export const uploadMandate = file => {
  const formData = new FormData();
  formData.append('mandate-file', file);
  const payload = (dispatch, getState) => {
    const state = getState();
    const inputs = state.getIn(['persal', 'affordability']);
    const ks = Object.keys(inputs);
    for(let i = 0; i < ks.length; i++) {
      const k = ks[i];
      formData.append(k, inputs[k]);
    }
    request
      .post(API_ROUTES.PERSAL_MANDATE_UPLOAD, formData, {
        headers: {'Content-Type': 'multipart/form-data'},
      })
      .then(res => dispatch(finishMandateUpload(res.data)))
      .catch(() => dispatch(error()));
  };
  return {
    type: PERSAL_START_MANDATE_UPLOAD,
    payload,
  };
};

export const selectMandateType = (mandateType) => {
  return {
    type: PERSAL_SELECT_MANDATE_TYPE,
    payload: {mandateType},
  };
};


// Electronic mandate

export const finishElectronicMandateRequest = (data) => {
  return {
    type: PERSAL_FINISH_ELECTRONIC_MANDATE_REQUEST,
    payload: data,
  };
};

export const requestElectronicMandate = () => {
  const payload = (dispatch, getState) => {
    const state = getState();
    const inputs = state.getIn(['persal', 'affordability']);
    request
      .post(API_ROUTES.PERSAL_REQUEST_ELECTRONIC_MANDATE, { params: inputs})
      .then(res => {
        dispatch(finishElectronicMandateRequest(res.data));
      })
      .catch(() => dispatch(error()));
  };
  return {
    type: PERSAL_START_ELECTRONIC_MANDATE_REQUEST,
    payload,
  };
};

export const finishConfirmMandateSigned = (data) => {
  return {
    type: PERSAL_FINISH_CONFIRM_ELECTRONIC_MANDATE_SIGNED,
    payload: data,
  };
};

export const startConfirmMandateSigned = () => {
  const payload = (dispatch, getState) => {
    const state = getState();
    const inputs = state.getIn(['persal', 'affordability']);
    request
      .post(API_ROUTES.PERSAL_CHECK_MANDATE_UPLOADED, { params: inputs})
      .then(res => {
        dispatch(finishConfirmMandateSigned(res.data));
      })
      .catch(() => dispatch(error()));
  };
  return {
    type: PERSAL_START_CONFIRM_ELECTRONIC_MANDATE_SIGNED,
    payload,
  };
};

export const toggleVoiceMandateTerm = (isAccepted) => {
  return{
    type:TOGGLE_VOICE_MANDATE_TERM,
    payload: isAccepted
  };
};


export const setCollectionDates = (options) => {
  return{
    type: PERSAL_SET_COLLECTION_DATES,
    payload: options
  };
};
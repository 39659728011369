import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IPropTypes from 'react-immutable-proptypes';
import buildQuestion from '../questions/questionBuilder';
import * as R from 'ramda';
import {
  PrimaryButton,
  Checkbox,
  Buttons,
  Modal,
} from '@simply-fin-services/astronomix3';
import { enterCompetition } from '../../actions/competition';
import { fromJS } from 'immutable';
import { COMPLIANCE_MESSAGES } from '../../config/constants';

const reduceListByEvaluationOfState = (evaluation, state, list) => {
  const isEvaluation = (val) => val === evaluation;
  const checkIfHasState = (val, current) =>
    current[R.head(R.keys(current))][state];
  return R.reduceWhile(isEvaluation, checkIfHasState, evaluation, list);
};

const reduceListByExplicitEvaluationOfState = (evaluation, state, list) => {
  const isEvaluation = (val) => val === evaluation;
  const checkIfHasState = (val, current) =>
    current[R.head(R.keys(current))][state] === evaluation;
  return R.reduceWhile(isEvaluation, checkIfHasState, evaluation, list);
};

const atLeastOneHasTheseTwoStatesTrue = (state1, state2, list) => {
  const atleatsOneHasState1 = reduceListByEvaluationOfState(
    false,
    state1,
    list
  );
  const atleatsOneHasState2 = reduceListByEvaluationOfState(
    false,
    state2,
    list
  );
  return atleatsOneHasState1 && atleatsOneHasState2;
};

const atLeastOneHasThisStateFalse = (state, list) =>
  reduceListByExplicitEvaluationOfState(false, state, list);

const atLeastOneHasThisStateTrue = (state, list) =>
  reduceListByExplicitEvaluationOfState(true, state, list);

const getLoadingQuestions = (questions, answers) => {
  return questions.map((qId) => {
    const questionExists = R.includes(qId, R.keys(answers.toJS()));
    const isLoading = questionExists
      ? answers.getIn([qId, 'isLoading'])
      : false;
    return R.assoc(qId, { isLoading }, {});
  });
};

const getAnsweredQuestions = (questions, answers) => {
  return questions.map((qId) => {
    const questionAnswer = answers.getIn([qId, 'answer']);
    const questionExists = R.includes(qId, R.keys(answers.toJS()));
    const hasQuestionBeenAnswered = !R.isEmpty(questionAnswer);
    const answered = questionExists ? hasQuestionBeenAnswered : false;
    return R.assoc(qId, { answered }, {});
  });
};

const getAnswerValidations = (questions, answers) => {
  return questions.map((qId) => {
    const validation = answers.getIn([qId, 'isValid']);
    const valid = !R.isNil(validation) ? validation : true;
    return R.assoc(qId, { valid }, {});
  });
};

export const getContactDetailsValidations = (answers) => {
  const questions = ['owner-contact-number'];
  const questionsWithAnsweredStatus = getAnsweredQuestions(questions, answers);
  const questionsWithLoadingStatus = getLoadingQuestions(questions, answers);
  const questionsWithValidationStatus = getAnswerValidations(
    questions,
    answers
  );
  const questionsWithAnsweredAndValidationStatus = R.zip(
    questionsWithAnsweredStatus,
    questionsWithValidationStatus
  ).map((pair) => R.mergeDeepLeft(pair[0], pair[1])); // e.g [ { email : { answered: true, valid: false } }]

  const questionAnswers = questions.map((qId) => {
    if (R.isNil(answers.get(qId))) {
      return fromJS({ answer: '' });
    }
    return answers.get(qId);
  });

  const allFieldsAreEmpty = R.reduceWhile(
    (val) => val === true,
    (val, curr) => R.isEmpty(curr.get('answer')),
    true,
    questionAnswers
  );

  const atleastOneQuestionIsAnsweredAndValid = atLeastOneHasTheseTwoStatesTrue(
    'answered',
    'valid',
    questionsWithAnsweredAndValidationStatus
  );

  const atleastOneQuestionIsInvalid = atLeastOneHasThisStateFalse(
    'valid',
    questionsWithValidationStatus
  );
  const atLeastOneQuestionIsLoading = atLeastOneHasThisStateTrue(
    'isLoading',
    questionsWithLoadingStatus
  );
  return {
    allFieldsAreEmpty,
    atleastOneQuestionIsAnsweredAndValid,
    atleastOneQuestionIsInvalid,
    atLeastOneQuestionIsLoading,
  };
};

export default class ContactDetails extends PureComponent {
  componentWillUnmount() {
    const { setContactFormViewDate } = this.props;
    setContactFormViewDate();
  }

  render() {
    const {
      pageQuestions,
      questions,
      answers,
      answerQuestion,
      optionalPageQuestions,
      isCompulsory,
      contactDetailsPopupVisisble,
    } = this.props;
    const {
      atleastOneQuestionIsAnsweredAndValid,
      atleastOneQuestionIsInvalid,
      atLeastOneQuestionIsLoading,
      allFieldsAreEmpty,
    } = getContactDetailsValidations(answers);

    const toControl = buildQuestion(
      questions,
      optionalPageQuestions,
      answers,
      answerQuestion,
      false
    );
    const hide = () => {
      const { hasOptOut, dispatch } = this.props;
      if (!hasOptOut && !allFieldsAreEmpty) {
        dispatch(enterCompetition());
      }
      this.props.hide();
      if (window) {
        window.scrollTo(0, 0);
      }
    };
    const optOut = () => {
      this.props.optOut();
      hide();
    };
    const canGoNext = atLeastOneQuestionIsLoading
      ? false
      : atleastOneQuestionIsInvalid
      ? false
      : atleastOneQuestionIsAnsweredAndValid
      ? true
      : this.props.hasOptOut || false;
    const cancelText = this.props.cancelText
      ? this.props.cancelText
      : 'I’d rather not leave details';
    const title = this.props.title
      ? this.props.title
      : 'Please leave your details so we can get in touch if you get stuck';
    return (
      <div>
        {contactDetailsPopupVisisble && (
          <Modal show>
            <h4 className="space-below space-above-tight text-center">
              {title}
            </h4>
            {pageQuestions.map(toControl)}
            <p className="space-below">
              {COMPLIANCE_MESSAGES.CELLPHONE_NUMBER_MESSAGE}
            </p>
            {!isCompulsory && (
              <Checkbox
                labelText={cancelText}
                id="I'd rather not"
                onClick={optOut}
              />
            )}
            <Buttons>
              <PrimaryButton disabled={!canGoNext} onClick={hide}>
                Next
              </PrimaryButton>
            </Buttons>
          </Modal>
        )}
      </div>
    );
  }
}

ContactDetails.propTypes = {
  questions: IPropTypes.map.isRequired,
  answers: IPropTypes.map.isRequired,
  pageQuestions: IPropTypes.listOf(PropTypes.string).isRequired,
  answerQuestion: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  defaults: PropTypes.object,
  optOut: PropTypes.func,
  canGoNext: PropTypes.bool,
  isCompulsory: PropTypes.bool,
  hasOptOut: PropTypes.bool.isRequired,
  hide: PropTypes.func,
  contactDetailsPopupVisisble: PropTypes.bool,
  setContactFormViewDate: PropTypes.func.isRequired,
};

import {
  SET_BROKER_QA_CONFIG,
  GET_BROKER_QA_CONFIG,
  UPDATE_CORRECTION_COMMENTS,
  SEND_FOR_CORRECTIONS,
  REJECT_SALE
} from './types';
import { API_ROUTES } from '../config/constants';
import { dispatchResetActionAfterCompletedSale, handleError } from './completeSale';
import request from 'axios';


export const setBrokerQaConfig = (data) => {
  const type = SET_BROKER_QA_CONFIG;
  return {
    type,
    payload: data
  };
};


const requestBrokerQAConfig = (brokerCode, interactionId) => (dispatch) => {
  request
    .post(API_ROUTES.GET_BROKER_QA_CONFIG, {
      brokerUserId: brokerCode,
      saleId: interactionId
    }).then(res => dispatch(setBrokerQaConfig(res.data)))
      .catch(() => {});
};

export const getBrokerQAConfig = (brokerCode, interactionId) => {
  const type = GET_BROKER_QA_CONFIG;
  const payload = requestBrokerQAConfig(brokerCode, interactionId);
  return {
    type,
    payload
  };
};

const submitCorrections = () => (dispatch, getState) => {
  const state = getState();
  const slug = state.get('selectedPackageSlug');
  const params = {
    comments: state.getIn(['brokerQa', 'correctionComments']),
    saleId: state.get('interactionId')
  };
  request
    .post(API_ROUTES.SEND_SALE_FOR_CORRECTIONS, params)
    .then(() => {
      dispatchResetActionAfterCompletedSale(slug, dispatch);
    })
    .catch(handleError(dispatch));
};

export const updateCorrectionComments = (comments) => {
  const type =  UPDATE_CORRECTION_COMMENTS;
  const payload = {
    comments
  };
  return {
    type,
    payload
  };
};

export const sendForCorrections = () => {
  const type = SEND_FOR_CORRECTIONS;
  const payload = submitCorrections();
  return {
    type,
    payload
  };
};

export const submitRejectSale = () => (dispatch, getState) => {
  const state = getState();
  const slug = state.get('selectedPackageSlug');
  const params = {
    saleId: state.get('interactionId')
  };

  request
    .post(API_ROUTES.REJECT_SALE, params)
    .then(() => {
      dispatchResetActionAfterCompletedSale(slug, dispatch);
    })
    .catch(handleError(dispatch));
};

export const rejectSale = () => {
  const type = REJECT_SALE;
  const payload = submitRejectSale();

  return {
    type,
    payload
  };
};

export default {
  updateCorrectionComments,
  sendForCorrections,
  rejectSale
};

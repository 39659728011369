import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InputQuestion from '../questions/InputQuestion';

export default class AccountNumber extends PureComponent {
  render() {
    const { answerQuestion } = this.props;
    return (
      <InputQuestion
        title="Account number"
        answerQuestion={answerQuestion}
        className="account-number"
        {...this.props}
        icon="lock"
        fitForm
      />
    );
  }
}

AccountNumber.propTypes = {
  answerQuestion: PropTypes.func.isRequired,
};

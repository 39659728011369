"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isOrganizationNominationValid = exports.beneficaryFormValid = exports.isPersonNominationFormValid = exports.isPersonNominationValid = exports.personWithRelationshipValidator = exports.MIN_AGE = exports.MAX_AGE = exports.RELATIONSHIP_TYPES = void 0;

var _index = require("./index");

var _immutable = require("immutable");

var RELATIONSHIP_TYPES = ['Child', 'Spouse'];
exports.RELATIONSHIP_TYPES = RELATIONSHIP_TYPES;
var MAX_AGE = 120;
exports.MAX_AGE = MAX_AGE;
var MIN_AGE = 0;
exports.MIN_AGE = MIN_AGE;

var organizationNominationValidator = function organizationNominationValidator(details) {
  return (0, _immutable.fromJS)({
    creditorName: (0, _index.validateRequiredText)(details.get('creditorName'))
  });
};

var personValidator = function personValidator(details, maxAge, minAge) {
  if (details.get('idNumber')) {
    return (0, _immutable.fromJS)({
      name: (0, _index.validateRequiredText)(details.get('name')),
      surname: (0, _index.validateRequiredText)(details.get('surname')),
      idNumber: (0, _index.validateOptionalIdNumber)(details.get('idNumber')),
      dateOfBirth: (0, _index.validateRequiredDateOfBirth)(details.get('dateOfBirth'), maxAge, minAge)
    });
  }

  return (0, _immutable.fromJS)({
    name: (0, _index.validateRequiredText)(details.get('name')),
    surname: (0, _index.validateRequiredText)(details.get('surname')),
    dateOfBirth: (0, _index.validateRequiredDateOfBirth)(details.get('dateOfBirth'), maxAge, minAge)
  });
};

var relationshipValidator = function relationshipValidator(details, validRelationships) {
  return (0, _immutable.fromJS)({
    relationship: (0, _index.validateRequiredSelection)(details.get('relationship'), validRelationships)
  });
};

var personWithRelationshipValidator = function personWithRelationshipValidator(details, maxAge, minAge, validRelationships) {
  return personValidator(details, maxAge, minAge).merge(relationshipValidator(details, validRelationships));
};

exports.personWithRelationshipValidator = personWithRelationshipValidator;

var personNominationValidator = function personNominationValidator(details, maxAge, minAge) {
  return personWithRelationshipValidator(details, maxAge, minAge, RELATIONSHIP_TYPES);
};

var personFormValidator = function personFormValidator(details) {
  return personNominationValidator(details, MAX_AGE, MIN_AGE);
};

var beneficiaryValid = function beneficiaryValid(details) {
  return personValidator(details, MAX_AGE, MIN_AGE);
};

var validationReducer = function validationReducer(reduction, value) {
  return reduction.set('isValid', reduction.get('isValid') && value.get('isValid'));
};

var isPersonNominationValid = function isPersonNominationValid(details, maxAge, minAge) {
  var validated = personNominationValidator(details, maxAge, minAge);
  return validated.reduce(validationReducer, new _immutable.Map((0, _immutable.fromJS)({
    details: validated
  }).merge({
    isValid: true
  })));
};

exports.isPersonNominationValid = isPersonNominationValid;

var isPersonNominationFormValid = function isPersonNominationFormValid(details) {
  return personFormValidator(details).reduce(validationReducer, new _immutable.Map({
    isValid: true
  }));
};

exports.isPersonNominationFormValid = isPersonNominationFormValid;

var beneficaryFormValid = function beneficaryFormValid(details) {
  return beneficiaryValid(details).reduce(validationReducer, new _immutable.Map({
    isValid: true
  }));
};

exports.beneficaryFormValid = beneficaryFormValid;

var isOrganizationNominationValid = function isOrganizationNominationValid(details) {
  return organizationNominationValidator(details).reduce(validationReducer, new _immutable.Map({
    isValid: true
  }));
};

exports.isOrganizationNominationValid = isOrganizationNominationValid;
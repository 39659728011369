import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IPropTypes from 'react-immutable-proptypes';
import buildQuestion from '../questions/questionBuilder';
import LoadingRow from '../common/LoadingRow';
import PageActionButtons from '../common/PageActionButtons';
import { fromJS } from 'immutable';
import QuoteContactDetailsContainer from '../common/QuoteContactDetailsContainer';
import { Form } from '@simply-fin-services/astronomix3';
import QuestionContainer from '../questions/QuestionContainer';

export default class Quote extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      defaultsChecked: false,
    };
  }

  proceedToNextPage = () => {
    if (this.props.isReplaceAndSaveEnabled) {
      this.props
        .populateExtendedFamilyFuneralMembers(this.props.packageId)
        .then(() =>
          this.props.rehydrateProductSelection(
            this.props.packageUrlSlug,
            this.props.interactionId
          )
        )
        .catch((error) => {
          throw error;
        });
    } else {
      this.props.goToNextPage();
    }
  };

  render() {
    const {
      pageQuestions,
      questions,
      answers,
      answerQuestion,
      isProcessingQuote,
      children,
    } = this.props;

    const { packageId, packageUrlSlug, canGoToNextPage } = this.props;
    const finePrint = packageId.startsWith('sim-family') ? '' : '';
    const toControl = buildQuestion(
      questions,
      fromJS([]),
      answers,
      answerQuestion,
      false
    );

    return (
      <div>
        <Form>
          {children}
          {pageQuestions.map((q) => (
            <QuestionContainer>{toControl(q)}</QuestionContainer>
          ))}
        </Form>
        <QuoteContactDetailsContainer packageSlug={packageUrlSlug} />
        {isProcessingQuote && <LoadingRow />}
        {!isProcessingQuote && (
          <PageActionButtons
            onClick={this.proceedToNextPage}
            disabled={!canGoToNextPage}
            canGoBack={this.props.canGoBack}
            customBackAction={() => this.props.goBack()}
            text="See my quote"
            fineprint={finePrint}
          />
        )}
      </div>
    );
  }
}

Quote.propTypes = {
  questions: IPropTypes.map.isRequired,
  answers: IPropTypes.map.isRequired,
  pageQuestions: IPropTypes.listOf(PropTypes.string).isRequired,
  answerQuestion: PropTypes.func.isRequired,
  canGoToNextPage: PropTypes.bool.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  isProcessingQuote: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  defaults: PropTypes.object,
};

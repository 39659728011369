import { COVER_MULTIPLIER } from '../../.shared/constants';
import numeral from 'numeral';

export const toCoveredAmount = amount => {
  const pricing = numeral(amount * COVER_MULTIPLIER).format('0,0');
  const text = `R${pricing}`;
  return text;
};

export const toPremium = amount => {
  const pricing = numeral(amount).format('0.00');
  const text = `R${pricing}pm`;
  return text;
};

export const toAnnualPremium = amount => {
  const pricing = numeral(amount).format('0.00');
  return `R${pricing}pa`;
};

export const toPremiumNoDecimal = amount => {
  const pricing = numeral(amount).format('0');
  const text = `R${pricing}pm`;
  return text;
};

export const toShortPremium = amount => {
  const pricing = numeral(amount).format('0');
  const text = `R${pricing}`;
  return text;
};

export const toShortPremiumWithDecimal = amount => {
  const pricing = numeral(amount).format('0.00');
  const text = `R${pricing}`;
  return text;
};

export const toCurrency = amount => {
  const pricing = numeral(amount).format('0,0.00');
  const text = `R${pricing}`;
  return text;
};

export const toCurrencyNoDecimal = amount => {
  const pricing = numeral(amount).format('0,0');
  const text = `R${pricing}`;
  return text;
};

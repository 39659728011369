import {
  FETCHING_CURRENT_INSURED_PRICING,
  UPDATE_FAMILY_MEMBER_PRICES,
} from './types';
import { API_ROUTES, ROUTES } from '../config/constants';
import request from 'axios';
import * as R from 'ramda';
import { push } from 'connected-react-router';

export const updateFamilyMemberPrices = (
  prices,
  insured,
  effProductId,
  funeralInsuredAmt
) => ({
  type: UPDATE_FAMILY_MEMBER_PRICES,
  payload: { prices, insured, effProductId, funeralInsuredAmt },
});

export const fetchPrice = (insured, effProductId) => (dispatch, getState) => {
  const currentState = getState();
  const interactionId = currentState.get('interactionId');
  const packageId = currentState.getIn([
    'packages',
    currentState.get('selectedPackageSlug'),
    'packageId',
  ]);
  const packageSlug = currentState.get('selectedPackageSlug');
  const funeralProductId = currentState.getIn([
    'packages',
    packageSlug,
    'funeralProduct',
  ]);
  const funeralInsuredAmt = currentState.getIn([
    'productSelection',
    'products',
    funeralProductId,
    'insuredAmount',
  ]);
  const answers = R.reduce(
    (questionAnswers, key) =>
      R.assoc(key, R.path([key, 'answer'], insured), questionAnswers),
    {},
    R.keys(insured)
  );
  answers.funeralCoverAmount = funeralInsuredAmt;
  return request
    .post(API_ROUTES.PRICE_EXTENDED_FAMILY_MEMBER, {
      answers,
      packageId,
      interactionId,
    })
    .then((response) => {
      dispatch(
        updateFamilyMemberPrices(
          response,
          insured,
          effProductId,
          funeralInsuredAmt
        )
      );
    })
    .catch(() => {
      dispatch(push(ROUTES.ERROR));
    });
};

export const fetchPricingForCurrentInsured = (insured, effProductId) => ({
  type: FETCHING_CURRENT_INSURED_PRICING,
  payload: fetchPrice({ ...insured, isCurrent: true }, effProductId),
});

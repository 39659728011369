import { Map, fromJS } from 'immutable';
import {
  RECOMMENDATIONS_RECEIVED,
  GET_RECOMMENDATION,
  GET_RECOMMENDATIONS_FAILED,
  RESET,
  RELOAD_OLD_PACKAGE_SELECTED_PRODUCTS,
  VIEW_QUOTE,
} from '../actions/types';
import createActionHandler from './createActionHandler';

const initialState = Map();

const actionHandlers = {};

const recommendationRetrieved = (state, action) => {
  const {
    recommended,
    recommendations,
    productPriceMap,
    productRopPriceMap,
    suggestedProductPriceMap,
    suggestedProductRopPriceMap,
    extras,
  } = action.payload;
  return state.merge({
    isFetching: false,
    recommendation: fromJS(recommended),
    recommendations: fromJS(recommendations),
    productPriceMap: fromJS(productPriceMap),
    productRopPriceMap: fromJS(productRopPriceMap),
    suggestedProductPriceMap: suggestedProductPriceMap
      ? fromJS(suggestedProductPriceMap)
      : null,
    suggestedProductRopPriceMap: suggestedProductRopPriceMap
      ? fromJS(suggestedProductRopPriceMap)
      : null,
    extras: fromJS(extras),
  });
};

const reloadOldRecommendations = (state, action) => {
  const {
    recommendation,
    recommendations,
    productPriceMap,
    productRopPriceMap,
    extras,
  } = action.payload.recommendation;
  return state.merge({
    isFetching: false,
    recommendation: fromJS(recommendation),
    recommendations: fromJS(recommendations),
    productPriceMap: fromJS(productPriceMap),
    productRopPriceMap: fromJS(productRopPriceMap),
    extras: fromJS(extras),
  });
};

const setQuoteViewed = state => state.set('hasViewedQuote', true);

const getRecommendation = state => state.set('isFetching', true);
const getRecommendationFailed = state => state.set('isFetching', false);
const toInitialState = () => initialState;

actionHandlers[RESET] = toInitialState;
actionHandlers[RECOMMENDATIONS_RECEIVED] = recommendationRetrieved;
actionHandlers[GET_RECOMMENDATION] = getRecommendation;
actionHandlers[GET_RECOMMENDATIONS_FAILED] = getRecommendationFailed;
actionHandlers[RELOAD_OLD_PACKAGE_SELECTED_PRODUCTS] = reloadOldRecommendations;
actionHandlers[VIEW_QUOTE] = setQuoteViewed;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

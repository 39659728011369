import { ROUTES } from '../../config/constants';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { shouldLinktoCampaign } from '../../actions/campaign';
import ActionOnLandingPage from '../common/ActionOnLandingPage';

export const mapStateToProps = () => ({});

export const mapDispatchToProps = (dispatch, ownProps) => {
  const { sourceId, campaignId } = ownProps.match.params;
  return {
    landed: () => {
      dispatch(push(ROUTES.HOME));
      dispatch(shouldLinktoCampaign(campaignId, sourceId));
    },
  };
};

const CampaignLandingPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionOnLandingPage);

export default CampaignLandingPageContainer;

import { Map, fromJS } from 'immutable';
import {
  ANSWER_QUESTION,
  ANSWER_QUESTION_SUCCESS,
  ANSWER_QUESTION_ERROR,
  RESET,
  REMOVE_ANSWER,
} from '../actions/types';
import createActionHandler from './createActionHandler';

const initialState = Map();

const actionHandlers = {};

const toAnswerQuestionState = (state, action) => {
  const { answer, questionId } = action.meta;
  const answerToSave = {
    isLoading: true,
    answer,
  };
  return state.set(questionId, fromJS(answerToSave));
};

const toAnswerQuestionSuccessState = (state, action) => {
  const { questionId } = action.payload;
  const answerToSave = Object.assign({ isLoading: false }, action.payload);
  return state.set(questionId, fromJS(answerToSave));
};

const toAnswerQuestionErrorState = (state, action) => {
  const questionId = action.payload;
  return state.remove(questionId);
};

const removeAnswer = (state, action) => {
  const {questionId} = action.payload;

  return state.delete(questionId);
};

const toInitialState = () => initialState;

actionHandlers[RESET] = toInitialState;
actionHandlers[ANSWER_QUESTION] = toAnswerQuestionState;
actionHandlers[ANSWER_QUESTION_SUCCESS] = toAnswerQuestionSuccessState;
actionHandlers[ANSWER_QUESTION_ERROR] = toAnswerQuestionErrorState;
actionHandlers[REMOVE_ANSWER] = removeAnswer;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

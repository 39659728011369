import React from 'react';
import { Route, Switch } from 'react-router';
import { ROUTES } from './config/constants';

import App from './views/App';
import Home from './views/home/HomeContainer';
import NotFound from './views/NotFound';
import ErrorPage from './views/ErrorPage';
import QuestionPageContainer from './views/questionnaire/QuestionPageContainer';
import PackageQuoteContainer from './views/quote/PackageQuoteContainer';
import Qtest from './views/QTEST';
import CustomizationPageContainer from './views/customization/CustomizationPageContainer';
import Features from './views/features/Features';
import BankingDetailContainer from './views/bankingDetails/BankingDetailContainer';
import SimplifiedTermsContainer from './views/terms/SimplifiedTermsContainer';
import SimplifiedInsuredTermsContainer from './views/terms/insured/SimplifiedInsuredTermsContainer';
import PeepsPage from './views/peeps/PeepsPage';
import ThankYouContainer from './views/thankYou/ThankYouContainer';
import CampaignLandingPage from './views/campaigns/CampaignLandingPage';
import ReferralSignup from './views/referralSignup/ReferralSignup';
import ReferralLogin from './views/referrals/login';
import ReferralTerms from './views/referralSignup/TermsAndConditions';
import ReferralHome from './views/referrals/ReferralHome';
import ReferrerLandingPage from './views/referrerLinking/ReferrerLandingPage';
import QuoteLandingPage from './views/quote/QuoteLandingPage';
import BrokerLandingPage from './views/broker/BrokerLandingPage';
import WelcomeBackPage from './views/welcomeBack/WelcomeBackPage';
import ConnectFriendsPage from './views/referrals/ConnectFriendsPage';
import DetailsCaptureInit from './views/detailsCapture/DetailsCaptureInit';
import Underwriting from './views/detailsCapture/Underwriting';
import DetailPeeps from './views/detailsCapture/DetailPeeps';
import Done from './views/detailsCapture/Done';
import DetailsCaptureCheckId from './views/detailsCapture/DetailsCaptureCheckId';
import PolicyReplacementPageContainer from './views/replaceAndSave/PolicyReplacementPageContainer';

export default () => {
  return (
    <App>
      <Switch>
        <Route exact path={ROUTES.REPLACE_AND_SAVE_DASHBOARD} component={PolicyReplacementPageContainer} />
        <Route exact path="/" component={Home} />
        <Route path="/qtest" component={Qtest} />
        <Route path={ROUTES.NOT_FOUND} component={NotFound} />
        <Route path={ROUTES.QUOTE_P} component={PackageQuoteContainer} />
        <Route path={ROUTES.QUOTE_LANDING} component={QuoteLandingPage} />
        <Route path={ROUTES.BROKER_LANDING} component={BrokerLandingPage} />
        <Route path={ROUTES.WELCOME_BACK} component={WelcomeBackPage} />
        <Route path={ROUTES.DETAIL_P} component={QuestionPageContainer} />
        <Route
          path={ROUTES.CUSTOMIZE_P}
          component={CustomizationPageContainer}
        />
        <Route
          path={ROUTES.NEARLY_THERE}
          component={CustomizationPageContainer}
        />

        <Route exact path={ROUTES.FEATURES_P} component={Features} />
        <Route
          path={ROUTES.BANKING_DETAILS_P}
          component={BankingDetailContainer}
        />
        <Route
          path={ROUTES.SIMPLIFIED_TERMS_P}
          component={SimplifiedTermsContainer}
        />
        <Route
          path={ROUTES.SIMPLIFIED_INSURED_TERMS_P}
          component={SimplifiedInsuredTermsContainer}
        />
        <Route path={ROUTES.PEEPS} component={PeepsPage} />
        <Route path={ROUTES.THANK_YOU_P} component={ThankYouContainer} />
        <Route path={ROUTES.THANK_YOU} component={ThankYouContainer} />
        <Route path={ROUTES.ERROR} component={ErrorPage} />
        <Route path={ROUTES.CAMPAIGN_P} component={CampaignLandingPage} />
        <Route
          path={ROUTES.REFERRAL_URL_WITH_DEETS_P}
          component={ReferrerLandingPage}
        />
        <Route path={ROUTES.CAMPAIGN_2_P} component={CampaignLandingPage} />
        <Route path={ROUTES.REFERRER_LOGIN} component={ReferralLogin} />
        <Route path={ROUTES.REFERRAL_SIGNUP} component={ReferralSignup} />
        <Route path={ROUTES.REFERRAL_TERMS} component={ReferralTerms} />
        <Route
          path={ROUTES.REFERRAL_HOMEPAGE_WITH_DEETS_P}
          component={ReferralHome}
        />
        <Route
          path={ROUTES.CONNECT_FRIENDS_PAGE}
          component={ConnectFriendsPage}
        />
        <Route
          exact
          path={ROUTES.DETAILS_CAPTURE_LANDING}
          component={DetailsCaptureCheckId}
        />
        <Route
          exact
          path={ROUTES.DETAILS_CAPTURE_INIT}
          component={DetailsCaptureInit}
        />
        <Route exact path={ROUTES.DETAILS_CAPTURE} component={Underwriting} />
        <Route
          exact
          path={ROUTES.DETAILS_CAPTURE_PEEPS}
          component={DetailPeeps}
        />
        <Route
          exact
          path={ROUTES.DETAILS_CAPTURE_PEEPS_P}
          component={DetailPeeps}
        />
        <Route path={ROUTES.DETAILS_CAPTURE_THANK_YOU} component={Done} />
        <Route path="*" component={NotFound} />
      </Switch>
    </App>
  );
};

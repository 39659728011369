import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { ROUTES } from '../../config/constants';
import * as R from 'ramda';
class CanRefer extends PureComponent {
  componentWillMount() {
    this.props.redirect();
  }

  render() {
    return <div />;
  }
}

export const mapStateToProps = state => {
  const url = state.getIn(['referralSignup', 'referralUrl']);
  return {
    referralCode: state.getIn(['referralSignup', 'referralCode']),
    referralUrl: window ? window.encodeURI(url) : url,
    referrerName: state.getIn(['referralSignup', 'referrerName']).answer,
  };
};

const toReferralHome = R.curry((dispatch, referralCode, name, referralUrl) =>
  dispatch(
    push(ROUTES.toReferrerHomePageWithDeets(referralCode, name, referralUrl))
  )
);

export const mapDispatchToProps = dispatch => ({
  redirect: toReferralHome(dispatch),
});

const mergeProps = (sp, dp) =>
  Object.assign({}, sp, dp, {
    redirect: () =>
      dp.redirect(sp.referralCode, sp.referrerName, sp.referralUrl),
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CanRefer);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IPropTypes from 'react-immutable-proptypes';
import { PageDescriptionHtml } from '../common/PageDescription';
import buildQuestion from '../questions/questionBuilder';
import LoadingRow from '../common/LoadingRow';
import PageActionButtons from '../common/PageActionButtons';
import { Form, FieldContainer, WallOfTextContainer } from '@simply-fin-services/astronomix3';
import * as R from 'ramda';
import styled from 'styled-components';
import PersonalInfoProtectionNotice from './pageComponents/pip';

const PageDetail = styled.p`
  max-width: 500px;
  margin-top: -0.8rem;
  @media (max-width: 767px) {
    margin-left: 1.6rem;
    margin-right: 1.5rem;
  }
  @media (min-width: 767px) {
    min-width: 500px;
  }
`;

const renderAdditionalPageComponent = (componentConfig, packageId) => {
  const components = {
    'personal-information-protection': PersonalInfoProtectionNotice,
  };
  const Component = components[componentConfig.id];
  if (Component) {
    return <Component packageId={packageId} {...componentConfig} />;
  }
  return '';
};

export class QuestionPageQuestionsBase extends PureComponent {
  render() {
    const {
      pageQuestions,
      optionalPageQuestions,
      questions,
      answers,
      answerQuestion,
      className,
      fieldClass,
      mustSuggestPackage,
      isAnsweringQuestion,
      pageDetail,
    } = this.props;

    const hideUnansweredQuestions =
      mustSuggestPackage && isAnsweringQuestion === false;

    const toControl = buildQuestion(
      questions,
      optionalPageQuestions,
      answers,
      answerQuestion,
      hideUnansweredQuestions
    );

    return (
      <Form className={className}>
        {pageDetail && <PageDetail>{pageDetail}</PageDetail>}
        {pageQuestions.map((q) => {
          const actualQuestionId = questions.getIn([q, 'questionId']);
          const answer = answers.get(actualQuestionId || q);
          const shouldHideOnRender = answer
            ? answer.get('shouldHideOnRender')
            : false;

          const isValid = answer ? answer.get('isValid') : false;
          const hideQuestion = hideUnansweredQuestions && !answer;
          if (
            !hideQuestion &&
            (!shouldHideOnRender || (shouldHideOnRender && !isValid))
          ) {
            return (
              <FieldContainer
                fieldClass={fieldClass}
              >
                {toControl(q)}
              </FieldContainer>
            );
          }
          return '';
        })}
      </Form>
    );
  }
}

QuestionPageQuestionsBase.propTypes = {
  questions: IPropTypes.map.isRequired,
  answers: IPropTypes.map.isRequired,
  pageQuestions: IPropTypes.listOf(PropTypes.string).isRequired,
  answerQuestion: PropTypes.func.isRequired,
};

export class QuestionPageQuestions extends PureComponent {
  render() {
    const { formContainerClass } = this.props;
    const formClass =
      !R.isNil(formContainerClass) && !R.isEmpty(formContainerClass)
        ? formContainerClass
        : ``;
    return (
      <div className={formClass}>
        <QuestionPageQuestionsBase {...this.props} />
      </div>
    );
  }
}

QuestionPageQuestions.propTypes = {
  questions: IPropTypes.map.isRequired,
  answers: IPropTypes.map.isRequired,
  pageQuestions: IPropTypes.listOf(PropTypes.string).isRequired,
  optionalPageQuestions: IPropTypes.listOf(PropTypes.string).isRequired,
  answerQuestion: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  sideBySide: PropTypes.bool,
};

class QuestionPage extends PureComponent {
  render() {
    const {
      description,
      subHeading,
      isProcessingQuote,
      footerDescription,
      mustSuggestPackage,
      suggestedPackageDescription,
      goToSuggestedPackage,
      suggestPackageButtonText,
      isAnsweringQuestion,
      callToAction,
      continueButtonText,
      packageId,
      additionalPageComponents,
      thirdPartyBrokerMessage,
      isThirdPartyBrokerSale
    } = this.props;
    const { canGoToNextPage, goToNextPage } = this.props;

    const additionalComponents =
      R.isEmpty(additionalPageComponents) || R.isNil(additionalPageComponents)
        ? null
        : additionalPageComponents.toJS();

    return (
      <div>
        <div>
          {description && (
            <PageDescriptionHtml
              description={description}
              subHeading={subHeading}
            />
          )}

          <QuestionPageQuestions {...this.props} />
          {isProcessingQuote && <LoadingRow />}
          {mustSuggestPackage && isAnsweringQuestion === false && (
            <Form>
              <FieldContainer>
                {suggestedPackageDescription}
              </FieldContainer>
            </Form>
          )}
          {footerDescription && canGoToNextPage && (
            <PageDescriptionHtml description={footerDescription} />
          )}
          <div>
            {additionalComponents &&
              additionalComponents.map((component) =>
                renderAdditionalPageComponent(component, packageId)
              )}
          </div>
        </div>

        {isThirdPartyBrokerSale && thirdPartyBrokerMessage &&
          <WallOfTextContainer className="text-center">
            <br />
            <h5 className='text-danger'>{thirdPartyBrokerMessage}</h5>
          </WallOfTextContainer>}

        {((!isProcessingQuote && !mustSuggestPackage) ||
          isAnsweringQuestion === true) && (
          <PageActionButtons
            onClick={goToNextPage}
            disabled={!canGoToNextPage}
            text={continueButtonText}
            fineprint={callToAction}
            canGoBack
          />
        )}
        {isProcessingQuote === false &&
          mustSuggestPackage &&
          isAnsweringQuestion === false && (
            <PageActionButtons
              onClick={goToSuggestedPackage}
              canGoBack
              text={suggestPackageButtonText}
            />
          )}
      </div>
    );
  }
}

QuestionPage.propTypes = {
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string,
  footerDescription: PropTypes.string,
  questions: IPropTypes.map.isRequired,
  answers: IPropTypes.map.isRequired,
  pageQuestions: IPropTypes.listOf(PropTypes.string).isRequired,
  optionalPageQuestions: IPropTypes.listOf(PropTypes.string).isRequired,
  answerQuestion: PropTypes.func.isRequired,
  canGoToNextPage: PropTypes.bool.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  isProcessingQuote: PropTypes.bool.isRequired,
  mustSuggestPackage: PropTypes.bool.isRequired,
  packageToSuggestSlug: PropTypes.string,
  suggestedPackageDescription: PropTypes.string,
  goToSuggestedPackage: PropTypes.func,
  suggestPackageButtonText: PropTypes.string,
  isAnsweringQuestion: PropTypes.bool,
  continueButtonText: PropTypes.string,
  callToAction: PropTypes.string,
  isThirdPartyBrokerSale: PropTypes.bool,
  thirdPartyBrokerMessage: PropTypes.string,
};

export default QuestionPage;

import { fromJS } from 'immutable';
import {
  INIT_DETAILS_CAPTURE,
  RESET,
  DETAILS_CAPTURE_SUBMIT_UNDERWRITING_SUCCESS,
} from '../actions/types';
import createActionHandler from './createActionHandler';

const initialState = fromJS({
  parent: '',
  reference: '',
  selectedPackage: '',
  shouldUnderwrite: false,
  cover: [],
  failedUnderwriting: false,
});

const actionHandlers = {};

const init = (state, action) => {
  const { meta } = action;
  return state.merge(meta);
};

const completeUnderwriting = (state, action) => {
  const { payload } = action;
  const isEligible =
    payload.data && payload.data.data && payload.data.data.isEligible;
  return state.set('failedUnderwriting', isEligible === false);
};

const toInitialState = () => initialState;

actionHandlers[RESET] = toInitialState;
actionHandlers[INIT_DETAILS_CAPTURE] = init;
actionHandlers[
  DETAILS_CAPTURE_SUBMIT_UNDERWRITING_SUCCESS
] = completeUnderwriting;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

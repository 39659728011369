/* eslint-disable react/jsx-indent */
import React from 'react';
import styled from 'styled-components';
import { toCurrencyNoDecimal } from '../../common/numberFormats';

const Container = styled.div``;

const PolicyContainer = styled.div`
  margin-bottom: 0.75rem;
  border: 0.5px solid #9797a5;
  box-shadow: -1px -1px 8px 1px #81818133;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.75rem;
  align-content: space-between;
  background-color: #2597d0;
  color: #fff;
`;

const HeaderRight = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  div {
    a {
      color: #fff;
      font-size: 1.2rem;
      margin-left: 1rem;
    }
    a:hover {
      color: #c4c4c4;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const PolicyHolders = styled.div`
  display: flex;
  border-bottom: 0.5px solid #9797a5;
  justify-content: space-between;
  align-content: space-evenly;
  padding: 0.7rem;
  &:last-child {
    border-bottom: none;
  }
`;

const MyPolicy = (props) => {
  const { policies, removeReplacementPolicy, maintainPolicy } = props;

  return (
    <Container>
      {policies.map((policy) => (
        <PolicyContainer key={policy.get('policyNumber')}>
          <Header key={policy.get('policyNumber')}>
            <div>
              <div>
                <strong>{policy.get('insurer')}: </strong>
                <span>{policy.get('policyNumber')}</span>
              </div>
              <div>
                <strong>Incepted on: </strong>
                {policy.get('formattedInceptionDate')}
              </div>
            </div>
            <HeaderRight>
              <div>
                <a onClick={() => maintainPolicy(policy)}>
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </a>

                <a
                  onClick={() =>
                    removeReplacementPolicy(policy.get('policyNumber'))
                  }
                >
                  <i className="fa fa-trash-o" aria-hidden="true"></i>
                </a>
              </div>
              <div>
                <strong>Premium: </strong>{toCurrencyNoDecimal(policy.get('monthlyPremium'))}
              </div>
            </HeaderRight>
          </Header>
          <Content>
            <PolicyHolders>
              <div>Main person: </div>
              <div>
                {toCurrencyNoDecimal(policy.get('mainLifeCoverAmount'))}
              </div>
            </PolicyHolders>

            {policy.get('policyCoverAllocation').map((coverAllocation) => (
              <PolicyHolders key={coverAllocation.get('insuredPersonId')}>
                <div>{coverAllocation.get('person').get('fullName')}:</div>
                <div>
                  {toCurrencyNoDecimal(coverAllocation.get('coverAmount'))}
                </div>
              </PolicyHolders>
            ))}
          </Content>
        </PolicyContainer>
      ))}
    </Container>
  );
};

export default MyPolicy;

import { connect } from 'react-redux';
import PrepareQuote from './PrepareQuote';

export const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, ownProps, {
    prepareQuotePopupVisible: state.getIn([
      'prepareQuotePopup',
      'prepareQuotePopupVisible',
    ]),
    isLoading: state.getIn(['prepareQuotePopup', 'prepareQuotePopupLoading']),
  });
};

export const mapDispatchToProps = dispatch => {
  return Object.assign(
    {},
    {
      dispatch,
    }
  );
};

export const mergeProps = (stateProps, dispatchProps) => {
  return Object.assign({}, stateProps, dispatchProps);
};

const PrepareQuoteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PrepareQuote);

export default PrepareQuoteContainer;

import React, { PureComponent } from 'react';
import { Toaster } from '@simply-fin-services/astronomix3';
import { replacementPolicySummarySentence } from '../sharedComponents';

class Banner extends PureComponent {
  componentDidMount() {
    if(this.props.isReplaceAndSaveEnabled){
      this.props.fetchLatestPolicyReplacementData();
    }
  }

  render() {
    // Component knows how to feature toggle itself
    if (!this.props.isReplaceAndSaveEnabled) return <React.Fragment />;

    return (
      <Toaster
        slices={[
          {
            type: 'primary',
            toast: (
              <span>
                {replacementPolicySummarySentence({
                  totalReplacementPolicies: this.props.totalReplacementPolicies,
                  totalMonthlyPremium: this.props.totalMonthlyPremium,
                })}
              </span>
            ),
          },
        ]}
      />
    );
  }
}

export default Banner;

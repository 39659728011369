import { connect } from 'react-redux';
import {
  isReplaceAndSaveEnabled,
  totalReplacementPolicies,
  totalMonthlyPremium,
  totalCover,
} from '../../../stateStore/replaceAndSave';
import Banner from './Banner';
import { fetchLatestPolicyReplacementData } from '../httpApi';

export const mapStateToProps = (state) => {
  return {
    isReplaceAndSaveEnabled: isReplaceAndSaveEnabled(state),
    totalReplacementPolicies: totalReplacementPolicies(state),
    totalMonthlyPremium: totalMonthlyPremium(state),
    totalCover: totalCover(state),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchLatestPolicyReplacementData: () =>
    fetchLatestPolicyReplacementData(dispatch),
});

const BannerContainer = connect(mapStateToProps, mapDispatchToProps)(Banner);

export default BannerContainer;

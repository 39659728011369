/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavBar from './layout/NavBar';
import Meta from './layout/Meta';
import '../polyfills/media.match.min._js';
import ProgressContainer from './common/ProgressContainer';
import {
  Theme as AstroTheme,
  Footer,
  Container,
  Row,
  Col,
} from '@simply-fin-services/astronomix3';
import CompleteSaleReminder, {
  isPostSaleContent,
} from './completeSaleReminder';
import { getLinksQuery } from '../common/getLinksQuery';

const App = function app({
  theme,
  children,
  canRenderSaleContent,
  linksQuery,
  WEB_SITE_BASE,
}) {
  const scroll = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // some browsers (like safari) may require a timeout to delay calling this
    // function after a page has loaded; otherwise, it may not update the position
    setTimeout(scroll, 100);
  }, []);

  useEffect(() => {
    const onChunkLoadFailure = (event) => {
      const { error } = event;
      if (/Loading chunk \d+ failed/.test(error.message)) {
        const body = document.getElementsByTagName('body')[0];
        body.innerHTML = `<p>🎉 Looks like something new is out. Please <a href="javascript:window.location.reload();">refresh</a>.</p>
           <p>If this message does not go away when you refresh then the new changes cannot be downloaded at this time. Please try again later.</p>`;
      }
    };
    window.addEventListener('error', onChunkLoadFailure);
    return () => {
      window.removeEventListener('error', onChunkLoadFailure);
    };
  }, []);

  return (
    <AstroTheme themeName={theme} applyBackground>
      <div id="top"></div>
      <Meta themeName={theme} />
      <NavBar linksQuery={linksQuery} />
      <Container>
        <Row>
          <Col xs={12} lg={10} lgOffset={1}>
            {canRenderSaleContent && <ProgressContainer />}
            {canRenderSaleContent === true && (
              <div className="shrink-to-fit-form">{children}</div>
            )}
            {canRenderSaleContent === false && <CompleteSaleReminder />}
          </Col>
        </Row>
      </Container>
      <Footer
        websiteDomain={WEB_SITE_BASE}
        domain={WEB_SITE_BASE}
        urlQuery={linksQuery}
      />
    </AstroTheme>
  );
};

App.propTypes = {
  children: PropTypes.object,
  theme: PropTypes.string.isRequired,
  WEB_SITE_BASE: PropTypes.string.isRequired,
  linksQuery: PropTypes.string,
  canRenderSaleContent: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  theme: state.getIn(['currentTheme', 'theme']),
  WEB_SITE_BASE: state.getIn(['config', 'WEB_SITE_BASE']),
  linksQuery: (() => {
    return getLinksQuery(state);
  })(),
  canRenderSaleContent: (() => {
    const customerHasCompletedSale = state.getIn([
      'completeSale',
      'customerHasCompletedSale',
    ]);
    const currentPath = state.getIn(['router', 'location', 'pathname']);
    return customerHasCompletedSale
      ? isPostSaleContent(currentPath)
      : !customerHasCompletedSale;
  })(),
});

export default connect(mapStateToProps)(App);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  QUESTION_TIMEOUT_DELAY_IN_MILLISECONDS,
  COUNTRY_CODE,
} from '../../config/appConfig';
import { InputText } from '@simply-fin-services/astronomix3';
import QuestionContainer, {
  isNotEligible,
  showError,
} from './QuestionContainer';

const INITIAL_ANSWER = '';

class PhoneNumberQuestion extends PureComponent {
  constructor() {
    super();
    this.submitQuestion = this.submitQuestion.bind(this);
    this.onChange = this.onChange.bind(this);
    this.startSubmitTimer = this.startSubmitTimer.bind(this);
    this.clearSubmitTimer = this.clearSubmitTimer.bind(this);
    this.typingTimeout = null;
    this.submittedValue = INITIAL_ANSWER;
  }

  componentWillMount() {
    this.setState({ value: this.props.answer || INITIAL_ANSWER });
  }

  submitQuestion() {
    this.clearSubmitTimer();
    const { value } = this.state;
    if (value !== this.submittedValue) {
      const { answerQuestion } = this.props;
      answerQuestion(value);
    }
    this.submittedValue = value;
  }

  onChange(e) {
    const zero = '0';
    const encodeSymbol = '+';
    let value = e.target.value;

    if (value.length > 3) {
      const isPrefixed = R.not(R.equals(zero, R.head(value)));
      const isEncodedPrefix = R.equals(encodeSymbol, R.head(value));
      const swapForZero = R.curry((char, str) => str.replace(char, zero));
      const swapPrefixForZero = swapForZero(COUNTRY_CODE);
      const swapEncodedPrefixForZero = swapForZero(
        R.concat(encodeSymbol, COUNTRY_CODE)
      );

      const prefixedValue = isEncodedPrefix
        ? swapEncodedPrefixForZero(value)
        : swapPrefixForZero(value);
      value = isPrefixed ? prefixedValue : value;
    }
    this.setState({ value });
  }

  startSubmitTimer() {
    this.clearSubmitTimer();
    this.typingTimeout = window.setTimeout(() => {
      this.submitQuestion();
    }, QUESTION_TIMEOUT_DELAY_IN_MILLISECONDS);
  }

  clearSubmitTimer() {
    window.clearTimeout(this.typingTimeout);
  }

  render() {
    const {
      placeholder,
      disable,
      icon,
      eligibility,
      errors,
      hasWarning,
      warnings,
      packageId,
      hint,
      title,
      max,
      min,
      optional,
    } = this.props;

    const { value } = this.state;
    const disabled = disable || false;

    const eligibilityErrors =
      isNotEligible(eligibility, packageId) && eligibility.failureMessage
        ? [eligibility.failureMessage]
        : [];
    const errorMessages = showError(this.props) ? errors || [] : [];
    const warningMessages = hasWarning ? warnings : [];
    const allErrors = R.concat(
      R.concat(errorMessages, warningMessages),
      eligibilityErrors
    );

    return (
      <QuestionContainer>
        <InputText
          type="text"
          label={title}
          errors={allErrors}
          placeholder={placeholder}
          onBlur={this.submitQuestion}
          onChange={this.onChange}
          onKeyUp={this.startSubmitTimer}
          onKeyDown={this.clearSubmitTimer}
          value={value}
          disabled={disabled}
          hint={hint}
          min={min}
          max={max}
          isOptional={optional}
          icon={icon}
        />
      </QuestionContainer>
    );
  }
}

PhoneNumberQuestion.propTypes = {
  answerQuestion: PropTypes.func,
  placeholder: PropTypes.string,
  answer: PropTypes.string,
  initialValue: PropTypes.string,
  disable: PropTypes.bool,
  hasDateMask: PropTypes.bool,
  labelIcon: PropTypes.string,
};

export default PhoneNumberQuestion;

import * as R from 'ramda';

const setPersonalDetails = state => {
  const updatedPackages = R.mapObjIndexed(p => {
    const { questionnaire, ownerIdentificationQuestionPageId } = p;
    if (
      !questionnaire ||
      !R.is(String, ownerIdentificationQuestionPageId) ||
      R.isEmpty(ownerIdentificationQuestionPageId)
    ) {
      return p;
    }
    const updatedQuestionnaire = R.append(
      ownerIdentificationQuestionPageId,
      questionnaire
    );
    return R.assoc('questionnaire', updatedQuestionnaire, p);
  }, state.packages);
  return R.assoc('packages', updatedPackages, state);
};

export default R.pipe(setPersonalDetails);

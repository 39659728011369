"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var R = _interopRequireWildcard(require("ramda"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var onlyDigits = function onlyDigits(accountNumber) {
  return /^\d+$/.test(accountNumber);
};

var lengthBetween = R.curry(function (minInclusive, maxInclusive, accountNumber) {
  return accountNumber.length >= minInclusive && accountNumber.length <= maxInclusive;
});
var lengthIs = R.curry(function (givenLength, accountNumber) {
  return accountNumber.length === givenLength;
});
var branchCodeIs = R.curry(function (given, branchCode) {
  return given === branchCode;
});
var branchCodeIsNot = R.curry(function (given, branchCode) {
  return given !== branchCode;
});
var accountNumberStartsWith = R.curry(function (givenCharacter, accountNumber) {
  return accountNumber.charAt(0) === givenCharacter;
});

var weirdStandardBankRule = function weirdStandardBankRule(accountnumber, branchCode) {
  return lengthIs(11, accountnumber) && (accountNumberStartsWith('1', accountnumber) || branchCodeIs('051001', branchCode));
};

var weirdSAPostRule = function weirdSAPostRule(accountnumber, branchCode) {
  return lengthIs(11, accountnumber) && (accountNumberStartsWith('0', accountnumber) || branchCodeIsNot('0011', branchCode));
};

var weirdInvestecRule = function weirdInvestecRule(accountnumber, branchCode) {
  return lengthIs(11, accountnumber) && (accountNumberStartsWith('0', accountnumber) || branchCodeIsNot('0011', branchCode));
};

var weirdAfricanBankRule = function weirdAfricanBankRule(accountnumber, branchCode) {
  return lengthIs(11, accountnumber) && (accountNumberStartsWith('0', accountnumber) || branchCodeIsNot('0011', branchCode));
};

var alwaysValid = function alwaysValid() {
  return true;
};

var exists = function exists(accountnumber) {
  return accountnumber && accountnumber.length;
};

var StandardBank = [lengthIs(9), weirdStandardBankRule];
var Nedbank = [lengthIs(10), lengthIs(11)];
var Absa = [lengthBetween(8, 11)];
var AfricanBank = [weirdAfricanBankRule];
var MercantileBank = [lengthIs(10)];
var Capitec = [lengthIs(10)];
var DiscoveryBank = [lengthIs(11)];
var SAPost = [weirdSAPostRule];
var Bidvest = [lengthIs(10), lengthIs(11)];
var Investec = [weirdInvestecRule];
var BankofAthens = [lengthIs(7), lengthIs(11)];
var FNB = [lengthBetween(10, 11)];
var Tyme = [lengthIs(11)];
var BankZero = [lengthIs(11)];
var Finbond = [lengthIs(12)];
var SasfinBank = [lengthIs(11)];
var Other = [alwaysValid];

var validationWrapper = function validationWrapper(validators) {
  return function (accountnumber, branchCode) {
    return exists(accountnumber) && onlyDigits(accountnumber) && validators.map(function (v) {
      return v(accountnumber, branchCode);
    }).reduce(function (valid, v) {
      return valid || v;
    }, false);
  };
};

var _default = {
  validateStandardBank: validationWrapper(StandardBank),
  validateNedbank: validationWrapper(Nedbank),
  validateAbsa: validationWrapper(Absa),
  validateAfricanBank: validationWrapper(AfricanBank),
  validateMercantileBank: validationWrapper(MercantileBank),
  validateCapitec: validationWrapper(Capitec),
  validateDiscoveryBank: validationWrapper(DiscoveryBank),
  validateSAPost: validationWrapper(SAPost),
  validateBidvest: validationWrapper(Bidvest),
  validateInvestec: validationWrapper(Investec),
  validateBankofAthens: validationWrapper(BankofAthens),
  validateFNB: validationWrapper(FNB),
  validateTyme: validationWrapper(Tyme),
  validateBankZero: validationWrapper(BankZero),
  validateFinbond: validationWrapper(Finbond),
  validateSasfinBank: validationWrapper(SasfinBank),
  validateOther: validationWrapper(Other)
};
exports["default"] = _default;
import { Map } from 'immutable';
import { SET_SHOW_CONTACT_DETAILS_POPUP_FLAG } from '../actions/types';
import createActionHandler from './createActionHandler';

const initialState = Map({
  contactDetailsPopupVisisble: true,
});

const actionHandlers = {};

const setContactDetailsPopupFlag = (state, action) => {
  return state.set('contactDetailsPopupVisisble', action.payload);
};

actionHandlers[
  SET_SHOW_CONTACT_DETAILS_POPUP_FLAG
] = setContactDetailsPopupFlag;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import AddPeep from './AddPeep';
import * as R from 'ramda';
import { fromJS } from 'immutable';
import {
  PrimaryButton,
  Checkbox,
  Buttons,
} from '@simply-fin-services/astronomix3';
import newId from '../../common/newId';
import moment from 'moment';
import styled from 'styled-components';

const DangerText = styled.p`
  color: #ff3232;
  margin-left: 25px;
  font-size: 12px;
`;
class Peep extends PureComponent {
  render() {
    const { text, checked, toggle, strokeThrough, warnIfUnder18, isUnder18 } =
      this.props;
    const uniqueId = newId();
    const under18Warning = (
      <DangerText>
        As this person is under 18, payout can only go to their legally
        appointed guardian or the Guardian's Fund, which is government
        controlled.
      </DangerText>
    );
    return (
      <Fragment key={text}>
        {strokeThrough && (
          <Checkbox checked={checked} onChange={toggle} id={uniqueId}>
            <s>{text}</s>
          </Checkbox>
        )}
        {!strokeThrough && (
          <React.Fragment>
            <Checkbox
              checked={checked}
              onChange={toggle}
              id={uniqueId}
              labelText={text}
            >
              {text}
              <br />
            </Checkbox>
            {warnIfUnder18 && isUnder18 && under18Warning}
          </React.Fragment>
        )}
      </Fragment>
    );
  }
}

Peep.propTypes = {
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  warnIfUnder18: PropTypes.bool,
};

class UnavailablePeep extends PureComponent {
  render() {
    return <Peep {...this.props} checked={false} />;
  }
}

UnavailablePeep.propTypes = {
  text: PropTypes.string.isRequired,
};

class SelectedPeep extends PureComponent {
  render() {
    return <Peep {...this.props} checked />;
  }
}

class AvailablePeep extends PureComponent {
  render() {
    return <Peep {...this.props} checked={false} />;
  }
}

/* eslint-disable no-underscore-dangle */
const ageFromDob = ({ dateOfBirth }) =>
  moment().diff(moment(dateOfBirth, 'DD/MM/YYYY'), 'years');
const childOver21 = R.pipe(ageFromDob, R.gt(R.__, 21));
const peepUnder18 = R.pipe(ageFromDob, R.lt(R.__, 18));
/* eslint-enable no-underscore-dangle */
const isChild = (p) => p.relationship === 'Child';
const isOther = (p) => p.relationship === 'Other';
const mapPeeps = (
  select,
  deselect,
  peeps,
  isInsuredFuneralPeep,
  warnIfUnder18
) => {
  const selectedPeep = R.omit(
    ['isSelected', 'canAdd'],
    R.head(peeps.filter((p) => p.isSelected))
  );
  const filteredPeeps = isInsuredFuneralPeep
    ? peeps.filter((p) => (isChild(p) ? !childOver21(p) : !isOther(p)))
    : peeps;
  return R.map((p) => {
    const text = `${p.name} ${p.surname} (${p.relationship}) (${p.dateOfBirth})`;
    const cleanP = R.omit(['isSelected', 'canAdd', 'isUnder18'], p);
    const peepWithWarnings = R.pipe(
      R.assoc('isUnder18', peepUnder18(p)),
      R.omit(['isSelected', 'canAdd'])
    );
    /* eslint-disable no-else-return */
    if (p.isSelected) {
      return (
        <SelectedPeep
          {...peepWithWarnings(p)}
          text={text}
          key={text}
          toggle={() => deselect(cleanP)}
          warnIfUnder18={warnIfUnder18}
        />
      );
    } else if (p.canAdd) {
      return (
        <AvailablePeep
          {...cleanP}
          text={text}
          key={text}
          toggle={() => select(cleanP)}
        />
      );
    }
    const onSelect = () => {
      deselect(selectedPeep);
      select(cleanP);
    };
    return <UnavailablePeep text={text} key={text} toggle={onSelect} />;
  }, filteredPeeps);
};

const relationshipTypePluralMapping = fromJS({
  Child: 'Children',
});

const mapRelationshipTypeToPlural = (type) => {
  if (relationshipTypePluralMapping.has(type)) {
    return relationshipTypePluralMapping.get(type);
  }
  return type;
};

const toMaxPerTypeText = (props) => {
  const { max, maxPerType, description } = props;
  if (description) {
    return description;
  }
  if (maxPerType) {
    const maxPerTypeText = R.map(
      (t) => `${t.max} ${mapRelationshipTypeToPlural(t.relationship)}`,
      maxPerType
    );
    const maxTypeText = R.join(' and ', maxPerTypeText);
    return `Nominate up to ${maxTypeText}`;
  }
  if (max) {
    return `Nominate up to ${max} people`;
  }
  return '';
};

const sortByDate = (arr) =>
  arr.sort((a, b) => {
    const dateA = new Date(a.dateOfBirth);
    const dateB = new Date(b.dateOfBirth);
    return dateA - dateB;
  });

const buildPeepHeader = (peepProps) => {
  const { title, descriptionRenderable, type } = peepProps;
  if (type === 'insureds' && !descriptionRenderable) {
    return '';
  }
  return (
    <React.Fragment>
      <h5 className="bold space-below">
        {title}
        {type === 'Beneficiary' ? ' Benefit' : ''}
      </h5>
      <p className="space-below">{toMaxPerTypeText(peepProps)}</p>
    </React.Fragment>
  );
};

const getProductPeepsButtonTextByType = (text, type) =>
  (type === 'insureds' && text) || 'Add beneficiaries';

export default class ProductPeeps extends PureComponent {
  constructor() {
    super();
    this.showAddPeep = this.showAddPeep.bind(this);
    this.hideAddPeep = this.hideAddPeep.bind(this);
    this.state = { showAddPeep: false };
  }

  showAddPeep() {
    this.setState({ showAddPeep: true });
  }

  hideAddPeep() {
    this.setState({ showAddPeep: false });
  }

  render() {
    const {
      title,
      peeps,
      select,
      deselect,
      relationships,
      addPeep,
      ageRanges,
      productId,
      type,
      warnIfUnder18,
      addPeepButtonText,
    } = this.props;

    const { showAddPeep } = this.state;
    const add = (peep) => {
      addPeep(peep);
      this.hideAddPeep();
    };
    const Header = buildPeepHeader(this.props);
    const buttonText = getProductPeepsButtonTextByType(addPeepButtonText, type);
    const isInsuredFuneralPeep =
      R.includes('sim-funeral', productId) && type === 'insureds';
    const canAdd = this.props.canAdd;
    return (
      <div id={`${title}-${type}`} key={title} className="space-below">
        {Header}
        <div className="space-below">
          {mapPeeps(
            select,
            deselect,
            sortByDate(peeps),
            isInsuredFuneralPeep,
            warnIfUnder18
          )}
        </div>
        {this.props.children}
        <div className="space-above-2"></div>
        {canAdd && (
          <Buttons alignment="flex-start">
            <PrimaryButton
              onClick={this.showAddPeep}
              className="space-below space-above"
            >
              {buttonText}
            </PrimaryButton>
          </Buttons>
        )}
        {showAddPeep && (
          <AddPeep
            relationshipTypes={relationships}
            ageRanges={ageRanges}
            hide={this.hideAddPeep}
            addPeep={add}
            type={type}
            containerId={`${title}-${type}`}
          />
        )}
      </div>
    );
  }
}

ProductPeeps.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  select: PropTypes.func.isRequired,
  deselect: PropTypes.func.isRequired,
  addPeep: PropTypes.func.isRequired,
  canAdd: PropTypes.bool.isRequired,
  peeps: PropTypes.arrayOf(
    PropTypes.shape({
      isSelected: PropTypes.bool.isRequired,
      canAdd: PropTypes.bool.isRequired,
    })
  ).isRequired,
  max: PropTypes.number,
  maxPerType: PropTypes.arrayOf(
    PropTypes.shape({
      relationship: PropTypes.string.isRequired,
      max: PropTypes.number.isRequired,
    })
  ),
  relationships: PropTypes.arrayOf(PropTypes.string).isRequired,
  ageRanges: PropTypes.arrayOf(
    PropTypes.shape({
      relationship: PropTypes.string.isRequired,
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    })
  ).isRequired,
  descriptionRenderable: PropTypes.bool.isRequired,
  warnIfUnder18: PropTypes.bool,
  addPeepButtonText: PropTypes.string,
};

import { connect } from 'react-redux';
import ThankYouPage from './ThankYouPage';
import { sortStateProducts } from '../../.shared/sortProducts';
import { initDetailsCaptureFromPolicy } from '../../actions/detailsCapture';
import { isFromBrokerPortal } from '../../common/brokers';

const mapStateToProps = (state) => ({
  policies: state.getIn(['policyInformation', 'policies']).toJS(),
  requiresPeeps: state.getIn(['policyInformation', 'requiresPeeps']),
  canSignupMultiplePolicies: state.getIn([
    'policyInformation',
    'supportMultiplePolicies',
  ]),
  isPartialSale: state.getIn([
    'packages',
    state.get('selectedPackageSlug'),
    'isPartialSale',
  ]),
  isDomesticPartialSale: !state.getIn([
    'packages',
    state.get('selectedPackageSlug'),
    'isThirdPartyPackage',
  ]),
  sortProducts: sortStateProducts(state),
  SUFFIX_URL: state.getIn(['config', 'SUFFIX_URL']),
  discountRate: state.getIn(['config', 'DISCOUNT_RATE']),
  winWinDiscountRate: state.getIn(['config', 'WINWIN_DISCOUNT_RATE']),
  hasWinWin: state.getIn(['winWinDetails', 'hasWinWin']),
  productConfig: state.get('products'),
  brokerSaleType: state.getIn(['brokerQa', 'brokerSaleType']),
  ropPricing: state.get('ropPricing').toJS(),
  paymentType: state.getIn(['paymentType', 'selectedPaymentType']),
  isFromBrokerPortal: isFromBrokerPortal(state),
  policyOwnerIsNotPayer: state.getIn([
    'packages',
    state.get('selectedPackageSlug'),
    'policyOwnerIsNotPayer',
  ]),
  isQaActive: state.getIn(['brokerQa', 'isQaActive']),
  brokerQaThankYouMessage: state.getIn(['brokerQa', 'brokerQaThankYouMessage']),
  isThirdPartyPackage: state.getIn([
    'packages',
    state.get('selectedPackageSlug'),
    'isThirdPartyPackage',
  ]),
  firstSalaryDeductionCollectionMonth:
    state.getIn(['policyInformation', 'bankingDetails',
                 'firstSalaryDeductionCollectionMonth']),
});

const mapDispatchToProps = (dispatch) => ({
  initDetailsCaptureFromPolicy: (policy) =>
    dispatch(initDetailsCaptureFromPolicy(policy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPage);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { QUESTION_TIMEOUT_DELAY_IN_MILLISECONDS } from '../../config/appConfig';
import { InputText } from '@simply-fin-services/astronomix3';
import QuestionContainer, {
  isNotEligible,
  showError,
} from './QuestionContainer';

const INITIAL_ANSWER = '';
const containsSpecialChars = (name) => !R.test(/^[a-zA-Z0-9]+$/, name);
const getSpecialChars = (name) =>
  R.filter((chr) => chr !== ' ' && !R.test(/[^\W\-]/, chr), R.split('', name));

const mergeByUpperCase = (x, y) => {
  const mapIndexed = R.addIndex(R.map);
  return mapIndexed((val, idx) => {
    const equivalentIsUpperCase = y.charAt(idx) === val.toUpperCase();
    return equivalentIsUpperCase ? y.charAt(idx) : val;
  }, R.split('', x)).join('');
};

class ProperCaseQuestion extends PureComponent {
  constructor() {
    super();
    this.submitQuestion = this.submitQuestion.bind(this);
    this.onChange = this.onChange.bind(this);
    this.startSubmitTimer = this.startSubmitTimer.bind(this);
    this.clearSubmitTimer = this.clearSubmitTimer.bind(this);
    this.typingTimeout = null;
    this.submittedValue = INITIAL_ANSWER;
  }

  componentWillMount() {
    this.setState({ value: this.props.answer || INITIAL_ANSWER });
  }

  submitQuestion() {
    this.clearSubmitTimer();
    const { value } = this.state;
    if (value !== this.submittedValue) {
      const { answerQuestion } = this.props;
      answerQuestion(value);
    }
    this.submittedValue = value;
  }

  onChange(e) {
    const value = R.map((word) => {
      if (containsSpecialChars(word)) {
        const specialChars = getSpecialChars(word);
        const fixedCasingByChars = R.map(
          (chr) =>
            R.map(
              (w) => R.head(w).toUpperCase().concat(R.tail(w).toLowerCase()),
              R.split(chr, word)
            ).join(chr),
          specialChars
        );
        return R.reduce(
          (currentWord, firstWord) => {
            if (currentWord.length === 0) {
              return firstWord.concat(currentWord);
            }
            return mergeByUpperCase(currentWord, firstWord);
          },
          '',
          fixedCasingByChars
        );
      }
      return R.head(word).toUpperCase().concat(R.tail(word).toLowerCase());
    }, R.split(' ', e.target.value)).join(' ');

    this.setState({
      value,
    });
  }

  startSubmitTimer() {
    this.clearSubmitTimer();
    this.typingTimeout = window.setTimeout(() => {
      this.submitQuestion();
    }, QUESTION_TIMEOUT_DELAY_IN_MILLISECONDS);
  }

  clearSubmitTimer() {
    window.clearTimeout(this.typingTimeout);
  }

  render() {
    const {
      placeholder,
      disable,
      questionId,
      icon,
      eligibility,
      errors,
      hasWarning,
      warnings,
      packageId,
      hint,
      title,
    } = this.props;

    const { value } = this.state;
    const disabled = disable || false;

    const eligibilityErrors =
      isNotEligible(eligibility, packageId) && eligibility.failureMessage
        ? [eligibility.failureMessage]
        : [];
    const errorMessages = showError(this.props) ? errors || [] : [];
    const warningMessages = hasWarning ? warnings : [];
    const allErrors = R.concat(
      R.concat(errorMessages, warningMessages),
      eligibilityErrors
    );

    return (
      <QuestionContainer>
        <InputText
          type="propercase-text"
          label={title}
          hint={hint}
          errors={allErrors}
          placeholder={placeholder}
          onBlur={this.submitQuestion}
          onChange={this.onChange}
          onKeyUp={this.startSubmitTimer}
          onKeyDown={this.clearSubmitTimer}
          value={value}
          disabled={disabled}
          name={questionId}
          icon={icon}
        />
      </QuestionContainer>
    );
  }
}

ProperCaseQuestion.propTypes = {
  answerQuestion: PropTypes.func,
  placeholder: PropTypes.string,
  answer: PropTypes.string,
  initialValue: PropTypes.string,
  disable: PropTypes.bool,
  labelIcon: PropTypes.string,
};

export default ProperCaseQuestion;

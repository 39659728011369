/* eslint-disable max-len */
import React, { Component } from 'react';
import {
  SecondaryButton,
  Buttons,
} from '@simply-fin-services/astronomix3';
import { ROUTES } from '../../config/constants';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PageDescription from '../common/PageDescription';
import styled from 'styled-components';

const Container = styled.p`
  padding: 50px 10px;
`;

class CompleteSaleReminder extends Component {
  componentDidMount() {
    const elem = document.getElementById('completeSaleReminder');
    if (elem) {
      setTimeout(
        () =>
          elem.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          }),
        100
      );
    }
  }

  render() {
    const { goToThankYou } = this.props;
    const description = (
      <span>
        <span> You have already completed your sale </span>
      </span>
    );
    return (
      <div id="completeSaleReminder">
        <Container className="text-center space-above-3 space-below-3">
          <PageDescription description={description} />

          <p className="text-center space-above space-below">
            You are now part of the Simply family. Please click the button below
            to view your policy details
          </p>

          <Buttons>
            <SecondaryButton onClick={goToThankYou}>
              View Policy Details
            </SecondaryButton>
          </Buttons>
        </Container>
      </div>
    );
  }
}

const postSalePages = [
  ROUTES.THANK_YOU,
  ROUTES.THANK_YOU_P,
  ROUTES.DETAILS_CAPTURE,
  ROUTES.DETAILS_CAPTURE_LANDING,
  ROUTES.DETAILS_CAPTURE_PEEPS,
  ROUTES.DETAILS_CAPTURE_THANK_YOU,
  ROUTES.PEEPS,
  ROUTES.ERROR,
  ROUTES.NOT_FOUND,
  ROUTES.REFERRAL_HOMEPAGE,
  ROUTES.REFERRAL_SIGNUP,
  ROUTES.REFERRAL_TERMS,
  ROUTES.REFERRAL_URL,
  ROUTES.REFERRER_LOGIN,
  ROUTES.CONNECT_FRIENDS_PAGE,
];

const exists = (i) => !R.isNil(i) && !R.isEmpty(i);
const pageExistsAndIsNotHomePage = (page) =>
  exists(page) && page !== ROUTES.HOME;

export const isPostSaleContent = (page) =>
  R.pipe(
    R.filter((p) => R.includes(p, page)),
    R.head,
    pageExistsAndIsNotHomePage
  )(postSalePages);

const mapStateToProps = (state) => ({
  slug: state.get('selectedPackageSlug'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { slug } = stateProps;
  return Object.assign(
    {
      goToThankYou: () => dispatch(push(ROUTES.toThankYou(slug))),
    },
    stateProps,
    dispatchProps,
    ownProps
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CompleteSaleReminder);

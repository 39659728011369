import { walkQuestionPageWithOptionals } from '../../.shared/validators/questionnaire/walkQuestionPage';
import validateQuestionPage from '../../.shared/validators/questionnaire/validateQuestionPage';
import validateQuestionPageEligibility, {
  shouldSuggestPackage,
} from '../../.shared/validators/questionnaire/validateQuestionPageEligibility';
import selectAllQuestions from '../../common/selectAllQuestions';
import { answerAffiliatedQuestionActionCreator } from '../../actions/answers';
import getPackageIdFromSlug, {
  getPackageSlugFromId,
} from '../../common/getPackageIdFromSlug';
import * as R from 'ramda';
import { selectChangedPackageProducts } from '../../actions/productSelection';
import { fromJS, Map } from 'immutable';
import pageSpecificTitles from '../../config/pageSpecificTitles';
import { isBrokerSale, isFromBrokerPortal } from '../../common/brokers';

export const walkQuestionPageGivenAnswers = (
  state,
  pageQuestions,
  optionalPageQuestions,
  answers
) =>
  walkQuestionPageWithOptionals(
    state.get('questions'),
    state.get('questionNodes'),
    pageQuestions,
    optionalPageQuestions,
    answers
  );

export const isPageValidGivenAnswers = (walkedPage, answers) =>
  validateQuestionPage(walkedPage, answers);

const walkPageQuestions = (state, pageQuestions, optionalPageQuestions) =>
  walkQuestionPageGivenAnswers(
    state,
    pageQuestions,
    optionalPageQuestions,
    state.get('answers')
  );

const getCurrentPackage = R.curry((questionPageId, state, field) =>
  state.getIn(['packages', state.get('selectedPackageSlug'), field])
);

const getQuestionPageId = (pageUrlSlug, state) =>
  state
    .getIn(['questionPages'])
    .filter(p => p.get('urlSlug') === pageUrlSlug)
    .map(p => p.get('questionPageId'))
    .first();

const isPageValid = (state, walkedPage) =>
  isPageValidGivenAnswers(walkedPage, state.get('answers'));

const isPageEligible = (state, walkedPage) =>
  validateQuestionPageEligibility(walkedPage, state.get('answers'));

export const stateToQuestionnaireProps = (
  state,
  pageQuestions,
  optionalPageQuestions,
  currentPackage
) => {
  const walkedPage = walkPageQuestions(
    state,
    pageQuestions,
    optionalPageQuestions
  );
  const isEligible = isPageEligible(state, walkedPage);
  let suggestedPackageId = currentPackage
    ? currentPackage('suggestPackageId')
    : '';
  if (
    !isEligible &&
    suggestedPackageId &&
    currentPackage('packageSuggestionOrder')
  ) {
    const answers = state.get('answers').toJS();
    const questionIds = R.keys(answers);
    const packageSuggestionOrder = currentPackage('packageSuggestionOrder');
    suggestedPackageId = questionIds.reduce(
      (suggestedPackageIdByEligibility, questionId) => {
        if (state.getIn(['answers', questionId, 'eligibility', 'isEligible'])) {
          return suggestedPackageIdByEligibility;
        }
        const suggestionByEligibility = state.getIn(
          ['answers', questionId, 'eligibility', 'suggestedPackageId'],
          suggestedPackageId
        );

        if (
          packageSuggestionOrder.get(suggestionByEligibility) <
          packageSuggestionOrder.get(
            suggestedPackageIdByEligibility,
            Number.MAX_SAFE_INTEGER
          )
        ) {
          return suggestionByEligibility;
        }

        return suggestedPackageIdByEligibility;
      },
      ''
    );
  }
  return {
    walkedPage,
    isValid: isPageValid(state, walkedPage),
    isEligibile: isEligible,
    questions: selectAllQuestions(state),
    answers: state.get('answers'),
    suggestedPackageId,
  };
};

export const stateToProps = (
  state,
  packageSlug,
  pageQuestions,
  optionalPageQuestions,
  pageUrlSlug
) => {
  const questionPageId = getQuestionPageId(pageUrlSlug, state);
  const currentPackage = getCurrentPackage(questionPageId, state);
  const {
    walkedPage,
    isValid,
    isEligibile,
    questions,
    answers,
    suggestedPackageId,
  } = stateToQuestionnaireProps(
    state,
    pageQuestions,
    optionalPageQuestions,
    currentPackage
  );

  const selectedPackageSlug = state.get('selectedPackageSlug');
  const mustSuggestPackage = shouldSuggestPackage(
    walkedPage,
    state.get('answers'),
    state.getIn(['packages', selectedPackageSlug]),
    questionPageId
  );
  const selectedPackage = state.getIn(['packages', packageSlug]);
  const packageTitles = R.isNil(selectedPackage.get('packageSpecificTitles'))
    ? selectedPackage.get('packageSpecificTitles')
    : selectedPackage.get('packageSpecificTitles').toJS();
  const brokerSpecificTitles =  R.isNil(selectedPackage.get('brokerSpecificTitles'))
  ? selectedPackage.get('brokerSpecificTitles')
  : selectedPackage.get('brokerSpecificTitles').toJS();
  const titles = (isBrokerSale(state) || isFromBrokerPortal(state)) && !R.isNil(brokerSpecificTitles) ? brokerSpecificTitles : packageTitles;
  const packageQuestions = R.isNil(titles)
    ? questions
    : R.map(
        question => {
          const matches = titles.filter(
            t => t.questionId === question.questionId
          );

          const pageSpecificTitle = R.pipe(
            R.prop(pageUrlSlug),
            R.prop(question.questionId)
          )(pageSpecificTitles);

          const hasPageSpecificTitle = !R.isNil(pageSpecificTitle);

          const hasPackageSpecificTitle = !(
            R.isEmpty(matches) || R.isNil(matches)
          );
          return hasPackageSpecificTitle
            ? hasPageSpecificTitle
              ? R.assoc('title', pageSpecificTitle, question)
              : R.assoc('title', R.head(matches).title, question)
            : hasPageSpecificTitle
            ? R.assoc('title', pageSpecificTitle, question)
            : question;
        },
        questions instanceof Map ? questions.toJS() : questions
      );

  return {
    questions: fromJS(packageQuestions),
    answers,
    pageQuestions,
    optionalPageQuestions: optionalPageQuestions || fromJS([]),
    canGoToNextPage: isValid && isEligibile,
    packageId: getPackageIdFromSlug(state, packageSlug),
    packageUrlSlug: packageSlug,
    mustSuggestPackage,
    packageToSuggestSlug: getPackageSlugFromId(state, suggestedPackageId),
    suggestPackageButtonText: currentPackage('suggestPackageButtonText'),
    suggestedPackageDescription: currentPackage('suggestPackageDescription')
      ? currentPackage('suggestPackageDescription').get(suggestedPackageId, '')
      : '',
    currentProductSelection: state.get('productSelection'),
    suggestedPackageId,
    quoteMessage: selectedPackage.get('quoteMessage'),
  };
};

export const answerQuestion = R.curry(
  (dispatch, packageId, questionId, answer) =>
    dispatch(
      answerAffiliatedQuestionActionCreator(packageId, questionId, answer)
    )
);

const goToSuggestedPackage = R.curry(
  (
    dispatch,
    packageSlug,
    currentProductSelection,
    suggestedPackageId,
    fromPackageId
  ) => () =>
    dispatch(
      selectChangedPackageProducts(
        packageSlug,
        currentProductSelection,
        suggestedPackageId,
        fromPackageId
      )
    )
);

export const dispatchToProps = dispatch => ({
  answerQuestion: answerQuestion(dispatch),
  goToSuggestedPackage: goToSuggestedPackage(dispatch),
});

export const mergeTheProps = (stateProps, dispatchProps) =>
  Object.assign({}, stateProps, dispatchProps, {
    answerQuestion: dispatchProps.answerQuestion(stateProps.packageId),
    goToSuggestedPackage: dispatchProps.goToSuggestedPackage(
      stateProps.packageToSuggestSlug,
      stateProps.currentProductSelection,
      stateProps.suggestedPackageId,
      stateProps.packageId
    ),
  });

export const mergeThePropsWithOwnProps = (
  stateProps,
  dispatchProps,
  ownProps
) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    answerQuestion: dispatchProps.answerQuestion(stateProps.packageId),
    goToSuggestedPackage: dispatchProps.goToSuggestedPackage(
      stateProps.packageToSuggestSlug,
      stateProps.currentProductSelection,
      stateProps.suggestedPackageId,
      stateProps.packageId
    ),
  });

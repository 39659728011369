import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IPropTypes from 'react-immutable-proptypes';
import buildQuestion from '../questions/questionBuilder';
import { Form, FieldContainer } from '@simply-fin-services/astronomix3';
import { COMPLIANCE_MESSAGES } from '../../config/constants';

export default class ContactDetails extends PureComponent {
  render() {
    const {
      pageQuestions,
      questions,
      answers,
      answerQuestion,
      optionalPageQuestions,
      quoteMessage,
    } = this.props;
    const toControl = buildQuestion(
      questions,
      optionalPageQuestions,
      answers,
      answerQuestion,
      false
    );
    return (
      <Form>
        {quoteMessage &&
          <div className="space-below">
            {quoteMessage}
          </div>
        }
        {pageQuestions.map(q => (
          <FieldContainer key={q}>{toControl(q)}</FieldContainer>
        ))}
        <div>
          {COMPLIANCE_MESSAGES.CELLPHONE_NUMBER_MESSAGE}
        </div>
      </Form>
    );
  }
}

ContactDetails.propTypes = {
  questions: IPropTypes.map.isRequired,
  answers: IPropTypes.map.isRequired,
  pageQuestions: IPropTypes.listOf(PropTypes.string).isRequired,
  answerQuestion: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  defaults: PropTypes.object,
};

import { connect } from 'react-redux';
import QuestionPage from './QuestionPage';
import getNextAction from './getNextPageAction';
import {
  stateToProps,
  dispatchToProps,
  mergeTheProps,
} from './toQuestionPageProps';
import * as R from 'ramda';
import getPackageIdFromSlug from '../../common/getPackageIdFromSlug';
import { isBrokerSale, isFromBrokerPortal } from '../../common/brokers';
import {effectiveQuestionIds, effectiveOptionalQuestionIds} from '../../.shared/conditionalQuestions';
import {determineEffectiveQuestionnaire} from './determineNextQuoteStep';

const getPageUrlSlug = (ownProps) => ownProps.match.params.questionPageUrlSlug;
const getPackageUrlSlug = (ownProps) => ownProps.match.params.packageUrlSlug;
const selectSubTitle = (state, ownProps) =>
  state.getIn(['packages', getPackageUrlSlug(ownProps), 'title']);

const getPropFromQuestionPage = R.curry((field, state, ownProps) =>
  state.getIn(['questionPages', getPageUrlSlug(ownProps), field])
);

const selectPageQuestions = getPropFromQuestionPage('questions');

const selectPageOptionalQuestions =
  getPropFromQuestionPage('optionalQuestions');

const getContinueButtonText = (state, ownProps) =>
  getPropFromQuestionPage('continueButtonText', state, ownProps) || 'Next';

const getPageTitle = (state, ownProps) =>
  getPropFromQuestionPage('title', state, ownProps) || '';

const getCallToActionText = (state, ownProps) =>
  getPropFromQuestionPage('callToAction', state, ownProps) || '';

const selectQuestionnaire = (state, ownProps) =>
  determineEffectiveQuestionnaire(getPackageUrlSlug(ownProps), state);

const selectPackageId = (state, ownProps) =>
  state.getIn(['packages', getPackageUrlSlug(ownProps), 'packageId']);

const selectDescription = (state, ownProps) => {
  const packageId = getPackageIdFromSlug(
    state,
    state.get('selectedPackageSlug')
  );
  const questionPage = state.getIn(['questionPages', getPageUrlSlug(ownProps)]);
  const pageDescriptionByPackage = questionPage.get('pageDescriptionByPackage');
  if (!R.isNil(pageDescriptionByPackage)) {
    return pageDescriptionByPackage.get(packageId);
  }
  return questionPage.get('description');
};

const selectThirdPartyBrokerMessage = (state, ownProps) => {
  const questionPage = state.getIn(['questionPages', getPageUrlSlug(ownProps)]);
  return questionPage.get('thirdPartyBrokerMessage');
};

const selectAdditionalPageComponents = (state, ownProps) => {
  const questionPage = state.getIn(['questionPages', getPageUrlSlug(ownProps)]);
  return questionPage.get('additionalPageComponents');
};

const selectPageDetail = (state, ownProps) => {
  const questionPage = state.getIn(['questionPages', getPageUrlSlug(ownProps)]);
  return questionPage.get('pageDetail') || '';
};

const selectSubHeading = (state, ownProps) => {
  const questionPage = state.getIn(['questionPages', getPageUrlSlug(ownProps)]);
  return questionPage.get('subHeading');
};

const selectFooterDescription = (state, ownProps) =>
  state.getIn(['questionPages', getPageUrlSlug(ownProps), 'footerDescription']);

const selectNextAction = (state, ownProps) => {
  const packageUrlSlug = getPackageUrlSlug(ownProps);
  const allowDeferredUnderwriting =
    state.getIn(['packages', packageUrlSlug, 'allowDeferredUnderwriting']) ||
    false;

  const allowBrokerPeepsFollowup =
    state.getIn(['packages', packageUrlSlug, 'allowBrokerPeepsFollowup']) ||
    false;

  const brokerCanDoPeepsFollowUp =
    (isFromBrokerPortal(state) || isBrokerSale(state)) &&
    allowBrokerPeepsFollowup;
  return getNextAction(
    selectPackageId(state, ownProps),
    packageUrlSlug,
    selectQuestionnaire(state, ownProps),
    getPageUrlSlug(ownProps),
    allowDeferredUnderwriting,
    brokerCanDoPeepsFollowUp,
    state
  );
};

const selectConditionalQuestions =
  getPropFromQuestionPage('conditionalQuestions');

const isProcessingQuote = (state) =>
  state.getIn(['recommendation', 'isFetching']) === true;

const toBaseProps = (state, ownProps) => {
  const conditionalQuestions = selectConditionalQuestions(state, ownProps);

  const questions = selectPageQuestions(state, ownProps);
  const answers = state.get('answers');

  const effectiveQuestions = effectiveQuestionIds(
                               {allQuestionIds: questions,
                                conditionalQuestions,
                                answers});

  const optionalQuestions = selectPageOptionalQuestions(state, ownProps);

  const effectiveOptionalQuestions = effectiveOptionalQuestionIds(
                                       {allQuestionIds: optionalQuestions,
                                        conditionalQuestions,
                                        answers});

  return stateToProps(
    state,
    getPackageUrlSlug(ownProps),
    effectiveQuestions,         // Previous code: selectPageQuestions(state, ownProps),
    effectiveOptionalQuestions, // Previous code: selectPageOptionalQuestions(state, ownProps),
    getPageUrlSlug(ownProps)
  );
};

export const mapStateToProps = (state, ownProps) => {
  const { packageUrlSlug } = toBaseProps(state, ownProps);
  const isThirdPartyPackage = state.getIn([
    'packages',
    packageUrlSlug,
    'isThirdPartyPackage',
  ]);
  const isBrokerOperatedSale = isBrokerSale(state) || isFromBrokerPortal(state);

  return Object.assign({}, toBaseProps(state, ownProps), {
    subTitle: selectSubTitle(state, ownProps),
    isProcessingQuote: isProcessingQuote(state),
    description: selectDescription(state, ownProps),
    thirdPartyBrokerMessage: selectThirdPartyBrokerMessage(state, ownProps),
    pageDetail: selectPageDetail(state, ownProps),
    subHeading: selectSubHeading(state, ownProps),
    next: selectNextAction(state, ownProps),
    footerDescription: selectFooterDescription(state, ownProps),
    continueButtonText: getContinueButtonText(state, ownProps),
    additionalPageComponents: selectAdditionalPageComponents(state, ownProps),
    callToAction: getCallToActionText(state, ownProps),
    title: getPageTitle(state, ownProps),
    packageId: selectPackageId(state, ownProps),
    isThirdPartyBrokerSale: isThirdPartyPackage && isBrokerOperatedSale,
    isAnsweringQuestion:
      state.get('answers').filter((a) => a.get('isLoading')).size > 0,
  });
};

export const mapDispatchToProps = (dispatch) =>
  Object.assign({}, dispatchToProps(dispatch), {
    curryNextWithDispatch: (next) => next(dispatch),
  });

export const mergeProps = (stateProps, dispatchProps) =>
  Object.assign({}, mergeTheProps(stateProps, dispatchProps), {
    goToNextPage: dispatchProps.curryNextWithDispatch(stateProps.next),
  });

const QuestionPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(QuestionPage);

export default QuestionPageContainer;

/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SITE_NAME } from '../../config/constants';
import { Head } from '@simply-fin-services/astronomix3';

class Meta extends PureComponent {
  render() {
    const { themeName } = this.props;
    return (
      <Head
        htmlAttributes={{ lang: 'en', amp: undefined }}
        themeName={themeName}
        title={SITE_NAME}
      >
        <meta
          name="description"
          content="Awesome insurance this way everyone! Great calue! Instant signup! No Blood tests! Simple combos: Life, Disability, and Family funeral cover."
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#2597D0" />
        <meta name="fb:app_id" content="1712145419040559" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"></link>
      </Head>
    );
  }
}

Meta.propTypes = {
  themeName: PropTypes.string.isRequired,
};

export default Meta;

/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ROUTES } from '../../config/constants';
import * as R from 'ramda';
import { push } from 'connected-react-router';
import { getRecommendation } from '../../actions/recommendation';
import { saveSelections } from '../../actions/productSelection';
import { updateSelectedPackage } from '../../actions/selectedPackageSlug';
import LoadingRow from '../common/LoadingRow';
import { getPackageSlugFromId } from '../../common/getPackageIdFromSlug';
import { answerPrefilledQuestions, answerQuestion } from '../../actions/answers';
import { linkBroker } from '../../actions/brokerLinking';
import { dispatchNewInteractionId } from '../../actions/startup';
import { handleSenderReferenceAndLinkCampaign } from '../../actions/campaign';
import {
  EXTERNAL_MAPPING,
  EXTERNAL_SENDERREFERENCES_WE_MAP_FOR,
  DEFAULT_PACKAGES,
} from '../../config/appConfig';
import toQueryParams from '../../common/propsToLocationQueryParams';
import getBasePackageId, {
  toIndexedPackages,
} from '../../.shared/getBasePackageId';
import { isReplaceAndSaveEnabled } from '../../stateStore/replaceAndSave';

class QuoteLandingPage extends PureComponent {
  componentDidMount() {
    this.props.landed(
      this.props.packageUrlSlug,
      this.props.packages,
      this.props.questionPages,
      this.props.questions,
      this.props.isThirdPartyPackage,
      this.props.isReplaceAndSaveEnabled
    );
  }

  render() {
    return <LoadingRow />;
  }
}

QuoteLandingPage.propTypes = {
  landed: PropTypes.func.isRequired,
};

const getQuoteAnswers = (requiredQuestions, queryProps) =>
  R.pick(requiredQuestions, queryProps);

const getNonQuoteAnswers = (
  quoteQuestionsWithPackageId,
  questions,
  queryProps
) =>
  R.pipe(
    R.omit(quoteQuestionsWithPackageId),
    R.pick(R.keys(questions)),
    R.filter(R.complement(R.isEmpty)),
    R.filter(R.complement(R.isNil))
  )(queryProps);

const hasValidPackageId = (pId, packages) =>
  R.any(
    R.equals(pId),
    R.values(R.mapObjIndexed((pkg) => R.prop('packageId', pkg), packages))
  );

const hasValidProducts = (packageProducts, products) =>
  R.pipe(
    R.map((p) => R.includes(p, packageProducts)),
    R.reduce(R.and, true)
  )(products);

const mappingHelper = (packages, prop, senderReference, packageId, input) => {
  const indexedPackages = toIndexedPackages(R.values(packages));
  if (
    R.includes(R.toUpper(senderReference), EXTERNAL_SENDERREFERENCES_WE_MAP_FOR)
  ) {
    return R.pipe(R.prop(prop), (theProp) => {
      if (R.isNil(packageId)) {
        return R.prop(input, theProp);
      }
      return R.path(
        [getBasePackageId(indexedPackages, packageId), input],
        theProp
      );
    })(EXTERNAL_MAPPING);
  }
  if (R.has(R.toLower(input), DEFAULT_PACKAGES)) {
    return R.prop(input, DEFAULT_PACKAGES);
  }
  return input;
};

const packageHelper = (packages, senderReference, packageId) =>
  mappingHelper(packages, 'packageId', senderReference, undefined, packageId);


export const mapStateToProps = (state, ownProps) => {
  const packages = state.get('packages').toJS();
  const queryParams = toQueryParams(ownProps);
  const senderReference = queryParams.senderReference;
  const packageId = packageHelper(
    packages,
    senderReference,
    queryParams.packageId
  );

  const packageUrlSlug = getPackageSlugFromId(state, packageId);
  const isThirdPartyPackage = state.getIn(["packages", packageUrlSlug, "isThirdPartyPackage"]);

  return {
    packages,
    questionPages: state.get('questionPages').toJS(),
    packageUrlSlug,
    questions: state.get('questions').toJS(),
    isThirdPartyPackage,
    isReplaceAndSaveEnabled: isReplaceAndSaveEnabled(state),
  };
};

const productHelper = R.curry(
  (packages, senderReference, packageId, productId) =>
    mappingHelper(packages, 'productId', senderReference, packageId, productId)
);

const fixProducts = (packages, senderReference, products, packageId) =>
  R.map(productHelper(packages, senderReference, packageId), products);

export const mapDispatchToProps = (dispatch, ownProps) => {
  const queryParams = toQueryParams(ownProps);
  const { senderReference, selection, brokerCode, interactionId } = queryParams;
  const getPackageId = (packages) =>
    packageHelper(packages, senderReference, queryParams.packageId);

  return {
    landed: (packageUrlSlug,
             packages,
             questionPages,
             questions,
             isThirdPartyPackage,
             replaceAndSaveEnabled) => {
      const packageId = getPackageId(packages);
      if (!hasValidPackageId(packageId, packages)) {
        return dispatch(push(ROUTES.HOME));
      }
      if (interactionId) {
        dispatchNewInteractionId(dispatch, interactionId);
      }
      dispatch(updateSelectedPackage(packageUrlSlug));
      const quotePageId = R.path(
        [packageUrlSlug, 'quoteQuestionPageId'],
        packages
      );
      const requiredQuoteQuestions = R.path(
        [quotePageId, 'questions'],
        questionPages
      );
      const nonQuoteAnswers = getNonQuoteAnswers(
        R.append('packageId', requiredQuoteQuestions),
        questions,
        queryParams
      );

      const packageProducts = R.path([packageUrlSlug, 'products'], packages);
      const defaultSelection = selection
        ? fixProducts(
            packages,
            senderReference,
            JSON.parse(selection),
            packageId
          )
        : packageProducts;
      const validProductSelection = hasValidProducts(
        packageProducts,
        defaultSelection
      );
      if (validProductSelection) {
        dispatch(saveSelections(defaultSelection));
      }

      if (nonQuoteAnswers && !isThirdPartyPackage) {
        dispatch(
          answerPrefilledQuestions(
            packageId,
            nonQuoteAnswers,
            () => null,
            senderReference
          )
        );
      }

      if(nonQuoteAnswers && isThirdPartyPackage){
        R.toPairs(nonQuoteAnswers).forEach(([questionId, answer]) => {
          dispatch(answerQuestion(packageId, questionId, answer));
        });
      }


      handleSenderReferenceAndLinkCampaign(dispatch, queryParams);

      if (brokerCode) {
        dispatch(linkBroker(brokerCode, queryParams.senderReference));
      }

      const QuoteAnswers = getQuoteAnswers(requiredQuoteQuestions, queryParams);

      const hasAllQuestions = R.allPass(R.map(R.has, requiredQuoteQuestions));

      if(QuoteAnswers && isThirdPartyPackage){
        R.toPairs(QuoteAnswers).forEach(([questionId, answer]) => {
          dispatch(answerQuestion(packageId, questionId, answer));
        });
        return dispatch(push(ROUTES.toQuote(packageUrlSlug)));
      }

      return dispatch(
        answerPrefilledQuestions(
          packageId,
          QuoteAnswers,
          (result) => {
            const isValid =
              R.reduce(
                (valid, validatedAnswer) => valid && validatedAnswer.isValid,
                true,
                result
              ) && hasAllQuestions(QuoteAnswers);
            if (isValid) {
              return dispatch(getRecommendation(packageId, packageUrlSlug));
            }

            if(replaceAndSaveEnabled) {
              return dispatch(push(ROUTES.REPLACE_AND_SAVE_DASHBOARD));
            }

            return dispatch(push(ROUTES.HOME));
          },
          senderReference
        )
      );
    },
  };
};

const QuoteLandingPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteLandingPage);

export default QuoteLandingPageContainer;

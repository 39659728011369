import React, { PureComponent } from 'react';

export default class FullWidthButtonWrapper extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <div className="row">
        <div className="col-sm-8 offset-md-2">{children}</div>
      </div>
    );
  }
}

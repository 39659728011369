import React from 'react';
import { Spinner } from '@simply-fin-services/astronomix3';

const LoadingRow = () => {
  return (
    <div className="text-center">
      <Spinner width="40px" />
    </div>
  );
};

export default LoadingRow;

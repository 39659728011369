// This reducer is required to make react-router-redux work with immutable.js
import { LOCATION_CHANGE } from 'connected-react-router';
import { GET_RECOMMENDATION } from '../actions/types';
import { ROUTES } from '../config/constants';
import { fromJS } from 'immutable';
const initialState = false;

const nonSaleRoutes = fromJS([
  ROUTES.GET_A_QUOTE,
  ROUTES.ABOUT,
  ROUTES.CONTACT,
  ROUTES.PRIVPOL,
  ROUTES.FAQ,
  ROUTES.TERMS,
]);

const isNonSaleRoute = path =>
  nonSaleRoutes.reduce(
    (isNonSale, prefix) => isNonSale || path.startsWith(prefix),
    false
  );

export default (state = initialState, action) => {
  if (action.type === GET_RECOMMENDATION) {
    return true;
  }
  if (action.type === LOCATION_CHANGE) {
    const pathname = action.payload.location.pathname;
    return !isNonSaleRoute(pathname);
  }
  return state;
};

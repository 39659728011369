import React, { PureComponent } from 'react';
import { linkBroker } from '../../actions/brokerLinking';
import LoadingRow from '../common/LoadingRow';
import { connect } from 'react-redux';
import { ROUTES } from '../../config/constants';
import { push } from 'connected-react-router';

class brokerLanding extends PureComponent {
  componentDidMount() {
    const { dispatch, brokerCode, senderReference } = this.props;
    dispatch(linkBroker(brokerCode, senderReference));
    dispatch(push(ROUTES.HOME));
  }

  render() {
    return <LoadingRow />;
  }
}

const mapStateFromProps = (state, ownProps) => ({
  brokerCode: ownProps.match.params.brokerCode,
  senderReference: ownProps.match.params.senderReference,
});

const mapDispatchFromProps = dispatch => ({
  dispatch,
});

export default connect(mapStateFromProps, mapDispatchFromProps)(brokerLanding);

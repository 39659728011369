import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { QUESTION_TIMEOUT_DELAY_IN_MILLISECONDS } from '../../config/appConfig';
import QuestionContainer, {
  isNotEligible,
  showError,
} from './QuestionContainer';
import { Checkbox } from '@simply-fin-services/astronomix3';

import R, { isNil } from 'ramda';
const INITIAL_ANSWER = "No";

class CheckboxQuestion extends PureComponent {
  constructor() {
    super();
    this.submitQuestion = this.submitQuestion.bind(this);
    this.onChange = this.onChange.bind(this);
    this.startSubmitTimer = this.startSubmitTimer.bind(this);
    this.clearSubmitTimer = this.clearSubmitTimer.bind(this);
    this.typingTimeout = null;
    this.submittedValue = INITIAL_ANSWER;
  }

  componentWillMount() {
    this.setState({ value: this.props.answer || INITIAL_ANSWER });
  }

  submitQuestion() {
    this.clearSubmitTimer();
    const { value } = this.state;
    if (value !== this.submittedValue) {
      const { answerQuestion } = this.props;
      answerQuestion(value);
    }
    this.submittedValue = value;
  }

  onChange() {
    const { value } = this.state;
    const answer = value === "Yes" ? "No" :"Yes";
    this.setState({
      value: answer,
    });
    this.startSubmitTimer();
  }

  startSubmitTimer() {
    this.clearSubmitTimer();
    this.typingTimeout = window.setTimeout(() => {
      this.submitQuestion();
    }, QUESTION_TIMEOUT_DELAY_IN_MILLISECONDS);
  }

  clearSubmitTimer() {
    window.clearTimeout(this.typingTimeout);
  }

  render() {
    const {
      placeholder,
      disable,
      questionId,
      eligibility,
      errors,
      hasWarning,
      warnings,
      packageId,
      isValid,
      hint,
      title,
    } = this.props;

    const { value } = this.state;

    const disabled = disable || false;

    const eligibilityErrors =
      !isNil(eligibility) &&
      isNotEligible(eligibility, packageId) &&
      eligibility.failureMessage
        ? [eligibility.failureMessage]
        : [];

    const errorMessages = showError(this.props) ? errors || [] : [];
    const warningMessages = hasWarning ? warnings : [];
    const allErrors = R.concat(
      R.concat(errorMessages, warningMessages),
      eligibilityErrors
    );
    return (
      <QuestionContainer isValid={isValid} hint={hint} errors={allErrors}>
        <Checkbox
          labelText={title}
          placeholder={placeholder}
          onChange={this.onChange}
          checked={value === "Yes"}
          disabled={disabled}
          id={questionId}
        />
      </QuestionContainer>
    );
  }
}

CheckboxQuestion.propTypes = {
  answerQuestion: PropTypes.func,
  placeholder: PropTypes.string,
  answer: PropTypes.string,
  disable: PropTypes.bool,
  hasDateMask: PropTypes.bool,
  labelIcon: PropTypes.string,
};

export default CheckboxQuestion;

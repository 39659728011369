import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { PageButtons, ErrorText } from '@simply-fin-services/astronomix3';

class PageActionButtonsComponent extends PureComponent {
  render() {
    const { disabled, text, canGoBack, colour, fineprint, back, customBackAction, errorText } =
      this.props;
    const warningText = disabled
      ? errorText || 'Please answer all questions above'
      : fineprint;
    const buttonColour = colour || 'primary';
    const buttonText = text || 'Next';
    return (
      <div className="text-center space-above">
        <ErrorText size="medium">
          {warningText}
        </ErrorText>
        <PageButtons
          {...this.props}
          colour={buttonColour}
          label={buttonText}
          backAction={canGoBack ? (customBackAction || back) : undefined}
        />
      </div>
    );
  }
}

PageActionButtonsComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  canGoBack: PropTypes.bool,
  fineprint: PropTypes.string,
  buttonText: PropTypes.string,
  color: PropTypes.string,
  errorText: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  back: () => {
    dispatch(goBack());
  },
});

const mapStateToProps = (state, ownProps) =>
  Object.assign({}, ownProps, {
    canGoBack:
      !!state.getIn(['routing', 'previousLocation']) && ownProps.canGoBack,
  });

const PageActionButtons = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageActionButtonsComponent);

export default PageActionButtons;

import {
  RESET_FRIEND_REFERRAL_FORM,
  SET_REFERRAL_PACKAGE,
  SET_REFERRAL_ANSWER,
  SAVE_REFERRAL_ENTRIES,
  DELETE_REFERRAL,
} from './types';
const resetFriendReferral = () => ({
  type: RESET_FRIEND_REFERRAL_FORM,
  payload: {},
});

export const setReferralPackage = packageId => ({
  type: SET_REFERRAL_PACKAGE,
  payload: packageId,
});

export const setReferralAnswer = (questionId, processedAnswer) => ({
  type: SET_REFERRAL_ANSWER,
  payload: { questionId, answer: processedAnswer },
});

export const reset = dispatch => () => {
  dispatch(resetFriendReferral());
};

export const saveReferral = (type, referrals) => ({
  type: SAVE_REFERRAL_ENTRIES,
  payload: { type, referrals },
});

export const deleteReferral = (type, id) => ({
  type: DELETE_REFERRAL,
  payload: { type, id },
});

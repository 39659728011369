import { fromJS } from 'immutable';
import {
  ACCEPT_TERMS_AND_CONDITONS,
  ACCEPT_TERMS_AND_CONDITONS_VALIDATION_FAILURE,
  ACCEPT_TERMS_AND_CONDITONS_SUCCESS,
  ACCEPT_TERMS_AND_CONDITONS_FAILURE,
  CHOOSE_IF_POLICY_REPLACED,
  AGREE_TO_POLICY_REPLACED_TERMS,
  AGREE_TO_POLICY_WAIVE_PERIOD_TERMS,
  AGREE_TO_GENERAL_TERMS,
  SET_WAIVE_QUESTIONNAIRE_VALIDATION,
  SAVE_PACKAGE_ALLOWS_POLICY_REPLACEMENT_FLAG,
  SET_REQUEST_WAIVE_PERIOD_DETAILS_FLAG,
  RESET,
  SET_TERMS_AND_CONDITIONS,
  ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_SUCCESS,
  ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_FAILURE,
  ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS,
  SET_THIRD_PARTY_WAIVE_QUESTIONNAIRE_VALIDATION,
  ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_VALIDATION_FAILURE,
  AGREE_TO_THIRD_PARTY_POLICY_REPLACED_TERMS,
  AGREE_TO_THIRD_PARTY_POLICY_WAIVE_PERIOD_TERMS,
  SAVE_PACKAGE_ALLOWS_THIRD_PARTY_POLICY_REPLACEMENT_FLAG,
  SET_REQUEST_THIRD_PARTY_WAIVE_PERIOD_DETAILS_FLAG,
  AGREE_TO_GENERAL_THIRD_PARTY_TERMS,
  CHOOSE_IF_THIRD_PARTY_POLICY_REPLACED,
  AGREE_TO_REPLACE_AND_SAVE_POLICY_INFORMATION,
} from '../actions/types';
import createActionHandler from './createActionHandler';
import * as R from 'ramda';

const initialState = fromJS({
  details: {
    generalTermsAccepted: false,
    policyReplacesOther: '',
    replacePolicyTermsAccepted: false,
    replacePolicyWaivePeriodTermsAccepted: false,
    packageAllowsPolicyReplacement: false,
    waiveQuestionnaireIsValid: false,
    waivePeriodDetailsShouldBeRequested: false,
    insuredPersonDetails: {
      generalTermsAccepted: false,
      policyReplacesOther: 'No',
      replacePolicyTermsAccepted: false,
      replacePolicyWaivePeriodTermsAccepted: false,
      packageAllowsPolicyReplacement: false,
      waiveQuestionnaireIsValid: false,
      waivePeriodDetailsShouldBeRequested: false,
    },
  },
  isSaving: false,
});

const actionHandlers = {};

const acceptTerms = (state, action) => {
  const { meta } = action;
  return state.merge({
    details: fromJS(meta),
    isSaving: true,
  });
};

const acceptInsuredTerms = (state, action) => {
  const { meta } = action;
  return R.assocPath(["details", "insuredPersonDetails"], fromJS(meta), state);
};

const setLoading = R.curry((isSaving, state) =>
  state.set('isSaving', isSaving)
);
const setField = R.curry((field, state, action) =>
  state.setIn(['details', field], action.payload)
);

const setInsuredTermsField = R.curry((field, state, action) =>
  state.setIn(['details', 'insuredPersonDetails', field], action.payload)
);

const setShowaWaivePeriodDetails = (state, action) =>
  state.set('waivePeriodDetailsShouldBeRequested', action.payload);

const setShowThirdPartyWaivePeriodDetails = (state, action) =>
  state.setIn(
    ['details','insuredPersonDetails', 'waivePeriodDetailsShouldBeRequested'],
    action.payload
  );

const toInitialState = () => initialState;

const setTermsAndConditions = (state, action) => {
  const details = action.payload;
  return state.mergeIn(['details'], {
    generalTermsAccepted: details.generalTermsAccepted,
    policyReplacesOther: details.policyReplacesOther,
    replacePolicyTermsAccepted: details.replacePolicyTermsAccepted,
    replacePolicyWaivePeriodTermsAccepted: details.replacePolicyWaivePeriodTermsAccepted,
    packageAllowsPolicyReplacement: details.packageAllowsPolicyReplacement,
    waiveQuestionnaireIsValid: details.waiveQuestionnaireIsValid,
    waivePeriodDetailsShouldBeRequested: details.waivePeriodDetailsShouldBeRequested,
    replaceAndSavePolicyInformationAccepted: details.replaceAndSavePolicyInformationAccepted,
  });
};

// 3rd party terms

actionHandlers[RESET] = toInitialState;
actionHandlers[ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS] = acceptInsuredTerms;
actionHandlers[ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_FAILURE] =
  setLoading(false);
actionHandlers[ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_SUCCESS] =
  setLoading(false);
actionHandlers[ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_VALIDATION_FAILURE] =
  setLoading(false);

actionHandlers[SET_THIRD_PARTY_WAIVE_QUESTIONNAIRE_VALIDATION] =
  setInsuredTermsField('waiveQuestionnaireIsValid');
actionHandlers[CHOOSE_IF_THIRD_PARTY_POLICY_REPLACED] = setInsuredTermsField(
  'policyReplacesOther'
);
actionHandlers[AGREE_TO_THIRD_PARTY_POLICY_REPLACED_TERMS] = setInsuredTermsField(
  'replacePolicyTermsAccepted'
);
actionHandlers[AGREE_TO_THIRD_PARTY_POLICY_WAIVE_PERIOD_TERMS] =
  setInsuredTermsField('replacePolicyWaivePeriodTermsAccepted');
actionHandlers[AGREE_TO_GENERAL_THIRD_PARTY_TERMS] = setInsuredTermsField(
  'generalTermsAccepted'
);
actionHandlers[SAVE_PACKAGE_ALLOWS_THIRD_PARTY_POLICY_REPLACEMENT_FLAG] =
  setInsuredTermsField('packageAllowsPolicyReplacement');
actionHandlers[SET_REQUEST_THIRD_PARTY_WAIVE_PERIOD_DETAILS_FLAG] =
  setShowThirdPartyWaivePeriodDetails;

// Policy payer terms

actionHandlers[ACCEPT_TERMS_AND_CONDITONS] = acceptTerms;
actionHandlers[ACCEPT_TERMS_AND_CONDITONS_FAILURE] = setLoading(false);
actionHandlers[ACCEPT_TERMS_AND_CONDITONS_SUCCESS] = setLoading(false);
actionHandlers[ACCEPT_TERMS_AND_CONDITONS_VALIDATION_FAILURE] = setLoading(
  false
);
actionHandlers[SET_TERMS_AND_CONDITIONS] = setTermsAndConditions;
actionHandlers[ACCEPT_TERMS_AND_CONDITONS_VALIDATION_FAILURE] =
  setLoading(false);

actionHandlers[SET_WAIVE_QUESTIONNAIRE_VALIDATION] = setField(
  'waiveQuestionnaireIsValid'
);
actionHandlers[CHOOSE_IF_POLICY_REPLACED] = setField('policyReplacesOther');
actionHandlers[AGREE_TO_POLICY_REPLACED_TERMS] = setField(
  'replacePolicyTermsAccepted'
);
actionHandlers[AGREE_TO_POLICY_WAIVE_PERIOD_TERMS] = setField(
  'replacePolicyWaivePeriodTermsAccepted'
);
actionHandlers[AGREE_TO_GENERAL_TERMS] = setField('generalTermsAccepted');
actionHandlers[SAVE_PACKAGE_ALLOWS_POLICY_REPLACEMENT_FLAG] = setField(
  'packageAllowsPolicyReplacement'
);
actionHandlers[SET_REQUEST_WAIVE_PERIOD_DETAILS_FLAG] =
  setShowaWaivePeriodDetails;

actionHandlers[AGREE_TO_REPLACE_AND_SAVE_POLICY_INFORMATION] = setField(
  'replaceAndSavePolicyInformationAccepted'
);

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

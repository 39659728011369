import React, { PureComponent } from 'react';
import { Toaster } from '@simply-fin-services/astronomix3';
import {
  replacementPolicyCustomisationPageSentence,
  replacementPolicyCoverExceedsMaxCover
 } from '../sharedComponents';
import lodash from 'lodash';
import { fromJS } from 'immutable';

class CustomisationPageSummary extends PureComponent {
  constructor() {
    super();
    this.state = { savingsInformation: fromJS({}) };
  }

  fetchSavingsInformation() {
    const quoteMonthlyPremiumChanged =
      this.props.quoteMonthlyPremium !==
      this.state.savingsInformation.get('quoteMonthlyPremium');

    if (quoteMonthlyPremiumChanged) {
      this.props
        .savingsInformationForComponent(this.props.quoteMonthlyPremium)
        .then((savingsInformation) => {
          // Only update the internal state if the quoteMonthlyPremium has changed.
          // Prevent React from going into an infinit loop from recurring component-did-update.
          if (
            savingsInformation.get('quoteMonthlyPremium') !==
            this.state.quoteMonthlyPremium
          ) {
            this.setState({ savingsInformation });
          }
        });
    }
  }

  // Because this component needs to respond to the benefit sliders changing, which
  // cause the component to update many times, we need to debouce fetching data from the HTTP API.
  // This requires 400 miliseconds to pass until this function hasn't been repeatedly called.
  debouncedFetchSavingsInformation = lodash.debounce(
    this.fetchSavingsInformation,
    400
  );

  componentDidMount() {
    if (this.props.isReplaceAndSaveEnabled) {
      this.fetchSavingsInformation();
    }
  }

  componentDidUpdate() {
    if (this.props.isReplaceAndSaveEnabled) {
      // Because this component needs to respond to the benefit sliders changing, which
      // cause the component to update many times, we need to debouce fetching data from the HTTP API.
      // This requires 400 miliseconds to pass until this function hasn't been repeatedly called.
      this.debouncedFetchSavingsInformation();
    }
  }

  hasPremiumSaving() {
    return this.state.savingsInformation.get('hasPremiumSaving');
  }

  noPremiumSaving() {
    return !this.hasPremiumSaving();
  }

  monthlyPremiumSaving() {
    return this.state.savingsInformation.get('monthlyPremiumSaving');
  }

  annualPremiumSaving() {
    return this.state.savingsInformation.get('annualPremiumSaving');
  }

  initialPremiumSaving() {
    return this.state.savingsInformation.get('initialPremiumSaving');
  }

  replacementPolicyTotalCover() {
    return this.state.savingsInformation.get('totalCover');
  }

  render() {
    // Component knows how to feature toggle itself
    if (!this.props.isReplaceAndSaveEnabled) return <React.Fragment />;
    return (
      <React.Fragment>
        <Toaster
          slices={[
            {
              type: 'primary',
              toast: (
                <span>
                  {replacementPolicyCustomisationPageSentence({
                    initialPremiumSaving: this.initialPremiumSaving(),
                  })}
                </span>
              ),
            },
            this.props.hasMaxCoverWarning && {
              type: 'danger',
              toast: (
                <span>
                  {replacementPolicyCoverExceedsMaxCover()}
                </span>
              ),
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

export default CustomisationPageSummary;

import React, { PureComponent } from 'react';
import { Table } from '@simply-fin-services/astronomix3';
import { fromJS } from 'immutable';

class ThankYouCancellation extends PureComponent {
  constructor() {
    super();
    this.state = {
      loading: true,
      cancellationLetters: fromJS([]),
    };
  }

  componentDidMount() {
    if(this.props.isReplaceAndSaveEnabled){
      this.props.listCancellationLetters().then((cancellationLetters) => {
        this.setState({
          loading: false,
          cancellationLetters,
        });
      });
    }
  }

  insuranceCompanyInfoComponent(insuranceCompanyInfo) {
    return (
      <React.Fragment>
        {insuranceCompanyInfo.get('emailAddress')}

        {insuranceCompanyInfo.get('hasContactNumber?') && (
          <div>{insuranceCompanyInfo.get('contactNumber')}</div>
        )}
      </React.Fragment>
    );
  }

  pluralisePolicyWording(totalReplacementPolicies) {
    return totalReplacementPolicies === 1 ? 'policy' : 'policies';
  }

  render() {
    // Component knows how to feature toggle itself
    if (!this.props.isReplaceAndSaveEnabled) return <React.Fragment />;

    return (
      <React.Fragment>
        <h4>Cancellation process</h4>
        <p>
          To make sure that you get the monthly savings outlined above, it is
          crucial that your existing policies are cancelled and replaced with
          the consolidated policy above.
        </p>
        <p>
          To simplify the process, we have created the cancellation letters
          below. Please make sure that you (with the help of your broker)
          initiate this cancellation process as soon as possible.
        </p>
        <p>The following policies must be cancelled:</p>

        <Table>
          <tbody>
            {this.state.cancellationLetters.map((cancellationLetter) => (
              <tr key={cancellationLetter.get('insurer')}>
                <td>
                  {cancellationLetter.get('totalReplacementPolicies')} x{' '}
                  {cancellationLetter.get('insurer')}{' '}
                  {this.pluralisePolicyWording(
                    cancellationLetter.get('totalReplacementPolicies')
                  )}
                </td>
                <td>
                  {cancellationLetter.get('hasInsuranceCompanyInfo') &&
                    this.insuranceCompanyInfoComponent(
                      cancellationLetter.get('insuranceCompanyInfo')
                    )}
                </td>
                <td>
                  <a
                    href={cancellationLetter.get('downloadUrl')}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Cancellation letter
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
}

export default ThankYouCancellation;

"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInceptionDateFromPaymentDetail = exports.extractContractPaymentMethod = exports.extractBankingDetailForContract = exports.validatePaymentDetails = void 0;

var _bankingDetails = require("./bankingDetails");

var _iemasSalaryDeduction = require("./iemasSalaryDeduction");

var _persalSalaryDeduction = require("./persalSalaryDeduction");

var _salaryDeduction = require("./salaryDeduction");

var R = _interopRequireWildcard(require("ramda"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var config = [{
  paymentType: 'debit-order',
  validate: function validate(currentDate, details) {
    return (0, _bankingDetails.validateBankingDetails)(currentDate, details);
  },
  contractType: 'DirectDebit',
  extractContractDetails: _bankingDetails.extractBankingContractDetails,
  getInceptionDate: _bankingDetails.getBankingContractInceptionDate
}, {
  paymentType: 'iemas-salary-deduction',
  validate: function validate(currentDate, details) {
    return (0, _iemasSalaryDeduction.validateIemasSalaryDeductionPaymentDetails)(details);
  },
  contractType: 'IemasSalaryDeduction',
  extractContractDetails: _iemasSalaryDeduction.extractIemasContractDetails,
  getInceptionDate: _iemasSalaryDeduction.getIemasContractInceptionDate
}, {
  paymentType: 'persal',
  validate: function validate(currentDate, details) {
    return (0, _persalSalaryDeduction.validatePersalPaymentDetails)(details);
  },
  contractType: 'Persal',
  extractContractDetails: _persalSalaryDeduction.extractPersalContractDetails,
  getInceptionDate: _persalSalaryDeduction.getPersalContractInceptionDate
}, {
  paymentType: 'salary-deduction',
  validate: function validate(currentDate, details) {
    return (0, _salaryDeduction.validateSalaryDeductionPaymentDetails)(details);
  },
  getInceptionDate: _salaryDeduction.getSalaryDeductionContractInceptionDate,
  extractContractDetails: _salaryDeduction.extractSalaryDeductionContractDetails,
  contractType: 'salary-deduction'
}];
var configMap = R.pipe(R.map(function (pt) {
  return [pt.paymentType, pt];
}), R.fromPairs)(config);

var getPaymentTypeConfig = function getPaymentTypeConfig(paymentType) {
  return configMap[paymentType] || configMap['debit-order'];
};

var validatePaymentDetails = function validatePaymentDetails(paymentType, currentDate, detailsImmutable) {
  return getPaymentTypeConfig(paymentType).validate(currentDate, detailsImmutable);
};

exports.validatePaymentDetails = validatePaymentDetails;

var extractBankingDetailForContract = function extractBankingDetailForContract(createContractBase) {
  var paymentType = createContractBase.getIn(['options', 'bankingDetails', 'paymentType']);
  return getPaymentTypeConfig(paymentType).extractContractDetails(createContractBase);
};

exports.extractBankingDetailForContract = extractBankingDetailForContract;

var extractContractPaymentMethod = function extractContractPaymentMethod(createContractBase) {
  var paymentType = createContractBase.getIn(['options', 'bankingDetails', 'paymentType']);
  return getPaymentTypeConfig(paymentType).contractType;
};

exports.extractContractPaymentMethod = extractContractPaymentMethod;

var getInceptionDateFromPaymentDetail = function getInceptionDateFromPaymentDetail(paymentDetailInput, generationDate, selectedInceptionMonth, isUpsellSale) {
  var paymentType = paymentDetailInput.get('paymentType');
  return getPaymentTypeConfig(paymentType).getInceptionDate(generationDate, selectedInceptionMonth, isUpsellSale);
};

exports.getInceptionDateFromPaymentDetail = getInceptionDateFromPaymentDetail;
import React, { PureComponent } from 'react';
import TermsFromConfig from '../../terms/TermsFromConfig';

class PaymentDetailsExtraTerms extends PureComponent {
  render() {
    // Component knows how to feature toggle itself
    if (!this.props.isReplaceAndSaveEnabled) return <React.Fragment />;

    return (
      <React.Fragment>
        {this.props.hasPaymentTermsConfig && (
          <TermsFromConfig showHeading={false} termsId={this.props.termsId} />
        )}

        {this.props.showGeneralPaymentTerms && (
          <TermsFromConfig
            showHeading={false}
            termsId="replace-and-save-policy-general-payment-terms"
          />
        )}
      </React.Fragment>
    );
  }
}

export default PaymentDetailsExtraTerms;

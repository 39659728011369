import { connect } from 'react-redux';
import {
  isReplaceAndSaveEnabled,
  replacementPolicies,
  totalReplacementPolicies,
  totalMonthlyPremium,
} from '../../../stateStore/replaceAndSave';
import { fetchLatestPolicyReplacementData } from '../httpApi';
import TermsReplacementPolicyList from './TermsReplacementPolicyList';

export const mapStateToProps = (state) => {
  return {
    isReplaceAndSaveEnabled: isReplaceAndSaveEnabled(state),
    replacementPolicies: replacementPolicies(state),
    totalReplacementPolicies: totalReplacementPolicies(state),
    totalMonthlyPremium: totalMonthlyPremium(state),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchLatestPolicyReplacementData: () =>
    fetchLatestPolicyReplacementData(dispatch),
});

const TermsReplacementPolicyListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsReplacementPolicyList);

export default TermsReplacementPolicyListContainer;

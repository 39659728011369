import { connect } from 'react-redux';
import QuickQuote from './QuickQuote';

import {replaceAndSaveEnabledPackage} from '../../stateStore/replaceAndSave';

import { isFromBrokerPortal } from '../../common/brokers';

const selectPackages = (state) =>
  state
    .get('packages')
    .filter((p) => p.get('isFeatured'))
    // Filters replace and save enabled packages if feature enabled.
    .filter((p) => replaceAndSaveEnabledPackage(state, p))
    .map((p) => ({
      basePackageId: p.get('basePackageId'),
      title: p.get('callToAction'),
      packageUrlSlug: p.get('urlSlug'),
      description: p.get('quoteDescription'),
      order: p.get('order'),
    }))
    .toList()
    .toJS();

export const mapStateToProps = (state) => ({
  packages: selectPackages(state),
  selectedPackageSlug:
    state.get('selectedPackageSlug') ||
    state
      .get('packages')
      .filter((p) => p.get('isFeatured'))
      .sortBy((p) => p.get('order'))
      .first()
      .get('urlSlug'),
  allowCoverSelection: !isFromBrokerPortal(state)
});

const QuickQuoteContainer = connect(mapStateToProps)(QuickQuote);

export default QuickQuoteContainer;

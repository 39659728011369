import { connect } from 'react-redux';
import { currentPolicyId } from '../../../stateStore/replaceAndSave';
import { allocateCover } from './../httpApi';

import CoverAllocation from './CoverAllocation';

const mapStateToProps = (state) => ({
  policyId: currentPolicyId(state),
});

const mapDispatchToProps = () => ({
  allocateCover: (params) => allocateCover(params),

  // Alias for allocateCover where coverAmount is null
  clearAllocatedCover: (params) => allocateCover(params),
});

const CoverAllocationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverAllocation);

export default CoverAllocationContainer;

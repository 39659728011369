"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.effectiveOptionalQuestionIds = exports.effectiveQuestionIds = exports.filterOptionalConditionalQuestions = void 0;

var _immutable = require("immutable");

var getAnswer = function getAnswer(questionId, answers) {
  var answer = answers.get(questionId);

  if (answer) {
    return answer.get('answer');
  }

  return null;
};

var quoteAnswerConditionMet = function quoteAnswerConditionMet(quoteAnswer, answers) {
  var conditionQuoteQuestion = quoteAnswer[0];
  var conditionQuoteAnswer = quoteAnswer[1];
  return getAnswer(conditionQuoteQuestion, answers) === conditionQuoteAnswer;
};

var isConditionMet = function isConditionMet(options) {
  // Only supports checking quoteAnswer for now, and assumes this is the
  // default behaviour.
  if (options.condition.quoteAnswers) {
    return options.condition.quoteAnswers.every(function (quoteAnswer) {
      return quoteAnswerConditionMet(quoteAnswer, options.answers);
    });
  }

  return quoteAnswerConditionMet(options.condition.quoteAnswer, options.answers);
};

var filterOptionalConditionalQuestions = function filterOptionalConditionalQuestions(options) {
  return options.conditionalQuestions.toJS().filter(function (question) {
    return !isConditionMet({
      condition: question.condition,
      answers: options.answers
    });
  }).map(function (item) {
    return item.questions;
  }).flat();
};

exports.filterOptionalConditionalQuestions = filterOptionalConditionalQuestions;

var effectiveQuestionIds = function effectiveQuestionIds(options) {
  var conditionalQuestions = options.conditionalQuestions;
  var allQuestionIds = options.allQuestionIds;

  if (conditionalQuestions) {
    var answers = options.answers;
    var questionsToExclude = filterOptionalConditionalQuestions({
      conditionalQuestions: conditionalQuestions,
      answers: answers
    });
    return allQuestionIds.filter(function (questionId) {
      return !questionsToExclude.includes(questionId);
    });
  }

  return allQuestionIds;
};

exports.effectiveQuestionIds = effectiveQuestionIds;

var effectiveOptionalQuestionIds = function effectiveOptionalQuestionIds(options) {
  var conditionalQuestions = options.conditionalQuestions;
  var allQuestionIds = options.allQuestionIds || (0, _immutable.fromJS)([]);

  if (conditionalQuestions) {
    var answers = options.answers;
    var questionsToExclude = filterOptionalConditionalQuestions({
      conditionalQuestions: conditionalQuestions,
      answers: answers
    });
    return options.allQuestionIds.concat(questionsToExclude);
  }

  return allQuestionIds;
};

exports.effectiveOptionalQuestionIds = effectiveOptionalQuestionIds;
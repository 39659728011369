import * as types from './types';

export const setFormEntity = (entityType, entity) => ({
  type: types.SET_FORM_ENTITY,
  payload: { entityType, entity },
});

export const clearFormEntity = (entityType) => ({
  type: types.CLEAR_FORM_ENTITY,
  payload: (dispatch, getState) => {
    const state = getState();

    const questionsIds = state.getIn([
      'questionPages',
      entityType,
      'questions',
    ]);

    questionsIds.forEach((questionId) => {
      dispatch({
        type: types.REMOVE_ANSWER,
        payload: { questionId },
      });
    });

    dispatch({
      type: types.SET_FORM_ENTITY,
      payload: { entityType, entity: {} },
    });
  },
});

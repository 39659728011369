import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initExternalDetailsCapture } from '../../actions/detailsCapture';
import toQueryParams from '../../common/propsToLocationQueryParams';

class DetailsCaptureInit extends PureComponent {
  componentDidMount() {
    this.props.init(this.props.config, this.props.defaultPackageId);
  }

  render() {
    return (
      <div className="row">
        <div className="col-xs-12" />
      </div>
    );
  }
}

DetailsCaptureInit.propTypes = {
  config: PropTypes.object.isRequired,
  defaultPackageId: PropTypes.string.isRequired,
  init: PropTypes.func.isRequired,
};

export const mapStateToProps = (state, ownProps) => {
  const defaultPackageId = state.getIn(['detailsCapture', 'defaultPackage']);
  return {
    config: toQueryParams(ownProps),
    defaultPackageId,
  };
};

export const mapDispatchToProps = dispatch => ({
  init: (config, defaultPackageId) =>
    dispatch(initExternalDetailsCapture(config, defaultPackageId)),
});

const DetailsCaptureInitContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsCaptureInit);

export default DetailsCaptureInitContainer;

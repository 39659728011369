import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import {
  Container,
  Row,
  Col,
  InputNumber,
  Spinner,
} from '@simply-fin-services/astronomix3';

class CoverAllocation extends PureComponent {
  constructor() {
    super();
    this.state = { loadingState: fromJS({}) };
  }

  getLoadingStateKey(insuredPersonId) {
    return `insuredPersonLoadingIndicator_${insuredPersonId}`;
  }

  setLoadingStateValue(insuredPersonId, { loading }) {
    const loadingStateKey = this.getLoadingStateKey(insuredPersonId);
    const loadingState = this.state.loadingState.set(loadingStateKey, loading);

    this.setState({ loadingState });
  }

  allocateCoverAmount(policyId, insuredPersonId, provideCoverAmount) {
    const coverAmount = parseInt(provideCoverAmount, 10);

    this.setLoadingStateValue(insuredPersonId, { loading: true });

    if (!Number.isNaN(coverAmount)) {
      this.props
        .allocateCover({ policyId, insuredPersonId, coverAmount })
        .then(() => {
          this.setLoadingStateValue(insuredPersonId, { loading: false });
        });
    } else {
      this.props.clearAllocatedCover({ policyId, insuredPersonId }).then(() => {
        this.setLoadingStateValue(insuredPersonId, { loading: false });
      });
    }
  }

  showLoadingIndicatorForCoverAllocation(insuredPersonId) {
    const loadingStateKey = this.getLoadingStateKey(insuredPersonId);

    return this.state.loadingState.get(loadingStateKey) === true;
  }

  render() {
    return (
      <React.Fragment>
        <p>
          Please add the details of any family members covered under this
          policy. If their details have already been captured under another
          policy you added previously, please list any additional cover they
          have under this particular policy.
        </p>

        <Container className="space-below">
          <Row>
            <Col></Col>
            <Col>
              <label>Funeral cover amount</label>
            </Col>
          </Row>
          {this.props.familyMembers.map((familyMember) => (
            <Row key={familyMember.get('id')}>
              {/* Ask UI team about the veritical alignment on this (resorted to fail fast CSS hack) */}
              <Col
                style={{
                  margin: 'auto',
                  'margin-top': '15px',
                }}
              >
                <div>
                  <strong>{familyMember.get('fullName')}</strong>{' '}
                  <small>({familyMember.get('relationship')})</small>
                </div>
              </Col>
              <Col>
                <InputNumber
                  currency
                  defaultValue={
                    (familyMember.get('coverAmount') &&
                      familyMember.get('coverAmount').toString()) ||
                    ''
                  }
                  onChange={(event) =>
                    this.allocateCoverAmount(
                      this.props.policyId,
                      familyMember.get('id'),
                      event.target.value
                    )
                  }
                />

                {this.showLoadingIndicatorForCoverAllocation(
                  familyMember.get('id')
                ) && <Spinner margin="0px" width="20px" />}
              </Col>
            </Row>
          ))}
        </Container>
      </React.Fragment>
    );
  }
}

export default CoverAllocation;

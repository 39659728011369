/* eslint-disable no-eval*/
import { connect } from 'react-redux';
import ContactDetails from './ContactDetails';
import * as R from 'ramda';
import { setContactFormViewDate } from '../../actions/contactDetailsPopup';
import {
  stateToProps,
  dispatchToProps,
  mergeThePropsWithOwnProps,
} from '../questionnaire/toQuestionPageProps';

const selectedPackageContactPageId = (state, packageSlug) =>
  state.getIn(['packages', packageSlug, 'contactQuestionPageId']);

const evaluateLessThan = (answer, threshold) =>
  parseInt(answer, 10) < parseInt(threshold, 10);

const CONDITION_EVALUATERS = {
  'value-less-than': evaluateLessThan,
};

const meetsThreshold = (state, packageSlug) => {
  const answers = state.get('answers');
  const questionPageConfigs = state.getIn([
    'questionPages',
    selectedPackageContactPageId(state, packageSlug),
  ]);
  const threshold = questionPageConfigs.get('threshold');
  const hasThreshold = !R.isNil(threshold);
  if (hasThreshold) {
    const answer = answers.getIn([threshold.get('question'), 'answer']);
    const condition = threshold.get('condition');
    const evaluateCondition = CONDITION_EVALUATERS[condition];
    const thresholdValue = threshold.get('value');
    return evaluateCondition(answer, thresholdValue);
  }
  return false;
};

export const selectedContactQuestions = (state, packageSlug) =>
  state.getIn([
    'questionPages',
    selectedPackageContactPageId(state, packageSlug),
    'questions',
  ]);

const toBaseProps = (state, ownProps) =>
  stateToProps(
    state,
    ownProps.packageSlug,
    selectedContactQuestions(state, ownProps.packageSlug),
    null,
    '/customization'
  );

export const mapStateToProps = (state, ownProps) =>
  Object.assign({}, toBaseProps(state, ownProps), {
    isCompulsory: meetsThreshold(state, ownProps.packageSlug),
    contactDetailsPopupVisisble: state.getIn([
      'contactDetailsPopup',
      'contactDetailsPopupVisisble',
    ]),
  });

export const mapDispatchToProps = dispatch =>
  Object.assign({}, dispatchToProps(dispatch), {
    dispatch,
    setContactFormViewDate: () => dispatch(setContactFormViewDate()),
  });

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  mergeThePropsWithOwnProps(stateProps, dispatchProps, ownProps);

const ContactDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ContactDetails);

export default ContactDetailsContainer;

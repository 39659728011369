import React, { PureComponent } from 'react';
import { toCurrencyNoDecimal } from '../../common/numberFormats';

class InsuredsList extends PureComponent {
  render() {
    return (
      <React.Fragment>
        <div>
          Main person:{' '}
          {toCurrencyNoDecimal(
            this.props.replacementPolicy.get('mainLifeCoverAmount')
          )}
        </div>

        {this.props.replacementPolicy
          .get('policyCoverAllocation')
          .map((coverAllocation) => (
            <div key={coverAllocation.get('insuredPersonId')}>
              {coverAllocation.get('person').get('fullName')}{' '}
              {toCurrencyNoDecimal(coverAllocation.get('coverAmount'))}
            </div>
          ))}
      </React.Fragment>
    );
  }
}

export default InsuredsList;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import QuoteContainer from './QuoteContainer';
import { updateSelectedPackage } from '../../actions/selectedPackageSlug';
import { answerQuestion } from '../questionnaire/toQuestionPageProps';
import getPackageIdFromSlug from '../../common/getPackageIdFromSlug';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Radios, Radio, Field } from '@simply-fin-services/astronomix3';

class QuickQuote extends PureComponent {
  constructor() {
    super();
    this.selectPackage = this.selectPackage.bind(this);
  }

  componentWillMount() {
    const { selectedPackageSlug } = this.props;
    this.setState({
      selectedPackageSlug,
    });
  }

  selectPackage(packageSlug) {
    const {
      answers,
      getPackageIdFromStateWithSlug,
      updatePackage,
      revalidateAnswer,
    } = this.props;
    const packageId = getPackageIdFromStateWithSlug(packageSlug);

    updatePackage(packageSlug);
    answers.map((answer) => {
      if (answer !== undefined) {
        return revalidateAnswer(
          packageId,
          answer.get('questionId'),
          answer.get('answer')
        );
      }
      return answer;
    });
    this.setState({
      selectedPackageSlug: packageSlug,
    });
  }

  render() {
    const { packages } = this.props;
    const { selectedPackageSlug } = this.state;
    const getDescription = (filteredPackages) => {
      if (R.isEmpty(filteredPackages)) {
        return '';
      }
      return R.prop('description');
    };

    const coverMessage = R.pipe(
      R.filter((p) => p.packageUrlSlug === selectedPackageSlug),
      R.head,
      getDescription
    )(packages);
    const isFunction = (object) => object instanceof Function;

    return (
      <QuoteContainer packageSlug={selectedPackageSlug}>
        {this.props.allowCoverSelection && (
          <Field label="Cover type">
            <Radios>
              {R.sortBy(R.prop('order'), packages).map((p) => (
                <Radio
                  value={p.packageUrlSlug}
                  key={p.packageUrlSlug}
                  id={p.packageUrlSlug}
                  className="list-group-item"
                  label={p.title}
                  checked={selectedPackageSlug === p.packageUrlSlug}
                  onChange={this.selectPackage}
                />
              ))}
            </Radios>
          </Field>
        )}
        {coverMessage &&
          !R.isEmpty(coverMessage) &&
          !isFunction(coverMessage) && (
            <Field>
              <p className="text-danger">{coverMessage}</p>
            </Field>
          )}
      </QuoteContainer>
    );
  }
}

export const mapStateToProps = (state) => ({
  answers: state.get('answers'),
  getPackageIdFromStateWithSlug: (slug) => {
    return getPackageIdFromSlug(state, slug);
  },
});

export const mapDispatchToProps = (dispatch) => ({
  updatePackage: (p) => dispatch(updateSelectedPackage(p)),
  revalidateAnswer: answerQuestion(dispatch),
});

QuickQuote.propTypes = {
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      packageUrlSlug: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  selectedPackageSlug: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickQuote);

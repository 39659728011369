import { resetStateToPreviousPackage } from '../actions/previousPackageSelections';
import { continueWithChangedPackage } from '../actions/selectedPackageSlug';

const BACK_ACTION = 'POP';
const NO_PACKAGE = '';
const PACKAGE_SLUG_INDEX = 2;
const hasPackageChanged = (locationPackage, statePackage) =>
  locationPackage !== statePackage;
const isGoingBack = (action) => action === BACK_ACTION;
const getPackageFromLocation = (location) =>
  location.pathname.split('/')[PACKAGE_SLUG_INDEX] || NO_PACKAGE;

export default (browserHistory, store) => {
  browserHistory.listen((location, action) => {
    const state = store.getState();
    const statePackage = state.get('selectedPackageSlug');
    const locationPackage = getPackageFromLocation(location);
    const isValidPackage = state.hasIn(['packages', locationPackage]);

    if (!isValidPackage) {
      return;
    }
    const noPackageDefined = locationPackage === NO_PACKAGE;
    const packageChanged = hasPackageChanged(locationPackage, statePackage);

    const goingBack = isGoingBack(action);
    if (packageChanged && goingBack && !noPackageDefined) {
      store.dispatch(resetStateToPreviousPackage(locationPackage));
    }
    if (packageChanged && !goingBack && !noPackageDefined) {
      store.dispatch(continueWithChangedPackage());
    }
  });
};

import { connect } from 'react-redux';
import {
  totalReplacementPolicies,
  totalMonthlyPremium,
  isReplaceAndSaveEnabled,
} from '../../../stateStore/replaceAndSave';
import { savingsInformationForComponent } from '../httpApi';
import CustomisationPageTotals from './CustomisationPageTotals';

export const mapStateToProps = (state) => {
  return {
    isReplaceAndSaveEnabled: isReplaceAndSaveEnabled(state),
    totalReplacementPolicies: totalReplacementPolicies(state),
    totalMonthlyPremium: totalMonthlyPremium(state),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  savingsInformationForComponent: (quoteMonthlyPremium) =>
    savingsInformationForComponent(quoteMonthlyPremium, dispatch),
});

export const CustomisationPageTotalsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomisationPageTotals);

export default CustomisationPageTotalsContainer;

import { fromJS } from 'immutable';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILURE,
  OTP_VALIDATED,
  THIRD_PARTY_OTP_VALIDATED
} from '../actions/types';
import createActionHandler from './createActionHandler';

const initialState = fromJS({
  isLoggedIn: false,
  token: null,
  roles: [],
  otp: {},
});

const actionHandlers = {};

const toSuccessState = (state, action) => {
  const { token, roles } = action.payload;
  const error = null;
  return state.merge({
    isLoggedIn: true,
    token,
    roles,
    error,
  });
};
actionHandlers[LOGIN_SUCCESS] = toSuccessState;

const toFailedState = (state, action) => {
  const error = action.payload;
  return state.merge({
    isLoggedIn: false,
    error,
    token: null,
    roles: [],
  });
};

const saveOtpSendFail = (state, action) => {
  const type = action.payload.type;
  return state.setIn(['otp', type, 'status', 'sent'], false);
};

const saveOtpSendSuccess = (state, action) => {
  const { id, type } = action.payload;
  const data = fromJS({
    status: true,
    id,
  });
  return state.setIn(['otp', type], data);
};

const setOtpValidationStatus = (state, action) => {
  const { isValid } = action.payload;
  return state.setIn(['otp', 'isValid'], isValid);
};

const setThirdPartyOtpValidationStatus = (state, action) => {
  const { isValid } = action.payload;
  return state.setIn(['otp', 'thirdPartyOtpIsValid'], isValid);
};

actionHandlers[LOGIN_FAILED] = toFailedState;
actionHandlers[SEND_OTP_FAILURE] = saveOtpSendFail;
actionHandlers[SEND_OTP_SUCCESS] = saveOtpSendSuccess;
actionHandlers[OTP_VALIDATED] = setOtpValidationStatus;
actionHandlers[THIRD_PARTY_OTP_VALIDATED] = setThirdPartyOtpValidationStatus;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Abstract from '../Abstract';
import { fromJS } from 'immutable';
import * as R from 'ramda';
import { getInsuredTerms } from '../../../.shared/getTerms';
import ToggleTerm from '../ToggleTerm';

const getSelectedProducts = (selection) => {
  const isSelected = (product) => product.selected;
  const selectedProducts = selection.get('products');
  const productsSelection = selectedProducts ? selectedProducts.toJS() : [];
  const filteredProducts = R.filter(isSelected, productsSelection);
  const products = R.toPairs(filteredProducts).map(
    (productArr) => productArr[0]
  );
  const hasCashBack = selection.get('withRop');
  const productsWithCashBack = hasCashBack
    ? R.append('sim-cashback', products)
    : products;
  return productsWithCashBack;
};

const renderTermsByProduct = (products, config, packageId) => {
  const withRop = R.includes('sim-cashback', products);
  const termsAndConditions = getInsuredTerms(config, packageId, products, withRop);
  const termsConfig = config.get('termsConfig').toJS();
  const matchedTermPairs = R.map(
    (pageTerm) =>
      R.head(
        R.toPairs(R.filter((term) => term.termId === pageTerm, termsConfig))
      ),
    termsAndConditions
  );
  const matchedTerms = R.map(
    (matchedPair) => R.last(matchedPair),
    matchedTermPairs
  );
  const pageTermsToBeRendered = R.addIndex(R.map)((term, i) => {
    if (term.termId === 'intro') {
      return <div key={`${term.title}intro${i}`}>{term.detail}</div>;
    }
    return (
      <Abstract title={term.title} key={`${term.title}${i}`}>
        {term.detail.map((detailItem) => {
          if (R.is(Object, detailItem)) {
            return <ToggleTerm term={detailItem} />;
          }
          return <p>{detailItem}</p>;
        })}
      </Abstract>
    );
  }, matchedTerms);
  return pageTermsToBeRendered;
};

class GeneralTerms extends PureComponent {
  render() {
    const {
      children,
      productSelection,
      packages,
      packageId,
      productsConfig,
      termsConfig,
    } = this.props;
    const config = fromJS({ packages, products: productsConfig, termsConfig });
    const selectedProducts = getSelectedProducts(productSelection);
    return (
      <div>
        {renderTermsByProduct(selectedProducts, config, packageId)}
        {children}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productSelection: state.get('productSelection'),
  packages: state.get('packages'),
  productsConfig: state.get('products'),
  termsConfig: state.get('terms'),
  packageId: state.get('selectedPackageSlug'),
});

export default connect(mapStateToProps)(GeneralTerms);

import * as R from 'ramda';
import * as actions from '../actions/types';
import { getSelectedPackageIdOrDefault } from './getPackageIdFromSlug';
import { isBrokerSale } from '../common/brokers';

export const mauticHeaderCodeSnipit = tagURL => {
  if (tagURL) {
    return `<script defer>
    (function(w,d,t,u,n,a,m){w['MauticTrackingObject']=n;
        w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},a=d.createElement(t),
        m=d.getElementsByTagName(t)[0];a.async=1;a.src=u;m.parentNode.insertBefore(a,m)
    })(window,document,'script','${tagURL}','mt');
</script>`;
  }
  return '';
};

const IDENTITY_ACTIONS = [actions.ANSWER_QUESTION_SUCCESS];

const IDENTITY_QUESTIONS = [
  'age',
  'gender',
  'income',
  'smoker',
  'married',
  'education',
  'owner-first-name',
  'owner-surname',
  'owner-contact-number',
  'owner-id-number',
  'delivery-email',
];

const liftProps = obj => R.assoc(obj.questionId, obj.answer, {});

const isDefined = val => R.complement(R.or(R.isNil, R.isEmpty))(val);

const toMauticEntity = (interactionId, data) =>
  R.pipe(
    liftProps,
    R.applySpec({
      interactionid: R.always(interactionId),
      firstname: R.prop('owner-first-name'),
      lastname: R.prop('owner-surname'),
      email: R.prop('delivery-email'),
      age: R.prop('age'),
      gender: R.prop('gender'),
      mobile: R.prop('owner-contact-number'),
      income: R.prop('income'),
      smoker: R.prop('smoker'),
      married: R.prop('married'),
      education: R.prop('education'),
      'owner-id-number': R.prop('owner-id-number'),
    }),
    R.pickBy(isDefined)
  )(data);

const isIdentifyAction = action =>
  R.all(R.equals(true), [
    R.includes(action.type, IDENTITY_ACTIONS),
    R.includes(R.path(['payload', 'questionId'], action), IDENTITY_QUESTIONS),
    R.path(['payload', 'isValid'], action),
  ]);

/* eslint-disable no-undef */
const createMauticActionMiddleware = ({ getState }) => next => action => {
  const state = getState();
  const packageId = getSelectedPackageIdOrDefault(state);
  const interactionId = state.get('interactionId');
  const actionWithPackage = R.assocPath(
    ['payload', 'packageId'],
    packageId,
    action
  );
  mt = window.mt || [];
  if (actionWithPackage.type === '@@router/LOCATION_CHANGE') {
    mt('send', 'pageview');
  }
  if (isIdentifyAction(actionWithPackage) && !isBrokerSale(state)) {
    mt(
      'send',
      'pageview',
      toMauticEntity(interactionId, actionWithPackage.payload)
    );
  }
  return next(action);
};
/* eslint-enable no-undef */

const createPassthroughMiddleware = () => next => action => next(action);

export const createMauticMiddleware = initialState => {
  const tagId = initialState.config.MAUTIC_URL;
  if (tagId) {
    return createMauticActionMiddleware;
  }
  return createPassthroughMiddleware;
};

import * as types from './types';
import request from 'axios';
import { API_ROUTES } from '../config/constants';
import cookieBakery from 'js-cookie';
import * as R from 'ramda';

const CAMPAIGN_COOKIE = 'campaign';
const SOURCE_COOKIE = 'campaign-source';
const CAMPAIGN_COOKIE_LIFETIME_IN_DAYS = 30;
const COOKIE_DOMAIN = '.simply.co.za';
export const UTM_PROPS_LIST = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];
const REFERRER_URL_COOKIE = 'referrerURL';
const INITIAL_URL_COOKIE = 'initialURL';

const getGCLIDCookie = () => cookieBakery.get('gclid');
const getFBCLIDCookie = () => cookieBakery.get('fbclid');
const getUTMPropsCookie = () => {
  const UTMdata = cookieBakery.get('utmProps');
  if (UTMdata) {
    return JSON.parse(UTMdata);
  }
  return undefined;
};
const getInitialURLCookie = () => cookieBakery.get('initialURL');
const getReffererURLCookie = () => cookieBakery.get('referrerURL');

const setCookies = (campaignId, source) => {
  cookieBakery.set(CAMPAIGN_COOKIE, campaignId, {
    expires: CAMPAIGN_COOKIE_LIFETIME_IN_DAYS,
  });
  cookieBakery.set(SOURCE_COOKIE, source, {
    expires: CAMPAIGN_COOKIE_LIFETIME_IN_DAYS,
  });
};

export const clearUTMProps = () => {
  cookieBakery.remove('utmProps');
  return {
    type: 'CLEAR_CAMPAIGN_COOKIES',
    payload: {},
  };
};

const linkCampaignCookie = (campaignId, sourceId) => {
  const type = types.SET_CAMPAIGN_COOKIE;
  return {
    type,
    payload: setCookies(campaignId, sourceId),
  };
};

const linkCampaignSuccess = (campaignId, sourceId) => {
  const type = types.LINK_TO_CAMPAIGN_SUCCESS;
  return {
    type,
    payload: { campaignId, sourceId },
  };
};

export const linkCampaignError = () => {
  const type = types.LINK_TO_CAMPAIGN_ERROR;
  return {
    type,
  };
};

export const noCampaignToLink = () => {
  const type = types.LINK_TO_CAMPAIGN_NO_CAMPAIGN;
  return {
    type,
  };
};

const getAdCookies = () => {
  const GCLID = getGCLIDCookie();
  const FBCLID = getFBCLIDCookie();
  const UTMProps = getUTMPropsCookie();
  const referrerURL = getReffererURLCookie();
  const initialURL = getInitialURLCookie();
  const CLID = FBCLID || GCLID;
  const whichId = FBCLID ? 'fbclid' : 'gclid';
  return { CLID, whichId, UTMProps, referrerURL, initialURL };
};

const getSourceId = (sourceIn, utmSource) => {
  const upperSource = sourceIn ? R.toUpper(sourceIn) : null;
  return upperSource === 'PREFIX'
    ? utmSource || cookieBakery.get(SOURCE_COOKIE) || sourceIn || 'UNKNOWN'
    : sourceIn || utmSource || cookieBakery.get(SOURCE_COOKIE) || 'UNKNOWN';
};

const toStringUTM = R.mapObjIndexed((v) => R.toString(v));

const link = (sourceIn, campaignIn) => (dispatch) => {
  const { CLID, whichId, UTMProps, referrerURL, initialURL } = getAdCookies();
  const utmCampaign = UTMProps ? UTMProps.utm_campaign : undefined;
  const utmSource = UTMProps ? UTMProps.utm_source : undefined;
  const campaignId =
    campaignIn ||
    utmCampaign ||
    cookieBakery.get(CAMPAIGN_COOKIE) ||
    'NOT_PROVIDED';
  const sourceId = getSourceId(sourceIn, utmSource);
  if (campaignId) {
    request
      .post(API_ROUTES.LINK_CAMPAIGN, {
        campaignId,
        sourceId,
        utmProps: R.pipe(
          toStringUTM,
          R.assoc(whichId, CLID),
          R.assoc('referrerURL', referrerURL || ''),
          R.assoc('initialURL', initialURL || '')
        )(UTMProps || {}),
      })
      .then(() => {
        dispatch(linkCampaignCookie(campaignId, sourceId));
        dispatch(linkCampaignSuccess(campaignId, sourceId));
      })
      .catch(() => {
        dispatch(linkCampaignError());
      });
  } else {
    dispatch(noCampaignToLink());
  }
};

export const linktoCampaign = (sourceId, campaignIn) => {
  const type = types.LINK_TO_CAMPAIGN;
  const payload = link(sourceId, campaignIn);

  return {
    type,
    payload,
  };
};

const linkWithUTM = (sourceId, queryparams) => (dispatch) => {
  const propsToSave = R.pick(UTM_PROPS_LIST, queryparams);
  const { campaign } = queryparams;
  if (!R.isEmpty(propsToSave)) {
    cookieBakery.set('utmProps', JSON.stringify(propsToSave), {
      expires: 30,
      domain: COOKIE_DOMAIN,
    });
  }
  if (
    !R.isEmpty(document.referrer) &&
    !R.isNil(document.referrer) &&
    (!R.isEmpty(propsToSave) || R.isNil(cookieBakery.get(REFERRER_URL_COOKIE)))
  ) {
    cookieBakery.set(REFERRER_URL_COOKIE, document.referrer, {
      expires: 30,
      domain: COOKIE_DOMAIN,
    });
  }
  if (
    document.URL &&
    (!R.isEmpty(propsToSave) || R.isNil(cookieBakery.get(INITIAL_URL_COOKIE)))
  ) {
    cookieBakery.set(INITIAL_URL_COOKIE, document.URL, {
      expires: 30,
      domain: COOKIE_DOMAIN,
    });
  }
  dispatch(linktoCampaign(sourceId, campaign));
};

export const linkThenAddCookie = (campaignId, sourceId) => {
  const type = types.LINK_TO_CAMPAIGN;
  const payload = link(sourceId, campaignId);
  return {
    type,
    payload,
  };
};

export const shouldLinktoCampaign = (campaignId, sourceId) => {
  const type = types.SHOULD_LINK_TO_CAMPAIGN;
  const source = sourceId || '';
  return {
    type,
    payload: link(source, campaignId),
  };
};

export const saveUTMProps = (queryparams, senderReference) => {
  const type = types.SAVE_UTM_PROPS;
  return {
    type,
    payload: linkWithUTM(senderReference, queryparams),
  };
};

export const handleSenderReferenceAndLinkCampaign = (dispatch, queryParams) => {
  const { senderReference } = queryParams;
  if (senderReference) {
    dispatch(saveUTMProps(queryParams, senderReference));
  }
};

/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReferralBannerThankYou } from '../referralSignup/ReferralBanner';
import BackToPolicies from '../thankYou/BackToPolicies';
import SummaryContainer from '../customization/SummaryContainer';
import SummaryProducts from '../customization/SummaryProducts';
import facebookIcon from '../../images/icons/facebook-natural.svg';
import googleIcon from '../../images/icons/google_natural.svg';
import {
  FacebookButton,
  GoogleButton,
  Buttons,
} from '@simply-fin-services/astronomix3';

import * as R from 'ramda';
const toProductSelectionMap = coverList =>
  coverList.map(cover =>
    R.pipe(
      R.assoc('selected', true),
      R.assoc('premium', null),
      R.assoc('insuredAmount', cover.cover)
    )(cover)
  );
const googleReviewLink =
  'https://search.google.com/local/writereview?placeid=ChIJER7gSdBCzB0RYDp1mVjMSiM';
const facebookReviewLink =
  'https://www.facebook.com/pg/simplylifeinsurance/reviews/';

export class Heading extends PureComponent {
  render() {
    const {
      reference,
      parentDisplayName,
      cover,
      failedUnderwriting,
    } = this.props;
    const correctCover = R.filter(
      R.compose(R.equals(failedUnderwriting), R.prop('failedUnderwriting')),
      cover
    );
    const productSelection = toProductSelectionMap(correctCover);
    const packageSignedUpFor = R.isNil(parentDisplayName)
      ? null
      : R.map(
          p => <span className="text-capitalize"> {p} </span>,
          parentDisplayName
        );
    return (
      <div className="text-center">
        <BackToPolicies />
        <div className="row">
          <div className="col-xs-12">
            <SummaryContainer transparent>
              <h3 className="text-center">
                Congratulations. Your policy follow-up is complete.
              </h3>
            </SummaryContainer>
            <p>
              Your cover is in place! Your policy number is
              <strong> {reference}</strong>.
            </p>
            <p>
              You have been signed up for:
              <strong>{packageSignedUpFor}</strong>
            </p>
            <p>Your benefits include:</p>
            {correctCover.length > 0 && (
              <div className="space-below">
                <SummaryContainer transparent>
                  <SummaryProducts
                    products={productSelection}
                    hidePremium
                    transparent
                    headerLess
                    hideCover
                  />
                </SummaryContainer>
                {failedUnderwriting === true && (
                  <div className="small">
                    * Cover will be limited to accidental causes only
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Heading.propTypes = {
  reference: PropTypes.string.isRequired,
  parentDisplayName: PropTypes.string.isRequired,
  failedUnderwriting: PropTypes.bool,
  cover: PropTypes.array,
};

export const mapStateToProps = state =>
  state.get('detailsCaptureProcess').toJS();

export const DoneHeading = connect(mapStateToProps)(Heading);

export default class Done extends PureComponent {
  render() {
    const goToLink = url => {
      const win = window.open(url, '_blank');
      win.focus();
    };
    const iconClass = { width: '25px', float: 'right' };
    return (
      <div className="text-center">
        <DoneHeading />
        <h5 className="bold">Thank You</h5>
        <p>You and your loved ones are now covered. </p>

        <p>
          <strong>Rate us on Facebook or Google</strong>
          <br />
          Please rate us on FB or Google and help us spread the word. We’ll send
          you a voucher for a coffee at Vida e Caffe to say thanks.
        </p>
        <Buttons>
          <FacebookButton
            colour="primary"
            size="medium"
            onClick={() => goToLink(facebookReviewLink)}
          >
            <span>Facebook</span>
            <img
              src={facebookIcon}
              alt="rate us on facebook"
              style={iconClass}
            />
          </FacebookButton>
          <GoogleButton
            colour="primary"
            size="medium"
            onClick={() => goToLink(googleReviewLink)}
          >
            <span>Google</span>
            <img src={googleIcon} alt="rate us on google" style={iconClass} />
          </GoogleButton>
        </Buttons>
        <ReferralBannerThankYou />
      </div>
    );
  }
}

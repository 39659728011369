import React, { PureComponent } from 'react';
import { toGenericPackage } from '../../config/appConfig';
import * as R from 'ramda';
import { SiteProgress } from '@simply-fin-services/astronomix3';

class Progress extends PureComponent {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { path, packageId } = this.props;

    const percentages = {
      'sim-family-accidental': {
        '/customization/simply-family-accidental': 60,
        '/detail/simply-family-accidental/compliance': 65,
        '/detail/simply-family-accidental/personal-details': 70,
        '/tell-us-more-about-your-loved-ones/simply-family-accidental': 75,
        '/terms/simply-family-accidental': 80,
        '/banking-details/simply-family-accidental': 90,
        '/thank-you/simply-family-accidental': 100,
      },
      'sim-family': {
        '/': 'home',
        '/quote/simply-family-combo': 5,
        '/nearly-there/simply-family-combo': 20,
        '/customization/simply-family-combo': 20,
        '/detail/simply-family-combo/citizenship': 25,
        '/detail/simply-family-combo/medical-questions': 30,
        '/detail/simply-family-combo/medical-eligibility': 30,
        '/detail/simply-family-combo/medical-underwriting': 30,
        '/detail/simply-family-combo/compliance': 40,
        '/detail/simply-family-combo/conditional-personal-details': 50,
        '/tell-us-more-about-your-loved-ones/simply-family-combo': 60,
        '/terms/simply-family-combo': 70,
        '/banking-details/simply-family-combo': 90,
        '/thank-you/simply-family-combo': 100,
      },

      'sim-flexi-funeral': {
        '/': 'home',
        '/quote/simply-flexi-funeral-combo': 5,
        '/nearly-there/simply-flexi-funeral-combo': 20,
        '/customization/simply-flexi-funeral-combo': 20,
        '/detail/simply-flexi-funeral-combo/citizenship': 25,
        '/detail/simply-flexi-funeral-combo/medical-questions': 30,
        '/detail/simply-flexi-funeral-combo/medical-eligibility': 30,
        '/detail/simply-flexi-funeral-combo/medical-underwriting': 30,
        '/detail/simply-flexi-funeral-combo/compliance': 40,
        '/detail/simply-flexi-funeral-combo/personal-details': 50,
        '/tell-us-more-about-your-loved-ones/simply-flexi-funeral-combo': 60,
        '/terms/simply-flexi-funeral-combo': 70,
        '/banking-details/simply-flexi-funeral-combo': 90,
        '/thank-you/simply-flexi-funeral-combo': 100,
      },

      'sim-family-fmp': {
        '/': 'home',
        '/quote/simply-family-combo-fmp': 5,
        '/nearly-there/simply-family-combo-fmp': 20,
        '/customization/simply-family-combo-fmp': 20,
        '/detail/simply-family-combo-fmp/insured-person-details': 30,
        '/detail/simply-family-combo-fmp/medical-eligibility': 30,
        '/detail/simply-family-combo-fmp/medical-underwriting': 30,
        '/detail/simply-family-combo-fmp/compliance': 40,
        '/detail/simply-family-combo-fmp/personal-details': 50,
        '/detail/simply-family-combo-fmp/insured-person-details-with-id': 55,
        '/detail/simply-family-combo-fmp/insured-person-medical-questions': 60,
        '/tell-us-more-about-your-loved-ones/simply-family-combo-fmp': 65,
        '/terms/simply-family-combo-fmp': 70,
        '/banking-details/simply-family-combo-fmp': 90,
        '/thank-you/simply-family-combo-fmp': 100,
      },

      'sim-domestic': {
        '/': 'home',
        '/quote/simply-employer-combo': 5,
        '/nearly-there/simply-employer-combo': 20,
        '/customization/simply-employer-combo': 20,
        '/detail/simply-employer-combo/compliance': 50,
        '/detail/simply-employer-combo/employee-details': 40,
        '/detail/simply-employer-combo/employer-details': 60,
        '/terms/simply-employer-combo': 70,
        '/banking-details/simply-employer-combo': 90,
        '/thank-you/simply-employer-combo': 100,
      },
    };
    const percent = R.path([toGenericPackage(packageId), path], percentages);
    const deriveValuesFromPercent = (percentDone) => {
      const percentMap = R.assoc(percentDone, { buyPercent: percentDone }, {});
      return R.merge(percentMap, {
        home: { buyPercent: '0' },
        quote: { buyPercent: '30' },
        medicals: { buyPercent: '30' },
      });
    };

    const toPercentText = (buyPercent) => {
      if (buyPercent === '0') {
        return `${buyPercent}%`;
      }
      return `${buyPercent}% complete`;
    };

    const { buyPercent } = R.prop(percent, deriveValuesFromPercent(percent));
    if (percent) {
      return (
        <SiteProgress text={toPercentText(buyPercent)} progress={buyPercent} />
      );
    }
    return false;
  }
}

export default Progress;

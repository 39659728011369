import {Map} from 'immutable';
import {SET_SHOW_PREPARE_QUOTE_POPUP_FLAG, SET_PREPARE_QUOTE_LOADING} from '../actions/types';
import createActionHandler from './createActionHandler';

const initialState = Map({
  prepareQuotePopupVisible: false,
  prepareQuotePopupLoading: false
});

const actionHandlers = {};

const setPrepareQuotePopupFlag = (state, action) => {
  return state.set('prepareQuotePopupVisible', action.payload);
};

const setPrepareQuotePopupLoading = (state, action) => {
  return state.set('prepareQuotePopupLoading', action.payload);
};

actionHandlers[SET_SHOW_PREPARE_QUOTE_POPUP_FLAG] = setPrepareQuotePopupFlag;
actionHandlers[SET_PREPARE_QUOTE_LOADING] = setPrepareQuotePopupLoading;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

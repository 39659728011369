import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  pipe as Rpipe,
  map as Rmap,
  filter as Rfilter,
  fromPairs as RfromPairs,
  has as Rhas,
  omit as Romit,
} from 'ramda';
import RadioSelectQuestion from '../questions/RadioSelectQuestion';
import { fromJS } from 'immutable';


export default class SelectDebitMonth extends PureComponent {
  constructor() {
    super();

    this.selectMonth = this.selectMonth.bind(this);

    const month0 = moment().utc().startOf('month'); // MUTABLE STATE SO STUPID
    const month1 = moment().utc().startOf('month').add(1,'months');
    const month2 = moment().utc().startOf('month').add(2,'months');
    const limit = moment().utc().add(45,'days');

    const months = Rpipe(
      Rfilter((m) => m.isBefore(limit)),
      Rmap((m) => ({
        label: m.isSame(month0) ? 'Immediately' : m.format('D MMMM'),
        value: m.toISOString(),
      }))
    )([month0, month1, month2]);

    const valueLookup = Rpipe(
      Rmap((m) => [m.label, m.value]),
      RfromPairs,
    )(months);

    const labelLookup = Rpipe(
      Rmap((m) => [m.value, m.label]),
      RfromPairs,
    )(months);

    this.state = {
      default: months[0].value,
      months,
      valueLookup,
      labelLookup,
      labels: fromJS(months.map(m => m.label)),
    };
  }

  selectMonth(label) {
    const value = this.state.valueLookup[label];
    this.props.answerQuestion(value);
  };

  componentDidMount() {
    const selectedMonth = this.props.answer;
    const inceptionMonth = this.props.details.getIn(['inceptionMonth', 'value']);
    const hasCorrectMonthSelected = Rhas(selectedMonth, this.state.labelLookup);
    if(!hasCorrectMonthSelected) {
      this.props.answerQuestion(inceptionMonth || this.state.default);
    }
  }

  render() {
    let selectedMonth;
    if(this.props.details) {
      selectedMonth = this.props.details.getIn(['inceptionMonth', 'value']) || this.props.answer;
    } else {
      selectedMonth = this.props.answer;
    }

    const selectedLabel = this.state.labelLookup[selectedMonth] || this.state.labels.first();
    const otherProps = Romit(['answerQuestion', 'answer'], this.props);
    return (
      <RadioSelectQuestion
        title="When would you like your cover to start"
        possibleAnswers={this.state.labels}
        answerQuestion={this.selectMonth}
        answer={selectedLabel}
        {...otherProps}
      />
    );
  }
}

SelectDebitMonth.propTypes = {
  answerQuestion: PropTypes.func.isRequired,
  answer: PropTypes.string,
};

import React from 'react';
import { toPremium, toCoveredAmount } from '../common/numberFormats';
import { push } from 'connected-react-router';
import { ROUTES } from '../../config/constants';
import { calculatePricingFromState } from '../customization/calculatePricing';
import * as R from 'ramda';
import { rehydrationComplete } from '../../actions/rehydration';
import saveRopPricing from '../../actions/ropPricing';

const formatProductSelection = R.pipe(
  R.values,
  R.filter(p => p.selected),
  R.map(
    R.applySpec({
      name: p => p.title,
      price: p => toCoveredAmount(p.insuredAmount),
    })
  )
);

const generateSettingsFromProps = props => {
  const { packageUrlSlug } = props;
  return [
    {
      left: {
        title: 'Adjust your cover',
        icon: 'controlsAlt',
        onClick: () => {
          props.dispatch(rehydrationComplete());
          props.dispatch(push(`${ROUTES.CUSTOMIZE}${packageUrlSlug}`));
        },
        color: 'primary',
      },
      right: {
        title: 'Need more Help?',
        icon: '',
        onClick: () => {
          props.dispatch(rehydrationComplete());
          props.openCallmeBackMenu();
        },
        color: 'primary',
        position: 'end',
      },
    },
  ];
};

const exists = item => !R.isNil(item);

const makeMutable = item => item.toJS();

const generatePackageSpecificRoutes = (startsWith, state) => {
  const packages = R.keys(state.get('packages').toJS());
  return packages.map(p => `${startsWith}/${p}`);
};

const getBlockedRoutes = state => {
  const customizationPageRoutes = R.union(
    generatePackageSpecificRoutes('/customization', state),
    generatePackageSpecificRoutes('/nearly-there', state)
  );
  return R.union(['/'], customizationPageRoutes);
};

const hasPricing = productSelection =>
  R.pipe(R.values, R.head, R.has('insuredAmount'))(productSelection.toJS());

const hasRopPricing = reccomendation =>
  R.has('productRopPriceMap', reccomendation.toJS());

const allRequiredDataIsPresent = (props, state) =>
  !exists(props.productSelection)
    ? false
    : hasPricing(props.productSelection) &&
      hasRopPricing(state.get('recommendation'));

export const getPricingPopupConfig = (props, state) => {
  const settings = generateSettingsFromProps(props);
  const pricing = allRequiredDataIsPresent(props, state)
    ? calculatePricingFromState(state, props.packageUrlSlug)
    : {};
  const productSelection = exists(props.productSelection)
    ? makeMutable(props.productSelection)
    : {};
  const currentPage = R.isEmpty(props.currentPage) ? '/' : props.currentPage;
  const hasPricingAndProductSelection =
    !R.isEmpty(pricing) && !R.isEmpty(productSelection);
  const products = !R.isEmpty(productSelection)
    ? formatProductSelection(productSelection)
    : productSelection;
  const withRop = state.getIn(['productSelection', 'withRop']);
  const productsWithRop = withRop
    ? R.append(
        { name: 'Cashback', price: `${pricing.ropPrecentage}%` },
        products
      )
    : products;
  if (
    exists(pricing) &&
    exists(props.rehydratedLastLocation) &&
    exists(props.rehydratedLastLocation.isRehydrating) &&
    props.rehydratedLastLocation.isRehydrating === true &&
    props.state.getIn('policyInformation', 'supportMultiplePolicies') === false
  ) {
    const { ropPrecentage, ropPremium, ropReturn, ropTotalPremium } = pricing;
    props.dispatch(
      saveRopPricing({
        ropPrecentage,
        ropPremium,
        ropReturn,
        ropTotalPremium,
      })
    );
  }
  return hasPricingAndProductSelection
    ? {
        currentPage,
        heading: 'Welcome Back !',
        description: 'So glad to have you back! This is where you left off:',
        price: (
          <p>
            For the price of <strong>{toPremium(pricing.finalCost)}</strong> you
            get:
          </p>
        ),
        products: productsWithRop,
        routeIsNotBlocked: !R.includes(currentPage, getBlockedRoutes(state)),
        settings,
      }
    : null;
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@simply-fin-services/astronomix3';

export default class AcceptCheckboxQuestion extends PureComponent {
  render() {
    const { onChange, checked, text, id, children, labelIsBold } = this.props;
    return (
      <Checkbox
        id={id || text || '  '}
        onChange={onChange}
        labelText={text || '  '}
        checked={checked}
        labelIsBold={labelIsBold}
      >
        {children}
      </Checkbox>
    );
  }
}

AcceptCheckboxQuestion.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

import React, { PureComponent } from 'react';
import {
  WallOfTextContainer,
  PageButtons,
} from '@simply-fin-services/astronomix3';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { stateToQuestionnaireProps } from '../questionnaire/toQuestionPageProps';
import { getSelectedPackageIdOrDefault } from '../../common/getPackageIdFromSlug';
import R from 'ramda';
import { QuestionPageQuestions } from '../questionnaire/QuestionPage';
import { answerQuestion as answerQuestionActionCreator } from '../../actions/answers';
import PageActionButtons from '../common/PageActionButtons';
import { getFinishButtonText } from './config';
import OneTimePin, { isOtpValid } from './Otp';
import {
  reserveContractNumber as reserveContractNumberAction,
  downloadMandate as downloadMandateAction,
} from '../../actions/salaryDeduction';
import BrokerQa from './brokerQa';
import BrokerCompliance from './brokerCompliance';
import { getAllowedProvider } from '../../common/saleOptions';
import moment from 'moment';
import PaymentDetailsExtraTermsContainer from '../../views/replaceAndSave/integration/PaymentDetailsExtraTermsContainer';

const getQuestionPageProps = R.curry((state, provider) => {
  const questionPageId = provider.get('question-page-id');
  const pageQuestions = state.getIn([
    'questionPages',
    questionPageId,
    'questions',
  ]);

  const optionalPageQuestions = fromJS([]);

  const { walkedPage, isValid, questions, answers } = stateToQuestionnaireProps(
    state,
    pageQuestions,
    optionalPageQuestions
  );

  return {
    walkedPage,
    isValid,
    questions,
    answers,
    pageQuestions,
    optionalPageQuestions,
  };
});

const MandateText = (props) => {
  const { employee, provider, downloadMandate, firstCollection } = props;
  return (
    <div style={{ textAlign: 'justify' }}>
      <p>I the undersigned: </p>
      <ul>
        <li>
          <strong>Full name: </strong>
          {employee.fullname}
        </li>
        <li>
          <strong>Employee Number: </strong>
          {employee.employeeNumber}
        </li>
        <li>
          <strong>Identity Number: </strong>
          {employee.idNumber}
        </li>
        <li>
          <strong>Policy Number: </strong>
          {employee.transactionNumber}
        </li>
      </ul>

      <p>
        Hereby authorise the Accountant of <b>{provider.get('name')} </b>
        to deduct monthly with effect from <b>{firstCollection}</b> the premium
        of <b>R{employee.pricing.finalCost}</b> from my salary and to remit it
        to Hollard until such time as I cancel this authorisation in writing, 
        or until I substitute it with a new authorisation.
      </p>
      <p>
        Should the relevant premium be adjusted by Hollard as a result of a
        general decrease/increase in premium or should I request Hollard to
        decrease/increase the premium for certain reasons, I confirm that the
        adjusted premium may be deducted from my salary, until such time as I
        instruct Hollard to cancel this authorisation in writing or until I
        substitute it with a new authorisation.
      </p>
      <p>
        I authorise Hollard to share my personal information with my employer for
        the purpose of my employer making the payment.
      </p>

      <br />

      <PageButtons
        label="Download Mandate"
        onClick={() => downloadMandate(provider)}
      />

      <br />

      <p>
        This mandate must be completed and physically signed by the client
        (using the information provided above), and then handed to the pay
        office to be reviewed and stamped. The pay office will not deduct the
        premium without an approved mandate on record.
        <strong>
          Please do not send the OTP and finalise the application until the
          mandate has been authorised.
        </strong>
      </p>
    </div>
  );
};

class SalaryDeduction extends PureComponent {
  render() {
    const {
      questionPageProps,
      provider,
      next,
      canContinue,
      isSaving,
      actionButtonText,
      updateCorrectionComments,
      validComments,
      sendForCorrections,
      requiresOTP,
      employee,
      stage,
      reserveContractNumber,
      brokerSaleType,
      rejectSale,
      downloadMandate,
      error,
      firstCollection,
    } = this.props;

    return (
      <WallOfTextContainer>
        {stage === 'busy' && <div className="text-center">Please wait...</div>}
        {stage === 'start' && (
          <div>
            <p>Please enter your salary deduction information below:</p>
            <QuestionPageQuestions {...questionPageProps} />
          </div>
        )}

        {stage === 'final' && (
          <MandateText
            provider={provider}
            employee={employee}
            downloadMandate={downloadMandate}
            firstCollection={firstCollection}
          />
        )}

        <PaymentDetailsExtraTermsContainer paymentType="stop-order-salary-deduction" />

        <BrokerCompliance type="salary-deduction" />

        {requiresOTP && stage === 'final' && (
          <div>
            <br />
            <br />
            <OneTimePin form="salary_deduction" />
            <br />
          </div>
        )}

        {error && <p className="text-center text-danger">{error}</p>}

        {brokerSaleType === 'qa' && stage === 'final' && (
          <div>
            <BrokerQa
              updateCorrectionComments={updateCorrectionComments}
              canContinue={canContinue}
              rejectSale={rejectSale}
              validComments={validComments}
              approveSale={next}
              sendForCorrections={sendForCorrections}
            />
          </div>
        )}

        {!isSaving && stage === 'final' && (
          <PageActionButtons
            onClick={next}
            disabled={!canContinue}
            text={actionButtonText}
            canGoBack
          />
        )}

        {!isSaving && stage === 'start' && (
          <PageActionButtons
            onClick={() => reserveContractNumber(provider)}
            disabled={!questionPageProps.isValid}
            text="Next"
            canGoBack
          />
        )}
      </WallOfTextContainer>
    );
  }
}

const isSalaryDeductionAnswersValid = R.curry((state, provider) => {
  const questionPageId = provider['question-page-id'];
  const questions = state.getIn(['questionPages', questionPageId, 'questions']);

  return questions
    .map((q) => state.getIn(['answers', q]))
    .every((a) => a.isValid);
});

const mapStateToProps = (state) => {
  return {
    getQuestionPageProps: getQuestionPageProps(state),
    packageId: getSelectedPackageIdOrDefault(state),
    getProvider: R.curry(getAllowedProvider)(state),
    isSalaryDeductionAnswersValid: isSalaryDeductionAnswersValid(state),
    brokerSaleType: state.getIn(['brokerQa', 'brokerSaleType']),
    validComments: state.getIn(['brokerQa', 'isValidCorrectionComments']),
    actionButtonText: getFinishButtonText(state),
    otpValid: isOtpValid(state),
    employee: state.getIn(['salaryDeduction', 'employee']),
    stage: state.getIn(['salaryDeduction', 'stage']),
    paymentDetails: state.getIn(['salaryDeduction', 'paymentDetails']),
    salePaymentDetails: state.getIn(['bankingDetails', 'details']),
    error: state.getIn(['salaryDeductions', 'error']),
    brokerComplianceValid: state.getIn(['brokerCompliance', 'isValid'])
  };
};

const mapDispatchToProps = (dispatch) => ({
  answerQuestion: R.curry((packageId, question, answer) =>
    dispatch(answerQuestionActionCreator(packageId, question, answer))
  ),
  reserveContractNumber: (provider) =>
    dispatch(reserveContractNumberAction(provider)),
  downloadMandate: (provider) => dispatch(downloadMandateAction(provider)),
});

const canContinue = (stateProps, ownProps, isValidForm) => {
  if (ownProps.isSaving) {
    return false;
  }

  if (!isValidForm) {
    return false;
  }

  if (ownProps.requiresOTP && !stateProps.otpValid) {
    return false;
  }

  if(!stateProps.brokerComplianceValid) {
    return false;
  }

  return true;
};

const toSaveableBankingDetails = R.pipe((stateProps) => {
  if (stateProps.stage === 'qa') {
    return stateProps.salePaymentDetails;
  }
  return stateProps.paymentDetails;
}, fromJS);

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const splitPaymentType = ownProps.selectedPaymentType.split('|');
  const providerId = splitPaymentType.length > 0 ? splitPaymentType[1] : '';
  const provider = stateProps.getProvider(providerId);
  const questionPageProps = stateProps.getQuestionPageProps(provider);
  const now = moment();
  const firstCollection =
    now.date() >= provider.get('submission-day')
      ? now.subtract(-1, 'months')
      : now;

  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    questionPageProps: Object.assign(questionPageProps, {
      answerQuestion: dispatchProps.answerQuestion(stateProps.packageId),
    }),
    next: () => ownProps.next(toSaveableBankingDetails(stateProps)),
    canContinue: canContinue(stateProps, ownProps, questionPageProps.isValid),
    provider,
    firstCollection: firstCollection.format('MMMM YYYY'),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SalaryDeduction);

import { fromJS } from 'immutable';

const stateKey = 'entityForm';

export const isEntityValid = (state, entityType) =>
  state.getIn([stateKey, entityType, 'isValid']) || false;

export const entity = (state, entityType) =>
  state.getIn([stateKey, entityType, 'entity']) || fromJS({});

export const getIsEntityValid = (state) => (entityType) =>
  state.getIn([stateKey, entityType, 'isValid']) || false;

export const getEntity = (state) => (entityType) =>
  state.getIn([stateKey, entityType, 'entity']) || fromJS({});

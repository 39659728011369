import React, { PureComponent } from 'react';
import { Table } from '@simply-fin-services/astronomix3';
import { toCurrencyNoDecimal } from '../../common/numberFormats';
import InsuredsList from '../policies/InsuredsList';
import {
  pluralisePolicyWording,
  RightAlignTablewHeader,
  RightAlignTableCell,
} from '../sharedComponents';

class TermsReplacementPolicyList extends PureComponent {
  componentDidMount() {
    if(this.props.isReplaceAndSaveEnabled){
      this.props.fetchLatestPolicyReplacementData();
    }
  }

  render() {
    // Component knows how to feature toggle itself
    if (!this.props.isReplaceAndSaveEnabled) return <React.Fragment />;

    return (
      <React.Fragment>
        <p>
          This policy will be replacing the following{' '}
          {this.props.totalReplacementPolicies}{' '}
          {pluralisePolicyWording(this.props.totalReplacementPolicies)}:
        </p>
        <Table>
          <thead>
            <tr>
              <th>Policy</th>
              <RightAlignTablewHeader>Funeral cover</RightAlignTablewHeader>
              <RightAlignTablewHeader>Monthly premium</RightAlignTablewHeader>
            </tr>
          </thead>
          <tbody>
            {this.props.replacementPolicies.map((replacementPolicy) => (
              <tr>
                <td>
                  {replacementPolicy.get('insurer')} /{' '}
                  {replacementPolicy.get('policyNumber')}{' '}
                </td>
                <RightAlignTableCell>
                  <InsuredsList replacementPolicy={replacementPolicy} />
                </RightAlignTableCell>
                <RightAlignTableCell>
                  {toCurrencyNoDecimal(replacementPolicy.get('monthlyPremium'))}
                </RightAlignTableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
}

export default TermsReplacementPolicyList;

import { connect } from 'react-redux';
import ListReplacementPolicies from './List';
import {
  addReplacementPolicy,
  addPolicyNextStep
} from '../../../actions/replaceAndSave';
import {
  totalReplacementPolicies,
  totalMonthlyPremium,
  totalCover,
} from '../../../stateStore/replaceAndSave';
import { 
  removeReplacementPolicy,
  fetchLatestPolicyReplacementData
 } from './../httpApi';

const mapStateToProps = (state) => ({
  totalReplacementPolicies: totalReplacementPolicies(state),
  totalMonthlyPremium: totalMonthlyPremium(state),
  totalCover: totalCover(state),
  removeReplacementPolicy,
});

const mapDispatchToProps = (dispatch) => ({
  addReplacementPolicy: () => dispatch(addReplacementPolicy()),
  maintainInsureds: (policy) => {
    dispatch(addPolicyNextStep(policy));
    window.scroll(0, 0);
  },
  fetchLatestPolicyReplacementData: () =>
    fetchLatestPolicyReplacementData(dispatch),
});

const AddPolicyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListReplacementPolicies);

export default AddPolicyContainer;

"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIemasContractInceptionDate = exports.extractIemasContractDetails = exports["default"] = exports.validateIemasSalaryDeductionPaymentDetails = exports.toNotEnoughDigitsResponse = exports.toValidationResponse = exports.toFailureResponse = exports.isIemasSalaryDeductionPaymentType = exports.iemasMemberNumberFormatValid = exports.calculateInceptionMonth = void 0;

var _immutable = require("immutable");

var _validators = require("../validators");

var R = _interopRequireWildcard(require("ramda"));

var _constants = require("../constants");

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var calculateInceptionMonth = function calculateInceptionMonth() {
  var today = (0, _moment["default"])(Date.now());
  var currentMonthIndex = today.month();
  var currentYear = today.year();
  var cutOffDay = R.toPairs(_constants.CUT_OFF_DATES[currentYear])[currentMonthIndex][1];
  var cutOffDate = (0, _moment["default"])([currentYear, currentMonthIndex, cutOffDay]);
  var currentDateIsBeforeCuttOffDate = cutOffDate.diff(today, 'days') >= 0;
  var nextInceptionDate = currentDateIsBeforeCuttOffDate ? _moment["default"].utc([currentYear, currentMonthIndex, 10]).add(1, 'months') : _moment["default"].utc([currentYear, currentMonthIndex, 10]).add(2, 'months');
  return nextInceptionDate.toISOString();
}; // An Iemas member number must be 7 digits, no more, no less.


exports.calculateInceptionMonth = calculateInceptionMonth;

var iemasMemberNumberFormatValid = function iemasMemberNumberFormatValid(iemasMemberNumber) {
  return /^\d{7}$/.test(iemasMemberNumber);
};

exports.iemasMemberNumberFormatValid = iemasMemberNumberFormatValid;

var isIemasSalaryDeductionPaymentType = function isIemasSalaryDeductionPaymentType(details) {
  return details.get('paymentType') === 'iemas-salary-deduction';
};

exports.isIemasSalaryDeductionPaymentType = isIemasSalaryDeductionPaymentType;

var toFailureResponse = function toFailureResponse(memberNumber) {
  return {
    memberNumber: memberNumber,
    found: false,
    status: 'Member number not found in Iemas provided data. This may result in salary deduction failures.',
    canContinue: true
  };
};

exports.toFailureResponse = toFailureResponse;

var toValidationResponse = function toValidationResponse(memberNumber, iemasMemberData) {
  if (iemasMemberData.fasIncluded === 'No') {
    return {
      memberNumber: memberNumber,
      found: true,
      status: 'FAS Deductions not allowed for this member',
      canContinue: false
    };
  }

  if (iemasMemberData.groupCategory === 'Inactive Employer') {
    return {
      memberNumber: memberNumber,
      found: true,
      status: 'Warning: Employer not active for deductions. This may result in salary deduction failures and policy lapse.',
      canContinue: true
    };
  }

  return {
    memberNumber: memberNumber,
    found: true,
    canContinue: true
  };
};

exports.toValidationResponse = toValidationResponse;

var toNotEnoughDigitsResponse = function toNotEnoughDigitsResponse(memberNumber) {
  return {
    memberNumber: memberNumber,
    found: false,
    status: 'Must contain 7 digits',
    canContinue: false
  };
};

exports.toNotEnoughDigitsResponse = toNotEnoughDigitsResponse;

var iemasMemberNumberValid = function iemasMemberNumberValid(iemasMemberNumber, memberData) {
  return toValidationResponse(iemasMemberNumber, memberData);
};

var withStatus = function withStatus(iemasMemberNumber, memberResult) {
  var err = memberResult.status;

  if (memberResult.canContinue) {
    return memberResult.status ? R.assoc('errors', [err], (0, _validators.toValidateableValue)(iemasMemberNumber)) : (0, _validators.toValidateableValue)(iemasMemberNumber);
  }

  return R.assoc('errors', [err], (0, _validators.toError)(iemasMemberNumber));
};

var validateIemasMemberNumber = function validateIemasMemberNumber(iemasMemberNumber, iemasMember) {
  var formatResult = iemasMemberNumberFormatValid(iemasMemberNumber);

  if (formatResult) {
    var validationResult = iemasMember ? iemasMemberNumberValid(iemasMemberNumber, iemasMember) : toFailureResponse(iemasMemberNumber);
    return withStatus(iemasMemberNumber, validationResult);
  }

  return (0, _validators.toError)(iemasMemberNumber, toNotEnoughDigitsResponse(iemasMemberNumber).status);
};

var validateIemasSalaryDeductionPaymentDetails = function validateIemasSalaryDeductionPaymentDetails(details) {
  var validationResult = validateIemasMemberNumber(details.get('iemasMemberNumber'), details.get('iemasMember'));
  return (0, _immutable.fromJS)({
    iemasMemberNumber: validationResult
  });
};

exports.validateIemasSalaryDeductionPaymentDetails = validateIemasSalaryDeductionPaymentDetails;
var _default = validateIemasSalaryDeductionPaymentDetails;
exports["default"] = _default;

var extractIemasContractDetails = function extractIemasContractDetails(createContractBase) {
  var iemasMemberNumber = createContractBase.getIn(['options', 'bankingDetails', 'iemasMemberNumber']) || '';
  return {
    PaymentType: 'iemas-salary-deduction',
    IemasMemberNumber: iemasMemberNumber.trim()
  };
};
/* eslint-disable no-unused-vars */


exports.extractIemasContractDetails = extractIemasContractDetails;

var getIemasContractInceptionDate = function getIemasContractInceptionDate(generationDate, selectedInceptionMonth, isUpsellSale) {
  return selectedInceptionMonth;
};

exports.getIemasContractInceptionDate = getIemasContractInceptionDate;
import * as types from './types';
import request from 'axios';
import { API_ROUTES, ROUTES } from '../config/constants';
import { push } from 'connected-react-router';
import * as R from 'ramda';

const saveTermsSuccess = () => {
  const type = types.ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_SUCCESS;
  return {
    type,
  };
};
const saveTermsFailure = (error) => {
  const type = types.ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_FAILURE;
  const payload = error;
  return {
    type,
    payload,
  };
};

const saveTermsValidationFailure = details => {
  const type = types.ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_VALIDATION_FAILURE;
  const payload = details;
  return {
    type,
    payload,
  };
};

export const toNextPageAfterTerms = (state) => {
  const slug = state.get('selectedPackageSlug');
  const nextPageIfThereAreNoRequirements = ROUTES.toSimplifiedTerms(slug);
  return nextPageIfThereAreNoRequirements;
};

const saveTermsAndConditions = details => (dispatch, getState) => {
  const state = getState();
  request
    .post(API_ROUTES.TERMS_AND_CONDITIONS, { details })
    .then(() => {
      dispatch(saveTermsSuccess());
      const nextRoute = toNextPageAfterTerms(state);
      dispatch(push(nextRoute));
    })
    .catch(error => {
      if (error.response && error.response.status < 500) {
        dispatch(saveTermsValidationFailure(error.response.data));
        dispatch(push(ROUTES.ERROR));
      } else {
        dispatch(saveTermsFailure(error));
        dispatch(push(ROUTES.ERROR));
      }
    });
};

export const acceptTermsAndConditions = details => {
  const type = types.ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS;
  const meta = details;
  const payload = saveTermsAndConditions(meta);
  return {
    type,
    payload,
    meta,
  };
};

export const choosePolicyReplaced = answer => {
  const type = types.CHOOSE_IF_THIRD_PARTY_POLICY_REPLACED;
  const payload = answer;
  return {
    type,
    payload,
  };
};

export const acceptPolicyReplacementTermsAndConditions = answer => {
  const type = types.AGREE_TO_THIRD_PARTY_POLICY_REPLACED_TERMS;
  const payload = answer;
  return {
    type,
    payload,
  };
};

export const acceptPolicyReplacementWaivePeriodTerms = answer => {
  const type = types.AGREE_TO_THIRD_PARTY_POLICY_WAIVE_PERIOD_TERMS;
  const payload = answer;
  return {
    type,
    payload,
  };
};

export const acceptGeneralTermsAndConditions = answer => {
  const type = types.AGREE_TO_GENERAL_THIRD_PARTY_TERMS;
  const payload = answer;
  return {
    type,
    payload,
  };
};

export const setWaiveQuestionnairevalidation = isValid => ({
  type: types.SET_THIRD_PARTY_WAIVE_QUESTIONNAIRE_VALIDATION,
  payload: isValid,
});

export const savePackageAllowsPolicyReplacementFlag = flag => ({
  type: types.SAVE_PACKAGE_ALLOWS_THIRD_PARTY_POLICY_REPLACEMENT_FLAG,
  payload: flag,
});

const saveRequestWaivePeriodDetailsFlag = flag => ({
  type: types.SET_REQUEST_THIRD_PARTY_WAIVE_PERIOD_DETAILS_FLAG,
  payload: flag,
});

const checkRequiredProducts = (dispatch, getState) => {
  const state = getState();
  const allowsPolicyReplacement = state.getIn([
    'termsAndConditions',
    'details',
    'packageAllowsPolicyReplacement',
  ]);
  if (allowsPolicyReplacement === true) {
    const packageSlug = state.get('selectedPackageSlug');
    const requiredProducts = state
      .getIn([
        'packages',
        packageSlug,
        'waivePeriodPolicyProductRequirements',
        'products',
      ])
      .toJS();
    const selectedProducts = state
      .getIn(['productSelection', 'products'])
      .toJS();
    const hasRequiredProducsts =
      R.map(
        p => R.find(R.equals(p))(selectedProducts),
        R.keys(requiredProducts)
      ).length !== 0;
    const requiredProductsSelected =
      R.keys(selectedProducts).filter(
        key =>
          R.includes(key, requiredProducts) &&
          R.path([key, 'selected'], selectedProducts)
      ).length !== 0;
    dispatch(
      saveRequestWaivePeriodDetailsFlag(
        hasRequiredProducsts && requiredProductsSelected
      )
    );
  } else {
    dispatch(saveRequestWaivePeriodDetailsFlag(allowsPolicyReplacement));
  }
};

export const setShouldRequestWaivePeriodDetailsFlag = () => ({
  type: types.CHECK_THIRD_PARTY_WAIVE_POLICY_PRODUCT_REQUIREMENTS,
  payload: checkRequiredProducts,
});

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Abstract extends PureComponent {
  render() {
    const { children, title } = this.props;
    return (
      <div>
        <h5 className="space-below space-above bold">{title}</h5>
        {children}
      </div>
    );
  }
}

Abstract.protoTypes = {
  title: PropTypes.string.isRequired,
};

export default Abstract;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InputQuestion from '../questions/InputQuestion';
import DropdownQuestion from '../questions/DropdownQuestion';
import { UNIVERSAL_BRANCH_CODES, getBankFromBranchCode } from '../../.shared/paymentDetails/bankingDetails';
import * as R from 'ramda';

const OTHER_OPTION = 'Other';

const bankChoices = UNIVERSAL_BRANCH_CODES.keySeq().toList();
const sortAlphabetically = R.sortBy(R.compose(R.toLower));
export default class BranchCode extends PureComponent {
  constructor() {
    super();
    this.state = { requireInput: false, selection: '' };
    this.select = this.select.bind(this);
    this.requireInput = this.requireInput.bind(this);
    this.useSelection = this.useSelection.bind(this);
  }

  requireInput() {
    this.setState({ requireInput: true, selection: '' });
  }

  componentWillMount() {
    const { details } = this.props;
    this.setState({ details });
  }

  useSelection(selection) {
    const { answerQuestion } = this.props;
    if (UNIVERSAL_BRANCH_CODES.has(selection)) {
      this.setState({ requireInput: false, selection });
      answerQuestion(UNIVERSAL_BRANCH_CODES.getIn([selection, 'code']));
    } else {
      this.requireInput();
    }
  }

  select(answer) {
    if (answer === OTHER_OPTION) {
      this.requireInput();
    } else {
      this.useSelection(answer);
    }
  }

  componentDidMount() {
    const { details } = this.props;
    if(details && details.toJS() && details.toJS().branchCode) {
      const branchCode = details.toJS().branchCode.value;
      const selection = getBankFromBranchCode(branchCode);
      if(selection) {
        this.select(selection);
      }
    }
  }

  render() {
    const { answerQuestion } = this.props;
    const { requireInput, selection } = this.state;
    const dropdownProps = {
      answer: selection,
      answerQuestion: this.select,
      possibleAnswers: sortAlphabetically(bankChoices.toJS()),
      isValid: this.props.isValid,
      placeholder: "Select bank",
    };
    return (
      <div>
        <DropdownQuestion title="Bank (where your salary is paid)" {...dropdownProps} fitForm />
        {requireInput && (
          <InputQuestion
            title="Branch code"
            answerQuestion={answerQuestion}
            {...this.props}
            fitForm
          />
        )}
      </div>
    );
  }
}

BranchCode.propTypes = {
  answerQuestion: PropTypes.func.isRequired,
};

import * as R from 'ramda';
import { getSelectedPackageIdOrDefault } from './getPackageIdFromSlug';
import * as actions from '../actions/types';
import { isBrokerSale } from './brokers';
import uuid from './newId';
import { reportError } from '../config/configureErrorHandling';

const SPECIAL_ACTIONS = [actions.ANSWER_QUESTION_SUCCESS];

const SPECIAL_QUESTIONS = ['owner-contact-number'];

const SPECIAL_QUESTION_MAPPING = {
  'owner-contact-number': {
    Family: 'FamilyContactNumber',
    Domestic: 'DomesticContactNumber',
  },
};

const FACEBOOK_CUSTOM_EVENTS = {
  [actions.COMPLETE_SALE_SUCCESS]: {
    Family: 'FamilyCompleteSale',
    Domestic: 'DomesticCompleteSale',
  },
  [actions.SAVE_PEEPS_SUCCESS]: {
    Family: 'FamilySavePeeps',
    Domestic: 'DomesticSavePeeps',
  },
  [actions.SAVE_PRODUCT_SELECTION_SUCCESS]: {
    Family: 'AcceptQuoteFamily',
    Domestic: 'AcceptQuoteDomestic',
  },
  [actions.VIEW_QUOTE]: {
    Family: 'FamilyQuoteView',
    Domestic: 'DomesticQuoteView',
  },
  [actions.ENTER_COMPETITION]: {
    Family: 'FamilyContactDetails',
    Domestic: 'DomesticContactDetails',
  },
  COMPLETE_MEDICAL_ELIGIBILITY: {
    Family: 'FamilyCompleteMedicalEligibility',
    Domestic: 'DomesticCompleteMedicalEligibility',
  },
  [actions.ACCEPT_TERMS_AND_CONDITONS]: {
    Family: 'FamilyAcceptTermsAndConditions',
    Domestic: 'DomesticAcceptTermsAndConditions',
  },
  [actions.SAVE_CALL_ME_BACK_DETAILS_SUCCESS]: {
    Family: 'FamilyCallBack',
    Domestic: 'DomesticCallBack',
  },
};

const TRANSFORM_ACTIONS = {
  [actions.SAVE_PRODUCT_SELECTION_SUCCESS]: ['Product Added', 'AddToCart'],
  [actions.COMPLETE_SALE_SUCCESS]: [
    'Order Completed',
    'CompletedSale',
    'Purchase',
  ],
  [actions.VIEW_QUOTE]: ['Product Viewed', 'ViewedAQuote'],
  [actions.SAVE_CALL_ME_BACK_DETAILS_SUCCESS]: [
    'Contact',
    'CallBack',
    'SAVE_CALL_ME_BACK_DETAILS_SUCCESS',
  ],
};

const isSpecialAction = (action) =>
  R.all(R.equals(true), [
    R.includes(action.type, SPECIAL_ACTIONS),
    R.includes(R.path(['payload', 'questionId'], action), SPECIAL_QUESTIONS),
    R.path(['payload', 'isValid'], action),
  ]);

const isTransformationAction = (actionType) =>
  R.has(actionType, TRANSFORM_ACTIONS);

export const toGenericPackage = (packageId) =>
  R.cond([
    [R.test(/FAMILY/), R.always('Family')],
    [R.test(/SINGLE-LIFE/), R.always('Family')], //sim-single-life-combo-package
    [R.test(/EMPLOYEE/), R.always('Domestic')],
  ])(R.toUpper(packageId));

const toGenericProduct = (productId) =>
  R.cond([
    [R.test(/LIFE/), R.always('Life')],
    [R.test(/DISABILITY/), R.always('Disability')],
    [R.test(/FUNERAL/), R.always('Funeral')],
  ])(R.toUpper(productId));

const toGoogleProducts = (payload) =>
  R.map(
    R.applySpec({
      name: R.prop('title'),
      id: R.prop('productId'),
      price: R.prop('premium'),
      brand: R.always('Simply'),
      category: R.always(
        R.concat('Retail/', toGenericPackage(R.prop('packageId', payload)))
      ),
      varient: R.always(''),
      quantity: R.always(1),
    }),
    R.prop('productSelection', payload)
  );

const recommendationToGenericProduct = (payload) => {
  const recommended = R.path(
    ['recommendations', R.prop('recommended', payload)],
    payload
  );
  const productSelection = R.keys(recommended).map((productId) => ({
    productId,
    premium: R.path([productId, 'premium'], recommended),
    title: toGenericProduct(productId),
  }));
  return R.merge(payload, {
    productSelection,
  });
};

const GOOGLE_ECOMMERCE_ACTIONS = {
  [actions.SAVE_PRODUCT_SELECTION_SUCCESS]: [
    R.applySpec({
      event: R.always('addToCart'),
      ecommerce: {
        currencyCode: R.always('ZAR'),
        add: {
          products: toGoogleProducts,
        },
      },
    }),
  ],
  [actions.COMPLETE_SALE_SUCCESS]: [
    R.applySpec({
      event: R.always('purchase'),
      ecommerce: {
        currencyCode: R.always('ZAR'),
        purchase: {
          actionField: {
            id: R.prop('interactionId'),
            affiliation: R.prop('parent'),
            revenue: R.prop('premium'),
          },
          products: toGoogleProducts,
        },
      },
    }),
  ],
  [actions.VIEW_QUOTE]: [
    R.applySpec({
      event: R.always('detail'),
      ecommerce: {
        currencyCode: R.always('ZAR'),
        products: R.pipe(recommendationToGenericProduct, toGoogleProducts),
      },
    }),
  ],
};

const isGoogleEcommerceAction = (actionType) =>
  R.has(actionType, GOOGLE_ECOMMERCE_ACTIONS);

export const anonymize = (payload) => {
  if (payload && R.type(payload) === 'Object') {
    return R.pick(
      [
        'category',
        'product_id',
        'revenue',
        'value',
        'currency',
        'parent',
        'premium',
        'contractNumber',
        'packageId',
        'recommendations',
        'recommended',
        'Premium',
        'ecommerce',
        'eventID',
      ],
      payload
    );
  }
  return payload;
};

export const gtmHeaderCodeSnipit = (tagId) => {
  if (tagId) {
    return `<script>
      dataLayer = [];
    </script>
    <!-- Google Tag Manager -->
    <script defer>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${tagId}');</script>
    <!-- End Google Tag Manager -->`;
  }
  return '';
};

export const gtmNoScriptSnipit = (tagId) => {
  if (tagId) {
    return `<!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${tagId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->`;
  }
  return '';
};

export const metaOrPayload = (payload) => {
  if (R.has('meta', payload)) {
    return payload.meta;
  }
  return payload;
};

const toGoogleEcommerceActions = (action) => {
  const events = R.prop(action.type, GOOGLE_ECOMMERCE_ACTIONS);
  return events.map((acionPayloadConverter) =>
    acionPayloadConverter(action.payload)
  );
};

const withUniquiId = R.assocPath(['payload', 'eventID'], uuid());
/* eslint-disable no-undef */
const pushToDataLayer = (action) => {
  dataLayer = dataLayer || [];
  dataLayer.push(withUniquiId(action));
  return action;
};
/* eslint-enable no-undef */

const trackGoogleActions = (action) => {
  if (isGoogleEcommerceAction(action.type)) {
    R.map(
      (googleAction) => pushToDataLayer(googleAction),
      toGoogleEcommerceActions(action)
    );
  }
  return action;
};

const toCustomConversion = (CUSTOM_EVENT_MAPPING, action) =>
  R.pipe(
    R.assoc('payload', metaOrPayload(action.payload)),
    R.assoc(
      'type',
      R.path(
        [action.type, toGenericPackage(action.payload.packageId)],
        CUSTOM_EVENT_MAPPING
      )
    ),
    R.assocPath(
      ['payload', 'category'],
      toGenericPackage(action.payload.packageId)
    ),
    R.assocPath(['payload', 'product_id'], action.payload.packageId),
    R.assocPath(['payload', 'value'], action.payload.premium || 1),
    R.assocPath(['payload', 'currency'], 'ZAR')
  )(action);

const toCustomActions = (action) => {
  const types = R.prop(action.type, TRANSFORM_ACTIONS);
  return R.map(
    (type) =>
      R.pipe(
        R.assoc('payload', metaOrPayload(action.payload)),
        R.assoc('type', type),
        R.assocPath(
          ['payload', 'category'],
          toGenericPackage(action.payload.packageId)
        ),
        R.assocPath(['payload', 'product_id'], action.payload.packageId),
        R.assocPath(['payload', 'revenue'], action.payload.premium || 1),
        R.assocPath(['payload', 'currency'], 'ZAR')
      )(action),
    types
  );
};

const toFacebookCustomConversion = (action) =>
  toCustomConversion(FACEBOOK_CUSTOM_EVENTS, action);

const toSpecialCustomConversion = (action) =>
  toCustomConversion(
    SPECIAL_QUESTION_MAPPING,
    R.assoc('type', R.path(['payload', 'questionId'], action), action)
  );

const isFacebookCustomConversion = (actionType) =>
  R.has(actionType, FACEBOOK_CUSTOM_EVENTS);

const cleanAndPushAction = (action) => {
  pushToDataLayer({
    event: action.type,
    payload: anonymize(action.payload),
  });
  return action;
};

const page = () =>
  pushToDataLayer({
    event: 'PageView',
  });

const trackCustomAction = (action) => {
  if (isTransformationAction(action.type)) {
    const customActions = toCustomActions(action);
    R.map(cleanAndPushAction, customActions);
  }
  if (isSpecialAction(action)) {
    const specialAction = toSpecialCustomConversion(action);
    cleanAndPushAction(specialAction);
  }
  return action;
};

const trackFacebookAction = (action) => {
  if (isFacebookCustomConversion(action.type)) {
    const customConversion = toFacebookCustomConversion(action);
    cleanAndPushAction(customConversion);
  }
  return action;
};

const track = (store, action) =>
  R.pipe(trackGoogleActions, trackFacebookAction, trackCustomAction)(action);

const createGoogleTagMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  try {
    if (!isBrokerSale(state)) {
      const packageId = getSelectedPackageIdOrDefault(state);
      const actionWithPackage = R.assocPath(
        ['payload', 'packageId'],
        packageId,
        action
      );
      if (actionWithPackage.type === '@@router/LOCATION_CHANGE') {
        page();
      }
      track(store, actionWithPackage);
    }
    return next(action);
  } catch(err) {
    reportError(err, store, action);
    return next(action);
  };
};

const createPassthroughMiddleware = () => (next) => (action) => next(action);

export const createTagManagerMiddleware = (initialState) => {
  const tagId = initialState.config.GOOGLE_TAG_MANAGER_ID;
  if (tagId) {
    return createGoogleTagMiddleware;
  }
  return createPassthroughMiddleware;
};

import { fromJS } from 'immutable';
import {
  FINISH_COMPLIANCE_DOC_UPLOAD,
  COMPLIANCE_DOC_UPLOAD_ERROR,
  SAVE_COMPLIANCE_REQUIREMENTS,
  VALIDATE_BROKER_COMPLIANCE,
  SAVE_COMPLIANCE_PAYMENT_TYPE,
  SET_FILE_UPLOAD_PROGRESS,
} from '../actions/types';
import createActionHandler from './createActionHandler';
import {
  assoc,
  insert,
  pipe,
  findIndex,
  propEq,
  isEmpty,
  without,
} from 'ramda';
const initialState = {
  files: [],
  isValid: false,
  selectedPaymentType: '',
};

const actionHandlers = {};

const setValid = (immutableState) => {
  const state = immutableState.toJS();
  const isValid = !isEmpty(state.files)
    ? state.files.every((file) => file.uploaded)
    : true;
  return fromJS(assoc('isValid', isValid, state));
};

const saveFiles = (state, action) => {
  return state.set('files', action.payload);
};

const savePaymentType = (state, action) => {
  return state.set('selectedPaymentType', action.payload);
};

const setFileUploadState = (newFileState) => {
  const { state, fileId, uploaded, failedToUpload, isUploading, message } =
    newFileState;
  const file = state.files.find((f) => f.id === fileId);
  const indexOfFile = findIndex(propEq('id', fileId))(state.files);
  const updatedFile = pipe(
    assoc('uploaded', uploaded),
    assoc('failedToUpload', failedToUpload),
    assoc('isUploading', isUploading),
    assoc('reasonFailedToUpload', message)
  )(file);
  return fromJS(
    assoc(
      'files',
      pipe(without([file]), insert(indexOfFile, updatedFile))(state.files),
      state
    )
  );
};

const setSuccessUploading = (state, action) => {
  const fileId = action.payload;
  const newFileState = {
    state: state.toJS(),
    fileId,
    uploaded: true,
    failedToUpload: false,
    isUploading: false,
  };
  return setFileUploadState(newFileState);
};

const setErrorUploading = (state, action) => {
  const { fileId, message } = action.payload;
  const newFileState = {
    state: state.toJS(),
    fileId,
    uploaded: false,
    failedToUpload: true,
    isUploading: false,
    message,
  };
  return setFileUploadState(newFileState);
};

const saveFileUploadProgress = (state, action) => {
  const { fileId, isUploading } = action.payload;
  const newFileState = {
    state: state.toJS(),
    fileId,
    uploaded: false,
    failedToUpload: false,
    isUploading,
  };
  return setFileUploadState(newFileState);
};

actionHandlers[VALIDATE_BROKER_COMPLIANCE] = setValid;
actionHandlers[FINISH_COMPLIANCE_DOC_UPLOAD] = setSuccessUploading;
actionHandlers[COMPLIANCE_DOC_UPLOAD_ERROR] = setErrorUploading;
actionHandlers[SAVE_COMPLIANCE_REQUIREMENTS] = saveFiles;
actionHandlers[SAVE_COMPLIANCE_PAYMENT_TYPE] = savePaymentType;
actionHandlers[SET_FILE_UPLOAD_PROGRESS] = saveFileUploadProgress;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

import * as types from './types';
import request from 'axios';
import * as R from 'ramda';
import { fromJS } from 'immutable';
import { API_ROUTES, ROUTES } from '../config/constants';
import { selectPreSelectedProducts } from './productSelection';
import { push } from 'connected-react-router';
import { answerQuestions } from './answers';
import { updateSelectedPackage } from './selectedPackageSlug';
import { isBlockedRehydrationRoute, rehydrationComplete } from './rehydration';
import { isReplaceAndSaveEnabled } from '../stateStore/replaceAndSave';

export const getRecommendationsReceived = (contract, packageId) => {
  const {
    recommendations,
    recommended,
    productPriceMap,
    productRopPriceMap,
    suggestedProductPriceMap,
    suggestedProductRopPriceMap,
    extras,
  } = contract;
  return {
    type: types.RECOMMENDATIONS_RECEIVED,
    payload: {
      packageId,
      recommendations,
      recommended,
      productPriceMap,
      productRopPriceMap,
      suggestedProductPriceMap,
      suggestedProductRopPriceMap,
      extras,
    },
  };
};

const getRecommendationsFailed = (error) => ({
  type: types.GET_RECOMMENDATIONS_FAILED,
  payload: {
    error,
  },
});

const convertToArray = (val, key) => R.merge({ productId: key }, val);

export const viewQuote = (payload) => {
  const { recommendation, packageId } = payload;

  const {
    recommendations,
    productPriceMap,
    productRopPriceMap,
    suggestedProductPriceMap,
    suggestedProductRopPriceMap,
  } = recommendation.toJS();
  const recommended =
    recommendation.get('recommended') || recommendation.get('recommendation');
  const initialQuote = R.values(
    R.mapObjIndexed(convertToArray, R.prop(recommended, recommendations))
  );
  const initialPremium = R.sum(R.map(R.prop('premium'), initialQuote));
  request.post(API_ROUTES.LOG_EVENT, {
    type: types.VIEW_QUOTE,
    payload: {
      packageId,
      quote: initialQuote,
    },
  });
  return {
    type: types.VIEW_QUOTE,
    payload: {
      packageId,
      recommendations,
      recommended,
      premium: initialPremium,
      productPriceMap,
      productRopPriceMap,
      suggestedProductPriceMap,
      suggestedProductRopPriceMap,
    },
  };
};

const getNextRouteToPushTo = (location) => {
  return location.location.pathname;
};

const showPreSelected = (
  dispatch,
  getState,
  response,
  packageSlug,
  packageId
) => {
  const state = getState();
  const brokerSaleType = state.getIn(['brokerQa', 'brokerSaleType']);
  const shouldStartAtLastSavedLocation = !(
    brokerSaleType === 'qa' || brokerSaleType === 'corrections'
  );
  dispatch(getRecommendationsReceived(response, packageId));
  dispatch(selectPreSelectedProducts());
  const popupVisibleGlobal = state.getIn([
    'contactDetailsPopup',
    'contactDetailsPopupVisisble',
  ]);
  const showPopupForPackage = state.getIn([
    'packages',
    packageSlug,
    'RequestCustomizationCellphone',
    'shouldRequestCellPhone',
  ]);
  const hasViewedQuote = state.getIn(['recommendation', 'hasViewedQuote']);
  const showsPopup =
    popupVisibleGlobal && showPopupForPackage && !hasViewedQuote;
  const url = !showsPopup
    ? ROUTES.toCustomisation(packageSlug)
    : ROUTES.toNearlyThere(packageSlug);
  if (!showsPopup) {
    dispatch(viewQuote({ recommendation: fromJS(response), packageId }));
  }
  dispatch(updateSelectedPackage(packageSlug));
  dispatch(push(ROUTES.HOME));
  const rehydratedLastLocation = state.getIn([
    'rehydration',
    'rehydratedLastLocation',
  ]);
  const exists = (i) => !R.isNil(i) && !R.isEmpty(i);
  const { location, isRehydrating } = exists(rehydratedLastLocation)
    ? rehydratedLastLocation
    : { location: null, isRehydrating: false };

  if (
    isRehydrating &&
    exists(location) &&
    !isBlockedRehydrationRoute(location) &&
    shouldStartAtLastSavedLocation
  ) {
    dispatch(rehydrationComplete());

    const nextRoute = getNextRouteToPushTo(location);
    dispatch(push(nextRoute));
  } else {
    dispatch(push(url));
  }
};

const fetchFailed = (dispatch, packageSlug, error, replaceAndSaveEnabled) => {
  if(replaceAndSaveEnabled) {
    dispatch(push(ROUTES.REPLACE_AND_SAVE_DASHBOARD));
  } else {
    dispatch(push(R.concat(ROUTES.QUOTE, packageSlug)));
  }

  dispatch(getRecommendationsFailed(error));
};

const requestRecommendation = R.curry(
  (packageId, packageSlug, dispatch, getState) => {
    request
      .post(API_ROUTES.GET_RECOMMENDATION, { packageId })
      .then((res) => {
        showPreSelected(dispatch, getState, res.data, packageSlug, packageId);
      })
      .catch((error) => {
        fetchFailed(dispatch, packageSlug, error, isReplaceAndSaveEnabled(getState()));
      });
  }
);

export const getRecommendation = (packageId, packageUrlSlug) => {
  const meta = {
    packageId,
    packageUrlSlug,
  };
  const type = types.GET_RECOMMENDATION;
  const payload = requestRecommendation(packageId, packageUrlSlug);
  return {
    type,
    meta,
    payload,
  };
};

export const getRecommendationFromAnswers = (
  packageId,
  packageUrlSlug,
  answers
) => ({
  type: types.GET_RECOMMENDATION_FROM_ANSWERS,
  payload: (dispatch) => {
    dispatch(
      answerQuestions(packageId, answers, (err) => {
        const error = err ? err.error : null;
        if (error) {
          push(ROUTES.HOME);
        } else {
          dispatch(getRecommendation(packageId, packageUrlSlug));
        }
      })
    );
  },
});

"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.getInsuredTerms = void 0;

var R = _interopRequireWildcard(require("ramda"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var defaultTerms = ['premiums', 'term', 'advice', 'personal-information', 'debit-order'];

var getTermsByType = function getTermsByType(config, packageId, productIds, withRop, termType) {
  var isInProductIdList = function isInProductIdList(p) {
    return R.includes(p.productId, productIds);
  };

  var hasAnyTerms = function hasAnyTerms(productTerm) {
    if (productTerm.terms) {
      return productTerm.terms.length !== 0;
    }

    if (productTerm.termsByPackage[packageId]) {
      return productTerm.termsByPackage[packageId].length !== 0;
    }

    return false;
  };

  var shouldShowTerms = R.allPass([isInProductIdList, hasAnyTerms]);

  var getProductTerms = function getProductTerms(products) {
    return R.pipe(R.filter(shouldShowTerms), R.map(function (p) {
      var hasTermsByPackage = p.termsByPackage;

      if (hasTermsByPackage) {
        return R.path(['termsByPackage', packageId], p);
      }

      return R.path(['terms'], p);
    }))(products);
  };

  var packageTerms = config.getIn(['packages', packageId, termType]);
  var packageUsesCustomTerms = config.getIn(['packages', packageId, 'usesCustomTerms']);
  var products = R.toPairs(config.get('products').toJS()).map(function (p) {
    return R.last(p);
  });
  var productTerms = getProductTerms(products);
  var fullTerms = packageUsesCustomTerms ? packageTerms.toJS() : R.insert(2, packageTerms.toJS(), defaultTerms);
  var fullTermsWithProducts = R.flatten([].concat(_toConsumableArray(fullTerms), _toConsumableArray(productTerms)));
  var fullTermsWithRop = withRop ? R.insert(3, 'rop', fullTermsWithProducts) : fullTermsWithProducts;
  return R.uniq(fullTermsWithRop);
};

var getTerms = function getTerms(config, packageId, productIds, withRop) {
  return getTermsByType(config, packageId, productIds, withRop, "terms");
};

var getInsuredTerms = function getInsuredTerms(config, packageId, productIds, withRop) {
  return getTermsByType(config, packageId, productIds, withRop, "insured-terms");
};

exports.getInsuredTerms = getInsuredTerms;
var _default = getTerms;
exports["default"] = _default;
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  SIGNUP_AS_REFERRER,
  PROVIDE_INPUT,
  ACCEPT_REFERRAL_TERMS,
  LINK_REFERRER_SUCCESS,
  LINK_REFERRER_FAILURE,
  AUTO_POPULATE_REFERRER_DETAILS,
  SAVE_REFFERAL_REWARD_ALLOCATION,
  SELECT_REFERRAL_OPTION_TYPE,
  ADD_REFERRAL_OPTION_RECEPIENT,
  ANSWER_PAYMENT_OPTION,
} from '../actions/types';
import createActionHandler from './createActionHandler';
import * as R from 'ramda';
import rw from '../common/referralWorkflow';

const IDENTIFIER = 'referralSignup';

const initialState = fromJS({
  isLoading: false,
  signupStep: rw.NEW_SIGNUP,
  referralCode: '',
  referralToken: '',
  referralUrl: '',
  referrerUrl: '',
  referrerName: {},
  referrerCellphoneNumber: {},
  referrerEmail: {
    optional: true,
  },
  hasAcceptedTerms: false,
  error: '',
  referralRewardAllocation: {
    option: 'Donate',
    recepient: {},
    canSubmit: false,
  },
  paymentOptionAnswers: {
    'referral-payment-option': {
      questionId: 'referral-payment-option',
      answer: 'Donate',
      isPaymentRecepientQuestion: false,
    },
  },
});

const answerPaymentOption = (state, action) => {
  const {
    answer,
    isPaymentRecepientQuestion,
    questionId: qId,
  } = action.payload;
  if (isPaymentRecepientQuestion) {
    return state
      .setIn(['paymentOptionAnswers', qId], fromJS(action.payload))
      .setIn(['referralRewardAllocation', 'recepient'], { id: answer })
      .setIn(['referralRewardAllocation', 'canSubmit'], true);
  }
  if (answer === 'Cash') {
    const fullAnswer = R.assoc('answer', answer, action.payload);
    return state
      .setIn(['paymentOptionAnswers', qId], fromJS(fullAnswer))
      .setIn(['referralRewardAllocation', 'option'], answer)
      .setIn(['referralRewardAllocation', 'recepient'], {})
      .setIn(['referralRewardAllocation', 'canSubmit'], true);
  }
  return state
    .setIn(['paymentOptionAnswers', qId], fromJS(action.payload))
    .setIn(['referralRewardAllocation', 'option'], answer)
    .setIn(['referralRewardAllocation', 'canSubmit'], false);
};

const toValidAnswerMap = answer => ({ answer, isValid: true });
const actionHandlers = {};

const loading = state => state.merge({ isLoading: true, error: '' });
const notLoading = state => state.set('isLoading', false);
const toWorkflowStep = R.curry((STEP, f, state, action) =>
  f(notLoading(state), action).set('signupStep', STEP)
);
const setReferrarCode = (state, action) =>
  state
    .set('referralCode', action.payload.referralCode)
    .set('referralToken', action.payload.referralToken)
    .set('referralUrl', action.payload.referralUrl)
    .set('referrerUrl', action.payload.referrerUrl);

const autoPopulateReferrerDetails = (state, action) =>
  state
    .set('referrerName', toValidAnswerMap(action.payload.fullname))
    .set('referrerEmail', toValidAnswerMap(action.payload.email))
    .set('referrerCellphoneNumber', toValidAnswerMap(action.payload.cell));

const saveFieldData = (state, action) => {
  const { owner, fieldname, value } = action.payload;
  return owner === IDENTIFIER ? state.set(fieldname, value) : state;
};

const saveReferralType = (state, action) => {
  const currentAllocation = state.get('referralRewardAllocation').toJS();
  if (action.payload.type === 'cash') {
    return state.setIn(
      ['referralRewardAllocation'],
      fromJS(
        R.merge(currentAllocation, {
          option: action.payload.type,
          choseCashOption: true,
          choseCharityOption: false,
          canSubmit: true,
        })
      )
    );
  }
  return state.set(
    'referralRewardAllocation',
    fromJS(
      R.merge(currentAllocation, {
        option: action.payload.type,
        choseCashOption: false,
        choseCharityOption: true,
      })
    )
  );
};

const saveReferralTypeReceipient = (state, action) =>
  state
    .setIn(['referralRewardAllocation', 'recepient'], action.payload.recepient)
    .setIn(['referralRewardAllocation', 'canSubmit'], true);

const saveReferralRewardAllocation = (state, action) =>
  state.set('referralRewardAllocation', action.payload);

actionHandlers[SIGNUP_AS_REFERRER] = loading;
actionHandlers[AUTO_POPULATE_REFERRER_DETAILS] = autoPopulateReferrerDetails;

actionHandlers[ACCEPT_REFERRAL_TERMS] = (state, action) =>
  state.set('hasAcceptedTerms', action.payload);
actionHandlers[LOCATION_CHANGE] = notLoading;
actionHandlers[LINK_REFERRER_SUCCESS] = toWorkflowStep(
  rw.CAN_REFER,
  setReferrarCode
);
actionHandlers[LINK_REFERRER_FAILURE] = (state, action) =>
  notLoading(state).set('error', action.payload);

actionHandlers[PROVIDE_INPUT] = saveFieldData;
actionHandlers[SAVE_REFFERAL_REWARD_ALLOCATION] = saveReferralRewardAllocation;
actionHandlers[SELECT_REFERRAL_OPTION_TYPE] = saveReferralType;
actionHandlers[ADD_REFERRAL_OPTION_RECEPIENT] = saveReferralTypeReceipient;
actionHandlers[ANSWER_PAYMENT_OPTION] = answerPaymentOption;
const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

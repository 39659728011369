import React, { PureComponent } from 'react';
import {
  Buttons,
  Modal,
  InputText,
  PrimaryButton,
} from '@simply-fin-services/astronomix3';
import LoadingRow from '../common/LoadingRow';
import { setPrepareQuotePopupFlag } from '../../actions/prepareQuotePopup';

const ID_NUMBER_LENGTH = 13;
const initialState = {
  isValidId: false,
  clientIdNumber: '',
};

export default class PrepareQuote extends PureComponent {
  clientIdNumber = '';

  constructor(props) {
    super(props);
    this.clientIdNumber = '';
    this.state = initialState;
  }

  componentDidMount() {
    this.setState({ isValidId: false });
  }

  idIsValid(value) {
    return (
      value.length === ID_NUMBER_LENGTH && !Number.isNaN(parseInt(value, 10))
    );
  }

  setClientIdNumberFromField(e) {
    const idNumber = e.target.value;
    this.setState({
      isValidId: this.idIsValid(idNumber),
      clientIdNumber: idNumber,
    });
  }

  reset() {
    this.setState(initialState);
  }

  render() {
    const {
      prepareQuotePopupVisible,
      downloadQuote,
      isLoading,
      dispatch,
    } = this.props;

    const download = () => {
      downloadQuote(this.state.clientIdNumber);
      this.reset();
    };

    const buttonStyle = {
      marginLeft: 10,
    };

    return (
      <div>
        {prepareQuotePopupVisible && (
          <Modal show>
            {!isLoading && (
              <React.Fragment>
                <h4 className="space-below space-above-tight text-center">
                  Enter client ID number
                </h4>

                <InputText
                  placeholder="Client ID number"
                  onChange={e => this.setClientIdNumberFromField(e)}
                  value={this.state.clientIdNumber}
                  label="Client ID number: "
                />

                <Buttons>
                  <PrimaryButton
                    onClick={download}
                    disabled={!this.state.isValidId}
                  >
                    Download
                  </PrimaryButton>

                  <PrimaryButton
                    onClick={() => dispatch(setPrepareQuotePopupFlag(false))}
                    style={buttonStyle}
                  >
                    Cancel
                  </PrimaryButton>
                </Buttons>
              </React.Fragment>
            )}

            {isLoading && <LoadingRow />}
          </Modal>
        )}
      </div>
    );
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import QuestionContainer from './QuestionContainer';
import newId from '../../common/newId';
import { Label } from '@simply-fin-services/astronomix3';

class Statement extends PureComponent {
  componentDidMount() {
    const { defaultAnswer, answerQuestion } = this.props;
    if (defaultAnswer) {
      answerQuestion(defaultAnswer);
    }
  }

  render() {
    const id = newId();
    return (
      <QuestionContainer {...this.props}>
        <Label id={id} key={id} {...this.props} />
      </QuestionContainer>
    );
  }
}

Statement.propTypes = {
  title: PropTypes.string.isRequired,
  defaultAnswer: PropTypes.string,
  answerQuestion: PropTypes.func.isRequired,
};

export default Statement;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';

export const WaivePeriodConditions = () => (
  <div className="space-below">
    <p>
      If this Simply policy is replacing an existing funeral benefit, 
      it is possible that the waiting period on the Simply Flexi Funeral Cover will be waived (or adjusted if the policies being replaced are less than 6 months old). 
      In that case funeral benefits would be paid during the waiting period even if the cause of death is natural causes. 
    </p>
    <p>
      There are several conditions applicable: 
    </p>
    <div>
      <ol className="indent">
        <li>
          Where the cancelled policies are less than 6 months old, 
          the waiting period will only be reduced by the period for which the old policies have been in force. 
        </li>
        <li>
          The waiting period will only be waived for the people who were covered under the old policies, 
          and only for the amount of cover that each person had under the old policies. 
          Where you add new family members to the benefit, 
          or you choose more cover than they had before, 
          the waiting period will not be waived for the new insured people, or for the extra cover added. 
        </li>
        <li>
          The policies replaced must be from licensed insurance companies recognised by the Prudential Authority. 
        </li>
        <li>
          The policy must have been cancelled within the last 2 months, or if it is still active, 
          it must be cancelled as soon as possible.
        </li>
        <li>
          Cancelled policies cannot be reinstated later.
        </li>
        <li>
          You must have requested to have the waiting period waived during your application process and accepted these conditions. 
          If you were eligible, no waiting period will be shown on your policy schedule.
        </li>
      </ol>
    </div>
    <p>
      These details may be checked at claims stage, and if they cannot be proved, the waiting period may still be applied, and claims due to natural causes will not be paid.
    </p>
  </div>
);

class ReplacePolicyTerms extends PureComponent {
  render() {
    const { terms, children } = this.props;
    return (
      <div>
        {R.addIndex(R.map)(
          (d, i) => (
            <p key={i}>{d}</p>
          ),
          terms
        )}
        <div>{children}</div>
      </div>
    );
  }
}

ReplacePolicyTerms.propTypes = {
  terms: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const mapStateToProps = state => {
  const packageSlug = state.get('selectedPackageSlug');
  const termsId = state.getIn(['packages', packageSlug, 'replacementTerms']);
  return {
    terms: state.getIn(['terms', termsId, 'detail']).toJS(),
  };
};

export default connect(mapStateToProps)(ReplacePolicyTerms);

import { fromJS } from 'immutable';
import {
  SALARY_DEDUCTION_TRANSACTION_NUMBER_RESERVED,
  SET_SALARY_DEDUCTION_STAGE,
  SET_SALARY_DEDUCTION_PAYMENT_DETAILS,
  SALARY_DEDUCTION_START_MANDATE_DOWNLOAD,
  SALARY_DEDUCTION_FINISH_MANDATE_DOWNLOAD,
  SALARY_DEDUCTION_ERROR
} from '../actions/types';
import createActionHandler from './createActionHandler';
import moment from 'moment';

const handleReservedContractNumber = (state, action) => 
  state
    .set('employee', action.payload)
    .set('stage', 'final');

const setStage = (state, action) => state.set('stage', action.payload.stage);

const setPaymentDetails = (state, action) => 
  state.set('paymentDetails', Object.assign(
    action.payload,
    {inceptionMonth: moment().toISOString()}
  ));

const initialState = fromJS({
    stage: 'start',
});

const busy = (state) => {
  return state
    .set('stage', 'busy')
    .set('error', null)
    .set('previousStage', state.get('stage'));
};

const notBusy = (state) => {
  return state .set('stage', state.get('previousStage'));
};

const err = state => {
  return state
    .set('stage', state.get('previousStage'))
    .set('error', 'Something serious has gone wrong');
};
  
const actionHandlers = {};

actionHandlers[SALARY_DEDUCTION_TRANSACTION_NUMBER_RESERVED] = handleReservedContractNumber;
actionHandlers[SET_SALARY_DEDUCTION_STAGE] = setStage;
actionHandlers[SET_SALARY_DEDUCTION_PAYMENT_DETAILS] = setPaymentDetails;
actionHandlers[SALARY_DEDUCTION_FINISH_MANDATE_DOWNLOAD] = notBusy;
actionHandlers[SALARY_DEDUCTION_START_MANDATE_DOWNLOAD] = busy;
actionHandlers[SALARY_DEDUCTION_ERROR] = err;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);
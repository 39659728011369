// Boker QA
export const QA = "qa";
export const CORRECTIONS = "corrections";
export const SALE = "sale";

export const getBrokerSaleType = state => state.getIn(['saleOptions', 'sale-type']);
const getIsQaActive = state => state.getIn(['saleOptions', 'qa-active?']);

export const isQaSale = (state) => getBrokerSaleType(state) === QA || getBrokerSaleType(state) === CORRECTIONS;
export const isQaActiveSale = (state) => getIsQaActive(state);
export const isQaInReview = (state) => getBrokerSaleType(state) === QA;
export const isQaInCorrections = (state) => getBrokerSaleType(state) === CORRECTIONS;
export const shouldSendToQa = (state) => (getBrokerSaleType(state) === CORRECTIONS || getBrokerSaleType(state) === SALE) && getIsQaActive(state);

// Payment methods 
const PERSAL = 'persal';
const SALARY_DEDUCTION = 'salary-deduction';
const getAllowedPaymentMethods = (state) => state.getIn(['saleOptions', 'payment-types']).filter((type) => type.get('allowed?'));
const getAllowedPaymentMethodTypes = (state) => getAllowedPaymentMethods(state).map((type) => type.get('type'));
export const isAllowedPaymentMethod = (state, paymentMethod) =>  getAllowedPaymentMethodTypes(state).includes(paymentMethod);

const getPaymentMethodOptions = (state, paymentType) => 
  state
    .getIn(['saleOptions', 'payment-types'])
    .find((v) => v.get('type') === paymentType)
    .get('options');

export const firstAvailablePaymentMethodType = (state) => {
  const firstAllowedPaymentMethod = getAllowedPaymentMethods(state).first(); 
   
  if (firstAllowedPaymentMethod.get('type') === SALARY_DEDUCTION) {
    const firstProviderId = firstAllowedPaymentMethod.getIn(['options', 'provider-ids']).first();
    return `${firstAllowedPaymentMethod.get('type')}|${firstProviderId}`;
  }

  return firstAllowedPaymentMethod.get('type');
};

// Salary deduction
export const getAllowedSalaryDeductionProviders = (state) =>
  getPaymentMethodOptions(state, SALARY_DEDUCTION)
    .get('providers');

export const getAllowedProvider = (state, providerId) =>
  getAllowedSalaryDeductionProviders(state)
    .find((provider) => provider.get('id') === providerId);

// Persal
export const getPersalMandateType = (state) => 
  getPaymentMethodOptions(state, PERSAL)
    .get('mandate-type');

// Compliance documents
export const getRequiredComplianceDocuments = (state) => state.getIn(['saleOptions', 'compliance-documents']);
import * as types from './types';
import request from 'axios';
import * as R from 'ramda';
import { API_ROUTES, ROUTES } from '../config/constants';
import { push } from 'connected-react-router';
import Immutable from 'immutable';
import processAnswer from '../.shared/processAnswer';

const simplePayloadAction = R.curry((type, payload) => ({
  type,
  payload,
}));

export const autoPopulateReferrerDetails = person => ({
  type: types.AUTO_POPULATE_REFERRER_DETAILS,
  payload: {
    fullname: [person.firstname, person.surname].join(' '),
    email: person.email || '',
    cell: person.cellphone,
  },
});

export const linkReferrerSuccess = (
  referralCode,
  referralToken,
  referralUrl,
  referrerUrl
) => ({
  type: types.LINK_REFERRER_SUCCESS,
  payload: { referralCode, referralToken, referralUrl, referrerUrl },
});

export const linkReferrerFailure = error => ({
  type: types.LINK_REFERRER_FAILURE,
  payload: error,
});

const link = (route, data, referralCode, dispatch) =>
  request
    .post(route, data)
    .then(res =>
      dispatch(
        linkReferrerSuccess(
          referralCode,
          res.data.token,
          res.data.url,
          res.data.referrerUrl
        )
      )
    )
    .catch(error => {
      if (error.response && error.response.status < 500) {
        dispatch(linkReferrerFailure(error.response.data));
      } else {
        dispatch(push(ROUTES.ERROR));
      }
    });

const submitSignup = R.curry(
  (fullname, cellphoneNumber, email, referralRewardAllocation, dispatch) => {
    link(
      API_ROUTES.REFERRER_SIGNUP,
      {
        fullname,
        referralCode: cellphoneNumber,
        email,
        referralRewardAllocation,
      },
      cellphoneNumber,
      dispatch
    );
  }
);

export const saveReferralRewardAllocation = dispatch => action => data =>
  dispatch({
    type: types.SAVE_REFFERAL_REWARD_ALLOCATION,
    payload: {
      action,
      data,
    },
  });

export const selectOption = type => ({
  type: types.SELECT_REFERRAL_OPTION_TYPE,
  payload: { type },
});

export const addRecepient = recepient => ({
  type: types.ADD_REFERRAL_OPTION_RECEPIENT,
  payload: { recepient },
});

const validateReferrerAllocation = rewardAllocation => {
  const isImmutable = i => i instanceof Immutable.Map;
  const allocation = isImmutable(rewardAllocation)
    ? rewardAllocation.toJS()
    : rewardAllocation;
  const withEmptyRecepient = item => R.assoc('recepient', {}, item);
  const isCashReferrer = R.path(['option'], allocation) === 'cash';
  const strippedAllocation = R.pick(['option', 'recepient'], allocation);
  if (isCashReferrer) {
    return withEmptyRecepient(strippedAllocation);
  }
  return strippedAllocation;
};

export const signupAsReferrer = (
  fullname,
  cellphoneNumber,
  email,
  rewardAllocation
) => {
  const referralRewardAllocation = validateReferrerAllocation(rewardAllocation);
  return {
    type: types.SIGNUP_AS_REFERRER,
    payload: submitSignup(
      fullname,
      cellphoneNumber,
      email,
      referralRewardAllocation
    ),
  };
};

export const toggleTermsAndConditions = simplePayloadAction(
  types.ACCEPT_REFERRAL_TERMS
);

export const answer = (type, dispatch, fieldname, owner) => value => {
  const provideAnswer = simplePayloadAction(types.PROVIDE_INPUT);
  dispatch(provideAnswer({ owner, fieldname, value }));
};

const validateOptionalAnswer = a => {
  if (a.optional && R.isEmpty(a.answer)) {
    return R.assoc('isValid', true, a);
  }
  return a;
};

export const validateAndAnswer = (
  questionPage,
  questions,
  dispatch,
  fieldname,
  owner
) => value => {
  const validatedAnswer = processAnswer(questions, fieldname, value);
  const optionalQuestions = questionPage.get('optionalQuestions');
  const isOptional =
    !!optionalQuestions && optionalQuestions.toSetSeq().has(fieldname);
  const optionalAnswer = R.assoc('optional', isOptional, validatedAnswer);
  const provideAnswer = simplePayloadAction(types.PROVIDE_INPUT);
  dispatch(
    provideAnswer({
      owner,
      fieldname,
      value: validateOptionalAnswer(optionalAnswer),
    })
  );
};

export const answerPaymentOption = (
  questionId,
  a,
  isPaymentRecepientQuestion
) => ({
  type: types.ANSWER_PAYMENT_OPTION,
  payload: {
    questionId,
    answer: a,
    isPaymentRecepientQuestion,
  },
});

import { fromJS } from 'immutable';
import {
  VALIDATE_IEMAS_MEMBER_NUMBER_SUCCESS,
  VALIDATE_IEMAS_MEMBER_NUMBER,
  RESET,
} from '../actions/types';
import * as R from 'ramda';
import createActionHandler from './createActionHandler';

const initialState = fromJS({
  memberNumber: '',
  found: false,
  canContinue: false,
  status: '',
  isLoading: false,
});

const actionHandlers = {};

const validateIemasMemberDetails = (state, action) =>
  fromJS(action.payload).set('isLoading', false);

const setLoading = R.curry((isLoading, state) =>
  state.set('isLoading', isLoading)
);

const toInitialState = () => initialState;

actionHandlers[RESET] = toInitialState;
actionHandlers[
  VALIDATE_IEMAS_MEMBER_NUMBER_SUCCESS
] = validateIemasMemberDetails;

actionHandlers[VALIDATE_IEMAS_MEMBER_NUMBER] = setLoading(true);

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

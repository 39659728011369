"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasAgreedToTerms = exports.agreedToTerms = exports.POLICY_REPLACES_OTHER_OPTIONS = exports.NO = exports.YES = void 0;

var _index = require("./index");

var _immutable = require("immutable");

var YES = 'Yes';
exports.YES = YES;
var NO = 'No';
exports.NO = NO;
var POLICY_REPLACES_OTHER_OPTIONS = [YES, NO];
exports.POLICY_REPLACES_OTHER_OPTIONS = POLICY_REPLACES_OTHER_OPTIONS;

var validateAllTerms = function validateAllTerms(details) {
  return (0, _immutable.fromJS)({
    generalTermsAccepted: (0, _index.validateBooleanAs)(true, details.get('generalTermsAccepted')),
    policyReplacesOther: (0, _index.validateRequiredText)(details.get('policyReplacesOther')),
    replacePolicyWaivePeriodTermsAccepted: (0, _index.validateBooleanAs)(details.get('policyReplacesOther') === YES, details.get('replacePolicyWaivePeriodTermsAccepted')),
    replacePolicyTermsAccepted: (0, _index.validateBooleanAs)(details.get('policyReplacesOther') === YES, details.get('replacePolicyTermsAccepted'))
  });
};

var validateGeneralTerms = function validateGeneralTerms(details) {
  return (0, _immutable.fromJS)({
    generalTermsAccepted: (0, _index.validateBooleanAs)(true, details.get('generalTermsAccepted')),
    policyReplacesOther: (0, _index.validateRequiredText)(details.get('policyReplacesOther')),
    replacePolicyTermsAccepted: (0, _index.validateBooleanAs)(details.get('policyReplacesOther') === YES, details.get('replacePolicyTermsAccepted'))
  });
};

var reduceValidation = function reduceValidation(validation) {
  return validation.reduce(function (reduction, value) {
    return reduction.set('isValid', reduction.get('isValid') && value.get('isValid'));
  }, new _immutable.Map({
    isValid: true
  }));
};

var agreedToTerms = function agreedToTerms(details) {
  var _details$toJS = details.toJS(),
      waivePeriodDetailsShouldBeRequested = _details$toJS.waivePeriodDetailsShouldBeRequested,
      packageAllowsPolicyReplacement = _details$toJS.packageAllowsPolicyReplacement;

  if (packageAllowsPolicyReplacement && waivePeriodDetailsShouldBeRequested) {
    return reduceValidation(validateAllTerms(details));
  }

  return reduceValidation(validateGeneralTerms(details));
};

exports.agreedToTerms = agreedToTerms;

var hasAgreedToTerms = function hasAgreedToTerms(details) {
  return agreedToTerms(details).get('isValid');
};

exports.hasAgreedToTerms = hasAgreedToTerms;
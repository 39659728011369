import * as R from 'ramda';
import request from 'axios';
import { API_ROUTES } from '../config/constants';

export const completeQuestionPage = (questionPageId) => {
  const type = R.replace(
    /-/g,
    '_',
    R.concat('COMPLETE_', R.toUpper(questionPageId))
  );
  request
    .post(API_ROUTES.LOG_EVENT, {
      type,
      payload: {},
    })
    .catch((err) => err); // it's not important if this doesn't work
  return {
    type,
    payload: {},
  };
};

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ProductSlider } from '@simply-fin-services/astronomix3';
import { connect } from 'react-redux';
import { toPremium } from '../common/numberFormats';
import styled from 'styled-components';

const Tooltip = styled.div`
  line-height: 19px;
`;
class ProductCustomization extends PureComponent {
  render() {
    const { productPricing, productId, selected, premium } = this.props;
    const pricingConfig = productPricing.get(productId)
      ? productPricing.get(productId).toJS()
      : null;
    const tooltip = (
      <Fragment>
        <Tooltip className="small">
          {this.props.tooltip} 
        </Tooltip>
        <Tooltip className="small">
          Premium:{' '}
          <strong>{selected ? toPremium(premium) : toPremium(0)}</strong>
        </Tooltip>
      </Fragment>
    );
    return (
      <ProductSlider
        {...this.props}
        productPrices={pricingConfig}
        notSelectedText="cover not selected"
        priceSuffix="cover"
        tooltip={tooltip}
      />
    );
  }
}

const ProductPackageProps = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  insuredAmount: PropTypes.number.isRequired,
  premium: PropTypes.number.isRequired,
  toggle: PropTypes.func.isRequired,
  onSlide: PropTypes.func.isRequired,
};

ProductCustomization.protoTypes = ProductPackageProps;

const mapStateToProps = (state) => ({
  productPricing: state.getIn(['recommendation', 'productPriceMap']),
});

export default connect(mapStateToProps)(ProductCustomization);

/* eslint-disable no-underscore-dangle */
import configureErrorHandling from './config/configureErrorHandling';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import preRenderMiddleware from './middleware/preRenderMiddleware';
import prepState from './store/prepState';
import App, { startup, configurePackageTransitions, wrapMiddleware } from './App';
configureErrorHandling(window.__INITIAL_STATE__);
require('./polyfills');

let previousPath = '';

// Grab the state from a global injected into
// server-generated HTML
const initialState = window.__INITIAL_STATE__;

const browserHistory = createBrowserHistory();

const store = configureStore(wrapMiddleware, prepState(initialState), browserHistory);

configurePackageTransitions(browserHistory, store);

// logs route to google analytics
export default function logRoute() {
  const currentPath = window.location.pathname;
  if (currentPath !== previousPath) {
    previousPath = currentPath;
  }
}
/* eslint-disable no-unused-vars */
/**
 * Callback function handling frontend route changes.
 */
function onUpdate() {
  // Prevent duplicate fetches when first loaded.
  // Explanation: On server-side render, we already have __INITIAL_STATE__
  // So when the client side onUpdate kicks in, we do not need to fetch twice.
  // We set it to null so that every subsequent client-side navigation will
  // still trigger a fetch data.
  // Read more: https://github.com/choonkending/react-webpack-node/pull/203#discussion_r60839356
  if (window.__INITIAL_STATE__ !== null) {
    window.__INITIAL_STATE__ = null;
    return;
  }

  const { components, params } = this.state;

  preRenderMiddleware(store.dispatch, components, params);
  logRoute();
}

/* eslint-enable no-unused-vars */

// Router converts <Route> element hierarchy to a route config:
// Read more https://github.com/rackt/react-router/blob/latest/docs/Glossary.md#routeconfig

store.dispatch(startup());

const renderApp = () => {
  render(
    <Provider store={store}>
      <App history={browserHistory} store={store}/>
    </Provider>,
    document.getElementById('app')
  );
};

if (module.hot) {
  // Enable Webpack hot module replacement for Views, actions and common
  module.hot.accept('./App', renderApp);
}

renderApp();

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isReflexiveCheckboxes = exports["default"] = void 0;

var _default = function _default(question) {
  return question.has('nodeId');
};

exports["default"] = _default;

var isReflexiveCheckboxes = function isReflexiveCheckboxes(question) {
  return question.has('type') && question.get('type') === 'reflexive-checkbox';
};

exports.isReflexiveCheckboxes = isReflexiveCheckboxes;
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getLabel } from '../../common/alternativeLabels';
import { InputSelect } from '@simply-fin-services/astronomix3';
import Immutable from 'immutable';
import QuestionContainer from './QuestionContainer';
class DropdownQuestion extends PureComponent {
  render() {
    const {
      answerQuestion,
      possibleAnswers,
      answer,
      hint,
      title,
      fitForm,
      errors,
      placeholder,
    } = this.props;
    const isImmutable =
      possibleAnswers instanceof Immutable.List ||
      possibleAnswers instanceof Immutable.Map;
    const options = isImmutable
      ? possibleAnswers
          .map((a) => ({
            value: a,
            label: getLabel(this.props, a),
          }))
          .toJS()
      : possibleAnswers.map((a) => ({
          value: a,
          label: getLabel(this.props, a),
        }));
    return (
      <QuestionContainer>
        <InputSelect
          id={this.props.questionId}
          options={options}
          onSelect={answerQuestion}
          placeholder={placeholder || answer}
          hint={hint}
          label={title}
          fitForm={fitForm}
          errors={errors}
          value={answer}
        />
      </QuestionContainer>
    );
  }
}

DropdownQuestion.propTypes = {
  possibleAnswers: PropTypes.arrayOf(PropTypes.string).isRequired,
  answer: PropTypes.string,
  answerQuestion: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default DropdownQuestion;

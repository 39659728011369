import { fromJS } from 'immutable';
import {
  SET_CURRENT_EXTENDED_FAMILY_INSURED_DETAIL,
  SET_CURRENT_EXTENDED_FAMILY_INSURED_DETAILS,
  ADD_CURRENT_EXTENDED_FAMILY_INSURED,
  RESET,
  UPDATE_FAMILY_MEMBER_PRICES,
  FETCHING_CURRENT_INSURED_PRICING,
  CURRENT_INSURED_REFRESHED,
  VALIDATED_CURRENT_INSURED,
  OVERWRITE_EFF_MEMBER_PRICES,
  OPEN_EFF_INSURED_EDITOR,
  UPDATE_EFF_INSURED,
  DELETE_EFF_INSURED,
  TOGGLE_EFF_SELECTABLE,
  REHYDRATE_EXTENDED_FAMILY_INSUREDS,
  FUNERAL_COVER_AMT_CHANGED,
  TOGGLE_FUNERAL_COVER_DISCLAIMER_ACCEPTED,
} from '../actions/types';
import createActionHandler from './createActionHandler';
import * as R from 'ramda';
import moment from 'moment';
import { getAffiliateMaxCover } from '../.shared/affiliateMaxCover';
import { DEFAULT_EFF_COVER_AMOUNT } from '../config/appConfig';

const affiliations = {
  Parent: 0,
  Sibling: 1,
  Grandparent: 2,
  Aunt: 2,
  Uncle: 2,
  'Child over 21': 2,
  Grandchild: 2,
};

const defaultExtendedFamilyInsured = {
  id: 0,
  name: { answer: '' },
  surname: { answer: '' },
  cellphoneNumber: { answer: '' },
  affiliation: { answer: '' },
  gender: { answer: '' },
  cover: { answer: 0 },
  age: { answer: null, isValid: false },
  relationship: { answer: null, isValid: false },
  affiliationKey: { answer: null, isValid: false },
  dateOfBirth: { answer: '', isValid: false },
  selected: { answer: true },
};

const initialState = fromJS({
  extendedFamilySelectable: false,
  currentExtenedFamilyInsured: defaultExtendedFamilyInsured,
  insureds: [],
  maximumFuneralCoverAmount: 0,
  funeralCoverDisclaimerAccepted: false,
});

const calculateAgeFromDateOfBirth = (state, answer) => {
  const answerToSave = { answer: 0, isValid: false };
  const parts = answer.split('/');
  if (parts.length === 3) {
    const dobMoment = moment(answer, 'DD/MM/YYYY');
    const yearsFromDob = moment().diff(dobMoment, 'years');

    answerToSave.answer = yearsFromDob;
  }
  return state.setIn(
    ['currentExtenedFamilyInsured', 'age'],
    fromJS(answerToSave)
  );
};

const setCurrentExtendedFamilyInsuredValue = (state, { payload }) => {
  return R.cond([
    // affiliation
    [
      R.propEq('value', 'affiliation'),
      ({ answer, value }) =>
        R.pipe(
          (s) =>
            s.setIn(
              ['currentExtenedFamilyInsured', 'affiliationKey'],
              fromJS({ answer: R.prop(answer, affiliations) })
            ),
          (s) =>
            s.setIn(['currentExtenedFamilyInsured', value], fromJS({ answer }))
        )(state),
    ],
    // date of birth
    [
      R.propEq('value', 'dateOfBirth'),
      ({ answer, value }) =>
        R.pipe(
          (s) => calculateAgeFromDateOfBirth(s, answer),
          (s) =>
            s.setIn(
              ['currentExtenedFamilyInsured', value],
              //answer
              fromJS({ answer })
            )
        )(state),
    ],
    // default
    [
      R.T,
      ({ answer, value }) =>
        state.setIn(['currentExtenedFamilyInsured', value], fromJS({ answer })),
    ],
  ])(payload);
};

const setCurrentExtendedFamilyInsuredValues = (state, { payload }) => {
  return state.update('currentExtenedFamilyInsured', (current) =>
    current.merge(fromJS(payload))
  );
};

const resetToDefaultInsured = R.pipe(
  (state) =>
    state.set(
      'currentExtenedFamilyInsured',
      fromJS(
        R.assoc(
          'id',
          R.inc(state.get('insureds').size),
          defaultExtendedFamilyInsured
        )
      )
    ),
  (state) => state.set('refreshCurrentInsured', true)
);

const getDefaultCoverAmount = (funeralInsuredAmt) =>
  funeralInsuredAmt
    ? funeralInsuredAmt > 0
      ? funeralInsuredAmt
      : DEFAULT_EFF_COVER_AMOUNT
    : DEFAULT_EFF_COVER_AMOUNT;

const getMaxCoverAmount = (pricingKeys, funeralInsuredAmt) =>
  pricingKeys 
    ? Math.max(
        ...pricingKeys.map((key) => Number.parseInt(key, 10))
      )
    : getDefaultCoverAmount(funeralInsuredAmt);

const addCurrentExtendedFamilyInsured = (state) =>
  R.pipe(
    () => state.get('currentExtenedFamilyInsured'),
    (currentInsured) => state.get('insureds').push(currentInsured),
    (newInsureds) => state.set('insureds', newInsureds),
    resetToDefaultInsured
  )(state);

const refreshCurrentInsured = (state) => {
  return state.set('refreshCurrentInsured', false);
};

const updateFamilyMemberPrices = (state, { payload }) => {
  const { effProductId, funeralInsuredAmt } = payload;
  const prices = R.assoc(effProductId, payload.prices.data, {});
  const pricingKeys = R.keys(payload.prices.data);
  const currentInsuredAmountVal = R.path(
    ['insured', 'insuredAmount', 'answer'],
    payload
  );
  const currentInsuredAmount =
    typeof currentInsuredAmountVal === 'string'
      ? currentInsuredAmountVal
      : R.toString(currentInsuredAmountVal);

  const preselectedPricingIndex =
    currentInsuredAmount && R.indexOf(currentInsuredAmount, pricingKeys) !== -1
      ? R.indexOf(currentInsuredAmount, pricingKeys)
      : R.indexOf(R.toString(R.apply(Math.min, pricingKeys)), pricingKeys);

  const preselectedPricingKey = pricingKeys[preselectedPricingIndex];

  const premium = R.path(
    ['prices', 'data', preselectedPricingKey, 'premium'],
    payload
  );
  const premiumWithRop = R.path(
    ['prices', 'data', preselectedPricingKey, 'premiumWithRop'],
    payload
  );
  return state
    .setIn(['currentExtenedFamilyInsured', 'prices'], fromJS(prices))
    .setIn(['currentExtenedFamilyInsured', 'fetchingPricing'], false)
    .setIn(
      ['currentExtenedFamilyInsured', 'insuredAmount', 'answer'],
      String(preselectedPricingKey)
    )
    .setIn(
      ['currentExtenedFamilyInsured', 'cover', 'answer'],
      String(preselectedPricingIndex)
    )
    .setIn(['currentExtenedFamilyInsured', 'premium', 'answer'], premium)
    .setIn(
      ['currentExtenedFamilyInsured', 'premiumWithRop', 'answer'],
      premiumWithRop
    )
    .setIn(['currentExtenedFamilyInsured', 'selected', 'answer'], true)
    .set(
      'maximumFuneralCoverAmount',
      getMaxCoverAmount(pricingKeys, funeralInsuredAmt)
    );
};

const fetchingCurrentInsuredPricing = (state) => {
  return state.setIn(['currentExtenedFamilyInsured', 'fetchingPricing'], true);
};

const validatedInsured = (state, { payload }) => {
  return R.pipe(
    R.mergeDeepRight(state.get('currentExtenedFamilyInsured').toJS()),
    (insured) => state.set('currentExtenedFamilyInsured', fromJS(insured))
  )(payload);
};

const overwriteAllMemberPremiums = (state, { payload }) => {
  const insureds = payload.insureds;
  const insuredsById = insureds.reduce((all, current) => {
    return R.assoc(current.id, current, all);
  }, {});
  const updated = state.get('insureds').map((i) => {
    const newInsured = insuredsById[i.get('id')];
    return i
      .setIn(['premium', 'answer'], newInsured.premium)
      .setIn(['premiumWithRop', 'answer'], newInsured.premiumWithRop)
      .setIn(['insuredAmount', 'answer'], newInsured.insuredAmount)
      .set('prices', fromJS(newInsured.prices));
    // cover index must also be set here
  });
  return state.set('insureds', updated);
};

const funeralCoverAmountChanged = (state, { payload }) => {
  const { funeralCoverAmount, extendedFamilyProductId, insuredPrices } = payload;
  const pricingKeys = R.keys(insuredPrices[0]);
  const insureds = state
    .get('insureds')
    .map((insured) => {
      const relationship = insured.getIn(['relationship', 'answer']);
      const age = insured.getIn(['age', 'answer']);

      const maxCover =
        Math.floor(
          getAffiliateMaxCover({
            relationship,
            age,
            funeralCoverAmount,
          }) / 50
        ) * 50;

      if (
        Number.parseInt(insured.getIn(['insuredAmount', 'answer']), 10) >
        maxCover
      ) {
        const premium = insured.getIn([
          'prices',
          extendedFamilyProductId,
          `${maxCover}`,
          'premium',
        ]);
        const ropPremium = insured.getIn([
          'prices',
          extendedFamilyProductId,
          `${maxCover}`,
          'premiumWithRop',
        ]);
        return insured
          .setIn(['premium', 'answer'], premium)
          .setIn(['premiumWithRop', 'answer'], ropPremium)
          .setIn(['insuredAmount', 'answer'], maxCover);
      }
      return insured;
    })
    .filter((i) => i.getIn(['insuredAmount', 'answer']) >= 100);

  let currentInsured = state.get('currentExtenedFamilyInsured');
  const coverExceedsAllowedCover =
    Number.parseInt(currentInsured.getIn(['insuredAmount', 'answer']), 10) >
    funeralCoverAmount;
  const hasPricing = currentInsured.get('prices') != null;
  if (coverExceedsAllowedCover && hasPricing) {
    const newPremium = currentInsured.getIn([
      'prices',
      extendedFamilyProductId,
      `${funeralCoverAmount}`,
      'premium',
    ]);
    const newROPPremium = currentInsured.getIn([
      'prices',
      extendedFamilyProductId,
      `${funeralCoverAmount}`,
      'premiumWithRop',
    ]);
    const selectedCoverIndex = Object.keys(
      currentInsured.getIn(['prices', extendedFamilyProductId]).toJS()
    )
      .sort()
      .indexOf(`${funeralCoverAmount}`);
    currentInsured = currentInsured
      .setIn(['premium', 'answer'], newPremium)
      .setIn(['premiumWithRop', 'answer'], newROPPremium)
      .setIn(['insuredAmount', 'answer'], funeralCoverAmount)
      .setIn(['cover', 'answer'], `${selectedCoverIndex + 2}`); //component uses 1 as index 0
  }
  return state
    .set('insureds', insureds)
    .set('currentExtenedFamilyInsured', currentInsured)
    .set('refreshCurrentInsured', true)
    .set(
      'maximumFuneralCoverAmount',
      getMaxCoverAmount(pricingKeys, funeralCoverAmount)
    );
};

const editInsured = (state, { payload }) =>
  R.pipe(
    () => R.prop('insuredId', payload),
    (insuredId) => state.get('insureds').find((i) => i.get('id') === insuredId),
    (insured) => insured.set('editing', true),
    (insured) => state.set('currentExtenedFamilyInsured', insured)
  )();

const isSameInsured = (id, otherInsured) => id === otherInsured.get('id');

const replaceUpatedInsured = (editedInsured) => (oldInsured) =>
  isSameInsured(editedInsured.get('id'), oldInsured)
    ? editedInsured
    : oldInsured;

const replaceAndResetInsureds = (state, insureds) =>
  resetToDefaultInsured(state.set('insureds', insureds));

const updateInsured = (state) =>
  R.pipe(
    () => state.get('currentExtenedFamilyInsured').set('editing', false),
    (editedInsured) =>
      state.get('insureds').map(replaceUpatedInsured(editedInsured)),
    (insureds) => replaceAndResetInsureds(state, insureds)
  )();

const deleteInsured = (state, action) =>
  R.pipe(
    () =>
      state
        .get('insureds')
        .filter((i) => !isSameInsured(action.payload.insuredId, i)),
    (insureds) => replaceAndResetInsureds(state, insureds)
  )();

const toggleEffSelectable = (state, { payload }) =>
  state.set('extendedFamilySelectable', payload);

const rehydrateInsureds = (state, { payload }) => {
  const asAnswer = (prop) => (insured) => ({ answer: R.prop(prop, insured) });
  return state.set(
    'insureds',
    fromJS(
      R.map(
        R.applySpec({
          id: R.prop('id'),
          name: asAnswer('name'),
          surname: asAnswer('surname'),
          cellphoneNumber: asAnswer('cellphoneNumber'),
          affiliation: asAnswer('affiliation'),
          affiliationKey: asAnswer('affiliationKey'),
          gender: asAnswer('gender'),
          cover: asAnswer('cover'),
          age: asAnswer('age'),
          dateOfBirth: asAnswer('dateOfBirth'),
          insuredAmount: asAnswer('insuredAmount'),
          premium: asAnswer('premium'),
          premiumWithRop: asAnswer('premiumWithRop'),
          relationship: asAnswer('relationship'),
          prices: R.prop('prices'),
          selected: asAnswer('selected'),
        }),
        payload
      )
    )
  );
};

const acceptFuneralCoverDisclaimer = (state, { payload }) =>
  state.set('funeralCoverDisclaimerAccepted', payload);

const toInitialState = () => initialState;
const actionHandlers = {};
actionHandlers[RESET] = toInitialState;
actionHandlers[SET_CURRENT_EXTENDED_FAMILY_INSURED_DETAIL] =
  setCurrentExtendedFamilyInsuredValue;
actionHandlers[SET_CURRENT_EXTENDED_FAMILY_INSURED_DETAILS] =
  setCurrentExtendedFamilyInsuredValues;
actionHandlers[ADD_CURRENT_EXTENDED_FAMILY_INSURED] =
  addCurrentExtendedFamilyInsured;
actionHandlers[UPDATE_FAMILY_MEMBER_PRICES] = updateFamilyMemberPrices;
actionHandlers[FETCHING_CURRENT_INSURED_PRICING] =
  fetchingCurrentInsuredPricing;
actionHandlers[CURRENT_INSURED_REFRESHED] = refreshCurrentInsured;
actionHandlers[VALIDATED_CURRENT_INSURED] = validatedInsured;
actionHandlers[OVERWRITE_EFF_MEMBER_PRICES] = overwriteAllMemberPremiums;
actionHandlers[OPEN_EFF_INSURED_EDITOR] = editInsured;
actionHandlers[UPDATE_EFF_INSURED] = updateInsured;
actionHandlers[DELETE_EFF_INSURED] = deleteInsured;
actionHandlers[TOGGLE_EFF_SELECTABLE] = toggleEffSelectable;
actionHandlers[REHYDRATE_EXTENDED_FAMILY_INSUREDS] = rehydrateInsureds;
actionHandlers[FUNERAL_COVER_AMT_CHANGED] = funeralCoverAmountChanged;
actionHandlers[TOGGLE_FUNERAL_COVER_DISCLAIMER_ACCEPTED] =
  acceptFuneralCoverDisclaimer;

const handle = createActionHandler(actionHandlers);
export default (state = initialState, action) => handle(state, action);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RadioSelectQuestion from '../questions/RadioSelectQuestion';
import { BANK_ACCOUNT_TYPES } from '../../.shared/paymentDetails/bankingDetails';

export default class AccountType extends PureComponent {
  render() {
    const { answerQuestion } = this.props;
    return (
      <RadioSelectQuestion
        title="Account type"
        possibleAnswers={BANK_ACCOUNT_TYPES}
        answerQuestion={answerQuestion}
        {...this.props}
      />
    );
  }
}

AccountType.propTypes = {
  answerQuestion: PropTypes.func.isRequired,
};

import React, { PureComponent } from 'react';
import {
  ShareToWhatsapp,
  ShareToTwitter,
  ShareToEmail,
  ShareToFacebook,
} from '../common/SocialSharing';

const url = 'https://www.simply.co.za';
const message = `Check out this awesome new life, disability and funeral insurance from Simply! I think it’s great. I’m sure you will too! Click here to find out more ${url}`; // eslint-disable-line max-len
const messageSansUrl =
  'Check out this awesome new life, disability and funeral insurance from Simply! I think it’s great. I’m sure you will too! Click here to find out more '; // eslint-disable-line max-len

export default class ThankYouPage extends PureComponent {
  render() {
    return (
      <div className="row text-center m-t-10 m-b-10">
        <div className="col-xs-12">
          <h4>Share your experience with friends</h4>
        </div>
        <div className="col-xs-12 btn-row">
          <ShareToWhatsapp text={message} />
          <ShareToFacebook url={url} />
          <ShareToTwitter text={messageSansUrl} url={url} />
          <ShareToEmail subject="Simply Awesome" body={message} />
        </div>
      </div>
    );
  }
}

import * as R from 'ramda';
import { isBrokerSale } from './brokers';
import { getPackageDiscountRate as getPackageDiscountRateShared } from '../.shared/calculatePricing';

const isNoDiscountSenderReference = state => {
  const senderReference = state.getIn(['currentTheme', 'senderReference']);
  const NO_DISCOUNT_SENDER_REFERENCES = state
    .getIn(['config', 'NO_DISCOUNT_SENDER_REFERENCES'])
    .toJS();
  if (R.is(String, senderReference)) {
    return R.includes(
      R.toUpper(senderReference),
      NO_DISCOUNT_SENDER_REFERENCES
    );
  }
  return false;
};

const NO_DISCOUNT_SCENARIOS = [isBrokerSale, isNoDiscountSenderReference];

const shouldNotHaveDiscount = state =>
  R.pipe(
    R.map(f => f(state)),
    R.reduce(R.or, false)
  )(NO_DISCOUNT_SCENARIOS);

export const getPackageDiscountRate = state => {
  const productSelection = state.getIn(['productSelection', 'products']);
  if (!productSelection || shouldNotHaveDiscount(state)) {
    return 0;
  }
  const slug = state.get('selectedPackageSlug');
  const selectedPackageConfig = state.getIn(['packages', slug]);
  const discounts = state.get('discounts');
  const senderReference = state.getIn(['currentTheme', 'senderReference']);
  const saleDetails = {
    productSelection,
    senderReference,
    isBrokerSale: isBrokerSale(state),
    selectedPackageConfig,
    discounts,
  };

  const sharedDiscountRate = getPackageDiscountRateShared(saleDetails);

  return sharedDiscountRate;
};

/* eslint-disable*/
import styled from 'styled-components';

export const FloatingAffordability = styled.div`
  position: fixed;
  width: 717px;
  margin-bottom: 20px;
  bottom: -18px;
  margin-left: 0px;
  background: #fefefe;
  @media (max-width: 599px) {
    width: calc(100vw - 82px);
    margin-left: 0px;
  }
  @media (min-width: 600px) and (max-width: 767px) {
    width: calc(100vw - 145px);
    margin-left: 0px;
  }
  @media (min-width: 768px) and (max-width: 800px) {
    width: calc(100vw - 131px);
    margin-left: 0px;
  }
  @media (max-width: 812px) and (orientation: landscape) {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Affordability = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

export const AffordabilityHeader = styled.div`
  background: ${(props) => (props.isAffordable ? 'green' : 'red')};
  border: 1px solid ${(props) => (props.isAffordable ? 'green' : 'red')};
  padding-left: 10px;
  color: #fff;
  font-weight: 500;
`;

export const AffordabilityBody = styled.div`
  border: 2px solid ${(props) => (props.isAffordable ? 'green' : 'red')};
  padding-left: 10px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

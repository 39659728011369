import React, { PureComponent } from 'react';
import { PrimaryButton } from '@simply-fin-services/astronomix3';
import { toCurrencyNoDecimal } from '../../common/numberFormats';
import styled from 'styled-components';
import MyPolicy from './MyPolicy';

const TotalContainer = styled.div`
  border: 0.5px solid #9797a5;
  box-shadow: -1px -1px 8px 1px #81818133;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0.5rem;
    align-content: space-between;
  }
`;
class ListReplacementPolicies extends PureComponent {
  constructor() {
    super();
    this.state = {};
    this.removeReplacementPolicy = this.removeReplacementPolicy.bind(this);
    this.maintainPolicy = this.maintainPolicy.bind(this);
  }

  maintainPolicy(policy) {
    this.props.maintainInsureds(policy);
  }

  addReplacementPolicy() {
    this.props.addReplacementPolicy();
    window.scroll(0, 0);
  }

  removeReplacementPolicy(policyId) {
    if (window.confirm('Are you sure you want to remove this policy?')) {
      // eslint-disable-line no-alert
      this.props
        .removeReplacementPolicy(policyId)
        .then(() => this.props.fetchLatestPolicyReplacementData());
    }
  }

  render() {
    const { replacementPolicies, totalMonthlyPremium } = this.props;

    return (
      <React.Fragment>
        <MyPolicy
          policies={replacementPolicies}
          maintainPolicy={this.maintainPolicy}
          removeReplacementPolicy={this.removeReplacementPolicy}
        />

        <TotalContainer>
          <PrimaryButton onClick={() => this.addReplacementPolicy()}>
            Add another policy
          </PrimaryButton>
          <div>
            <strong>
              Total premium: {toCurrencyNoDecimal(totalMonthlyPremium)}
            </strong>
          </div>
        </TotalContainer>
      </React.Fragment>
    );
  }
}

export default ListReplacementPolicies;

import { Map } from 'immutable';
import { SAVE_ACTIVE_USERS } from '../actions/types';
import createActionHandler from './createActionHandler';

const initialState = Map({
  activeUsers: 0,
});

const actionHandlers = {};

const saveActiveUsers = (state, action) => {
  return state.set('activeUsers', action.payload.activeUsers);
};

actionHandlers[SAVE_ACTIVE_USERS] = saveActiveUsers;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

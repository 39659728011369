import { connect } from 'react-redux';
import * as R from 'ramda';
import PeepsPage from './Peeps';
import getSelectedProductIds from './getSelectedProductIds';
import { Set, List } from 'immutable';
import { RELATIONSHIP_TYPES } from '../../.shared/validators/validateNomination';
import {
  selectInsured,
  selectBeneficiary,
  deselectBeneficiary,
  addPeep,
} from '../../actions/peeps';
import getSelectedPackageDetail from '../common/getSelectedPackageDetail';
import { validateRequiredDateOfBirth } from '../../.shared/validators';
import { CHILD_MAX_AGE, CHILD_MIN_AGE } from './Insureds';

const RELATIONSHIPS = R.concat(RELATIONSHIP_TYPES, ['Other']);

const mergeProductConfig = R.curry((product) =>
  R.merge(product, {
    max: product.maxBeneficiaries,
    relationships: RELATIONSHIPS,
    ageRanges: [],
    description: product.extraBeneficiaryDescription,
    addPeepButtonText: product.addBeneficiaryText,
  })
);

const getDescription = (state) =>
  getSelectedPackageDetail(state).get('beneficiaryPageDescription');

const getSelectedProductConfig = (state) =>
  R.map(
    (productId) => state.getIn(['products', productId]).toJS(),
    getSelectedProductIds(state)
  );

const getBeneficiaryConfig = R.pipe(
  R.filter((p) => p.canAddBeneficiaries || p.extraBeneficiaryDescription),
  R.map(mergeProductConfig),
  R.sortBy(R.prop('peepsOrder'))
);

const buildBeneficiarySelection = (allPeeps, selectedPeeps, canAddMore) =>
  new Set(allPeeps)
    .map((p) =>
      p.set('isSelected', selectedPeeps.includes(p)).set('canAdd', canAddMore)
    )
    .toJS();

const getProductPeeps = (
  allPeeps,
  beneficiaryProductConfig,
  selectedBeneficiaries
) =>
  R.map((p) => {
    const selectedPeeps = selectedBeneficiaries.get(p.productId) || new List();
    const canAddMore = selectedPeeps.size < p.max;
    const peeps = p.canAddBeneficiaries
      ? buildBeneficiarySelection(allPeeps, selectedPeeps, canAddMore)
      : [];
    return R.merge(p, {
      peeps,
      canAdd: canAddMore,
      type: 'beneficiary',
    });
  }, beneficiaryProductConfig);

const isChildOver21 = (peep) => {
  const dob = peep.dateOfBirth;
  const isAboveMaxAge = !validateRequiredDateOfBirth(
    dob,
    CHILD_MAX_AGE,
    CHILD_MIN_AGE
  ).isValid;
  return peep.relationship === 'Child' ? isAboveMaxAge : false;
};

export const mapBeneficiaryStateToProps = (state, ownProps) => {
  const selectedProductConfig = getSelectedProductConfig(state);
  const beneficiaryProductConfig = getBeneficiaryConfig(selectedProductConfig);
  const selectedBeneficiaries = state.getIn(['peeps', 'beneficiaries']);
  const allPeeps = state.getIn(['peeps', 'peeps']);
  return {
    productPeeps: getProductPeeps(
      allPeeps,
      beneficiaryProductConfig,
      selectedBeneficiaries
    ),
    heading: 'Beneficiary Details',
    description: ownProps.description || getDescription(state),
    state,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  select: (productId) => (peep) => dispatch(selectBeneficiary(productId, peep)),
  deselect: (productId) => (peep) =>
    dispatch(deselectBeneficiary(productId, peep)),
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { state } = stateProps;
  const { dispatch } = dispatchProps;
  const selectedProductConfig = getSelectedProductConfig(state);
  const funeralProduct = R.head(
    selectedProductConfig.filter((p) => p.isFuneralProduct)
  );
  const funeralInsuredProductId =
    !R.isNil(funeralProduct) && !R.isEmpty(funeralProduct)
      ? funeralProduct.productId
      : '';
  const funeralBeneficiaries = state.getIn([
    'peeps',
    'beneficiaries',
    funeralInsuredProductId,
  ]);
  const hasFuneralBeneficiaries = funeralBeneficiaries
    ? R.not(R.isEmpty(funeralBeneficiaries))
    : false;
  return Object.assign(
    {
      selectedProductConfig,
      addPeep: (productId) => (peep) => {
        dispatch(addPeep(peep));
        dispatch(selectBeneficiary(productId, peep));
        const notSingleLifeFuneral = funeralProduct ? funeralProduct.baseProductId !== "sim-single-fun" : true;
        if (funeralInsuredProductId && !hasFuneralBeneficiaries) {
          dispatch(selectBeneficiary(funeralInsuredProductId, peep));
        }
        if (
          peep.relationship !== 'Other' &&
          !R.isEmpty(funeralInsuredProductId) &&
          !isChildOver21(peep) &&
          notSingleLifeFuneral
        ) {
          dispatch(selectInsured(funeralInsuredProductId, peep));
        }
      },
      warnIfUnder18: true,
    },
    stateProps,
    dispatchProps,
    ownProps
  );
};

export default connect(
  mapBeneficiaryStateToProps,
  mapDispatchToProps,
  mergeProps
)(PeepsPage);

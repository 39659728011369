import {
  REQUEST_MORE,
  REQUEST_MORE_SUCCESS,
  REQUEST_MORE_FAILURE,
} from '../actions/types';
import { fromJS } from 'immutable';
import * as R from 'ramda';
import createActionHandler from './createActionHandler';

const initialState = fromJS({
  alreadyRequested: false,
  isSaving: false,
});

const success = state =>
  state.merge({
    alreadyRequested: true,
    isSaving: false,
  });

const failure = state =>
  state.merge({
    alreadyRequested: false,
    isSaving: false,
  });

const actionHandlers = {};

const setLoading = R.curry((isSaving, state) =>
  state.set('isSaving', isSaving)
);

actionHandlers[REQUEST_MORE] = setLoading(true);
actionHandlers[REQUEST_MORE_SUCCESS] = success;
actionHandlers[REQUEST_MORE_FAILURE] = failure;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

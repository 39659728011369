import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { QuestionPageQuestions } from '../questionnaire/QuestionPage';
import { stateToQuestionnaireProps } from '../questionnaire/toQuestionPageProps';
import { validateQuestionAnswer } from '../../actions/answers';
import getPackageIdFromSlug from '../../common/getPackageIdFromSlug';
import { getIsEntityValid, getEntity } from '../../stateStore/entityForm';

class EntityForm extends PureComponent {
  constructor() {
    super();

    this.state = { currentValidation: null };
  }

  updateQuestionnaierValidation() {
    const { isValid } = this.props;
    const { currentValidation } = this.state;
    if (currentValidation !== isValid) {
      this.setState({ currentValidation: isValid });
    }
  }

  componentWillMount() {
    this.updateQuestionnaierValidation();

    //console.log('xxx', this.props.pageQuestions);
  }

  componentDidUpdate() {
    this.updateQuestionnaierValidation();
  }

  debugInfoPanel() {
    return (
      <pre>
        {this.props.isEntityValid(this.props.entityType).toString()}
        {'\n'}-----{'\n'}
        {JSON.stringify(
          this.props.entity(this.props.entityType).toJS(),
          null,
          3
        )}
      </pre>
    );
  }

  render() {
    const { answerQuestionDispatch } = this.props;
    return (
      <React.Fragment>
        {this.props.showDebugInfo && this.debugInfoPanel()}

        <QuestionPageQuestions
          questions={this.props.questions}
          answers={this.props.answers}
          pageQuestions={this.props.pageQuestions}
          optionalPageQuestions={this.props.optionalPageQuestions}
          answerQuestion={answerQuestionDispatch}
        />
      </React.Fragment>
    );
  }
}

const getQuestionConfig = (state, questionPageId, questionType) =>
  state.getIn(['questionPages', questionPageId, questionType]);

const mapStateToProps = (state, ownProps) => {
  const entityType = ownProps.entityType;

  const pageQuestions = getQuestionConfig(state, entityType, 'questions');

  const optionalPageQuestions = getQuestionConfig(
    state,
    entityType,
    'optionalQuestions'
  );

  const { walkedPage, isValid, questions, answers } = stateToQuestionnaireProps(
    state,
    pageQuestions,
    optionalPageQuestions
  );

  const selectedPackage = getPackageIdFromSlug(
    state,
    state.get('selectedPackageSlug')
  );

  return {
    entityType,
    isEntityValid: getIsEntityValid(state),
    entity: getEntity(state),
    walkedPage,
    isValid,
    questions,
    answers,
    pageQuestions,
    optionalPageQuestions,
    selectedPackage,
  };
};

export const answerQuestionInternal = R.curry(
  (
    dispatch,
    packageId,
    entityType,
    pageQuestions,
    optionalPageQuestions,
    questionId,
    answer
  ) => {
    dispatch(
      validateQuestionAnswer(
        packageId,
        entityType,
        pageQuestions,
        optionalPageQuestions,
        questionId,
        answer
      )
    );
  }
);

export const mapDispatchToProps = (dispatch) => ({
  answerQuestion: answerQuestionInternal(dispatch),
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, dispatchProps, {
    answerQuestionDispatch: dispatchProps.answerQuestion(
      stateProps.selectedPackage,
      ownProps.entityType,
      stateProps.pageQuestions,
      stateProps.optionalPageQuestions
    ),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EntityForm);

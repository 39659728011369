import { fromJS } from 'immutable';
import {
  COMPLETE_SALE,
  COMPLETE_SALE_SUCCESS,
  COMPLETE_SALE_VALIDATION_FAILURE,
  COMPLETE_SALE_FAILURE,
  COMPLETE_SALE_BUSY,
  SET_SALE_COMPLETE,
  RESET,
  SEND_SALE_TO_QA,
} from '../actions/types';
import createActionHandler from './createActionHandler';
import * as R from 'ramda';

const initialState = fromJS({
  isSaving: false,
  isSubmitted: false,
  customerHasCompletedSale: false,
});

const failure = (state, action) =>
  state.merge({
    isSaving: false,
    isSubmitted: false,
    error: action.payload,
  });

const actionHandlers = {};

const setLoading = R.curry((isSaving, state) =>
  state.set('isSaving', isSaving)
);

const setSubmitted = R.curry((isSubmitted, state) =>
  state.set('isSubmitted', isSubmitted)
);

const saleComplete = state => state.set('customerHasCompletedSale', true);

const toInitialState = () => initialState;

actionHandlers[RESET] = toInitialState;
actionHandlers[COMPLETE_SALE] = setLoading(true);
actionHandlers[SEND_SALE_TO_QA] = setLoading(true);
actionHandlers[COMPLETE_SALE_SUCCESS] = setLoading(false);
actionHandlers[COMPLETE_SALE_VALIDATION_FAILURE] = failure;
actionHandlers[COMPLETE_SALE_FAILURE] = failure;
actionHandlers[COMPLETE_SALE_BUSY] = setSubmitted(true);
actionHandlers[SET_SALE_COMPLETE] = saleComplete;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

import { Map } from 'immutable';
import { SAVE_LOCAL_STORAGE_STATUS } from '../actions/types';
import createActionHandler from './createActionHandler';

const initialState = Map({
  enableLocalStorage: false,
});

const actionHandlers = {};

const saveLocalStorageStatus = (state, action) => {
  return state.set('enableLocalStorage', action.payload);
};

actionHandlers[SAVE_LOCAL_STORAGE_STATUS] = saveLocalStorageStatus;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  signupAsReferrer,
  toggleTermsAndConditions,
  validateAndAnswer as answerQuestion,
  answerPaymentOption,
} from '../../actions/referralSignup';
import { generateInputField, toTermsInput } from '../common/inputHelpers';
import PageActionButtons from '../common/PageActionButtons';
import * as R from 'ramda';
import buildQuestion from '../questions/questionBuilder';
import Immutable, { fromJS } from 'immutable';
import { ROUTES } from '../../config/constants';
import { Link } from 'react-router-dom';
import { QuestionPageQuestions } from '../questionnaire/QuestionPage';
import { stateToQuestionnaireProps } from '../questionnaire/toQuestionPageProps';
import {
  Form,
  FieldContainer,
  WallOfTextContainer,
} from '@simply-fin-services/astronomix3';

const isImmutable = (ob) => ob instanceof Immutable.Map;

class NewSignup extends PureComponent {
  render() {
    const {
      submit,
      hasAcceptedTerms,
      toggleTerms,
      error,
      referralRewardAllocation,
      canSubmitAnswers,
      referrerSignupPage,
      answerQuestionField,
      buildQuestionField,
      questionPageProps,
    } = this.props;
    const { canSubmit: canSubmitRewardAllocation } =
      referralRewardAllocation.toJS();
    const canSubmit =
      hasAcceptedTerms && canSubmitRewardAllocation && canSubmitAnswers;

    const signUpQuestions = referrerSignupPage.get('questions').toJS();
    const TnCsLink = (
      <React.Fragment>
        I am 18+ and accept the Simply referral programme
        <Link to={ROUTES.REFERRAL_TERMS}> T&Cs </Link>
      </React.Fragment>
    );

    return (
      <WallOfTextContainer>
        <h4 className="text-center">Your Details</h4>
        <div className="space-below">
          <Form>
            {signUpQuestions.map((qId) => (
              <FieldContainer fieldClass="no-max-width no-padding">
                {buildQuestionField(
                  answerQuestionField('referralSignup'),
                  false,
                  qId
                )}
              </FieldContainer>
            ))}
          </Form>
        </div>
        <h4 className="text-center"> Programme Details</h4>
        <p>
          <h5>Why should I refer Simply?</h5>
          Simply policies are simple, affordable and secure - and can be bought
          online in 5 minutes. You’d probably share with your friends anyway.
          This way, if your friends sign up, they get a 5% premium discount and
          you get cash for yourself or a charity of your choice. It’s a win-win.
        </p>
        <p>
          <h5>Is there a catch? </h5>
          No! What you see is what you get. We’re just trying to make it easy
          and worthwhile for you to share with friends. All it takes to join is
          a few details.
        </p>
        <p>
          <h5>Are you going to sell my phone number or SPAM me?</h5>
          No! Your details are yours and yours alone. We won’t use them for
          anything other than SMSing you when one of your friends buys, or when
          we make a referral payout. You can see
          <a href="https://www.simply.co.za/privacy-policy">
            {' '}
            <span> &nbsp; Our Privacy Policy </span>{' '}
          </a>
          <span> here and opt out any time. </span>
        </p>
        <br />
        <h4 className="text-center space-below">Payment Details</h4>
        <QuestionPageQuestions
          fieldContainerClass="col-xs-12 inner-space-around-none"
          fieldClass="no-max-width no-padding"
          className="space-below"
          {...questionPageProps}
        />
        <h4 className="text-center space-below">Important Rules</h4>
        <ul>
          <li>You must be 18+ years old</li>
          <li>You may not give anyone advice - the choice is up to them</li>
          <li>You may not refer yourself.</li>
          <li>You must know the people that you are referring</li>
          <li>You may not buy a policy on behalf of someone else</li>
          <li>
            The person you are referring must be over 18 years old and earning
            an income
          </li>
        </ul>

        <div className="space-below">
          {toTermsInput(TnCsLink, hasAcceptedTerms, toggleTerms)}
        </div>
        {error && <div>{error}</div>}
        <PageActionButtons
          disabled={!canSubmit}
          onClick={submit}
          text="Sign up"
        />
      </WallOfTextContainer>
    );
  }
}

NewSignup.propTypes = {
  submit: PropTypes.func.isRequired,
  hasAcceptedTerms: PropTypes.bool.isRequired,
  toggleTerms: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export const mapStateToProps = (state) => {
  const answers = {
    referrerName: state.getIn(['referralSignup', 'referrerName']),
    referrerEmail: state.getIn(['referralSignup', 'referrerEmail']),
    referrerCellphoneNumber: state.getIn([
      'referralSignup',
      'referrerCellphoneNumber',
    ]),
  };
  const validation = R.map((a) => {
    const answer = isImmutable(a) ? a.toJS() : a;
    const isValidated = !R.isNil(answer.isValid);
    const isEmpty = R.isEmpty(answer);
    const validatedAnswer = isValidated ? answer.isValid : answer.optional;
    return isEmpty ? false : validatedAnswer;
  }, answers);

  const isValid = (e) => e === true;
  const canSubmitAnswers = R.reduceWhile(
    isValid,
    (prev, curr) => curr,
    true,
    R.values(validation)
  );
  const pageQuestions = fromJS(['referral-payment-option-reflexive']);
  const optionalPageQuestions = fromJS([]);
  const {
    walkedPage,
    isValid: isQuestionPageValid,
    questions,
  } = stateToQuestionnaireProps(state, pageQuestions, optionalPageQuestions);
  const eligibilityResult = null;
  const paymentOptionAnswers = state.getIn([
    'referralSignup',
    'paymentOptionAnswers',
  ]);
  const questionPageProps = {
    walkedPage,
    isValid: isQuestionPageValid,
    questions,
    answers: paymentOptionAnswers,
    pageQuestions,
    optionalPageQuestions,
    eligibilityResult,
  };
  return {
    answers,
    canSubmitAnswers,
    hasAcceptedTerms: state.getIn(['referralSignup', 'hasAcceptedTerms']),
    error: state.getIn(['referralSignup', 'error']),
    referralRewardAllocation: state.getIn([
      'referralSignup',
      'referralRewardAllocation',
    ]),
    referrerSignupPage: state.getIn(['questionPages', 'referrer-sign-up']),
    questions: state.get('questions'),
    questionPageProps,
  };
};
const paymentRecepientQuestions = ['referral-charity-option'];

export const mapDispatchToProps = (dispatch) => ({
  generateInput: generateInputField(dispatch),
  submit: (f, c, e, cn, d, mu) => () =>
    dispatch(signupAsReferrer(f, c, e, cn, d, mu)),
  toggleTerms: (t) => () => dispatch(toggleTermsAndConditions(!t)),
  answerQuestionField: R.curry((questionPage, questions, page, field) =>
    answerQuestion(questionPage, questions, dispatch, field, page)
  ),
  answerQuestionPageQuestion: (questionId) => (answer) => {
    const isPaymentRecepientQuestion = R.includes(
      questionId,
      paymentRecepientQuestions
    );
    dispatch(
      answerPaymentOption(questionId, answer, isPaymentRecepientQuestion)
    );
  },
});

export const mergeProps = (stateProps, dispatchProps) =>
  Object.assign({}, stateProps, dispatchProps, {
    submit: dispatchProps.submit(
      R.path(['referrerName', 'answer'], stateProps.answers),
      R.path(['referrerCellphoneNumber', 'answer'], stateProps.answers),
      R.path(['referrerEmail', 'answer'], stateProps.answers),
      stateProps.referralRewardAllocation
    ),
    toggleTerms: dispatchProps.toggleTerms(stateProps.hasAcceptedTerms),
    answerQuestionField: dispatchProps.answerQuestionField(
      stateProps.referrerSignupPage,
      stateProps.questions
    ),
    buildQuestionField: buildQuestion(
      stateProps.questions,
      stateProps.referrerSignupPage.get('optionalQuestions'),
      fromJS(stateProps.answers)
    ),
    questionPageProps: R.assoc(
      'answerQuestion',
      dispatchProps.answerQuestionPageQuestion,
      stateProps.questionPageProps
    ),
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NewSignup);

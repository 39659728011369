import * as types from './types';
import request from 'axios';
import { API_ROUTES } from '../config/constants';

const postRequestToScreenForRisk = (state) => (dispatch) => {
  const answers = state.get('answers');
  return request
    .post(API_ROUTES.REQUEST_TO_SCREEN_FOR_RISK, {
      name: answers.getIn(['owner-first-name', 'answer']),
      surname: answers.getIn(['owner-surname', 'answer']),
      dateOfBirth: answers.getIn(['owner-date-of-birth', 'answer']),
      idNumber: answers.getIn(['owner-id-number', 'answer']),
      passportNumber: answers.getIn(['owner-passport-number', 'answer']),
      role: 'Owner',
    })
    .then((response) => {
      dispatch({
        type: types.REQUEST_TO_SCREEN_FOR_RISK_SUCCESSFULLY_SENT,
        payload: response.body,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.REQUEST_TO_SCREEN_FOR_RISK_FAILED,
        payload: error.response.data,
      });
    });
};

export const requestToScreenForAcceptableRisk = (state) => ({
  type: types.REQUEST_TO_SCREEN_FOR_RISK,
  payload: postRequestToScreenForRisk(state),
});

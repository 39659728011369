/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FullWidthButtonWrapper from './FullWidthButtonWrapper';
import ButtonText from './ButtonText';
import LoadingRow from './LoadingRow';
import { connect } from 'react-redux';
import { requestForMore } from '../../actions/IwantMore';

// hiding this component for now

class IWantMoreComponent extends PureComponent {
  render() {
    const { requestMoreCover, alreadyRequested, isSaving } = this.props;
    return <span />;
  }
}

IWantMoreComponent.propTypes = {
  requestMoreCover: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  requestMoreCover: () => {
    dispatch(requestForMore());
  },
});

const mapStateToProps = state => ({
  alreadyRequested: state.getIn(['moreCover', 'alreadyRequested']),
  isSaving: state.getIn(['moreCover', 'isSaving']),
});

const IWantMore = connect(
  mapStateToProps,
  mapDispatchToProps
)(IWantMoreComponent);

export default IWantMore;

// <div className="text-center m-t-25">
//         {
//           !alreadyRequested && !isSaving &&
//             <FullWidthButtonWrapper>
//               <button
//                 type="button" onClick={requestMoreCover}
//                 className="btn btn-alternate text-center"
//               >I want more cover</button>
//             </FullWidthButtonWrapper>
//         }
//         {
//           !alreadyRequested && isSaving &&
//             <LoadingRow />
//         }
//         {
//           alreadyRequested &&
//             <ButtonText text="We'll get back to you with more cover options" />
//         }
//       </div>

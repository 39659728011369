"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.shouldSuggestPackage = exports.eligibilitySummary = void 0;

var R = _interopRequireWildcard(require("ramda"));

var _immutable = require("immutable");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var allAnswerQuestionsEligibleReducer = R.curry(function (answers, reduction, questionId) {
  return reduction && answers.getIn([questionId, 'eligibility', 'isEligible']) === true;
});

var validateQuestionPageEligibility = function validateQuestionPageEligibility(walkedPage, answers) {
  var allQuestionsEligible = walkedPage.get('questionsAnswered').reduce(allAnswerQuestionsEligibleReducer(answers), true);
  return allQuestionsEligible;
};

var eligibilitySummaryReducer = R.curry(function (answers, reduction_, questionId) {
  var reduction = reduction_ || {
    isEligible: true,
    declinedProducts: [],
    exclusions: []
  };
  var eligibility = answers.getIn([questionId, 'eligibility']);

  if (eligibility) {
    var products = eligibility.get('declinedProducts');
    var exclusionsToApply = eligibility.get('exclusions');
    var isEligible = reduction.isEligible && eligibility.get('isEligible') === true;
    var declinedProducts = products.size > 0 ? R.uniq(R.concat(products.toJS(), reduction.declinedProducts)) : reduction.declinedProducts;
    var exclusions = exclusionsToApply.size > 0 ? R.concat(exclusionsToApply.toJS(), reduction.exclusions) : reduction.exclusions;
    return {
      isEligible: isEligible,
      declinedProducts: declinedProducts,
      exclusions: exclusions
    };
  }

  return reduction;
});

var eligibilitySummary = function eligibilitySummary(walkedPage, answers) {
  return walkedPage.get('questionsAnswered').reduce(eligibilitySummaryReducer(answers), null);
};

exports.eligibilitySummary = eligibilitySummary;

var shouldSuggestPackage = function shouldSuggestPackage(walkedPage, answers, selectedPackage, questionPageId) {
  if (!selectedPackage) {
    return false;
  }

  if (!selectedPackage.get('questionnairePagesThatSuggestPackage').contains(questionPageId)) {
    return false;
  }

  var questionPreventingSuggestion = selectedPackage.get('questionPreventingPackageSuggestion');
  var suggestableQuestions = walkedPage.update('questionsAnswered', function (l) {
    return new _immutable.Set(l).subtract(new _immutable.Set(questionPreventingSuggestion)).toList();
  });
  var notSuggestableQuestions = walkedPage.update('questionsAnswered', function (l) {
    return new _immutable.Set(l).intersect(new _immutable.Set(questionPreventingSuggestion)).toList();
  });
  var areSuggestableQuestionsNotEligible = !validateQuestionPageEligibility(suggestableQuestions, answers);
  var areNotSuggestableQuestionsEligible = validateQuestionPageEligibility(notSuggestableQuestions, answers);
  return areSuggestableQuestionsNotEligible && areNotSuggestableQuestionsEligible;
};

exports.shouldSuggestPackage = shouldSuggestPackage;
var _default = validateQuestionPageEligibility;
exports["default"] = _default;
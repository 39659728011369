"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.walkQuestionPageWithOptionals = exports["default"] = void 0;

var _immutable = require("immutable");

var R = _interopRequireWildcard(require("ramda"));

var _isReflexive = require("../isReflexive");

var _index = require("../index");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var walkableItem = (0, _immutable.fromJS)({
  allNodesAnswered: true,
  questionsAnswered: []
});

var concatWalkedQuestions = function concatWalkedQuestions(walkable, questions) {
  var questionsAnswered = walkable.get('questionsAnswered').concat(questions);
  return walkable.set('questionsAnswered', questionsAnswered);
};

var isReflexive = function isReflexive(questionNodes, questionId) {
  return questionNodes.has(questionId);
};

var isReflexiveCheckbox = function isReflexiveCheckbox(questions, questionId) {
  return questions.has(questionId) && (0, _isReflexive.isReflexiveCheckboxes)(questions.get(questionId));
};

var isQuestionAnswered = function isQuestionAnswered(questionId, answers) {
  return answers.has(questionId) && !R.isEmpty(answers.getIn([questionId, 'answer']));
};

var getActualQuestionId = function getActualQuestionId(questionNodes, questionId) {
  var actualId = isReflexive(questionNodes, questionId) ? questionNodes.getIn([questionId, 'questionId']) : questionId;
  return actualId;
};

var getAnswer = function getAnswer(answers, actualQuestionId) {
  return answers.getIn([actualQuestionId, 'answer']);
};

var reduceWalkables = function reduceWalkables(reduction, walkable) {
  var reduced = concatWalkedQuestions(reduction, walkable.get('questionsAnswered'));

  if (walkable.get('allNodesAnswered') === false) {
    return reduced.set('allNodesAnswered', false);
  }

  return reduced;
};

var isSearchableSelection = function isSearchableSelection(questionNodes, questionId) {
  return questionNodes.has(questionId) && (questionNodes.get(questionId).get('type') === 'searchable-selection' || questionNodes.get(questionId).get('type') === 'searchable-single-selection');
};

var walkQuestion = function walkQuestion(walkable, questions, questionNodes, questionId, answers) {
  var actualQuestionId = getActualQuestionId(questionNodes, questionId);

  if (!isQuestionAnswered(actualQuestionId, answers)) {
    return walkable.set('allNodesAnswered', false);
  }

  var withWalkedQuestion = concatWalkedQuestions(walkable, (0, _immutable.fromJS)([actualQuestionId]));

  if (isReflexive(questionNodes, questionId) && !isSearchableSelection(questions, actualQuestionId)) {
    var answer = getAnswer(answers, actualQuestionId);
    var childKey = [questionId, 'children', answer];

    if (questionNodes.hasIn(childKey)) {
      var questionIdsToWalk = questionNodes.getIn(childKey);
      return questionIdsToWalk.map(function (qid) {
        return walkQuestion(walkableItem, questions, questionNodes, qid, answers);
      }).reduce(reduceWalkables, withWalkedQuestion);
    }
  } else if (isSearchableSelection(questions, actualQuestionId)) {
    var checkedAnswers = (0, _immutable.fromJS)(getAnswer(answers, actualQuestionId).split(_index.CHECKBOX_QUESTION_ANSWER_SPLITTER));
    var nextQuestionIds = checkedAnswers.map(function (a) {
      var childKey = [questionId, 'children', a];
      return questionNodes.hasIn(childKey) ? questionNodes.getIn(childKey) : null;
    }).filter(function (a) {
      return a !== null;
    }).flatten();
    return nextQuestionIds.map(function (qid) {
      return walkQuestion(walkableItem, questions, questionNodes, qid, answers);
    }).reduce(reduceWalkables, withWalkedQuestion);
  } else if (isReflexiveCheckbox(questions, questionId)) {
    var question = questions.get(questionId);

    var _checkedAnswers = (0, _immutable.fromJS)(getAnswer(answers, actualQuestionId).split(_index.CHECKBOX_QUESTION_ANSWER_SPLITTER));

    var _nextQuestionIds = _checkedAnswers.map(function (a) {
      return question.getIn(['possibleAnswers', a]);
    }).filter(function (a) {
      return a !== null;
    });

    return _nextQuestionIds.map(function (qid) {
      return walkQuestion(walkableItem, questions, questionNodes, qid, answers);
    }).reduce(reduceWalkables, withWalkedQuestion);
  }

  return withWalkedQuestion;
};

var walkQuestionPage = R.curry(function (questions, questionNodes, pageQuestions, answers) {
  return pageQuestions.map(function (qid) {
    return walkQuestion(walkableItem, questions, questionNodes, qid, answers);
  }).reduce(reduceWalkables, walkableItem);
});
var _default = walkQuestionPage;
exports["default"] = _default;
var walkQuestionPageWithOptionals = R.curry(function (questions, questionNodes, pageQuestions, pageOptionalQuestions, answers) {
  var optionals = pageOptionalQuestions || (0, _immutable.fromJS)([]);
  var pageQuestionsToRemove = optionals.filter(function (questionId) {
    return !answers.has(questionId) || R.isNil(answers.getIn([questionId, 'answer'])) || answers.getIn([questionId, 'answer']) === '';
  });
  var answeredPageQuestions = pageQuestions.filter(function (questionId) {
    return !pageQuestionsToRemove.includes(questionId);
  });
  return walkQuestionPage(questions, questionNodes, answeredPageQuestions, answers);
});
exports.walkQuestionPageWithOptionals = walkQuestionPageWithOptionals;
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IPropTypes from 'react-immutable-proptypes';
import { QuestionPageQuestionsBase } from '../questionnaire/QuestionPage';
import { PrimaryButton, Modal } from '@simply-fin-services/astronomix3';
import styled from 'styled-components';
import { COMPLIANCE_MESSAGES } from '../../config/constants';

const StyledForm = styled.form`
  .a-field {
    min-width: 400px !important;
  }
  .button-container {
    text-align: center;
  }
`;

const P = styled.p`
  margin-bottom: 5px !important;
  text-align: center;
`;
class SendMyLink extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      linkSent: false,
      modal: false,
    };
    this.toggleLinkSent = this.toggleLinkSent.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleLinkSent() {
    this.setState({ linkSent: !this.state.linkSent });
  }

  toggleModal(e) {
    e.preventDefault();
    this.setState({ modal: !this.state.modal });
  }

  render() {
    const { pageQuestions, sendLink, rehydrationLink, isBrokerSale } =
      this.props;
    const linkSent = this.state.linkSent;
    if (pageQuestions.isEmpty()) {
      return <div />;
    }
    const sendButtonAction = () => sendLink(this.toggleLinkSent);
    if (rehydrationLink && !isBrokerSale) {
      return (
        <div className="row">
          <Modal show={this.state.modal} handleClose={this.toggleModal}>
            <h2>Save your progress</h2>
            <p>
              If you can't finish your application now, we can email you your
              quote and a link so you can easily continue when it's more
              convenient.
            </p>
            <div>
              {!linkSent && (
                <StyledForm>
                  <QuestionPageQuestionsBase {...this.props} />
                  <div className="button-container">
                    <PrimaryButton onClick={sendButtonAction}>
                      Send quote
                    </PrimaryButton>
                    <p className="no-share space-above">
                      {COMPLIANCE_MESSAGES.CELLPHONE_NUMBER_MESSAGE}
                    </p>
                    <br />
                    <div className="cancel" onClick={this.toggleModal}>
                      Cancel
                    </div>
                  </div>
                </StyledForm>
              )}
              {linkSent && (
                <P className="no-share">Your link has been sent to you!</P>
              )}
            </div>
          </Modal>
          <div>
            <P lassName="text-center small">
              Can't finish now?
              <br />
              <a href="#" onClick={this.toggleModal}>
                Save my session
              </a>
            </P>
            <br />
          </div>
        </div>
      );
    }
    return <div />;
  }
}

SendMyLink.protoTypes = {
  description: PropTypes.string.isRequired,
  pageQuestions: IPropTypes.listOf(PropTypes.string).isRequired,
};

export default SendMyLink;

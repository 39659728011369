/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingRow from '../common/LoadingRow';
import { getPackageSlugFromId } from '../../common/getPackageIdFromSlug';
import { rehydrateInteraction } from '../../actions/rehydration';
import { linkThenAddCookie, linktoCampaign } from '../../actions/campaign';
import toQueryParams from '../../common/propsToLocationQueryParams';
import * as R from 'ramda';

class WelcomeBackPage extends PureComponent {
  componentDidMount() {
    this.props.landed(this.props.packageUrlSlug);
  }

  render() {
    return <LoadingRow />;
  }
}

WelcomeBackPage.propTypes = {
  landed: PropTypes.func.isRequired,
};

export const mapStateToProps = (state, ownProps) => {
  const { packageId, interactionId } = toQueryParams(ownProps);
  const packageUrlSlug = getPackageSlugFromId(state, packageId);

  return {
    questionPages: state.get('questionPages').toJS(),
    packageUrlSlug,
    questions: state.get('questions').toJS(),
    interactionId,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  const { senderReference, interactionId, packageId, campaign } = toQueryParams(
    ownProps
  );
  return {
    landed: packageUrlSlug => {
      if (senderReference) {
        dispatch(
          linkThenAddCookie(
            R.defaultTo('SavedSession', campaign),
            senderReference
          )
        );
      }
      dispatch(linktoCampaign());
      return dispatch(
        rehydrateInteraction({ packageUrlSlug, packageId }, interactionId)
      );
    },
  };
};

const WelcomeBackPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomeBackPage);

export default WelcomeBackPageContainer;

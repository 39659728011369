import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { QUESTION_TIMEOUT_DELAY_IN_MILLISECONDS } from '../../config/appConfig';
import { InputText } from '@simply-fin-services/astronomix3';
import QuestionContainer, {
  isNotEligible,
  showError,
} from './QuestionContainer';
import * as R from 'ramda';

const INITIAL_ANSWER = '';

const replaceAll = (value, search, replacement) =>
  value.replace(new RegExp(search, 'g'), replacement);

class InputQuestion extends PureComponent {
  constructor() {
    super();
    this.submitQuestion = this.submitQuestion.bind(this);
    this.onChange = this.onChange.bind(this);
    this.startSubmitTimer = this.startSubmitTimer.bind(this);
    this.clearSubmitTimer = this.clearSubmitTimer.bind(this);
    this.typingTimeout = null;
    this.submittedValue = INITIAL_ANSWER;
  }

  componentWillMount() {
    this.state = { value: this.props.answer || INITIAL_ANSWER };
  }

  submitQuestion() {
    this.clearSubmitTimer();
    const { value } = this.state;
    if (value !== this.submittedValue) {
      const { answerQuestion } = this.props;
      answerQuestion(value);
    }
    this.submittedValue = value;
  }

  onChange(e) {
    let value = e.target.value;
    if (value.length < this.state.value.length) {
      this.setState({
        value,
      });
      return;
    }
    if (value.length === 2 || value.length === 5) {
      value += '/';
    }
    this.setState({
      value: replaceAll(value, '//', '/'),
    });
  }

  startSubmitTimer() {
    this.clearSubmitTimer();
    this.typingTimeout = window.setTimeout(() => {
      this.submitQuestion();
    }, 3 * QUESTION_TIMEOUT_DELAY_IN_MILLISECONDS);
  }

  clearSubmitTimer() {
    window.clearTimeout(this.typingTimeout);
  }

  render() {
    const {
      disable,
      icon,
      eligibility,
      errors,
      hasWarning,
      warnings,
      packageId,
      hint,
      title,
    } = this.props;

    const { value } = this.state;
    const disabled = disable || false;

    const eligibilityErrors =
      isNotEligible(eligibility, packageId) && eligibility.failureMessage
        ? [eligibility.failureMessage]
        : [];
    const errorMessages = showError(this.props) ? errors || [] : [];
    const warningMessages = hasWarning ? warnings : [];
    const allErrors = R.uniq(R.concat(
      R.concat(errorMessages, warningMessages),
      eligibilityErrors
    ));

    return (
      <QuestionContainer>
        <InputText
          type="text"
          label={title}
          placeholder="DD/MM/YYYY"
          errors={allErrors}
          onBlur={this.submitQuestion}
          onChange={this.onChange}
          onKeyUp={this.startSubmitTimer}
          onKeyDown={this.clearSubmitTimer}
          value={value}
          disabled={disabled}
          icon={icon}
          hint={hint}
        />
      </QuestionContainer>
    );
  }
}

InputQuestion.propTypes = {
  answerQuestion: PropTypes.func,
  answer: PropTypes.string,
  initialValue: PropTypes.string,
  disable: PropTypes.bool,
};

export default InputQuestion;

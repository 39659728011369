/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { toPremium } from '../common/numberFormats';
import SummaryProducts from '../customization/SummaryProducts';
import moment from 'moment';
import {
  Buttons,
  PrimaryButton,
  WallOfTextContainer,
  Reviews,
} from '@simply-fin-services/astronomix3';
import * as R from 'ramda';
import Immutable from 'immutable';
import ThankYouCancellation from '../replaceAndSave/integration/ThankYouCancellationContainer';

const getDebitDay = (policy, dateformat = 'DD MMMM YYYY') => {
  const firstDebitOrder = moment(policy.inceptionDate).set(
    'date',
    policy.debitDay
  );

  const todayDate = moment(Date.now());
  const firstDebitOrderDate =
    todayDate.month() === firstDebitOrder.month()
      ? todayDate.get('date') < firstDebitOrder.get('date')
        ? firstDebitOrder
        : firstDebitOrder.add(1, 'months')
      : firstDebitOrder;
  return firstDebitOrderDate.format(dateformat);
};

const captureDetailsButton = (initDetailsCaptureFromPolicy, policy) => {
  const { completed, underwrite, deferredUnderwriting } = policy;
  if (completed) {
    return null;
  }
  const peepsButtonText = deferredUnderwriting
    ? 'Add People Now'
    : 'Add details now';
  const buttonText = underwrite ? 'Add details now' : peepsButtonText;
  const captureDetails = () => initDetailsCaptureFromPolicy(policy);
  return (
    <Buttons>
      <PrimaryButton onClick={captureDetails}>{buttonText}</PrimaryButton>
    </Buttons>
  );
};
const sortyOrder = (selection, config) => {
  const getOrder = (pId) => config[pId].order;
  const sortByProductConfigOrder = R.sortBy((p) =>
    getOrder(R.prop('productId', p))
  );
  return sortByProductConfigOrder(selection);
};

const hasDebitOrderPaymentType = (policy) => {
  if (policy.debitDay) {
    return true;
  }

  return false;
};

const formatSalaryDeductionMonth = (yearMonth) =>
  moment.utc(`${yearMonth}/01`, 'YYYY/MM/DD').format('MMMM YYYY');

export class PolicyInformation extends PureComponent {
  debitOrderInformation() {
    const { policy, isThirdPartyPackage } = this.props;

    if (hasDebitOrderPaymentType(policy)) {
      const { deferredUnderwriting } = policy;
      const firstDebitOrderDate = getDebitDay(policy);
      const firstDebitOrderDay = getDebitDay(policy, 'Do');

      const domesticPolicyPaymentDate = deferredUnderwriting
        ? `,
        provided that your ${
          isThirdPartyPackage ? 'family member' : 'domestic worker'
        } completes the process in time.
        Otherwise, it will move to the ${firstDebitOrderDay} of the following month`
        : '';

      return (
        <p>
          Your first Debit Order will go through on{' '}
          <strong>{firstDebitOrderDate}</strong>
          {domesticPolicyPaymentDate}. You will see the shortcode "SIMPLYSURE" on
          your bank statement.
        </p>
      );
    }
    if (policy.paymentType === 'Persal') {
      return (
        <p>
          Your first premium will be deducted from your salary in{' '}
          {formatSalaryDeductionMonth(this.props.firstSalaryDeductionCollectionMonth)}. The deduction code
          in your Persal payslip will be Simply-Hollard.
        </p>
      );
    }

    return null;
  }

  render() {
    const {
      policy,
      initDetailsCaptureFromPolicy,
      policyDocumentHome,
      ropPricing,
      requiresPeeps,
    } = this.props;
    const isImmutable = (i) => i instanceof Immutable.Map;
    const productConfig = isImmutable(this.props.productConfig)
      ? this.props.productConfig.toJS()
      : this.props.productConfig;

    const { premium, isPartialSale, firstname, withRop } = policy;

    const productSelection = sortyOrder(policy.productSelection, productConfig);
    const checkIfContainsId = (evaluation, item) =>
      R.includes('sim-funeral', item.productId);
    const isFalse = (ev) => ev === false;
    const hasFuneral = R.reduceWhile(
      isFalse,
      checkIfContainsId,
      false,
      productSelection
    );
    const { completed } = policy;
    const docLinkUrl =
      R.includes('http://', policyDocumentHome) ||
      R.includes('https://', policyDocumentHome)
        ? `${policyDocumentHome}/p/${policy.contractNumber}`
        : `https://${policyDocumentHome}/p/${policy.contractNumber}`;

    const { ropPrecentage } = ropPricing;
    const policyStartsToday = moment(new Date()).isSame(policy.inceptionDate);
    return (
      <WallOfTextContainer>
        {!policyStartsToday && (
          <p>
            Your cover will start on{' '}
            <strong>
              {moment(policy.inceptionDate).format('DD MMM YYYY')}
            </strong>
            .{' '}
          </p>
        )}
        {policyStartsToday && <p>Your cover has started. </p>}

        {isPartialSale && (
          <div>
            <h5 className="bold">Next Steps</h5>
            <p>
              We now need to contact <strong>{firstname}</strong> to confirm
              their details. If anything changes, we will contact you to see if
              you still want to take the policy. Otherwise, cover will start and
              you’ll be sent your policy documents.
            </p>
          </div>
        )}
        <h5>Policy details</h5>
        <p>
          Your policy number is
          <strong> {policy.contractNumber}</strong>
        </p>
        <p>
          Your monthly premium is <strong>{toPremium(premium)}</strong> and your
          policy includes the following benefits:
        </p>
        <SummaryProducts
          products={productSelection}
          hidePremium
          multiplyCover
          transparent
          headerLess
          hideCover
          withRop={withRop}
          ropPercentage={ropPrecentage}
          isCompleteSale
        />

        {this.debitOrderInformation()}

        {isPartialSale !== true && (
          <p>
            You can view your policy documents
            <a href={docLinkUrl} target="_blank" rel="noopener noreferrer">
              {' '}
              online{' '}
            </a>
            .
          </p>
        )}

        <ThankYouCancellation contractId={policy.contractNumber} />

        {isPartialSale !== true && !completed && requiresPeeps && (
          <div>
            <h5 className="bold">Information required</h5>
            {hasFuneral && (
              <p>
                We now need the details of your beneficiaries (who we should pay
                if there’s a claim) and your family members covered by your
                funeral benefit. If you can't provide the details now, you can
                follow the link in the SMS we've just sent and do it later.
              </p>
            )}
            {!hasFuneral && (
              <p>
                We now need the details of your beneficiaries (who we should pay
                if there’s a claim).If you can't provide the details now, you
                can follow the link in the SMS we've just sent and do it later.
              </p>
            )}
            {captureDetailsButton(initDetailsCaptureFromPolicy, policy)}
          </div>
        )}

        <Reviews alignment="left">
          <h5 className="bold">Review us</h5>
        </Reviews>
      </WallOfTextContainer>
    );
  }
}

PolicyInformation.propTypes = {
  canSignupMultiplePolicies: PropTypes.bool.isRequired,
  policy: PropTypes.shape({
    identityNumber: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    inceptionDate: PropTypes.string.isRequired,
    debitDay: PropTypes.number.isRequired,
    withRop: PropTypes.bool.isRequired,
    productSelection: PropTypes.array.isRequired,
    premium: PropTypes.number.isRequired,
    suggestedPremium: PropTypes.number.isRequired,
    suggestedRopPremium: PropTypes.number.isRequired,
    contractNumber: PropTypes.string.isRequired,
    deferredUnderwriting: PropTypes.bool,
    isPartialSale: PropTypes.bool,
    completed: PropTypes.bool.isRequired,
    underwrite: PropTypes.bool.isRequired,
    parent: PropTypes.string.isRequired,
  }).isRequired,
  sortProducts: PropTypes.func.isRequired,
  initDetailsCaptureFromPolicy: PropTypes.func.isRequired,
  productConfig: PropTypes.object,
  ropPricing: PropTypes.object,
  requiresPeeps: PropTypes.bool.isRequired,
  isDomesticPartialSale: PropTypes.bool,
  isThirdPartyPackage: PropTypes.bool,
  firstSalaryDeductionCollectionMonth: PropTypes.string,
};

export default PolicyInformation;

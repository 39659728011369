"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateSalaryDeductionPaymentDetails = exports.getSalaryDeductionContractInceptionDate = exports.extractSalaryDeductionContractDetails = void 0;

var _validators = require("../validators");

var _immutable = require("immutable");

var validateEmployeeNumber = function validateEmployeeNumber(e) {
  var v = (0, _validators.validateRequiredNumberText)(e);

  if (v.isValid) {
    if (e.length >= 6) {
      return v;
    }

    return (0, _validators.toError)(e, 'Employee number should have 8 numbers');
  }

  return v;
};

var extractSalaryDeductionContractDetails = function extractSalaryDeductionContractDetails(createContractBase) {
  return {
    EmployeeNumber: createContractBase.getIn(['options', 'bankingDetails', 'employeeNumber']),
    PaymentType: 'salary-deduction',
    PayrollIdentifier: createContractBase.getIn(['options', 'bankingDetails', 'payrollProvider'])
  };
};

exports.extractSalaryDeductionContractDetails = extractSalaryDeductionContractDetails;

var getSalaryDeductionContractInceptionDate = function getSalaryDeductionContractInceptionDate(generationDate, selectedInceptionMonth, isUpsellSale) {
  // eslint-disable-line
  return selectedInceptionMonth;
};

exports.getSalaryDeductionContractInceptionDate = getSalaryDeductionContractInceptionDate;

var validateSalaryDeductionPaymentDetails = function validateSalaryDeductionPaymentDetails(details) {
  return (0, _immutable.fromJS)({
    employeeNumber: validateEmployeeNumber(details.get('employeeNumber')),
    inceptionMonth: (0, _validators.validateRequiredISODate)(details.get('inceptionMonth'))
  });
};

exports.validateSalaryDeductionPaymentDetails = validateSalaryDeductionPaymentDetails;
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import * as R from 'ramda';
import { QuestionPageQuestions } from '../questionnaire/QuestionPage';
import { stateToQuestionnaireProps } from '../questionnaire/toQuestionPageProps';
import { answerQuestionWithRelatedProduct } from '../../actions/answers';
import getPackageIdFromSlug from '../../common/getPackageIdFromSlug';
import { setWaiveQuestionnairevalidation } from '../../actions/terms';

class WaivePeriodQuestionnaire extends PureComponent {
  constructor() {
    super();
    this.updateQuestionnaierValidation =
      this.updateQuestionnaierValidation.bind(this);
    this.state = {
      currentValidation: null,
    };
  }

  updateQuestionnaierValidation() {
    const { dispatch, isValid } = this.props;
    const { currentValidation } = this.state;
    if (currentValidation !== isValid) {
      dispatch(setWaiveQuestionnairevalidation(isValid));
      this.setState({ currentValidation: isValid });
    }
  }

  componentWillMount() {
    this.updateQuestionnaierValidation();
  }

  componentDidUpdate() {
    this.updateQuestionnaierValidation();
  }

  render() {
    const { answerQuestionDispatch, relatedProduct } = this.props;
    const answerQuestion = answerQuestionDispatch(relatedProduct);
    return (
      <QuestionPageQuestions
        className="space-below"
        {...this.props}
        sidebyside
        answerQuestion={answerQuestion}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const packageSlug = state.get('selectedPackageSlug');
  const pageQuestions = state.getIn([
    'questionPages',
    'replacement-policy-questionnaire',
    'questions',
  ]);
  const optionalPageQuestions = fromJS([]);
  const { walkedPage, isValid, questions, answers } = stateToQuestionnaireProps(
    state,
    pageQuestions,
    optionalPageQuestions
  );

  const funeralProductId = state.getIn([
    'packages',
    packageSlug,
    'funeralProduct',
  ]);

  const productSelection = state.get('productSelection');
  const relatedProduct = productSelection
    .getIn(['products', funeralProductId])
    .toJS();

  return {
    walkedPage,
    isValid,
    questions,
    answers,
    pageQuestions,
    relatedProduct,
    selectedPackage: getPackageIdFromSlug(state, packageSlug),
  };
};

export const answerQuestion = R.curry(
  (dispatch, packageId, relatedProduct, questionId, answer) =>
    dispatch(
      answerQuestionWithRelatedProduct(
        packageId,
        relatedProduct,
        questionId,
        answer
      )
    )
);

export const mapDispatchToProps = (dispatch) => ({
  answerQuestion: answerQuestion(dispatch),
  dispatch,
});

const mergeProps = (stateProps, dispatchProps) => {
  return Object.assign({}, stateProps, dispatchProps, {
    answerQuestionDispatch: dispatchProps.answerQuestion(
      stateProps.selectedPackage
    ),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(WaivePeriodQuestionnaire);

import { fromJS, Map } from 'immutable';
import {
  COPY_PACKAGE_SELECTED_PRODUCTS,
  CONTINUE_WITH_CHANGED_PACKAGE,
  RESET,
  CLEAR_OLD_PACKAGE_SELECTIONS,
} from '../actions/types';
import createActionHandler from './createActionHandler';

const initialState = fromJS({
  packageChanged: false,
});

const actionHandlers = {};

const saveCopyOfProductSelection = (state, action) =>
  state.merge({
    products: fromJS(action.payload.products).reduce(
      (productMap, product) =>
        productMap.set(product.get('productId'), product),
      new Map()
    ),
    recommendation: fromJS(action.payload.recommendation),
    packageId: fromJS(action.payload.packageId),
  });

const setChangedPackage = (state) =>
  state.merge({
    packageChanged: true,
  });

const toInitialState = () => initialState;

actionHandlers[CLEAR_OLD_PACKAGE_SELECTIONS] = toInitialState;
actionHandlers[COPY_PACKAGE_SELECTED_PRODUCTS] = saveCopyOfProductSelection;
actionHandlers[CONTINUE_WITH_CHANGED_PACKAGE] = setChangedPackage;
actionHandlers[RESET] = toInitialState;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

import { fromJS, Map } from 'immutable';
import { push } from 'connected-react-router';
import * as R from 'ramda';
import request from 'axios';
import { API_ROUTES, ROUTES } from '../config/constants';
import { getRecommendationsReceived } from './recommendation';
import { isValidCellphoneNumber, isValidEmail } from '../.shared/validators';
import { updateSelectedPackage } from './selectedPackageSlug';
import { isBrokerSale } from '../common/brokers';

import * as types from './types';

const exists = (i) => !R.isNil(i) && !R.isEmpty(i);

export const selectProducts = (products) => ({
  type: types.SELECT_PRODUCTS,
  payload: products,
});

export const saveSelections = (selections) => ({
  type: types.SAVE_SELECTIONS,
  payload: selections,
});

const selectIfAvailable = (v, selections) => {
  if (selections) {
    return R.includes(v.get('productId'), selections);
  }
  return true;
};

export const toggleExtendedFamilySelectable = (
  dispatch,
  getState,
  selection
) => {
  const currentState = getState();
  const packageSlug = currentState.get('selectedPackageSlug');
  const funeralProductId = currentState.getIn([
    'packages',
    packageSlug,
    'funeralProduct',
  ]);
  let funeralProductIsSelected = false;
  if (selection) {
    const funeral = selection.find((p) => p.productId === funeralProductId);
    funeralProductIsSelected = funeral ? funeral.selected : false;
  } else {
    const selectedProducts = currentState.getIn([
      'productSelection',
      'products',
    ]);
    funeralProductIsSelected = selectedProducts.getIn([
      funeralProductId,
      'selected',
    ]);
  }
  const packageAllowsEffWithoutFuneral = currentState.getIn([
    'packages',
    packageSlug,
    'supportsExtendedFamilyWithoutFuneralCover',
  ]);
  dispatch({
    type: types.TOGGLE_EFF_SELECTABLE,
    payload: packageAllowsEffWithoutFuneral || funeralProductIsSelected,
  });
};

export const selectPreSelectedProducts = () => ({
  type: types.SELECT_PRESELECTED_PRODUCTS,
  payload: (dispatch, getState) => {
    const state = getState();
    const products = state.get('products');
    const selections = state.getIn(['productSelection', 'selections']);
    const productSelection = state.getIn(['productSelection', 'products']);
    if (productSelection) {
      const selectableProducts = productSelection
        .map((v, k) => v.merge(products.get(k)))
        .toList()
        .toJS();
      return dispatch(selectProducts(selectableProducts));
    }
    const recommendedSize = state.getIn(['recommendation', 'recommendation']);
    const recommendation =
      state.getIn(['recommendation', 'recommendations', recommendedSize]) ||
      state.getIn(['recommendation', 'recommendations']).first();
    const selection = recommendation
      .map((v, k) => v.merge(products.get(k)))
      .map((v) => v.set('selected', selectIfAvailable(v, selections)))
      .map((v) => v.set('productTitle', v.get('title')))
      .toList()
      .toJS();

    toggleExtendedFamilySelectable(dispatch, getState, selection);

    return dispatch(selectProducts(selection));
  },
});

const requestNewExtendedFamilyPricing = (
  dispatch,
  getState,
  newPackageId,
  oldState,
  callback
) => {
  const currentState = getState();
  const packageSlug = oldState.get('selectedPackageSlug');
  const funeralProductId = oldState.getIn([
    'packages',
    packageSlug,
    'funeralProduct',
  ]);
  const funeralInsuredAmt = oldState.getIn([
    'productSelection',
    'products',
    funeralProductId,
    'insuredAmount',
  ]);
  const funeralProductIsSelected = oldState.getIn([
    'productSelection',
    'products',
    funeralProductId,
    'selected',
  ]);
  const interactionId = oldState.get('interactionId');
  R.when(
    (cs) => cs.getIn(['productSelection', 'withExtendedFamilyFuneral']),
    R.pipe(
      (cs) => cs.getIn(['extendedFamilyInsureds', 'insureds']).toJS(),
      R.map((i) =>
        R.pipe(
          R.keys,
          R.reduce(
            (copy, key) =>
              R.assoc(key, R.pathOr(R.prop(key, i), [key, 'answer'], i), copy),
            {}
          ),
          R.assoc('funeralCoverAmount', funeralInsuredAmt)
        )(i)
      ),
      (newInsureds) =>
        request
          .post(API_ROUTES.GET_NEW_EXTENDED_FAMILY_PRICING, {
            packageId: newPackageId,
            insureds: newInsureds,
            interactionId,
          })
          .then((response) => {
            const { insureds, newExtendedFamilyProductId } = response.data;
            const extendedFamilyPremiumTotal = insureds.reduce(
              (total, { premium }) => total + premium,
              0
            );
            const extendedFamilyInsuredAmountTotal = insureds.reduce(
              (total, { insuredAmount }) =>
                total + Number.parseInt(insuredAmount, 10),
              0
            );
            const productTitle = oldState.getIn([
              'products',
              newExtendedFamilyProductId,
              'title',
            ]);
            const extendedFamilyProduct = {
              selected: funeralProductIsSelected,
              insureds,
              productId: newExtendedFamilyProductId,
              premium: extendedFamilyPremiumTotal,
              title: productTitle,
              insuredAmount: extendedFamilyInsuredAmountTotal,
              isExtendedFamily: true,
            };
            callback(newExtendedFamilyProductId, extendedFamilyProduct);
          })
          .catch(() => {
            dispatch(push(ROUTES.ERROR));
          })
    )
  )(currentState);
};

const updateExtendedFamilyPricing = (dispatch, getState) => {
  const currentState = getState();
  const packageSlug = currentState.get('selectedPackageSlug');
  const packageAllowsEffWithoutFuneral = currentState.getIn([
    'packages',
    packageSlug,
    'supportsExtendedFamilyWithoutFuneralCover',
  ]);
  const currentInsureds = currentState
    .getIn(['extendedFamilyInsureds', 'insureds'])
    .toJS();
  const withExtendedFamilyFuneral = currentState.getIn([
    'productSelection',
    'withExtendedFamilyFuneral',
  ]);
  const hasInsureds = currentInsureds != null && currentInsureds.length > 0;
  const currentPriceMap = currentState.getIn([
    'extendedFamilyInsureds',
    'currentExtenedFamilyInsured',
    'prices',
  ]);
  if ((withExtendedFamilyFuneral && hasInsureds) || currentPriceMap != null) {
    const selectedProducts = getState().getIn(['productSelection', 'products']);
    const funeralProductId = currentState.getIn([
      'packages',
      packageSlug,
      'funeralProduct',
    ]);
    const funeralProductIsSelected = selectedProducts.getIn([
      funeralProductId,
      'selected',
    ]);
    if (!funeralProductIsSelected) {
      dispatch({
        type: types.TOGGLE_EFF,
        payload: packageAllowsEffWithoutFuneral || false,
      });
      dispatch({
        type: types.TOGGLE_EFF_SELECTABLE,
        payload: packageAllowsEffWithoutFuneral || false,
      });
    } else {
      const funeralCoverAmount = selectedProducts.getIn([
        funeralProductId,
        'insuredAmount',
      ]);
      const extendedFamilyProductId = currentState.getIn([
        'packages',
        packageSlug,
        'extendedFamilyFuneralProduct',
      ]);
      const insuredPrices = currentInsureds.reduce((acc, insured) => {
        const price = insured.prices[extendedFamilyProductId];
        if (price) acc.push(price);
        return acc;
      }, []);
      dispatch({
        type: types.FUNERAL_COVER_AMT_CHANGED,
        payload: { funeralCoverAmount, extendedFamilyProductId, insuredPrices },
      });
    }
  }
};

export const toggleExtendedFamilyActionCreator = (withExtendedFamily) => ({
  type: types.TOGGLE_EFF,
  payload: (dispatch) => {
    request
      .post(API_ROUTES.SELECT_EXTENDED_FAMILY_FUNERAL, {
        selected: withExtendedFamily,
      })
      .then(() => {
        dispatch({
          type: types.TOGGLE_EFF_SUCCESS,
          payload: withExtendedFamily,
        });
      })
      .catch(() => {
        dispatch(push(ROUTES.ERROR));
      });
  },
});

export const updateProductSelection = (selections) => ({
  type: types.UPDATING_PRODUCT_SELECTION,
  payload: (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_PRODUCT_SELECTION,
      payload: fromJS(selections)
        .reduce(
          (productSelection, selection) =>
            productSelection.set(selection.get('productId'), selection),
          new Map()
        )
        .toJS(),
    });
    toggleExtendedFamilySelectable(dispatch, getState);
    updateExtendedFamilyPricing(dispatch, getState);
  },
});

const overwriteProductSelection = (selections) => ({
  type: types.OVERWRITE_PRODUCT_SELECTION,
  payload: fromJS(selections)
    .reduce(
      (productSelection, selection) =>
        productSelection.set(selection.get('productId'), selection),
      new Map()
    )
    .toJS(),
});

const suggestedProductIdMapper = (currentPackage, newPackageId, productId) =>
  currentPackage.getIn(['suggestPackageProductMap', newPackageId, productId]);

const mapCurrentSelectionToNewSelection = (
  currentSelection,
  newPackageId,
  productPriceMap,
  packages,
  currentPackageId
) =>
  currentSelection
    .get('products')
    .map((policy, productId) => {
      const currentPackage =
        packages
          .filter((p) => p.get('packageId') === currentPackageId)
          .first() || new Map();
      const newProductId = suggestedProductIdMapper(
        currentPackage,
        newPackageId,
        productId
      );
      const insuredAmount = policy.get('insuredAmount');
      if (!newProductId) {
        return null;
      }
      return {
        productId: newProductId,
        insuredAmount,
        premium: productPriceMap.getIn([
          newProductId,
          insuredAmount.toString(),
        ]),
        selected: policy.get('selected'),
      };
    })
    .filter((p) => p !== null);

const updateExtendedFamilyIfApplicable = (
  dispatch,
  getState,
  newPackageId,
  oldState
) => {
  return requestNewExtendedFamilyPricing(
    dispatch,
    getState,
    newPackageId,
    oldState,
    (newExtendedFamilyProductId, extendedFamilyProduct) => {
      const selectedProducts = getState()
        .getIn(['productSelection', 'products'])
        .set(newExtendedFamilyProductId, extendedFamilyProduct)
        .valueSeq()
        .toJS();

      dispatch(updateProductSelection(selectedProducts));
      dispatch({
        type: types.SET_EXTENDED_FAMILY_PRICING,
        payload: extendedFamilyProduct,
      });
      // we don't need to do this when the package changes
      /* dispatch({
        type: types.OVERWRITE_EFF_MEMBER_PRICES,
        payload: extendedFamilyProduct,
      }); */
    }
  );
};

const getNewProductPriceMap =
  (newPackageId, currentProductSelection, currentPackageId, next) =>
  (dispatch, getState) => {
    request
      .post(API_ROUTES.GET_RECOMMENDATION, { packageId: newPackageId })
      .then((response) => {
        dispatch(getRecommendationsReceived(response.data, newPackageId));
        const productPriceMap = fromJS(response.data.productPriceMap);
        const packages = getState().get('packages');
        const selections = mapCurrentSelectionToNewSelection(
          currentProductSelection,
          newPackageId,
          productPriceMap,
          packages,
          currentPackageId
        );
        const oldState = getState();
        dispatch(overwriteProductSelection(selections.toJS()));
        updateExtendedFamilyIfApplicable(
          dispatch,
          getState,
          newPackageId,
          oldState
        );
        if (next) {
          next();
        }
      })
      .catch(() => {
        dispatch(push(ROUTES.ERROR));
      });
  };

const updateProductSelectionToNewPackage = (
  currentProductSelection,
  newPackageId,
  currentPackageId,
  next
) => ({
  type: types.GET_NEW_PRODUCT_LIST_FOR_PACKAGE,
  payload: getNewProductPriceMap(
    newPackageId,
    currentProductSelection,
    currentPackageId,
    next
  ),
});

export const toggleRop = (withRop) => {
  const type = types.TOGGLE_ROP;
  return {
    type,
    payload: withRop,
  };
};

export const selectExtendedFamilyProduct = (selected) => {
  return {
    type: types.SELECT_EXTENDED_FAMILY_PRODUCT,
    payload: selected,
  };
};

export const selectChangedPackageProducts = (
  packageSlug,
  currentProductSelection,
  suggestedPackageId,
  fromPackageId
) => {
  const type = types.SELECT_CHANGED_PACKAGE_PRODUCTS;
  const payload = (dispatch) => {
    dispatch(
      updateProductSelectionToNewPackage(
        currentProductSelection,
        suggestedPackageId,
        fromPackageId,
        () => {
          dispatch(push(ROUTES.toCustomisation(packageSlug)));
          dispatch(updateSelectedPackage(packageSlug));
        }
      )
    );
  };
  return {
    type,
    payload,
  };
};

export const saveProductSelectionSuccess = (packageId, productSelection) => ({
  type: types.SAVE_PRODUCT_SELECTION_SUCCESS,
  payload: { packageId, productSelection },
});

export const saveProductSelectionFailure = () => ({
  type: types.SAVE_PRODUCT_SELECTION_FAILURE,
});

export const sendQuoteNotificationSuccess = () => ({
  type: types.SEND_QUOTE_NOTIFICATION_SUCCESS,
});

export const sendQuoteNotificationFailure = () => ({
  type: types.SEND_QUOTE_NOTIFICATION_FAILURE,
});

export const getContactInfo = (state, packageId) => {
  const contactInfoQuestions = state
    .get('packages')
    .filter((p) => p.get('packageId') === packageId)
    .first()
    .get('contactInfo');
  const cellphoneNumber = state.getIn([
    'answers',
    contactInfoQuestions.get('cellphone'),
    'answer',
  ]);
  const email = state.getIn([
    'answers',
    contactInfoQuestions.get('email'),
    'answer',
  ]);
  const name = state.getIn([
    'answers',
    contactInfoQuestions.get('name'),
    'answer',
  ]);
  const surname = state.getIn([
    'answers',
    contactInfoQuestions.get('surname'),
    'answer',
  ]);
  return {
    cellphoneNumber: isValidCellphoneNumber(cellphoneNumber)
      ? cellphoneNumber
      : null,
    email: isValidEmail(email) ? email : null,
    name: name || 'Customer',
    surname: surname || '',
  };
};

const postQuoteNotifications =
  (packageId, productSelection, pricing, excludeLink, forceSend) =>
  (dispatch, getState) => {
    //eslint-disable-line
    const state = getState();
    const link = exists(excludeLink)
      ? null
      : state.getIn(['rehydration', 'url']);
    const withRop = state.getIn(['productSelection', 'withRop']);
    const withRopAndRopAmount = {
      ropReturn: withRop ? pricing.ropReturn : 0,
      ropAmount: pricing.ropPremium,
      withRop,
    };
    const { cellphoneNumber, email, name, surname } = getContactInfo(
      state,
      packageId
    );
    const hasWinWin = state.getIn(['winWinDetails', 'hasWinWin']);
    const winWinDiscountRate = hasWinWin
      ? state.getIn(['config', 'WINWIN_DISCOUNT_RATE'])
      : 0;
    request
      .post(API_ROUTES.POST_QUOTE_NOTIFICAITON, {
        packageId,
        productSelection,
        withRopAndRopAmount,
        winWinDiscountRate,
        cellphoneNumber,
        email,
        name,
        surname,
        link,
        forceSend,
      })
      .then(() => {
        dispatch(sendQuoteNotificationSuccess());
      })
      .catch(() => {
        dispatch(sendQuoteNotificationFailure());
      });
  };

export const sendSelectionQuotes = (
  packageId,
  selections,
  pricing,
  excludeLink,
  forceSend
) => {
  const type = types.SEND_QUOTE_NOTIFICATION;
  const productSelection = R.map(
    R.omit(['pricing', 'selectedIndex', 'tooltip']),
    selections
  );
  const meta = { productSelection };
  return {
    type,
    meta,
    payload: postQuoteNotifications(
      packageId,
      productSelection,
      pricing,
      excludeLink,
      forceSend
    ),
  };
};

const postProductSelection =
  (packageId, productSelection, nextUrl, pricing) => (dispatch, getState) => {
    //eslint-disable-line
    const state = getState();
    const withRop = state.getIn(['productSelection', 'withRop']);
    const withExtendedFamily = state.getIn([
      'productSelection',
      'withExtendedFamilyFuneral',
    ]);
    const extendedFamilyPricing = state.getIn([
      'productSelection',
      'extendedFamilyProductAndPricing',
    ]);
    let withEffSelection = productSelection;
    if (withExtendedFamily && extendedFamilyPricing) {
      withEffSelection = [...productSelection, extendedFamilyPricing.toJS()];
    }
    const withRopAndRopAmount = { ropAmount: pricing.ropPremium, withRop };
    const hasWinWin = state.getIn(['winWinDetails', 'hasWinWin']);
    const winWinDiscountRate = hasWinWin
      ? state.getIn(['config', 'WINWIN_DISCOUNT_RATE'])
      : 0;
    return request
      .post(API_ROUTES.SELECTED_PRODUCTS, {
        packageId,
        productSelection: withEffSelection,
        withRopAndRopAmount,
        winWinDiscountRate,
      })
      .then(() => {
        dispatch(updateProductSelection(withEffSelection));
        dispatch(saveProductSelectionSuccess(packageId, withEffSelection));
        if (!isBrokerSale(state)) {
          dispatch(sendSelectionQuotes(packageId, withEffSelection, pricing));
        }
        if (nextUrl) {
          dispatch(push(nextUrl));
        }
      })
      .catch(() => {
        dispatch(saveProductSelectionFailure());
        dispatch(push(ROUTES.ERROR));
      });
  };

export const saveProductSelection = (
  packageId,
  selections,
  nextUrl,
  pricing
) => {
  const type = types.SAVE_PRODUCT_SELECTION;
  const productSelection = R.map(
    R.omit(['pricing', 'selectedIndex', 'tooltip']),
    selections
  );
  const meta = { productSelection };
  return {
    type,
    meta,
    payload: postProductSelection(
      packageId,
      productSelection,
      nextUrl,
      pricing
    ),
  };
};

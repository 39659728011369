import * as types from './types';

export const supportsIemasSalaryDeductionPaymentType = () => {
  return {
    type: types.SUPPORTS_IEMAS_SALARY_DEDUCTION_PAYMENT_TYPE,
    payload: {},
  };
};

export const onlyDebitOrderPaymentTypeSupported = () => {
  return {
    type: types.ONLY_DEBIT_ORDER_PAYMENT_TYPE_SUPPORTED,
    payload: {},
  };
};

export const setPaymentType = paymentType => {
  return {
    type: types.SET_PAYMENT_TYPE,
    payload: { paymentType },
  };
};

export const setIemasSupport = support => {
  return {
    type: types.SET_IEMAS_SUPPORT,
    payload: { support },
  };
};

export const setOtpSmsTemplate = template => {
  return {
    type: types.SET_OTP_SMS_TEMPLATE,
    payload: { template },
  };
};

import { merge } from 'ramda';

const getSelectedProductIds = (state) =>
  state
    .getIn(['productSelection', 'products'])
    .filter((p) => p.get('selected'))
    .keySeq()
    .toJS();

export const getBenefits = (state) => {
  const selectedProductIds = getSelectedProductIds(state);
  const benefits = selectedProductIds.reduce(
    (requirements, productId) => {
      const isLife =
        state.getIn(['products', productId, 'isLifeProduct']) || false;
      const isDisability =
        state.getIn(['products', productId, 'isDisabilityProduct']) || false;
      const isFuneral =
        state.getIn(['products', productId, 'isFuneralProduct']) || false;

      return merge(requirements, {
        hasLifeBenefit: requirements.hasLifeBenefit || isLife,
        hasDisabilityBenefit: requirements.hasDisabilityBenefit || isDisability,
        hasFuneralbenefit: requirements.hasFuneralbenefit || isFuneral,
      });
    },
    {
      hasLifeBenefit: false,
      hasDisabilityBenefit: false,
      hasFuneralbenefit: false,
    }
  );
  const hasEffBenefit = state.getIn([
    'productSelection',
    'extendedFamilyProductAndPricing',
    'selected',
  ]);
  return merge(benefits, { hasEffBenefit });
};

export default getSelectedProductIds;

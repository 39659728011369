import {
  SET_SHOW_PREPARE_QUOTE_POPUP_FLAG,
  SET_PREPARE_QUOTE_LOADING
} from './types';

export const setPrepareQuotePopupFlag = value => ({
  type: SET_SHOW_PREPARE_QUOTE_POPUP_FLAG,
  payload: value,
});

export const setPrepareQuoteLoading = value => ({
  type: SET_PREPARE_QUOTE_LOADING,
  payload: value
});

import React, { PureComponent } from 'react';
import { PageButtons } from '@simply-fin-services/astronomix3';
import request from 'axios';
import { API_ROUTES } from '../../../config/constants';
import EntityForm from '../../entityForm/form';

class AddReplacementPolicy extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  componentWillUnmount() {
    // This will clear the underlying entity which is built by EntityForm component.
    // This is hooked into the unmount lifecycle to ensure the form is cleared when.
    this.props.clearEntity();
  }

  requestCreate() {
    const replacementPolicy = this.props.entity(this.props.entityType);

    request
      .post(
        API_ROUTES.REPLACE_AND_SAVE_ADD_REPLACEMENT_POLICY,
        replacementPolicy
      )
      .then((response) => {
        const addedReplacementPolicy = response.data;

        this.props.addPolicyNextStep(addedReplacementPolicy);
      })
      .catch(() => {
        // FAILFAST: Error handling is a perfect activity for cool down.
      });
  }

  nextButtonDisabled() {
    return !this.props.isEntityValid(this.props.entityType);
  }

  goBackAction() {
    if (!this.props.hasReplacementPolicies) return null;

    return () => this.props.backToPolicies();
  }

  introductionWording() {
    const firstOrSubsequentPolicy = this.props.hasReplacementPolicies
      ? 'next'
      : 'first';

    return `Please provide details of the ${firstOrSubsequentPolicy} policy you plan to replace`;
  }

  render() {
    return (
      <React.Fragment>      

        <EntityForm entityType={this.props.entityType} />

        <PageButtons
          disabled={this.nextButtonDisabled()}
          backText="back to replacement policies"
          backAction={this.goBackAction()}
          label="Add Policy"
          onClick={() => this.requestCreate()}
        />
      </React.Fragment>
    );
  }
}

export default AddReplacementPolicy;

import * as types from './types';
import { API_ROUTES } from '../config/constants';
import request from 'axios';
import * as R from 'ramda';

const removeBaseURI = url => {
  const firstSlashIndex = R.findIndex(R.equals('/'))(url);
  const urlDirectory = R.last(R.splitAt(firstSlashIndex, url));
  return urlDirectory;
};

const setReferrerStatus = status => ({
  type: types.SET_REFERRER_STATUS,
  payload: status,
});

const findReferrer = (referralCode, dispatch) =>
  request
    .get(API_ROUTES.CHECK_IF_REFERRER_EXISTS, {
      params: {
        referralCode,
      },
    })
    .then(res => {
      dispatch(setReferrerStatus(res.data));
      const { referrerUrl } = res.data;
      if (!R.isNil(referrerUrl)) {
        const url = removeBaseURI(referrerUrl);
        return new Promise(resolve => resolve({ exists: R.T(), url }));
      }
      return new Promise(resolve => resolve({ exists: R.F(), url: '' }));
    })
    .catch(err => {
      throw err;
    });

export const checkReferrerStatus = (cellphoneNumber, dispatch) =>
  findReferrer(cellphoneNumber, dispatch);

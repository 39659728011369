import * as types from './types';
import request from 'axios';
import { API_ROUTES } from '../config/constants';
import * as R from 'ramda';

export const sendOtpSuccess = (id, type) => ({
  type: types.SEND_OTP_SUCCESS,
  payload: { id, type },
});

export const sendOtpFailure = type => ({
  type: types.SEND_OTP_FAILURE,
  payload: { type },
});

export const sendOtpRequest = (
  otpEndpoint,
  cellphoneNumber,
  smsTemplate,
  otpType,
  roaOptions
) =>
  Promise.resolve(
    request.post(otpEndpoint, {
      cellphoneNumber,
      smsTemplate,
      otpType,
      roaOptions,
    })
  );

const requestOtp = (
  cellphoneNumber,
  pinType,
  smsTemplate,
  roaOptions
) => dispatch => {
  if (!R.isNil(pinType)) {
    sendOtpRequest(
      API_ROUTES.SEND_OTP,
      cellphoneNumber,
      smsTemplate,
      pinType,
      roaOptions
    )
      .then(res => {
        const pinId = res.data.pinId;
        dispatch(sendOtpSuccess(pinId, pinType));
      })
      .catch(() => {
        dispatch(sendOtpFailure(pinType));
      });
  }
};

export const sendOtpActionCreator = (
  cellphoneNumber,
  otpType,
  smsTemplate,
  roaOptions
) => ({
  type: types.SEND_OTP,
  payload: requestOtp(cellphoneNumber, otpType, smsTemplate, roaOptions),
});

const otpValidated = v => ({
  type: types.OTP_VALIDATED,
  payload: { isValid: v },
});

const thirdPartyOtpValidated = v => ({
  type: types.THIRD_PARTY_OTP_VALIDATED,
  payload: { isValid: v },
});

export const otpValidActionCreator = () => otpValidated(true);
export const otpInvalidActionCreator = () => otpValidated(false);

export const thirdPartyOtpValidActionCreator = () => thirdPartyOtpValidated(true);
export const thirdPartyOtpInvalidActionCreator = () => thirdPartyOtpValidated(false);

export const willSendRecordOfAdviceWithOtp = () => ({
  type: types.WILL_SEND_RECORD_OF_ADVICE_OTP,
});

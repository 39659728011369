import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { ROUTES } from '../../config/constants';
import {
  replacementPolicies,
  hasReplacementPolicies,
  isAddingInsuredPerson,
  isAddingReplacementPolicy,
  shouldShowPolicyListing,
  totalReplacementPolicies,
  totalMonthlyPremium,
} from '../../stateStore/replaceAndSave';
import { fetchLatestPolicyReplacementData } from './httpApi';
import PolicyReplacementPage from './PolicyReplacementPage';

export const mapStateToProps = (state) => {
  return {
    replacementPolicies: replacementPolicies(state),
    isAddingInsuredPerson: isAddingInsuredPerson(state),
    isAddingReplacementPolicy: isAddingReplacementPolicy(state),
    shouldShowPolicyListing: shouldShowPolicyListing(state),
    hasReplacementPolicies: hasReplacementPolicies(state),
    totalReplacementPolicies: totalReplacementPolicies(state),
    totalMonthlyPremium: totalMonthlyPremium(state),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchLatestPolicyReplacementData: () =>
    fetchLatestPolicyReplacementData(dispatch),

  goToNextPage: () => {
    dispatch(push(ROUTES.HOME));
  },
});

const PolicyReplacementPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyReplacementPage);

export default PolicyReplacementPageContainer;

import { fromJS } from 'immutable';
import {
  SUPPORTS_IEMAS_SALARY_DEDUCTION_PAYMENT_TYPE,
  ONLY_DEBIT_ORDER_PAYMENT_TYPE_SUPPORTED,
  SET_PAYMENT_TYPE,
  SET_IEMAS_SUPPORT,
} from '../actions/types';

const initialState = fromJS({
  supportsIemasSalaryDeduction: false,
  selectedPaymentType: 'debit-order',
});

export default (state = initialState, action) => {
  if (action.type === SUPPORTS_IEMAS_SALARY_DEDUCTION_PAYMENT_TYPE) {
    return state.merge({
      supportsIemasSalaryDeduction: true,
      selectedPaymentType: 'debit-order',
    });
  }

  if (action.type === ONLY_DEBIT_ORDER_PAYMENT_TYPE_SUPPORTED) {
    return state.merge({
      supportsIemasSalaryDeduction: false,
      selectedPaymentType: 'debit-order',
    });
  }

  if (action.type === SET_PAYMENT_TYPE) {
    return state.merge({ selectedPaymentType: action.payload.paymentType });
  }

  if (action.type === SET_IEMAS_SUPPORT) {
    return state.merge({
      supportsIemasSalaryDeduction: action.payload.support,
    });
  }

  return state;
};

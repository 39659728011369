import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LoadingRow from '../common/LoadingRow';
import PageActionButtons from '../common/PageActionButtons';

export default class SummaryActions extends PureComponent {
  render() {
    const { next, isSaving, nextDisabled, summaryContent } = this.props;

    const { fineprint, buttonText } = summaryContent;

    return (
      <React.Fragment>
        {!isSaving && (
          <PageActionButtons
            text={buttonText}
            onClick={next}
            disabled={nextDisabled}
            fineprint={fineprint}
            canGoBack
          />
        ) //eslint-disable-line
        }
        {isSaving && (
          <div className="row control">
            <div className="col-xs-12">
              <br />
              <LoadingRow />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

SummaryActions.propTypes = {
  next: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  nextDisabled: PropTypes.bool,
};

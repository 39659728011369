import React from 'react';
import { connect } from 'react-redux';
import BrokerCompliance from './bankingDetails/brokerCompliance';
import brokerQaActions from '../actions/brokerQA';
import { saveBankingDetails } from '../actions/bankingDetails';
import * as R from 'ramda';
class Test extends React.Component {
  render() {
    return (
      <BrokerCompliance type="iemas-salary-deduction" />
    );
  }
}

const next = R.curry((dispatch, details) =>
  dispatch(saveBankingDetails(details.toJS()))
);

const updateCorrectionComments = R.curry((dispatch, comments) => {
  dispatch(brokerQaActions.updateCorrectionComments(comments));
});

const mapDispatchToProps = (dispatch) => ({
  next: next(dispatch),
  updateCorrectionComments: updateCorrectionComments(dispatch),
  sendForCorrections: () => dispatch(brokerQaActions.sendForCorrections()),
  rejectSale: () => dispatch(brokerQaActions.rejectSale()),
});

export default connect(null, mapDispatchToProps)(Test);

import { fromJS } from 'immutable';
import {
  SAVE_BANKING_DETAILS,
  SAVE_BANKING_DETAILS_NOT_VALID,
  SAVE_BANKING_DETAILS_FAILURE,
  SET_BANKING_DETAILS,
  COMPLETE_SALE_VALIDATION_FAILURE,
  SAVE_PEEPS_SUCCESS,
  SAVE_PEEPS_FAILURE,
  RESET,
} from '../actions/types';
import createActionHandler from './createActionHandler';

const initialState = fromJS({
  isSaving: false,
  details: {
    debitDay: null,
    accountNumber: '',
    branchCode: null,
    accountType: 'Savings',
    inceptionMonth: '',
  },
});

const actionHandlers = {};

const saveBankingDetails = (state, action) =>
  state.set('isSaving', true).set('details', fromJS(action.meta));

const completeSavingBankingDetails = state => state.set('isSaving', false);

const setBankingDetails = (state, action) =>
  state.set('details', fromJS(action.payload));

const saveBankingDetailsFailure = state => state.set('isSaving', false);
const bankingDetailsNotValid = (state, action) =>
  state.merge(
    fromJS({
      isSaving: false,
      details: action.payload,
    })
  );

const toInitialState = () => initialState;

actionHandlers[RESET] = toInitialState;
actionHandlers[SAVE_BANKING_DETAILS] = saveBankingDetails;
actionHandlers[SAVE_BANKING_DETAILS_FAILURE] = saveBankingDetailsFailure;
actionHandlers[SAVE_BANKING_DETAILS_NOT_VALID] = bankingDetailsNotValid;
actionHandlers[COMPLETE_SALE_VALIDATION_FAILURE] = completeSavingBankingDetails;
actionHandlers[SAVE_PEEPS_SUCCESS] = completeSavingBankingDetails;
actionHandlers[SAVE_PEEPS_FAILURE] = completeSavingBankingDetails;
actionHandlers[SET_BANKING_DETAILS] = setBankingDetails;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

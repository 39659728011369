import { connect } from 'react-redux';
import SimplifiedTerms from './SimplifiedTerms';
import {
  acceptTermsAndConditions,
  acceptGeneralTermsAndConditions,
  acceptPolicyReplacementTermsAndConditions,
  acceptPolicyReplacementWaivePeriodTerms,
  savePackageAllowsPolicyReplacementFlag,
  setShouldRequestWaivePeriodDetailsFlag,
  choosePolicyReplaced,
} from '../../../actions/insured-terms';
import * as R from 'ramda';
import { fromJS } from 'immutable';
import { stateToQuestionnaireProps } from '../../questionnaire/toQuestionPageProps';

const getQuestionTitle = (state, qId) =>
  state.getIn(['questions', qId, 'title']);

const mapDispatchToProps = dispatch => ({
  dispatch,
  next: contract => {
    dispatch(acceptTermsAndConditions(contract));
  },
  acceptGeneralTermsAndConditions: value => {
    dispatch(acceptGeneralTermsAndConditions(value));
  },
  acceptPolicyReplacementTermsAndConditions: value => {
    dispatch(acceptPolicyReplacementTermsAndConditions(value));
  },
  acceptPolicyReplacementWaivePeriodTerms: value => {
    dispatch(acceptPolicyReplacementWaivePeriodTerms(value));
  },
  choosePolicyReplaced: value => {
    dispatch(choosePolicyReplaced(value));
  },
});

const buildTermsUrl = state => {
  const WEB_SITE_BASE = state.getIn(['config', 'WEB_SITE_BASE']);
  const packageSlug = state.get('selectedPackageSlug');
  const endpoint = state.getIn([
    'packages',
    packageSlug,
    'termsAndConditionsUrl',
  ]);
  const senderReference = state.getIn(['currentTheme', 'senderReference']);
  const reference = senderReference
    ? `?senderReference=${senderReference}`
    : '';
  return `${WEB_SITE_BASE}${endpoint}/${reference}`;
};

const mapStateToProps = state => {
  const pageQuestions = state.getIn([
    'questionPages',
    'replacement-policy-questionnaire',
    'questions',
  ]);
  const { walkedPage, answers } = stateToQuestionnaireProps(
    state,
    pageQuestions,
    fromJS([])
  );
  const { questionsAnswered } = walkedPage.toJS();
  const waiveQuestionnaireErrors = !R.isEmpty(questionsAnswered)
    ? pageQuestions
        .toJS()
        .filter(q => !R.isNil(answers.get(q)))
        .map(q => answers.get(q))
        .filter(a => !a.get('isValid'))
        .map(a => R.pick(['questionId', 'errors'], a.toJS()))
        .map(a =>
          !R.isEmpty(a)
            ? R.assoc('title', getQuestionTitle(state, a.questionId), a)
            : a
        )
        .filter(a => !R.isEmpty(a))
    : [];
  return {
    details: state.getIn(['termsAndConditions', 'details', 'insuredPersonDetails']),
    isSaving: state.getIn(['termsAndConditions', 'details', 'isSaving']),
    termsAndConditionsUrl: buildTermsUrl(state),
    waiveQuestionnaireErrors,
    walkedWaiveQuestionnaire: walkedPage,
    waiveQuestionnaireIsValid: state.getIn([
      'termsAndConditions',
      'details',
      'insuredPersonDetails',
      'waiveQuestionnaireIsValid',
    ]),
    allowsPolicyreplacement: false,
    waivePeriodDetailsShouldBeRequested: state.getIn([
      'termsAndConditions',
      'details',
      'insuredPersonDetails',
      'waivePeriodDetailsShouldBeRequested',
    ])
  };
};

const mergeProps = (stateProps, dispatchprops) => {
  dispatchprops.dispatch(
    savePackageAllowsPolicyReplacementFlag(stateProps.allowsPolicyreplacement)
  );
  dispatchprops.dispatch(setShouldRequestWaivePeriodDetailsFlag());
  return Object.assign({}, stateProps, dispatchprops, {});
};

const SimplifiedTermsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SimplifiedTerms);

export default SimplifiedTermsContainer;

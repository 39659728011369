import request from 'axios';
import {
  SETTING_CURRENT_INSURED_DETAIL,
  SET_CURRENT_EXTENDED_FAMILY_INSURED_DETAIL,
  SET_CURRENT_EXTENDED_FAMILY_INSURED_DETAILS,
  ADD_CURRENT_EXTENDED_FAMILY_INSURED,
  REMOVE_EXTENDED_FAMILY_INSURED,
  FETCHING_CURRENT_INSURED_PRICING,
  ADD_EXTENDED_FAMILY_WITH_SELECTED_FAMILY_MEMBERS,
  SET_EXTENDED_FAMILY_PRICING,
  CURRENT_INSURED_REFRESHED,
  VALIDATE_CURRENT_INSURED_DETAIL,
  VALIDATED_CURRENT_INSURED,
  EDIT_EFF_INSURED,
  UPDATE_EFF_INSURED,
  DELETE_EFF_INSURED,
  DELETING_EFF_INSURED,
  OPEN_EFF_INSURED_EDITOR,
} from './types';
import { API_ROUTES, ROUTES } from '../config/constants';
import { updateProductSelection } from './productSelection';
import * as R from 'ramda';
import { push } from 'connected-react-router';
import { fetchPrice } from './extendedFamilyPricing';
import getPackageIdFromSlug from '../common/getPackageIdFromSlug';

const pricingKeys = [
  'dateOfBirth',
  'gender',
  'affiliation',
  'relationship',
  'affiliationKey',
];
const pricingKeyWasModified = (modifiedKey) =>
  pricingKeys.indexOf(modifiedKey) > -1;
const allPricingKeysValid = ({ age, gender, affiliation }) =>
  age.isValid && gender.isValid && !R.isEmpty(affiliation.answer);

export const fetchPricingForCurrentInsured = (insured, effProductId) => ({
  type: FETCHING_CURRENT_INSURED_PRICING,
  payload: fetchPrice({ ...insured, isCurrent: true }, effProductId),
});

export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction() {
    const context = this;
    const args = arguments; //eslint-disable-line prefer-rest-params
    const later = () => {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
    if (!timeout) func.apply(context, args);
  };
};

const debouncedValidationRequst = debounce(
  (packageId, insured, dispatch, validationCallback) => {
    request
      .post(API_ROUTES.VALIDATE_EXTENDED_FAMILY_MEMBER, {
        insured,
        packageId,
        productId: insured.productId,
      })
      .then(
        R.pipe(R.path(['data', 'insured']), (validatedInsured) => {
          dispatch({
            type: VALIDATED_CURRENT_INSURED,
            payload: validatedInsured,
          });
          if (validationCallback) {
            validationCallback(validatedInsured);
          }
        })
      )
      .catch(() => {
        dispatch(push(ROUTES.ERROR));
      });
  },
  1500
);

const validateInsured = (packageId, insured, dispatch, validationCallback) => {
  debouncedValidationRequst(packageId, insured, dispatch, validationCallback);
};

const getPackageUrlSlug = (state) => state.get('selectedPackageSlug');

export const setCurrentExtendedFamilyInsuredValues = (values, effProductId) => {
  return {
    type: VALIDATE_CURRENT_INSURED_DETAIL,
    payload: (dispatch, getState) => {
      const slug = getState().get('selectedPackageSlug');
      const packageId = getState().getIn(['packages', slug, 'packageId']);

      const assocValidationData = R.pipe(
        R.assoc('packageId', packageId),
        R.assoc('productId', effProductId)
      );

      const anyPricingKeyModified = R.pipe(
        R.keys,
        R.reduce(
          (aKeyWasModified, key) =>
            aKeyWasModified || pricingKeyWasModified(key),
          false
        )
      );
      dispatch({
        type: SET_CURRENT_EXTENDED_FAMILY_INSURED_DETAILS,
        payload: values,
      });
      R.pipe(
        (currentState) =>
          currentState.getIn([
            'extendedFamilyInsureds',
            'currentExtenedFamilyInsured',
          ]),
        assocValidationData,
        (currentInsured) => currentInsured.toJS(),
        (insured) => R.mergeDeepRight(insured, values),
        (insured) =>
          validateInsured(packageId, insured, dispatch, (validatedInsured) => {
            if (
              allPricingKeysValid(validatedInsured) &&
              anyPricingKeyModified(values)
            ) {
              dispatch(
                fetchPricingForCurrentInsured(validatedInsured, effProductId)
              );
            }
          })
      )(getState());
    },
  };
};

const keysNotRequiringValidation = [
  'name',
  'surname',
  'premium',
  'premiumWithRop',
  'insuredAmount',
  'cover',
];

const requiresValidation = ({ value }) =>
  keysNotRequiringValidation.indexOf(value) === -1;

export const setCurrentExtendedFamilyInsuredValue = (answer, effProductId) => ({
  type: SETTING_CURRENT_INSURED_DETAIL,
  payload: (dispatch, getState) => {
    const packageSlug = getPackageUrlSlug(getState());
    const packageId = getPackageIdFromSlug(getState(), packageSlug);
    const insured = R.pipe(
      (currentState) =>
        currentState
          .getIn(['extendedFamilyInsureds', 'currentExtenedFamilyInsured'])
          .toJS(),
      R.assoc(answer.value, { answer: answer.answer })
    )(getState());

    dispatch({
      type: SET_CURRENT_EXTENDED_FAMILY_INSURED_DETAIL,
      payload: answer,
    });

    if (requiresValidation(answer)) {
      const assocValidationData = R.pipe(
        R.assoc('packageId', packageId),
        R.assoc('productId', effProductId)
      );
      validateInsured(
        packageId,
        assocValidationData(insured),
        dispatch,
        (validatedInsured) => {
          const currentInsured = getState()
            .getIn(['extendedFamilyInsureds', 'currentExtenedFamilyInsured'])
            .toJS();
          if (
            pricingKeyWasModified(answer.value) &&
            allPricingKeysValid(validatedInsured)
          ) {
            dispatch(
              fetchPricingForCurrentInsured(currentInsured, effProductId)
            );
          }
        }
      );
    }
  },
});

const setExtendedFamilyProductPricing = (extendedFamilyProduct) => ({
  type: SET_EXTENDED_FAMILY_PRICING,
  payload: extendedFamilyProduct,
});

export const saveExtendedFamilyToProductSelection = (
  dispatch,
  getState,
  insureds,
  extenedFamilyProductId
) => {
  const extendedFamilyTotal = insureds.reduce(
    (total, { premium }) => total + premium,
    0
  );
  const totalInsuredAmount = insureds.reduce(
    (total, { insuredAmount }) => total + Number.parseInt(insuredAmount, 10),
    0
  );
  const productTitle = getState().getIn([
    'products',
    extenedFamilyProductId,
    'title',
  ]);
  const extendedFamilyProduct = {
    selected: true,
    insureds,
    productId: extenedFamilyProductId,
    premium: extendedFamilyTotal,
    title: productTitle,
    insuredAmount: totalInsuredAmount,
    isExtendedFamily: true,
  };
  let selectedProducts = getState().getIn(['productSelection', 'products']);
  selectedProducts = selectedProducts.set(
    extenedFamilyProductId,
    extendedFamilyProduct
  );
  selectedProducts = selectedProducts.valueSeq().toJS();
  dispatch(updateProductSelection(selectedProducts));
  dispatch(setExtendedFamilyProductPricing(extendedFamilyProduct));
};

const saveEffWithMembers = (extenedFamilyProductId) => (dispatch, getState) => {
  const currentState = getState();
  const interactionId = currentState.get('interactionId');
  const packageId = currentState.getIn([
    'packages',
    currentState.get('selectedPackageSlug'),
    'packageId',
  ]);
  const undesiredFields = ['cover', 'fetchingPricing'];

  const prepareInsured = (i) =>
    R.pipe(
      R.keys,
      (keys) =>
        R.reduce(
          (copy, key) =>
            R.assoc(key, R.pathOr(R.prop(key, i), [key, 'answer'], i), copy),
          {},
          keys
        ),
      R.omit(undesiredFields)
    )(i);

  const insureds = R.pipe(
    (s) => s.getIn(['extendedFamilyInsureds', 'insureds']).toJS(),
    R.map(prepareInsured)
  )(currentState);

  const requestBody = {
    details: insureds,
    packageId,
    interactionId,
  };

  return request
    .post(API_ROUTES.SAVE_EXTENDED_FAMILY_PEEPS, requestBody)
    .then(() => {
      saveExtendedFamilyToProductSelection(
        dispatch,
        getState,
        insureds,
        extenedFamilyProductId
      );
    })
    .catch(() => {
      dispatch(push(ROUTES.ERROR));
    });
};

export const removeExtendedFamilyInsured = (payload) => ({
  type: REMOVE_EXTENDED_FAMILY_INSURED,
  payload,
});

export const addExtendedFamilyWithSelectedFamilyMembers = (
  payload,
  extenedFamilyProductId
) => ({
  type: ADD_EXTENDED_FAMILY_WITH_SELECTED_FAMILY_MEMBERS,
  payload: saveEffWithMembers(payload, extenedFamilyProductId),
});

export const insuredRefreshed = () => ({
  type: CURRENT_INSURED_REFRESHED,
  payload: {},
});

export const addCurrentExtendedFamilyInsured = (extendedFamilyProductId) => ({
  type: ADD_CURRENT_EXTENDED_FAMILY_INSURED,
  payload: saveEffWithMembers(extendedFamilyProductId),
});

const openEditor = (insuredId) => ({
  type: OPEN_EFF_INSURED_EDITOR,
  payload: { insuredId },
});

export const editInsured = (insuredId, effProductId) => ({
  type: EDIT_EFF_INSURED,
  payload: (dispatch, getState) => {
    const currentState = getState();
    const insuredToEdit = currentState
      .getIn(['extendedFamilyInsureds', 'insureds'])
      .find((i) => i.get('id') === insuredId)
      .set('editing', true)
      .toJS();
    dispatch(openEditor(insuredId));
    dispatch(fetchPricingForCurrentInsured(insuredToEdit, effProductId));
    const packageSlug = getPackageUrlSlug(getState());
    const packageId = getPackageIdFromSlug(getState(), packageSlug);
    validateInsured(packageId, insuredToEdit, dispatch);
  },
});

export const updateInsured = (extendedFamilyProductId) => ({
  type: UPDATE_EFF_INSURED,
  payload: saveEffWithMembers(extendedFamilyProductId),
});

export const deleteInsured = (insuredId, extendedFamilyProductId) => ({
  type: DELETING_EFF_INSURED,
  payload: (dispatch, getState) => {
    dispatch({ type: DELETE_EFF_INSURED, payload: { insuredId } });
    saveEffWithMembers(extendedFamilyProductId)(dispatch, getState);
  },
});

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IPropTypes from 'react-immutable-proptypes';
import { partial } from 'ramda';
import buildQuestion from './questionBuilder';
import { List, fromJS } from 'immutable';
import { CHECKBOX_QUESTION_ANSWER_SPLITTER } from '../../.shared/validators';
import isReflexive from '../../.shared/validators/isReflexive';

const getQuestionId = node => node.get('questionId');

const isAnswered = (node, answers) => answers.has(getQuestionId(node));

const getAnswerForNode = (node, answers) =>
  answers.get(getQuestionId(node)).get('answer');

const nodeCanHaveMultipleAnswers = node => {
  return node.get('type') === 'reflexive-searchable-selection';
};

const getSubsequentQuestionId = (node, answer) =>
  node.getIn(['children', answer]);

const getSubsequentQuestionIds = (node, answers) => {
  const allowsMultipleAnswers = nodeCanHaveMultipleAnswers(node);
  const answer = getAnswerForNode(node, answers);
  if (!allowsMultipleAnswers) {
    return getSubsequentQuestionId(node, answer);
  }
  const individualAnswers = answer.split(CHECKBOX_QUESTION_ANSWER_SPLITTER);
  const subsequentQuestionIds = new List(
    individualAnswers.map(partial(getSubsequentQuestionId, [node]))
  );
  return subsequentQuestionIds
    .flatten()
    .filter(qId => qId !== null && qId !== undefined);
};

export const getQuestionIdsToRender = (
  questions,
  answers,
  questionNode,
  hideUnansweredQuestions
) => {
  const questionIdsToRender = new List([questionNode.get('questionId')]);
  if (isAnswered(questionNode, answers)) {
    const subsequentQuestionIds = getSubsequentQuestionIds(
      questionNode,
      answers
    );

    if (subsequentQuestionIds) {
      if (hideUnansweredQuestions) {
        const withoutUnAnswered = subsequentQuestionIds.filter(
          subsequentQuestionId => {
            const question = questions.get(subsequentQuestionId);
            if (isReflexive(question)) {
              return isAnswered(question, answers);
            }
            return answers.get(subsequentQuestionId) !== null;
          }
        );
        return questionIdsToRender.concat(withoutUnAnswered);
      }
      return questionIdsToRender.concat(subsequentQuestionIds);
    }
  }
  return questionIdsToRender;
};

class ReflexiveQuestion extends PureComponent {
  render() {
    const {
      questions,
      answers,
      questionNode,
      answerQuestion,
      hideUnansweredQuestions,
    } = this.props;

    const questionIds = getQuestionIdsToRender(
      questions,
      answers,
      questionNode,
      hideUnansweredQuestions
    );

    const build = qId =>
      buildQuestion(
        questions,
        fromJS([]),
        answers,
        answerQuestion,
        hideUnansweredQuestions,
        qId
      );

    return (
      <div>
        {build(questionIds.first())}
        {questionIds.size > 1 && (
          <div className={questionNode.get('indent') === true ? 'indent' : ''}>
            {questionIds.rest().map(build)}
          </div>
        )}
      </div>
    );
  }
}

ReflexiveQuestion.propTypes = {
  questions: IPropTypes.map.isRequired,
  answers: IPropTypes.map.isRequired,
  questionNode: IPropTypes.map.isRequired,
  answerQuestion: PropTypes.func.isRequired,
  hideUnansweredQuestions: PropTypes.bool.isRequired,
};

export default ReflexiveQuestion;

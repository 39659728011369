import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Quote from './Quote';
import {
  stateToProps,
  dispatchToProps,
  mergeTheProps,
} from '../questionnaire/toQuestionPageProps';
import { getRecommendation } from '../../actions/recommendation';
import {
  selectedContactQuestions,
  selectedOptionalContactQuestions,
} from '../common/QuoteContactDetailsContainer';
import { uniq, isEmpty, flatten, isNil, curry } from 'ramda';
import { ROUTES } from '../../config/constants';

import { isReplaceAndSaveEnabled } from '../../stateStore/replaceAndSave';
import { populateExtendedFamilyFuneralMembers } from '../replaceAndSave/httpApi';
import { rehydrateInteraction } from '../../actions/rehydration';

const selectPackageQuotePageId = (state, packageSlug) =>
  state.getIn(['packages', packageSlug, 'quoteQuestionPageId']);

const selecteQuoteQuestions = (state, packageSlug) =>
  state.getIn([
    'questionPages',
    selectPackageQuotePageId(state, packageSlug),
    'questions',
  ]);

const selecteOptionalQuoteQuestions = (state, packageSlug) =>
  state.getIn([
    'questionPages',
    selectPackageQuotePageId(state, packageSlug),
    'optionalQuestions',
  ]);

const toBaseProps = (state, ownProps) =>
  stateToProps(
    state,
    ownProps.packageSlug,
    selecteQuoteQuestions(state, ownProps.packageSlug),
    selecteOptionalQuoteQuestions(state, ownProps.packageSlug)
  );

const next = curry((dispatch, packageId, packageUrlSlug) =>
  dispatch(getRecommendation(packageId, packageUrlSlug))
);

const getContactQuestionsValidation = (
  answers,
  questions,
  optionalQuestions
) => {
  const contactQuestions = uniq(
    flatten([questions.toJS(), optionalQuestions.toJS()])
  );
  const contactQuestionAnswers = contactQuestions.map(q => answers.get(q));
  const allContactQuestionsHaveBeenAnswered =
    contactQuestionAnswers.filter(a => isNil(a)).length < 1;
  return isEmpty(answers.toJS()) || !allContactQuestionsHaveBeenAnswered
    ? false
    : contactQuestionAnswers.map(a => a.get('isValid')).filter(v => v === false)
        .length < 1;
};

export const mapStateToProps = (state, ownProps) =>
  Object.assign({}, toBaseProps(state, ownProps), {
    interactionId: state.get('interactionId'),
    isProcessingQuote: state.getIn(['recommendation', 'isFetching']) === true,
    children: ownProps.children,
    appState: state,
    canGoBack: isReplaceAndSaveEnabled(state),
    isReplaceAndSaveEnabled: isReplaceAndSaveEnabled(state)
  });

export const mapDispatchToProps = (dispatch) =>
  Object.assign({}, dispatchToProps(dispatch), {
    next: next(dispatch),
    dispatch,
    goBack: () => {
      dispatch(push(ROUTES.REPLACE_AND_SAVE_DASHBOARD));
    },
    populateExtendedFamilyFuneralMembers: (packageId) =>
      populateExtendedFamilyFuneralMembers(packageId, dispatch),
    rehydrateProductSelection: (packageUrlSlug, interactionId) =>
      dispatch(rehydrateInteraction(packageUrlSlug, interactionId)),
  });

const mergeProps = (stateProps, dispatchProps) =>
  Object.assign({}, mergeTheProps(stateProps, dispatchProps), {
    goToNextPage: () =>
      dispatchProps.next(stateProps.packageId, stateProps.packageUrlSlug),
    canGoToNextPage:
      stateProps.canGoToNextPage &&
      getContactQuestionsValidation(
        stateProps.answers,
        selectedContactQuestions(
          stateProps.appState,
          stateProps.packageUrlSlug
        ),
        selectedOptionalContactQuestions(
          stateProps.appState,
          stateProps.packageUrlSlug
        )
      ),
  });

const QuoteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Quote);

export default QuoteContainer;

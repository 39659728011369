import { fromJS } from 'immutable';
import { LINK_TO_CAMPAIGN_SUCCESS } from '../actions/types';

const initialState = fromJS({
  campaignId: '',
  sourceId: '',
});

export default (state = initialState, action) => {
  if (action.type === LINK_TO_CAMPAIGN_SUCCESS) {
    return fromJS(action.payload);
  }

  return state;
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import REFERAL_WORKFLOW from '../../common/referralWorkflow';
import NewSignup from './NewSignup';
import CanRefer from './CanRefer';
import LoadingRow from '../common/LoadingRow';
import { Map } from 'immutable';

const workflowViewMap = new Map()
  .set(REFERAL_WORKFLOW.NEW_SIGNUP, NewSignup)
  .set(REFERAL_WORKFLOW.CAN_REFER, CanRefer);

class ReferralSignup extends PureComponent {
  render() {
    const { signupStep, isLoading } = this.props;
    const Page = workflowViewMap.get(signupStep);
    return (
      <div>
        <h3 className="text-center space-above-2">Simply referral programme</h3>
        <p className="text-center space-below-base">
          Join our referral programme for free.
          <br />
          Your friends get a discount and you get cash for yourself or a charity
          of your choice.
        </p>
        {isLoading && <LoadingRow />}
        {!isLoading && <Page />}
      </div>
    );
  }
}

ReferralSignup.propTypes = {
  signupStep: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export const mapStateToProps = state => ({
  signupStep: state.getIn(['referralSignup', 'signupStep']),
  isLoading: state.getIn(['referralSignup', 'isLoading']),
});

export default connect(mapStateToProps)(ReferralSignup);

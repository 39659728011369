import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IPropTypes from 'react-immutable-proptypes';
import { toPremium } from '../common/numberFormats';
import SummaryCost from './SummaryCost';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { ShadowedContainer, Eyebrow } from '@simply-fin-services/astronomix3';
import {
  getPackageSlugFromId,
  getSelectedPackageIdFromState,
} from '../../common/getPackageIdFromSlug';
import getBasePackageId, {
  toIndexedPackages,
} from '../../.shared/getBasePackageId';
import { isBrokerSale } from '../../.shared/calculatePricing';
import { isFromBrokerPortal } from '../../common/brokers';
import { ScrollPercentage } from 'react-scroll-percentage';
import {
  Affordability,
  AffordabilityHeader,
  AffordabilityBody,
  FloatingAffordability,
} from './affordability';
import CustomisationPageTotalsContainer from '../replaceAndSave/integration/CustomisationPageTotalsContainer';

const toMinimumPremiumMessage = (minimumPremium) =>
  `Unfortunately ${toPremium(minimumPremium)} is the smallest premium we offer,
  please increase your benefits to get your premium up to this level.`;

class CustomizationSummary extends PureComponent {
  constructor() {
    super();
    this.state = {
      affordabilityType: 'floating',
    };
    this.useFloatingAffordability = this.useFloatingAffordability.bind(this);
    this.useNormalAffordability = this.useNormalAffordability.bind(this);
  }

  useFloatingAffordability() {
    this.setState({ affordabilityType: 'floating' });
  }

  useNormalAffordability() {
    this.setState({ affordabilityType: 'normal' });
  }

  render() {
    const {
      minimumPremium,
      finalCost,
      aboveMinPremium,
      discountRate,
      discountAmount,
      cost,
      winWinDiscountRate,
      winWinDiscountAmount,
      isNotAffordable,
      allowAffordabilityWarning,
      packageDiscountDescription,
      winWinDetails,
      ropPremium,
      withRop,
      currentSaleIsBrokerSale,
    } = this.props;
    const totalDiscountRate = discountRate + winWinDiscountRate;
    const showDiscount = totalDiscountRate > 0;
    const showWinWin = winWinDiscountRate > 0;

    const winWinName = winWinDetails
      ? R.head(R.split(' ', winWinDetails.get('fullname')))
      : null;
    const winWinDiscountDescription = `${winWinDiscountRate}% Discount (referred by ${winWinName})`;
    const discountDescription = `${discountRate}% ${
      packageDiscountDescription || ''
    }`;
    return (
      <ScrollPercentage
        as="div"
        onChange={(percentage) =>
          percentage > 0
            ? this.useNormalAffordability()
            : this.useFloatingAffordability()
        }
      >
        <ShadowedContainer>
          {!aboveMinPremium && (
            <Eyebrow colour="red" className="text-bold text-danger space-below">
              {toMinimumPremiumMessage(minimumPremium)}
            </Eyebrow>
          )}
          {allowAffordabilityWarning &&
            currentSaleIsBrokerSale &&
            isNotAffordable &&
            this.state.affordabilityType === 'floating' && (
              <FloatingAffordability>
                <AffordabilityHeader isAffordable={!isNotAffordable}>
                  Affordability
                </AffordabilityHeader>
                <AffordabilityBody isAffordable={!isNotAffordable}>
                  <p className="text-bold text-danger space-below">
                    Your policy seems expensive relative to your income. Are you
                    sure you can afford it?
                  </p>
                </AffordabilityBody>
              </FloatingAffordability>
            )}
          {allowAffordabilityWarning &&
            currentSaleIsBrokerSale &&
            isNotAffordable &&
            this.state.affordabilityType === 'normal' && (
              <Affordability>
                <AffordabilityHeader isAffordable={!isNotAffordable}>
                  Affordability
                </AffordabilityHeader>
                <AffordabilityBody isAffordable={!isNotAffordable}>
                  <p className="text-bold text-danger space-below">
                    Your policy seems expensive relative to your income. Are you
                    sure you can afford it?
                  </p>
                </AffordabilityBody>
              </Affordability>
            )}
          {cost !== finalCost && (
            <SummaryCost cost={toPremium(cost)} description="Premium" bordered/>
          )}
          {withRop && (
            <SummaryCost cost={toPremium(ropPremium)} description="Cash Back" bordered/>
          )}
          {showDiscount && (
            <SummaryCost
              cost={discountAmount}
              description={discountDescription}
              discount
              bordered
            />
          )}
          {showWinWin && (
            <SummaryCost
              cost={winWinDiscountAmount}
              description={winWinDiscountDescription}
              discount
              bordered
            />
          )}
          <SummaryCost
            cost={toPremium(finalCost)}
            description="Premium"
            promote
            bordered
          />

          <CustomisationPageTotalsContainer quoteMonthlyPremium={finalCost} />

        </ShadowedContainer>
      </ScrollPercentage>
    );
  }
}

//<CustomisationTotalSummaryContainer />

CustomizationSummary.propTypes = {
  selectedProducts: IPropTypes.map.isRequired,
  cost: PropTypes.number.isRequired,
  finalCost: PropTypes.number.isRequired,
  aboveMinPremium: PropTypes.bool.isRequired,
  discountRate: PropTypes.number.isRequired,
  winWinDiscountRate: PropTypes.number.isRequired,
  minimumPremium: PropTypes.number.isRequired,
  withRop: PropTypes.bool.isRequired,
  ropPremium: PropTypes.number.isRequired,
  discountAmount: PropTypes.number.isRequired,
  winWinDiscountAmount: PropTypes.number.isRequired,
  ropReturn: PropTypes.number.isRequired,
  suggestedPricing: PropTypes.shape({
    hasSuggestedPricing: PropTypes.bool.isRequired,
    finalCost: PropTypes.number,
  }).isRequired,
  isNotAffordable: PropTypes.bool.isRequired,
  allowAffordabilityWarning: PropTypes.bool.isRequired,
};

const exists = (i) => !R.isNil(i) && !R.isEmpty(i);

const mapStateToProps = (state) => ({
  cellphoneNumber: state.getIn(['answers', 'owner-contact-number']),
  email: state.getIn(['answers', 'delivery-email']),
  currentSaleIsBrokerSale: isBrokerSale(state) || isFromBrokerPortal(state),
  allowAffordabilityWarning: (() => {
    const packageId = getSelectedPackageIdFromState(state);
    const packageSlug = getPackageSlugFromId(state, packageId);
    const packages = toIndexedPackages(state.get('packages').valueSeq().toJS());
    const basePackageId = getBasePackageId(packages, packageId);
    const allowAffordabilityWarning = state.getIn([
      'packages',
      packageSlug,
      'allowAffordabilityWarning',
    ]);
    return exists(allowAffordabilityWarning)
      ? allowAffordabilityWarning
      : basePackageId === 'sim-family';
  })(),
});

export default connect(mapStateToProps)(CustomizationSummary);

import { fromJS } from 'immutable';
import * as R from 'ramda';
import {
  PROVIDE_INPUT,
  RESET_FRIEND_REFERRAL_FORM,
  SET_REFERRAL_PACKAGE,
  SET_REFERRAL_ANSWER,
  SAVE_REFERRAL_ENTRIES,
  DELETE_REFERRAL,
} from '../actions/types';
import createActionHandler from './createActionHandler';

const IDENTIFIER = 'referral';

const initialState = fromJS({
  answers: {
    invite: [],
    call: [],
  },
  packageId: null,
});

const actionHandlers = {};

const saveFieldData = (state, action) => {
  const { owner, fieldname, value } = action.payload;
  return owner === IDENTIFIER ? state.set(fieldname, value) : state;
};

const setPackage = (state, action) => state.set('packageId', action.payload);

const setAnswer = (state, action) =>
  state.setIn(
    ['answers', action.payload.questionId],
    fromJS(action.payload.answer)
  );

const saveReferral = (state, action) => {
  const { type, referrals } = action.payload;
  const currentReferrals = state.getIn(['answers', type]).toJS();
  const answerPageId = R.head(R.keys(referrals));
  const answerExistsFor = id =>
    !R.isEmpty(currentReferrals.filter(referral => referral.id === id));

  if (answerExistsFor(answerPageId)) {
    const otherReferrals = currentReferrals.filter(
      referral => referral.id !== answerPageId
    );
    const newReferralsAnswerMap = R.values(referrals);
    const answerValid = validation => validation === true;
    const isValid = R.reduceWhile(
      answerValid,
      (prev, answer) => answer.isValid,
      true,
      R.values(R.head(newReferralsAnswerMap))
    );
    const errors = R.head(
      R.map(answersMap => {
        const fieldErrors = R.values(answersMap).map(a => a.errors);
        const fieldNames = R.keys(answersMap);
        return R.zipObj(fieldNames, fieldErrors);
      }, R.values(referrals))
    );
    const newReferral = {
      id: answerPageId,
      answers: newReferralsAnswerMap,
      errors,
      isValid,
    };
    return state.setIn(
      ['answers', type],
      fromJS(R.append(newReferral, otherReferrals))
    );
  }

  const answerValid = validation => validation === true;
  const isValid = R.reduceWhile(
    answerValid,
    (prev, answer) => R.head(R.values(answer)).isValid,
    true,
    R.values(referrals)
  );
  const errors = R.head(
    R.map(answersMap => {
      const fieldErrors = R.values(answersMap).map(a => a.errors);
      const fieldNames = R.keys(answersMap);
      return R.zipObj(fieldNames, fieldErrors);
    }, R.values(referrals))
  );
  const referral = {
    id: R.head(R.keys(referrals)),
    isValid,
    errors,
    answers: R.values(referrals),
  };
  return state.setIn(
    ['answers', type],
    fromJS(R.append(referral, currentReferrals))
  );
};

const deleteReferral = (state, action) => {
  const { id, type } = action.payload;
  const answers = state.getIn(['answers', type]);
  const referrals = answers.toJS();
  const hasId = R.find(R.propEq('id', id))(referrals);
  if (!hasId) {
    return state;
  }
  const newReferrals = R.filter(referral => referral.id !== id, referrals);
  return state.setIn(['answers', type], fromJS(newReferrals));
};

const reset = state => state.set('answers', fromJS({}));

actionHandlers[PROVIDE_INPUT] = saveFieldData;
actionHandlers[RESET_FRIEND_REFERRAL_FORM] = reset;
actionHandlers[SET_REFERRAL_PACKAGE] = setPackage;
actionHandlers[SET_REFERRAL_ANSWER] = setAnswer;
actionHandlers[SAVE_REFERRAL_ENTRIES] = saveReferral;
actionHandlers[DELETE_REFERRAL] = deleteReferral;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPersalContractInceptionDate = exports.extractPersalContractDetails = exports["default"] = exports.validatePersalPaymentDetails = exports.MANDATE_TYPE_VOICE = exports.MANDATE_TYPE_ELECTRONIC = exports.MANDATE_TYPE_PAPER = void 0;

var _immutable = require("immutable");

var _validators = require("../validators");

var MANDATE_TYPE_PAPER = "paper";
exports.MANDATE_TYPE_PAPER = MANDATE_TYPE_PAPER;
var MANDATE_TYPE_ELECTRONIC = "electronic";
exports.MANDATE_TYPE_ELECTRONIC = MANDATE_TYPE_ELECTRONIC;
var MANDATE_TYPE_VOICE = "voice";
exports.MANDATE_TYPE_VOICE = MANDATE_TYPE_VOICE;

var validateEmployeeNumber = function validateEmployeeNumber(e) {
  var v = (0, _validators.validateRequiredNumberText)(e);

  if (v.isValid) {
    if (e.length === 8) {
      return v;
    }

    return (0, _validators.toError)(e, 'Employee number should have 8 numbers');
  }

  return v;
};

var validateMandateType = function validateMandateType(t) {
  if (t === MANDATE_TYPE_PAPER || t === MANDATE_TYPE_ELECTRONIC || t === MANDATE_TYPE_VOICE) {
    return (0, _validators.toValidateableValue)(t);
  }

  return (0, _validators.toError)(t, 'Only paper, electronic and voice mandates are allowed');
};

var validatePersalPaymentDetails = function validatePersalPaymentDetails(details) {
  return (0, _immutable.fromJS)({
    employeeNumber: validateEmployeeNumber(details.get('employeeNumber')),
    mandateType: validateMandateType(details.get('mandateType')),
    inceptionMonth: (0, _validators.validateRequiredISODate)(details.get('inceptionMonth'))
  });
};

exports.validatePersalPaymentDetails = validatePersalPaymentDetails;
var _default = validatePersalPaymentDetails;
exports["default"] = _default;

var extractPersalContractDetails = function extractPersalContractDetails(createContractBase) {
  return {
    EmployeeNumber: createContractBase.getIn(['options', 'bankingDetails', 'employeeNumber']),
    PaymentType: 'Persal',
    MandateType: createContractBase.getIn(['options', 'bankingDetails', 'mandateType']),
    PayrollIdentifier: createContractBase.getIn(['options', 'bankingDetails', 'payrollIdentifier'])
  };
};

exports.extractPersalContractDetails = extractPersalContractDetails;

var getPersalContractInceptionDate = function getPersalContractInceptionDate(generationDate, selectedInceptionMonth, isUpsellSale) {
  // eslint-disable-line
  return selectedInceptionMonth;
};

exports.getPersalContractInceptionDate = getPersalContractInceptionDate;
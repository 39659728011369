import React from 'react';
import config, { processSalaryDeductionProviders } from './config';
import PropTypes from 'prop-types';
import PageDescription from '../common/PageDescription';
import {
  InputSelect
} from '@simply-fin-services/astronomix3';
import { connect } from 'react-redux';
import { setPaymentType } from '../../actions/paymentType';
import { fromJS } from 'immutable';
import { isFromBrokerPortal } from '../../common/brokers';
import { isQaInReview, getAllowedSalaryDeductionProviders } from '../../common/saleOptions';
import * as R from 'ramda';

// COMPONENT

function ChoosePaymentType(props) {
  const {
    supportedPaymentTypes,
    selectedPaymentType,
    selectedPaymentTypeDetails,
    selectPaymentType,
    canChoose,
    isSaving,
    next,
    requiresOTP,
    updateCorrectionComments,
    sendForCorrections,
    rejectSale,
    requiresThirdPartyOTP
  } = props;

  const Form = selectedPaymentTypeDetails
    ? selectedPaymentTypeDetails.component
        : null;
  const heading = canChoose
        ? "Finally, please provide your payment and compliance info to complete your purchase"
        : `Finally, please complete your ${selectedPaymentTypeDetails.text} authorisation below.`;


  const inputSelectItems = supportedPaymentTypes.map(({text, value}) => ({
    value,
    label: text
  }));

  return (
    <div>
      <PageDescription
        description={heading}
      />

      {canChoose && (
        <div>
          <InputSelect
            label="Payment Options"
            placeholder="Choose payment type"
            value={selectedPaymentType}
            options={inputSelectItems}
            onSelect={selectPaymentType}
          />
          <br />
        </div>)}

      {Form && (
        <Form
          isSaving={isSaving}
          next={next}
          updateCorrectionComments={updateCorrectionComments}
          requiresOTP={requiresOTP}
          sendForCorrections={sendForCorrections}
          rejectSale={rejectSale}
          requiresThirdPartyOTP={requiresThirdPartyOTP}
          selectedPaymentType={selectedPaymentType}
        />
        )}
    </div>
  );
}

ChoosePaymentType.propTypes = {
  next: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

// STATE

const allSupportedPaymentTypes = fromJS(config);

const processProviders = R.pipe(
  processSalaryDeductionProviders,
  fromJS
);

const getSupportedPaymentTypesFromState = (state) => {
  const salaryDeductionProviders = processProviders(getAllowedSalaryDeductionProviders(state)
                                    .valueSeq().toJS()).valueSeq();

  return allSupportedPaymentTypes
    .valueSeq()
    .concat(salaryDeductionProviders)
    .filter((paymentType) => {
      const isSupported = paymentType.get('isSupported');
      return isSupported(state);
    })
    .toJS();
};

const paymentTypesAndSalaryDeductionProviders = (state) => {
  const salaryProviders = processProviders(getAllowedSalaryDeductionProviders(state).valueSeq().toJS());
  return allSupportedPaymentTypes.merge(salaryProviders);
};

const getPaymentType = R.curry((state, paymentType) => {
  return paymentTypesAndSalaryDeductionProviders(state).get(paymentType).toJS();
});

const getSelectedPaymentType = (state) => state.getIn([
  'paymentType',
  'selectedPaymentType',
]);

const mapStateToProps = (state) => {
  const selectedPaymentType = getSelectedPaymentType(state);

  const selectedPaymentTypeDetails = paymentTypesAndSalaryDeductionProviders(state).has(
    selectedPaymentType
  )
    ? getPaymentType(state, selectedPaymentType)
        : null;
  const supportedPaymentTypes = getSupportedPaymentTypesFromState(state);
  const canChoose = supportedPaymentTypes.length > 1;



  const packageSlug = state.get('selectedPackageSlug');
  const isThirdPartyPackage = state.getIn(['packages', packageSlug, 'isThirdPartyPackage']);
  return {
    selectedPaymentType,
    selectedPaymentTypeDetails,
    supportedPaymentTypes,
    canChoose ,
    requiresOTP: (isFromBrokerPortal(state) && !isQaInReview(state)),
    requiresThirdPartyOTP: isFromBrokerPortal(state) && isThirdPartyPackage,
    getPaymentType: getPaymentType(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectPaymentType: R.curry((state, paymentType) => {
    const onLoadAction = state.getPaymentType(paymentType).onLoadAction;

    if (onLoadAction) {
      dispatch(onLoadAction());
    }

    dispatch(setPaymentType(paymentType));
  })
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    selectPaymentType: dispatchProps.selectPaymentType(stateProps)
  });
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ChoosePaymentType);

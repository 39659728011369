"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fromValidateableValue = exports.validateRequiredSelection = exports.validateRequiredDateOfBirthWithoutAge = exports.validateRequiredDateOfBirth = exports.validateRequiredAge = exports.validateRequiredProduct = exports.validateOptionalEducation = exports.validateRequiredEducation = exports.validateOptionalMarritalStatus = exports.validateRequiredMarritalStatus = exports.validateOptionalSmokerStatus = exports.validateRequiredISODate = exports.validateRequiredDate = exports.validateBooleanAs = exports.validateOptionalNumberText = exports.validateOptionalBoolean = exports.validateOptionalYesNoText = exports.validateOptionalIdNumber = exports.validateRequiredIdNumber = exports.validateRequiredBoolean = exports.validateRequiredYesNoText = exports.validateRequiredNumberText = exports.validateOptionalCellphone = exports.validateRequiredCellPhone = exports.isValidCellphoneNumber = exports.validateOptionalPackageId = exports.validateRequiredPackageId = exports.validateOptionalEmail = exports.validateRequiredEmail = exports.isValidEmail = exports.validateRequiredText = exports.isNumberOnlyString = exports.isNotEmptyString = exports.toError = exports.toNotValidatedValidateableValue = exports.toValidateableValue = exports.NUMBER_MIN_LENGTH = exports.CHECKBOX_QUESTION_ANSWER_SPLITTER = exports.MAX_AGE = exports.DATE_FORMAT = void 0;

var _moment = _interopRequireDefault(require("moment"));

var R = _interopRequireWildcard(require("ramda"));

var saIdParser = _interopRequireWildcard(require("south-african-id-parser"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var DATE_FORMAT = 'D/M/YYYY';
exports.DATE_FORMAT = DATE_FORMAT;
var MAX_AGE = 100;
exports.MAX_AGE = MAX_AGE;
var ID_NUMBER_LENGTH = 13;
var CHECKBOX_QUESTION_ANSWER_SPLITTER = '|';
exports.CHECKBOX_QUESTION_ANSWER_SPLITTER = CHECKBOX_QUESTION_ANSWER_SPLITTER;
var NUMBER_MIN_LENGTH = 10;
exports.NUMBER_MIN_LENGTH = NUMBER_MIN_LENGTH;

var toValidateableValue = function toValidateableValue(value) {
  return {
    value: value,
    isValid: true,
    errors: [],
    hasWarning: false,
    warnings: []
  };
};

exports.toValidateableValue = toValidateableValue;

var toNotValidatedValidateableValue = function toNotValidatedValidateableValue(value) {
  return {
    value: value
  };
};

exports.toNotValidatedValidateableValue = toNotValidatedValidateableValue;

var properlyWrappedError = function properlyWrappedError(error) {
  if (Array.isArray(error)) {
    return error;
  }

  return [error];
};

var toError = function toError(value, error) {
  return {
    value: value,
    errors: properlyWrappedError(error),
    isValid: false,
    hasWarning: false,
    warnings: []
  };
};

exports.toError = toError;

var isNotEmptyString = function isNotEmptyString(value) {
  return typeof value === 'string' && value.length > 0;
};

exports.isNotEmptyString = isNotEmptyString;

var isNumberOnlyString = function isNumberOnlyString(value) {
  return /^\d+$/.test(value);
};

exports.isNumberOnlyString = isNumberOnlyString;

var validateRequiredText = function validateRequiredText(value) {
  var isValid = typeof value === 'string' && value.length > 0;
  return isValid ? toValidateableValue(value) : toError(value, 'Please complete');
};

exports.validateRequiredText = validateRequiredText;
var isValidEmail = R.test(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
);
exports.isValidEmail = isValidEmail;

var validateRequiredEmail = function validateRequiredEmail(value) {
  if (!value) {
    return toError(value, 'Please complete');
  }

  var isValid = isValidEmail(value);
  return isValid ? toValidateableValue(value) : toError(value, 'invalid email address');
};

exports.validateRequiredEmail = validateRequiredEmail;

var validateOptionalEmail = function validateOptionalEmail(value) {
  if (!value) {
    return toValidateableValue(value);
  }

  return validateRequiredEmail(value);
};

exports.validateOptionalEmail = validateOptionalEmail;

var validateRequiredPackageId = function validateRequiredPackageId(value, VALID_API_PACKAGES) {
  if (!value) {
    return toError(value, 'Please complete');
  }

  var isValid = R.includes(value, VALID_API_PACKAGES);
  return isValid ? toValidateableValue(value) : toError(value, 'invalid package ID');
};

exports.validateRequiredPackageId = validateRequiredPackageId;

var validateOptionalPackageId = function validateOptionalPackageId(value, VALID_API_PACKAGES) {
  if (!value.interactionData) {
    return toValidateableValue(value);
  }

  return validateRequiredPackageId(value.interactionData.packageId.value, VALID_API_PACKAGES);
};

exports.validateOptionalPackageId = validateOptionalPackageId;

var isValidCellphoneNumber = function isValidCellphoneNumber(value) {
  if (typeof value === 'string') {
    var isCorrectLength = value.length === NUMBER_MIN_LENGTH;
    var hasLeadingZero = value.charAt(0) === '0';
    var hasOnlyDigits = !Number.isNaN(value);
    return isCorrectLength && hasLeadingZero && hasOnlyDigits;
  }

  return false;
};

exports.isValidCellphoneNumber = isValidCellphoneNumber;

var validateRequiredCellPhone = function validateRequiredCellPhone(value) {
  if (!value) {
    return toError(value, 'Please complete');
  }

  return isValidCellphoneNumber(value) ? toValidateableValue(value) : toError(value, 'Phone numbers should start with a 0 and have 10 numbers');
};

exports.validateRequiredCellPhone = validateRequiredCellPhone;

var validateOptionalCellphone = function validateOptionalCellphone(value) {
  if (!value) {
    return toValidateableValue(value);
  }

  return isValidCellphoneNumber(value) ? toValidateableValue(value) : toError(value, 'Phone numbers should start with a 0 and have 10 numbers');
};

exports.validateOptionalCellphone = validateOptionalCellphone;

var validateRequiredNumberText = function validateRequiredNumberText(value) {
  var isValid = typeof value === 'string' && value.length > 0;
  var detail = isValid ? toValidateableValue(value) : toError(value, 'Please complete');
  var isNumberOnly = /^\d+$/.test(value);

  if (!isNumberOnly) {
    detail.errors.push('Only numbers are allowed');
    detail.isValid = false;
  }

  return detail;
};

exports.validateRequiredNumberText = validateRequiredNumberText;

var validateRequiredYesNoText = function validateRequiredYesNoText(value) {
  var isValid = value.toUpperCase() === "YES" || value.toUpperCase === "NO";
  return isValid ? toValidateableValue(value) : toError(value, "Expected either a yes or a no.");
};

exports.validateRequiredYesNoText = validateRequiredYesNoText;

var validateRequiredBoolean = function validateRequiredBoolean(value) {
  var isValid = typeof value === "boolean";
  return isValid ? toValidateableValue(value) : toError(value, "Expected a boolean value.");
};

exports.validateRequiredBoolean = validateRequiredBoolean;

var validateRequiredIdNumber = function validateRequiredIdNumber(value) {
  var dateOfBirth = value.substring(0, 6);
  var isCorrectLength = value.length === ID_NUMBER_LENGTH;
  var isValidDate = (0, _moment["default"])(dateOfBirth, 'YYMMDD', true).isValid();
  var isValidIDParse = saIdParser.validate(value);
  var isValid = isValidDate && isCorrectLength && isValidIDParse;
  var errors = ['ID number invalid'];

  if (isValid) {
    return toValidateableValue(value);
  }

  return toError(value, errors);
};

exports.validateRequiredIdNumber = validateRequiredIdNumber;

var validateOptionalIdNumber = function validateOptionalIdNumber(value) {
  if (!value) {
    return toValidateableValue(value);
  }

  return validateRequiredIdNumber(value);
};

exports.validateOptionalIdNumber = validateOptionalIdNumber;
var validateOptional = R.curry(function (validator, value) {
  if (!value) {
    return toValidateableValue(value);
  }

  return validator(value);
});
var validateOptionalYesNoText = validateOptional(validateRequiredYesNoText);
exports.validateOptionalYesNoText = validateOptionalYesNoText;
var validateOptionalBoolean = validateOptional(validateRequiredBoolean);
exports.validateOptionalBoolean = validateOptionalBoolean;

var validateOptionalNumberText = function validateOptionalNumberText(value) {
  if (!value) {
    return toValidateableValue(value);
  }

  return validateRequiredNumberText(value);
};

exports.validateOptionalNumberText = validateOptionalNumberText;

var validateBooleanAs = function validateBooleanAs(expected, value) {
  var isValid = typeof value === 'boolean' && value === expected;
  var detail = isValid ? toValidateableValue(value) : toError(value, 'Should be true or false');
  return detail;
};

exports.validateBooleanAs = validateBooleanAs;

var validateRequiredDate = function validateRequiredDate(value) {
  var isValid = typeof value === 'string' && (0, _moment["default"])(value, DATE_FORMAT, true).isValid();

  if (!isValid) {
    return toError(value, 'Date invalid, please use dd/mm/yyyy');
  }

  return toValidateableValue(value);
};

exports.validateRequiredDate = validateRequiredDate;

var validateRequiredISODate = function validateRequiredISODate(value) {
  var isValid = typeof value === 'string' && (0, _moment["default"])(value).isValid();

  if (!isValid) {
    return toError(value, 'Invalid Date specified');
  }

  return toValidateableValue(value);
};

exports.validateRequiredISODate = validateRequiredISODate;

var validateOptionalSmokerStatus = function validateOptionalSmokerStatus(value) {
  if (value) {
    var isValid = value === 'Yes' || value === 'No';

    if (!isValid) {
      return toError(value, 'Please enter Yes or No for smoker status');
    }
  }

  return toValidateableValue(value);
};

exports.validateOptionalSmokerStatus = validateOptionalSmokerStatus;

var validateRequiredMarritalStatus = function validateRequiredMarritalStatus(value) {
  var isValid = value === 'Married' || value === 'Not Married';

  if (isValid) {
    return toValidateableValue(value);
  }

  return toError(value, 'Please enter Married or Not Married Yes or No for marred');
};

exports.validateRequiredMarritalStatus = validateRequiredMarritalStatus;

var validateOptionalMarritalStatus = function validateOptionalMarritalStatus(value) {
  if (!value) {
    return toValidateableValue(value);
  }

  return validateRequiredMarritalStatus(value);
};

exports.validateOptionalMarritalStatus = validateOptionalMarritalStatus;

var validateRequiredEducation = function validateRequiredEducation(value) {
  var isValid = value === 'No Matric' || value === 'Matric' || value === '3yr Diploma' || value === 'Undergrad' || value === '4yr Degree';

  if (isValid) {
    return toValidateableValue(value);
  }

  return toError(value, 'Please enter a valid Education level.');
};

exports.validateRequiredEducation = validateRequiredEducation;

var validateOptionalEducation = function validateOptionalEducation(value) {
  if (!value) {
    return toValidateableValue(value);
  }

  return validateRequiredEducation(value);
};

exports.validateOptionalEducation = validateOptionalEducation;

var validateRequiredProduct = function validateRequiredProduct(value, products) {
  var isValid = products.map(function (e) {
    return e.get('productId');
  }).has(value);

  if (!isValid) {
    return toError(value, 'Please enter a valid productId in cover the object');
  }

  return toValidateableValue(value);
};

exports.validateRequiredProduct = validateRequiredProduct;

var validateRequiredAge = function validateRequiredAge(age, maxAge, minAge) {
  var isOldEnough = age >= minAge;

  if (!isOldEnough) {
    return toError(age, "Should be ".concat(minAge, " years or older."));
  }

  var isYoungEnough = age <= maxAge;

  if (!isYoungEnough) {
    return toError(age, "Cannot be older than ".concat(maxAge, " years"));
  }

  return toValidateableValue(age);
};

exports.validateRequiredAge = validateRequiredAge;

var validateRequiredDateOfBirth = function validateRequiredDateOfBirth(value, maxAge, minAge) {
  var givenDate = (0, _moment["default"])(value, DATE_FORMAT, true);
  var age = Math.abs((0, _moment["default"])().diff(givenDate, 'years'));
  var isString = typeof value === 'string';
  var formatValid = givenDate.isValid();
  var isValid = isString && formatValid;

  if (!isValid) {
    return toError(value, 'Date invalid, please use dd/mm/yyyy');
  }

  var isInPast = (0, _moment["default"])().isAfter(givenDate);

  if (!isInPast) {
    return toError(value, 'Date of Birth cannot be in the future');
  }

  var isCorrectAge = validateRequiredAge(age, maxAge, minAge);

  if (!isCorrectAge.isValid) {
    return isCorrectAge;
  }

  return toValidateableValue(value);
};

exports.validateRequiredDateOfBirth = validateRequiredDateOfBirth;

var validateRequiredDateOfBirthWithoutAge = function validateRequiredDateOfBirthWithoutAge(value) {
  var givenDate = (0, _moment["default"])(value, DATE_FORMAT, true);
  var isString = typeof value === 'string';
  var formatValid = givenDate.isValid();
  var isValid = isString && formatValid;

  if (!isValid) {
    return toError(value, 'Date invalid, please use dd/mm/yyyy');
  }

  var isInPast = (0, _moment["default"])().isAfter(givenDate);

  if (!isInPast) {
    return toError(value, 'Date of Birth cannot be in the future');
  }

  return toValidateableValue(value);
};

exports.validateRequiredDateOfBirthWithoutAge = validateRequiredDateOfBirthWithoutAge;

var validateRequiredSelection = function validateRequiredSelection(value, list) {
  var isValid = typeof value === 'string' && list.indexOf(value) > -1;
  return isValid ? toValidateableValue(value) : toError(value, 'invalid selection');
};

exports.validateRequiredSelection = validateRequiredSelection;

var fromValidateableValue = function fromValidateableValue(validateable) {
  return validateable.value;
};

exports.fromValidateableValue = fromValidateableValue;
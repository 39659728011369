import * as R from 'ramda';
import { fromJS, Map } from 'immutable';
import getIncome from '../../common/getIncome';
import {
  getPremium,
  ROP_PERCENTAGE,
  totalPremiumOverFiveYears,
  getRopReturn,
  getRopPremium,
  getDiscount,
  isNotAffordable,
  getDiscountedRopPremium,
} from '../../.shared/calculatePricing';
import { getPackageDiscountRate } from '../../common/pricing';

export const getSelectedProductPricing = (product) => {
  const selectedIndex = product.get('selectedIndex').toString();
  const pricing = product.getIn(['pricing', selectedIndex]);
  const { insuredAmount, premium } = pricing.toJS();
  return fromJS({
    insuredAmount,
    premium,
    selectedIndex,
  });
};

const filterSelectedProducts = (products) =>
  products.filter((p) => p.get('selected'));

export const getPricingConfig = (state, packageSlug) => {
  const selectedPackage = state.getIn(['packages', packageSlug]);
  const packageDiscount = getPackageDiscountRate(state);
  const hasRopOption = state.getIn(['packages', packageSlug, 'hasRopOption']);
  const hasExtendedFamilyOption = state.getIn([
    'packages',
    packageSlug,
    'hasExtendedFamilyOption',
  ]);
  const hasWinWin = state.getIn(['winWinDetails', 'hasWinWin']);
  const withRop = state.getIn(['productSelection', 'withRop']) && hasRopOption;
  const withExtendedFamily =
    hasExtendedFamilyOption &&
    state.getIn(['productSelection', 'withExtendedFamilyFuneral']);
  const extendedFamilyProductId = state.getIn([
    'packages',
    packageSlug,
    'extendedFamilyFuneralProduct',
  ]);
  const productRopPriceMap = state.getIn([
    'recommendation',
    'productRopPriceMap',
  ]);
  const discountRate = state.getIn(['config', 'DISCOUNT_RATE']);
  const winWinDiscountRate = hasWinWin
    ? state.getIn(['config', 'WINWIN_DISCOUNT_RATE'])
    : 0;
  const minimumPremium = state.getIn([
    'packages',
    packageSlug,
    'minimumPremium',
  ]);
  const packageExtras = state.getIn(['recommendation', 'extras'])
    ? state.getIn(['recommendation', 'extras']).toJS()
    : [];

  return {
    discountRate,
    winWinDiscountRate,
    hasWinWin,
    withRop,
    hasRopOption,
    hasExtendedFamilyOption,
    withExtendedFamily,
    extendedFamilyProductId,
    minimumPremium,
    productRopPriceMap,
    packageSlug,
    packageExtras,
    selectedPackage,
    packageDiscount,
  };
};

const calculatePricing = (productSelection, pricingConfig, income) => {
  const {
    packageExtras,
    productRopPriceMap,
    discountRate: appDiscount,
    packageDiscount,
    withRop,
    hasWinWin,
    hasRopOption,
    winWinDiscountRate,
    minimumPremium,
  } = pricingConfig;

  const packageOrAppDiscount = R.max(appDiscount, packageDiscount);
  const selectedProducts = filterSelectedProducts(productSelection);
  const premium = getPremium(selectedProducts, packageExtras);
  const ropPremium = getRopPremium(
    selectedProducts,
    productRopPriceMap,
    packageExtras
  );
  const discountedRopPremium = getDiscountedRopPremium(
    selectedProducts,
    productRopPriceMap,
    packageExtras,
    packageOrAppDiscount
  );
  const premiumWithRop = premium + ropPremium;
  const discountAmount = getDiscount(premium, packageOrAppDiscount);
  const discountAmountWithRop = getDiscount(
    premiumWithRop,
    packageOrAppDiscount
  );
  const winWinDiscountAmount = hasWinWin
    ? getDiscount(premium, winWinDiscountRate)
    : 0;
  const winWinDiscountAmountWithRop = hasWinWin
    ? getDiscount(premiumWithRop, winWinDiscountRate)
    : 0;
  const discountedPremiumWithRop =
    premiumWithRop - discountAmountWithRop - winWinDiscountAmountWithRop;
  const discountedPremium = premium - discountAmount - winWinDiscountAmount;
  const ropReturn = getRopReturn(premiumWithRop, ROP_PERCENTAGE);
  const ropTotalPremium = totalPremiumOverFiveYears(ropPremium);
  const checkWithRopIsAbovePremium =
    (withRop ? premiumWithRop : premiumWithRop - ropPremium) >= minimumPremium;
  const aboveMinPremium = hasRopOption
    ? checkWithRopIsAbovePremium
    : premium >= minimumPremium;
  return {
    cost: premium,
    finalCost: withRop ? discountedPremiumWithRop : discountedPremium,
    discountedPremiumWithRop,
    discountAmount: withRop ? discountAmountWithRop : discountAmount,
    discountRate: packageOrAppDiscount,
    winWinDiscountAmount,
    isNotAffordable: isNotAffordable(
      income,
      withRop ? premiumWithRop : premium
    ),
    ropPremium,
    discountedRopPremium,
    ropPrecentage: ROP_PERCENTAGE,
    ropReturn,
    ropTotalPremium,
    aboveMinPremium,
    minimumPremium,
    premiumWithRop,
  };
};

export default calculatePricing;

export const calculatePricingFromState = (state, packageUrlSlug) => {
  const config = getPricingConfig(state, packageUrlSlug);
  const income = getIncome(state);
  const productSelection = state.getIn(['productSelection', 'products']);
  return calculatePricing(productSelection, config, income);
};

export const getSuggestedPricing = (state, selectedProducts) => {
  const slug = state.get('selectedPackageSlug');
  const selectedPackage = state.getIn(['packages', slug]);
  const hasSuggestedPricing =
    selectedPackage.has('suggestPackageId') &&
    state.getIn(['recommendation', 'suggestedProductPriceMap']);
  if (!hasSuggestedPricing) {
    return { hasSuggestedPricing: false };
  }
  const suggestedPackageId = selectedPackage.get('suggestPackageId');
  const suggestedPackageSlug = state
    .get('packages')
    .valueSeq()
    .filter((p) => p.get('packageId') === suggestedPackageId)
    .first()
    .get('urlSlug');
  const baseConfig = getPricingConfig(state, suggestedPackageSlug);
  const priceMap = state.getIn(['recommendation', 'suggestedProductPriceMap']);
  const ropPriceMap = state.getIn([
    'recommendation',
    'suggestedProductRopPriceMap',
  ]);
  const config = R.assoc('productRopPriceMap', ropPriceMap, baseConfig);
  const suggestedProducts = selectedProducts
    .map((p) =>
      p.set(
        'productId',
        selectedPackage.getIn([
          'suggestPackageProductMap',
          suggestedPackageId,
          p.get('productId'),
        ])
      )
    )
    .map((p) =>
      p.set(
        'premium',
        priceMap.getIn([p.get('productId'), p.get('insuredAmount').toString()])
      )
    )
    .reduce((r, v) => r.set(v.get('productId'), v), new Map());
  const pricing = calculatePricing(suggestedProducts, config, getIncome(state));
  return {
    hasSuggestedPricing: true,
    finalCost: pricing.finalCost,
  };
};

import { fromJS, Set } from 'immutable';
import {
  RESET,
  ADD_PEEP,
  SELECT_BENEFICIARY,
  DESELECT_BENEFICIARY,
  SELECT_INSURED,
  DESELECT_INSURED,
  SAVE_PEEPS,
  SAVE_PEEPS_FAILURE,
  SAVE_PEEPS_SUCCESS,
  TOGGLE_HAS_NO_SPOURSE_OR_CHILDREN_UNDER_21,
} from '../actions/types';
import createActionHandler from './createActionHandler';

const initialState = fromJS({
  peeps: [],
  beneficiaries: {},
  insureds: {},
  isSaving: false,
  hasNoSpouseOrChildrenUnder21: false,
});

const toInitialState = () => initialState;
const isSaving = state => state.set('isSaving', true);
const isNotSaving = state => state.set('isSaving', false);

const addPeep = (state, action) =>
  state.update('peeps', peeps => new Set(peeps).add(fromJS(action.payload)));

const createProductIfNotExists = (type, productId, state) => {
  if (!state.hasIn([type, productId])) {
    return state.setIn([type, productId], new Set());
  }
  return state;
};

const selectBeneficiary = (state, action) => {
  const { productId, peep } = action.payload;
  const s = createProductIfNotExists('beneficiaries', productId, state);
  return s.updateIn(['beneficiaries', productId], peeps =>
    new Set(peeps).add(fromJS(peep))
  );
};

const deselectBeneficiary = (state, action) => {
  const { productId, peep } = action.payload;
  return state.updateIn(['beneficiaries', productId], peeps =>
    new Set(peeps).delete(fromJS(peep))
  );
};

const selectInsured = (state, action) => {
  const { productId, peep } = action.payload;
  const s = createProductIfNotExists('insureds', productId, state);
  return s.updateIn(['insureds', productId], peeps =>
    new Set(peeps).add(fromJS(peep))
  );
};

const deselectInsured = (state, action) => {
  const { productId, peep } = action.payload;
  return state.updateIn(['insureds', productId], peeps =>
    new Set(peeps).delete(fromJS(peep))
  );
};

const toggleHasNoSpouseOrChildrenUnder21 = (state, { payload }) => {
  return state.set(
    'hasNoSpouseOrChildrenUnder21',
    payload.hasNoSpouseOrChildrenUnder21
  );
};

const actionHandlers = {};

actionHandlers[RESET] = toInitialState;
actionHandlers[ADD_PEEP] = addPeep;
actionHandlers[SELECT_BENEFICIARY] = selectBeneficiary;
actionHandlers[DESELECT_BENEFICIARY] = deselectBeneficiary;
actionHandlers[SELECT_INSURED] = selectInsured;
actionHandlers[DESELECT_INSURED] = deselectInsured;
actionHandlers[SAVE_PEEPS] = isSaving;
actionHandlers[
  TOGGLE_HAS_NO_SPOURSE_OR_CHILDREN_UNDER_21
] = toggleHasNoSpouseOrChildrenUnder21;
actionHandlers[SAVE_PEEPS_SUCCESS] = isNotSaving;
actionHandlers[SAVE_PEEPS_FAILURE] = isNotSaving;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

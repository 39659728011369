import { connect } from 'react-redux';
import {
  stateToQuestionnaireProps,
  answerQuestion,
} from '../questionnaire/toQuestionPageProps';
import { sendRehydrationLink } from '../../actions/rehydration';
import {
  getSelectedPackageIdOrDefault,
  getPackageSlugFromId,
} from '../../common/getPackageIdFromSlug';
import * as R from 'ramda';
import { fromJS } from 'immutable';
import SendMyLink from '../common/SendMyLink';
import { isBrokerSale } from '../../common/brokers';

const mapStateToProps = (state, ownProps) => {
  const packageId = getSelectedPackageIdOrDefault(state);
  const packageSlug = getPackageSlugFromId(state, packageId);
  const selectedPackage = state.getIn(['packages', packageSlug]);
  const rehydrationLink = state.getIn(['rehydration', 'url']);
  const isBrokerSaleFlag = isBrokerSale(state);
  const pageQuestions = selectedPackage
    .get('contactInfo')
    .valueSeq()
    .toList();
  return R.merge(
    {
      pageQuestions,
      rehydrationLink,
      packageId,
      caller: ownProps.caller,
      isBrokerSale: isBrokerSaleFlag,
    },
    stateToQuestionnaireProps(state, pageQuestions, fromJS([]))
  );
};

const sendLink = R.curry((dispatch, rehydrationLink, toggleLinkSent) => {
  toggleLinkSent();
  dispatch(sendRehydrationLink(rehydrationLink));
});

const mapDispatchToProps = dispatch => ({
  answerQuestion: answerQuestion(dispatch),
  sendLink: sendLink(dispatch),
});

const mergeProps = (stateProps, dispatchProps) =>
  Object.assign({}, stateProps, dispatchProps, {
    answerQuestion: dispatchProps.answerQuestion(stateProps.packageId),
    sendLink: dispatchProps.sendLink(stateProps.rehydrationLink),
    rehydrationLink: stateProps.rehydrationLink,
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SendMyLink);

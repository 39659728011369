import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Home from '../home/Home';

export default class ActionOnLandingPage extends PureComponent {
  componentDidMount() {
    this.props.landed();
  }

  render() {
    return <Home />;
  }
}

ActionOnLandingPage.propTypes = {
  landed: PropTypes.func.isRequired,
};

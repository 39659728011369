import React from 'react';
import InputQuestion from '../questions/InputQuestion';
import PhoneNumberQuestion from '../questions/PhoneNumberQuestion';
import ProperCaseQuestion from '../questions/ProperCaseQuestion';
import AcceptCheckboxQuestion from '../questions/AcceptCheckboxQuestion';
import * as shared from '../../.shared/validators';
import { fromJS, List } from 'immutable';
import * as R from 'ramda';
import { getFieldUtilities } from './inputTypes';

const toErrors = validationResult =>
  new List([validationResult])
    .filter(v => v.get('hasValue') && !v.get('isValid'))
    .map(v => v.get('errors'))
    .reduce((r, v) => r.concat(v), new List())
    .toJS();

export const toProperCaseInput = R.curry(
  (validator, title, answerQuestion, value) => {
    const validationResult = fromJS(validator(value)).set('hasValue', !!value);
    const errors = toErrors(validationResult);
    const canSubmit =
      validationResult.get('hasValue') === true &&
      validationResult.get('isValid') === true;
    return {
      canSubmit,
      control: (
        <ProperCaseQuestion
          title={title}
          answerQuestion={answerQuestion}
          answer={value}
          initialValue={value}
          errors={errors}
        />
      ),
    };
  }
);

export const toInput = R.curry((validator, title, answerQuestion, value) => {
  const validationResult = fromJS(validator(value)).set('hasValue', !!value);
  const errors = toErrors(validationResult);
  const canSubmit =
    validationResult.get('hasValue') === true &&
    validationResult.get('isValid') === true;
  return {
    canSubmit,
    control: (
      <InputQuestion
        title={title}
        answerQuestion={answerQuestion}
        answer={value}
        initialValue={value}
        errors={errors}
      />
    ),
  };
});

export const toPhoneNumberInput = R.curry(
  (validator, title, answerQuestion, value) => {
    const validationResult = fromJS(validator(value)).set('hasValue', !!value);
    const errors = toErrors(validationResult);
    const canSubmit =
      validationResult.get('hasValue') === true &&
      validationResult.get('isValid') === true;
    return {
      canSubmit,
      control: (
        <PhoneNumberQuestion
          title={title}
          answerQuestion={answerQuestion}
          answer={value}
          initialValue={value}
          errors={errors}
        />
      ),
    };
  }
);

export const toOptionalInput = R.curry(
  (validator, title, answerQuestion, value) => {
    if (value) {
      return toInput(validator, title, answerQuestion, value);
    }
    return {
      canSubmit: true,
      control: (
        <InputQuestion
          title={title}
          answerQuestion={answerQuestion}
          answer={value}
          initialValue={value}
          optional
        />
      ),
    };
  }
);

export const toCellNumberInput = toInput(
  shared.validateRequiredCellPhone,
  'Cell Number'
);
export const toFriendsCellNumberInput = toInput(
  shared.validateRequiredCellPhone,
  "Your friend's cellphone number:"
); // eslint-disable-line max-len
export const toFullnameInput = toProperCaseInput(
  shared.validateRequiredText,
  'Full Name'
);
export const toFriendsFullnameInput = toProperCaseInput(
  shared.validateRequiredText,
  "Your friend's name:"
); // eslint-disable-line max-len
export const toOptionalEmailInput = toOptionalInput(
  shared.validateRequiredEmail,
  'Email'
);

export const toTermsInput = (text, hasAcceptedTerms, toggle) => {
  const onChange = e => toggle(e.target.value);
  return (
    <AcceptCheckboxQuestion
      checked={hasAcceptedTerms}
      onChange={onChange}
      text={text}
    />
  );
};

export const generateInputField = dispatch => (
  owner,
  fieldname,
  type,
  label,
  value,
  validation
) => {
  const fieldUtilities = getFieldUtilities();
  if (!R.has(type, fieldUtilities)) {
    throw new Error(`No fieldUtil for ${type}`);
  }
  const { constructField, saveToState, setValidation } = fieldUtilities[type];
  const validator = setValidation(validation);
  const onSave = saveToState(dispatch, fieldname, owner);
  return constructField(validator, label, onSave, value);
};

import { fromJS } from 'immutable';
import {
  PERSAL_START_AFFORDABILITY_CHECK,
  PERSAL_FINISH_AFFORDABILITY_CHECK,
  PERSAL_START_MANDATE_UPLOAD,
  PERSAL_FINISH_MANDATE_UPLOAD,
  PERSAL_START_MANDATE_DOWNLOAD,
  PERSAL_FINISH_MANDATE_DOWNLOAD,
  PERSAL_FINISH_ELECTRONIC_MANDATE_REQUEST,
  PERSAL_SELECT_MANDATE_TYPE,
  PERSAL_START_CONFIRM_ELECTRONIC_MANDATE_SIGNED,
  PERSAL_FINISH_CONFIRM_ELECTRONIC_MANDATE_SIGNED,
  PERSAL_RESTART,
  PERSAL_QA_RESTART,
  PERSAL_ERROR,
  TOGGLE_VOICE_MANDATE_TERM,
  PERSAL_SET_COLLECTION_DATES,
} from '../actions/types';
import createActionHandler from './createActionHandler';
import { PERSAL_STAGE_START,
  PERSAL_STAGE_BUSY,
  PERSAL_STAGE_QA,
  PERSAL_STAGE_FINISH,
  PERSAL_STAGE_AFFORDABLE,
  PERSAL_STAGE_INVALID_EMPLOYEE,
  PERSAL_STAGE_NOT_AFFORDABLE,
  PERSAL_STAGE_POLLING,
  PERSAL_STAGE_UNKNOWN } from '../config/constants';


const initialState = fromJS({
  stage: PERSAL_STAGE_START,
  affordability: null,
  voiceMandateTermAccepted: false,
});

const actionHandlers = {};

const busy = (state) => {
  return state
    .set('stage', PERSAL_STAGE_BUSY)
    .set('error', null)
    .set('previousStage', state.get('stage'));
};

const notBusy = (state) => {
  return state.set('stage', state.get('previousStage'));
};

const restart = state => initialState; // eslint-disable-line

const qaRestart = state => {
  return state.set('stage', PERSAL_STAGE_QA);
};

const err = state => {
  return state
    .set('stage', state.get('previousStage'))
    .set('error', 'Something serious has gone wrong');
};

const stageFromAffordabilityResult = (result) => {
  if (result.hasValidStatus === true && result.isAffordable === true) {
    return PERSAL_STAGE_AFFORDABLE;
  }
  if (result.hasValidStatus === false) {
    return PERSAL_STAGE_INVALID_EMPLOYEE;
  }
  if (result.isAffordable === false) {
    if (result.isPolling === true) {
      return PERSAL_STAGE_POLLING;
    }
    return PERSAL_STAGE_NOT_AFFORDABLE;
  }
  return PERSAL_STAGE_UNKNOWN;
};

const finishAffordabilityCheck = (state, action) => {
  const effectiveInceptionDate = action.payload.inceptionDate;

  return state
    .set('stage', stageFromAffordabilityResult(action.payload))
    .set('affordability', action.payload)
    .set('paymentDetails', fromJS({
      paymentType: 'persal',
      employeeNumber: action.payload.employeeNumber,
      payrollIdentifier: action.payload.payrollIdentifier,
      mandateType: 'not-selected',
      inceptionMonth: effectiveInceptionDate,
      firstSalaryDeductionCollectionMonth: action.payload.salaryMonth,
    }));
};

const selectMandateType = (state, action) => {
  return state
    .setIn(['paymentDetails', 'mandateType'], action.payload.mandateType);
};

const finishMandateUpload = (state, action) => {
  return state
    .set('stage', PERSAL_STAGE_FINISH)
    .set('mandate', action.payload);
};

const finishElectronicMandateRequest = (state, action) => {
  return state
    .set('electronicMandate', fromJS(action.payload));
};

const startConfirmMandateSigned = (state) => {
  return state.setIn(['electronicMandate', 'isUploaded'], 'checking');
};

const toggleVoiceMandateTerm = (state, action) => {
  const isAccepted = action.payload.isAccepted;
  if (isAccepted) {
    return state.setIn(['voiceMandateTermAccepted'], isAccepted)
      .set('stage', PERSAL_STAGE_FINISH);
  }
  return state.setIn(['voiceMandateTermAccepted'], isAccepted)
    .set('stage', PERSAL_STAGE_AFFORDABLE);
};

const finishConfirmMandateSigned = (state, action) => {
  const isFinished = action.payload.isUploaded === true;
  const newState = state.setIn(['electronicMandate', 'isUploaded'], action.payload.isUploaded);
  if(isFinished) {
    return newState.set('stage', PERSAL_STAGE_FINISH);
  }
  return newState;
};

const setCollectionDates = (state, action) => {
  const inceptionDate = action.payload.inceptionDate;
  const salaryMonth = action.payload.salaryMonth;

  return state
    .setIn(['paymentDetails', 'inceptionMonth'], inceptionDate)
    .setIn(['paymentDetails', 'firstSalaryDeductionCollectionMonth'], salaryMonth);
};

actionHandlers[PERSAL_START_AFFORDABILITY_CHECK] = busy;
actionHandlers[PERSAL_FINISH_AFFORDABILITY_CHECK] = finishAffordabilityCheck;
actionHandlers[PERSAL_START_MANDATE_UPLOAD] = busy;
actionHandlers[PERSAL_FINISH_MANDATE_UPLOAD] = finishMandateUpload;
actionHandlers[PERSAL_START_MANDATE_DOWNLOAD] = busy;
actionHandlers[PERSAL_FINISH_MANDATE_DOWNLOAD] = notBusy;
actionHandlers[PERSAL_FINISH_ELECTRONIC_MANDATE_REQUEST] = finishElectronicMandateRequest;
actionHandlers[PERSAL_SELECT_MANDATE_TYPE] = selectMandateType;
actionHandlers[PERSAL_START_CONFIRM_ELECTRONIC_MANDATE_SIGNED] = startConfirmMandateSigned;
actionHandlers[PERSAL_FINISH_CONFIRM_ELECTRONIC_MANDATE_SIGNED] = finishConfirmMandateSigned;
actionHandlers[PERSAL_RESTART] = restart;
actionHandlers[PERSAL_QA_RESTART] = qaRestart;
actionHandlers[PERSAL_ERROR] = err;
actionHandlers[TOGGLE_VOICE_MANDATE_TERM] = toggleVoiceMandateTerm;
actionHandlers[PERSAL_SET_COLLECTION_DATES] = setCollectionDates;


const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

import { Map } from 'immutable';
import {
  REHYDRATE_INTERACTION_FAILURE,
  REHYDRATE_INTERACTION_SUCCESS,
  REHYDRATE_INTERACTION,
  RESET,
  SAVE_LAST_ROUTE,
  SET_REHYDRATION_URL_SUCCESS,
  REHYDRATION_COMPLETE,
  SAVE_LAST_LOCATION,
} from '../actions/types';
import { API_ROUTES } from '../config/constants';
import request from 'axios';
import { LOCATION_CHANGE } from 'connected-react-router';
import createActionHandler from './createActionHandler';
import { isBlockedRehydrationRoute } from '../actions/rehydration';

const initialState = Map();

const actionHandlers = {};

const toInitialState = () => initialState;

const toRehydrateState = state => state;

const toRehydrateSuccessState = (state, action) => {
  const savedState = action.payload;
  return state.merge(savedState);
};

const toRehydrateUrlSucessState = (state, action) => {
  const savedState = action.payload;
  return state.merge(savedState);
};

const saveLastLocation = (state, action) => {
  if (!isBlockedRehydrationRoute(action.payload)) {
    request
      .post(API_ROUTES.SAVE_LAST_LOCATION, action.payload)
      .then(response => {
        if (response.status === 200) {
          if (action.payload.executeAfter) {
            action.payload.executeAfter();
          }
        }
      })
      .catch(err => {
        throw err;
      });
  }
  return state.set('lastLocation', action.payload);
};

const saveRehydratedLastLocation = (state, action) => {
  return state.set('rehydratedLastLocation', {
    location: action.payload,
    isRehydrating: action.meta || false,
  });
};

const completeRehydration = state => {
  return state.set('rehydratedLastLocation', {
    isRehydrating: false,
  });
};

const toRehydrateErrorState = state => state;

actionHandlers[RESET] = toInitialState;
actionHandlers[REHYDRATE_INTERACTION] = toRehydrateState;
actionHandlers[REHYDRATE_INTERACTION_SUCCESS] = toRehydrateSuccessState;
actionHandlers[REHYDRATE_INTERACTION_FAILURE] = toRehydrateErrorState;
actionHandlers[SET_REHYDRATION_URL_SUCCESS] = toRehydrateUrlSucessState;
actionHandlers[LOCATION_CHANGE] = saveLastLocation;
actionHandlers[SAVE_LAST_ROUTE] = saveRehydratedLastLocation;
actionHandlers[REHYDRATION_COMPLETE] = completeRehydration;
actionHandlers[SAVE_LAST_LOCATION] = saveLastLocation;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

import { fromJS, Map } from 'immutable';
import * as R from 'ramda';
import isReflexive, {
  isReflexiveCheckboxes,
} from '../../.shared/validators/isReflexive';
import DropdownQuestion from './DropdownQuestion';
import TrailingPlusQuestion from './TrailingPlusQuestion';
import InputQuestion from './InputQuestion';
import ProperCaseQuestion from './ProperCaseQuestion';
import NumberQuestion from './NumberQuestion';
import PhoneNumberQuestion from './PhoneNumberQuestion';
import RadioSelectQuestion from './RadioSelectQuestion';
import DateQuestion from './DateQuestion';
import CheckboxQuestion from './CheckboxQuestion';
import ReflexiveQuestion from './ReflexiveQuestion';
import ReflexiveCheckboxListQuestion from './ReflexiveCheckboxListQuestion';
import { SearchableMultiSelectListQuestion } from './SearchableMultiSelectListQuestion';
import { SearchableSingleSelectListQuestion } from './SearchableSingleSelectListQuestion';
import AutocompleteAddressQuestion from './AutocompleteAddressQuestion';
import Statement from './Statement';

const DROPDOWN = 'dropdown';
const TRAILINGPLUS = 'trailing-plus';
const SELECTION = 'selection';
const REFLEXIVE = 'reflexive';
const REFLEXIVE_CHECKBOX = 'reflexive-checkbox';
const CHECKBOX = 'checkbox';
const TEXT = 'text';
const PROPERCASE_TEXT = 'propercase-text';
const NUMBER = 'number';
const PHONE_NUMBER = 'phone-number';
const DATE = 'date';
const SEARCHABLE_SELECTION = 'searchable-selection';
const SEARCHABLE_SINGLE_SELECTION = 'searchable-single-selection';
const REFLEXIVE_SEARCHABLE_SELECTION = 'reflexive-searchable-selection';
const ADDRESS = 'autocomplete-address';
const STATEMENT = 'statement';

const mapping = {};
mapping[SELECTION] = RadioSelectQuestion;
mapping[DROPDOWN] = DropdownQuestion;
mapping[TRAILINGPLUS] = TrailingPlusQuestion;
mapping[TEXT] = InputQuestion;
mapping[PROPERCASE_TEXT] = ProperCaseQuestion;
mapping[NUMBER] = NumberQuestion;
mapping[PHONE_NUMBER] = PhoneNumberQuestion;
mapping[DATE] = DateQuestion;
mapping[REFLEXIVE] = ReflexiveQuestion;
mapping[REFLEXIVE_CHECKBOX] = ReflexiveCheckboxListQuestion;
mapping[CHECKBOX] = CheckboxQuestion;
mapping[SEARCHABLE_SELECTION] = SearchableMultiSelectListQuestion;
mapping[SEARCHABLE_SINGLE_SELECTION] = SearchableSingleSelectListQuestion;
mapping[REFLEXIVE_SEARCHABLE_SELECTION] = SearchableMultiSelectListQuestion;
mapping[ADDRESS] = AutocompleteAddressQuestion;
mapping[STATEMENT] = Statement;

const questionMapping = fromJS(mapping);

const controlTypeExists = (type) => questionMapping.has(type);

const typeToQuestionControl = (type) => {
  if (!controlTypeExists(type)) {
    const err = `Question Control for type ${type} does not exist`;
    throw err;
  }

  return questionMapping.get(type);
};

const shouldRenderDropdown = (question) =>
  question.get('possibleAnswers').size > 5;
const isSelection = (question) => question.get('type') === SELECTION;

// to be optimized
const isInline = (question) => {
  const typeExists = R.isNil(question.get('type'));
  return !typeExists
    ? R.includes('inline', question.get('type').split('-'))
    : R.F();
};

const isQuestionWithNoInput = (question) => {
  return question.has('type') && question.get('type') === 'statement';
};

const makeInlineQuestionMap = (question) => {
  const pureType = R.last(question.get('type').split('-'));
  const pureTypeQuestion = R.assoc('type', pureType, question.toJS());
  return fromJS(R.assoc('isInline', true, pureTypeQuestion));
};

const toQuestionType = (q) => {
  const question = isInline(q) ? makeInlineQuestionMap(q) : q;
  if (isQuestionWithNoInput(question)) {
    return STATEMENT;
  }
  if (isReflexive(question)) {
    return REFLEXIVE;
  }
  if (isReflexiveCheckboxes(question)) {
    return REFLEXIVE_CHECKBOX;
  }
  if (isSelection(question)) {
    if (shouldRenderDropdown(question)) {
      return DROPDOWN;
    }
    return SELECTION;
  }
  return question.get('type');
};

const isMap = (question) => Map.isMap(question);
const hasNodeId = (question) => question.has('nodeId');
const hasType = (question) => question.has('type');
const isValidQuestionForm = (question) =>
  hasNodeId(question) || hasType(question);

const validateQuestion = (question) => {
  if (!isMap(question)) {
    throw new 'Question not of type Map'();
  }
  if (!isValidQuestionForm(question)) {
    throw new 'Question requires either a type or a nodeId'();
  }
  return question;
};

const toQuestionControl = R.pipe(
  validateQuestion,
  toQuestionType,
  typeToQuestionControl
);

export default toQuestionControl;

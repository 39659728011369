import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from '@simply-fin-services/astronomix3';
import * as R from 'ramda';
import QuestionContainer, {
  isNotEligible,
  showError,
} from './QuestionContainer';
import { QUESTION_TIMEOUT_DELAY_IN_MILLISECONDS } from '../../config/appConfig';

const INITIAL_ANSWER = '';
class NumberQuestion extends PureComponent {
  constructor() {
    super();
    this.answer = this.answer.bind(this);
    this.onChange = this.onChange.bind(this);
    this.startSubmitTimer = this.startSubmitTimer.bind(this);
    this.clearSubmitTimer = this.clearSubmitTimer.bind(this);
    this.submitQuestion = this.submitQuestion.bind(this);
    this.typingTimeout = null;
    this.submittedValue = INITIAL_ANSWER;
  }

  componentWillMount() {
    this.setState({ value: this.props.answer || undefined });
  }

  answer() {
    const { value } = this.state;
    const { answerQuestion } = this.props;
    answerQuestion(value);
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  startSubmitTimer() {
    this.clearSubmitTimer();
    this.typingTimeout = window.setTimeout(() => {
      this.submitQuestion();
    }, QUESTION_TIMEOUT_DELAY_IN_MILLISECONDS);
  }

  clearSubmitTimer() {
    window.clearTimeout(this.typingTimeout);
  }

  submitQuestion() {
    this.clearSubmitTimer();
    const { value } = this.state;
    if (value !== this.submittedValue) {
      const { answerQuestion } = this.props;
      answerQuestion(value);
    }
    this.submittedValue = value;
  }

  render() {
    const {
      placeholder,
      disable,
      icon,
      eligibility,
      errors,
      hasWarning,
      warnings,
      packageId,
      hint,
      title,
      max,
      min,
      optional,
      currency
    } = this.props;

    const { value } = this.state;

    const disabled = disable || false;
    const hasError = !R.isNil(errors) && !R.isEmpty(errors);

    const eligibilityErrors =
      isNotEligible(eligibility, packageId) && eligibility.failureMessage
        ? [eligibility.failureMessage]
        : [];
    const errorMessages = showError(this.props) ? errors || [] : [];
    const warningMessages = hasWarning ? warnings : [];
    const allErrors = R.concat(
      R.concat(errorMessages, warningMessages),
      eligibilityErrors
    );

    return (
      <QuestionContainer>
        <InputNumber
          type="number"
          label={title}
          hasError={hasError}
          errors={allErrors}
          placeholder={placeholder}
          onBlur={this.submitQuestion}
          onChange={this.onChange}
          onKeyUp={this.startSubmitTimer}
          onKeyDown={this.clearSubmitTimer}
          value={value}
          disabled={disabled}
          isOptional={optional}
          hint={hint}
          icon={icon}
          pattern="^[1-9]\d*$"
          min={min}
          max={max}
          currency={currency || false}
        />
      </QuestionContainer>
    );
  }
}

NumberQuestion.propTypes = {
  answerQuestion: PropTypes.func,
  placeholder: PropTypes.string,
  answer: PropTypes.string,
  initialValue: PropTypes.number,
  disable: PropTypes.bool,
};

export default NumberQuestion;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InputQuestion from '../questions/InputQuestion';
import PhoneNumberQuestion from '../questions/PhoneNumberQuestion';
import LoadingRow from '../common/LoadingRow';
import { validateCallMeBack } from '../../.shared/validators/validateCallMeBack';
import * as R from 'ramda';
import { fromJS } from 'immutable';
import {
  validateRequiredText,
  validateRequiredCellPhone,
} from '../../.shared/validators';
import { SecondaryButton, Buttons } from '@simply-fin-services/astronomix3';

const answerQuestion = R.curry((a, id, answer) => a(id, answer));
const getSubmitableDetails = (details) =>
  details.map((detail) => detail.get('value'));

const PHONENUMBER_HINT = `By providing us with this number, you authorise Simply to contact
 you via SMS or phone to help you with this application or to tell you about other products.
 You can opt out of being contacted at any time.`;
export default class CallMeBackForm extends PureComponent {
  constructor() {
    super();
    this.setFullName = this.setFullName.bind(this);
    this.setNumber = this.setNumber.bind(this);
    this.saveDetails = this.saveDetails.bind(this);
  }

  componentWillMount() {
    const details = fromJS({});
    this.setState({ details });
  }

  setFullName(id, value) {
    this.setDetail(id, validateRequiredText(value));
  }

  setNumber(id, value) {
    this.setDetail(id, validateRequiredCellPhone(value));
  }

  setDetail(id, value) {
    const details = this.state.details.set(id, fromJS(value));
    this.setState({ details });
  }

  saveDetails() {
    const { saveDetails } = this.props;
    const details = this.state.details;
    const submitableDetails = getSubmitableDetails(details);
    saveDetails(submitableDetails);
  }

  render() {
    const { details } = this.state;
    const { isSaving } = this.props;
    const isValid = validateCallMeBack(getSubmitableDetails(details)).get(
      'isValid'
    );
    return (
      <div className="row">
        <div className="col-xs-12">
          <h4 className="text-center">
            Need us to give you a call? Just enter your details below:
          </h4>
          <form onSubmit={(e) => e.preventDefault()}>
            <InputQuestion
              title="Full name"
              answerQuestion={answerQuestion(this.setFullName, 'full-name')}
              {...(details.get('full-name')
                ? details.get('full-name').toJS()
                : {})}
            />
            <PhoneNumberQuestion
              title="Cell number"
              hint={PHONENUMBER_HINT}
              answerQuestion={answerQuestion(
                this.setNumber,
                'cellphone-number'
              )}
              {...(details.get('cellphone-number')
                ? details.get('cellphone-number').toJS()
                : {})}
            />
            <div className="text-center">
              {!isSaving && (
                <Buttons>
                  <SecondaryButton
                    onClick={this.saveDetails}
                    disabled={!isValid}
                  >
                    Call Me Back
                  </SecondaryButton>
                </Buttons>
              )}
              {isSaving && <LoadingRow />}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

CallMeBackForm.propTypes = {
  saveDetails: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

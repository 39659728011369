// This function is probably is the wrong place now
// since this functionality has been moved to the common folder
import { getPricingOverlayConfig } from '../views/layout/pricingOverlayConfig';

const getPricingOverlayConfigFromState = (state) => {
  const props = {
    currentPage: state.getIn(['router', 'location', 'pathname']),
    productSelection: state.getIn(['productSelection', 'products']),
    packageUrlSlug: state.get('selectedPackageSlug'),
  };
  const config = getPricingOverlayConfig(props, state);
  return config || { products: [], total: 0 };
};

const canBuildQuerystring = (config) => {
  return config.total.toString() !== '0';
};

const getTotalKeyValue = (total) => {
  return `total=${total}`;
};

const getProductsKeyValue = (products) => {
  const value = products.map((p) => `${p.id}=${p.price}`);
  return value.join('&');
};

const getRehydrationKeyValue = (state) => {
  const link = state.getIn(['rehydration', 'url']);
  return `rehydrationLink=${link}`;
};

const getLinksQuery = (state) => {
  const config = getPricingOverlayConfigFromState(state);
  if (canBuildQuerystring(config)) {
    const total = getTotalKeyValue(config.total);
    const products = getProductsKeyValue(config.products);
    const rehydrationLink = getRehydrationKeyValue(state);
    const keyValuePairs = [total, products, rehydrationLink];
    const querystring = keyValuePairs.join('&');
    return `?${querystring}`;
  }
  return '';
};

export { getLinksQuery };

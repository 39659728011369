import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AutoCompleteAddress } from '@simply-fin-services/astronomix3';
import { readConfigFromState } from '../../config/appConfig';

import * as R from 'ramda';
import QuestionContainer from './QuestionContainer';

class AutocompleteAddressQuestion extends PureComponent {
  constructor(props) {
    super();
    this.props = props;
    this.initialValue = this.props.answer || '';
    this.apiKey = readConfigFromState('GOOGLE_MAPS_API_KEY');
  }

  handleSelect = (address) => {
    const { answerQuestion } = this.props;
    answerQuestion(address);
  };

  render() {
    const { placeholder, disable, questionId, errors, title } = this.props;
    const disabled = disable || false;
    const hasError = !R.isNil(errors) && !R.isEmpty(errors);
    return (
      <QuestionContainer>
        <AutoCompleteAddress
          onSelect={this.handleSelect}
          apiKey={this.apiKey}
          initialValue={this.initialValue}
          name={questionId}
          hasError={hasError}
          disabled={disabled}
          placeholder={placeholder}
          errors={errors}
          label={title}
        />
      </QuestionContainer>
    );
  }
}

AutocompleteAddressQuestion.propTypes = {
  answerQuestion: PropTypes.func,
  answer: PropTypes.string,
  disable: PropTypes.bool,
  placeholder: PropTypes.string,
  questionId: PropTypes.string,
  errors: PropTypes.array,
};

export default AutocompleteAddressQuestion;

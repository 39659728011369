/* eslint-disable max-len */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  linkReferrerCode,
  linkExistingReferrerCode,
} from '../../actions/referrerLinking';
import PageSeo from '../common/PageSeo';
import { ROUTES } from '../../config/constants';
import { Button, TwoPaneContainer } from '@simply-fin-services/astronomix3';

import PageDescription from '../common/PageDescription';
import styled from 'styled-components';

const p = <span className="alternate">+</span>;
const pageImage =
  'https://storage.googleapis.com/simply-public/people_heart.png';

const CenterDiv = styled.div`
  text-align: center;
`;

const TickIcon = () => (
  <svg
    width="20"
    height="25"
    viewBox="-5 -10 24 24"
    fill="none"
    stroke="#fff"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="7.5" cy="7.5" r="8" fill="#0876AC" strokeWidth="0" />
    <path d="M2.90625 8.59375 c 1.7561681,0.7702427 2.7523447,1.871153 3.6875,3.3125 0.6687211,-2.8631278 2.0524127,-7.3946548 4.6875,-8.71875" />
  </svg>
);

const bullet1 = (
  <div className="text-left">
    <span>
      <TickIcon />
    </span>
    <span className="text">100% online process</span>
  </div>
);

const bullet2 = (
  <div className="text-left">
    <span>
      <TickIcon />
    </span>
    <span className="text">Backed by trusted insurer</span>
  </div>
);

const bullet3 = (
  <div className="text-left">
    <span>
      <TickIcon />
    </span>
    <span className="text">Instant cover</span>
  </div>
);

const bullet4 = (
  <div className="text-left">
    <span>
      <TickIcon />
    </span>
    <span className="text">Awesome value</span>
  </div>
);

class ReferrerLandingPage extends PureComponent {
  componentDidMount() {
    this.props.landed();
  }

  render() {
    const { referrerName, WEB_SITE_BASE, redirectTo } = this.props;
    const description = 'Welcome to SIMPLY, where friendships are a WIN-WIN!';
    const windowRedirectTo = route => (window.location = route);
    return (
      <div>
        <PageSeo title="Simply" description={description} image={pageImage} />
        <PageDescription description="Welcome to SIMPLY" />
        <div className="row">
          <CenterDiv>
            <p>
              Thanks to <strong>{referrerName}</strong> you can get a 5%
              discount on your premiums!
            </p>
            <p>
              Simply offers great value life insurance combos for you and your
              employees.
            </p>
            <h5 className="bold">Benefits include:</h5>
            <div className="space-below">
              <TwoPaneContainer
                left={bullet1}
                right={bullet2}
                vertical="left"
                minheight="0"
              />
              <TwoPaneContainer
                left={bullet3}
                right={bullet4}
                vertical="left"
                minheight="0"
              />
            </div>
          </CenterDiv>
          <CenterDiv>
            <p>
              <strong>Get a free quote</strong> in a few seconds by selecting
              one of our three products below{' '}
            </p>
            <div className="text-center">
              <div className="space-below">
                <p>Cover for me and my family</p>
                <Button
                  size="medium"
                  onClick={() => redirectTo(ROUTES.QUOTE_FAMILY)}
                >
                  Family Cover<span> → </span>
                </Button>
              </div>
              <div className="space-below">
                <p className="">Cover for my domestic worker</p>
                <Button
                  size="medium"
                  onClick={() => redirectTo(ROUTES.QUOTE_DOMESTIC)}
                >
                  Domestic Cover<span> → </span>
                </Button>
              </div>
              <div className="space-below">
                <p className="">Cover for my employees</p>
                <Button
                  size="medium"
                  onClick={() =>
                    windowRedirectTo(WEB_SITE_BASE.concat(ROUTES.QUOTE_GROUP))
                  }
                >
                  Group Cover<span> → </span>
                </Button>
              </div>
            </div>
          </CenterDiv>
        </div>
        <br />
      </div>
    );
  }
}

ReferrerLandingPage.propTypes = {
  landed: PropTypes.func.isRequired,
  referrerName: PropTypes.string.isRequired,
};

export const mapStateToProps = (state, ownProps) => ({
  referrerName: ownProps.match.params.name,
  WEB_SITE_BASE: state.getIn(['config', 'WEB_SITE_BASE']),
});

export const mapDispatchToProps = (dispatch, ownProps) => {
  const { referralCode } = ownProps.match.params;
  return {
    redirectTo: route => dispatch(push(route)),
    landed: () => {
      dispatch(linkReferrerCode(referralCode));
      dispatch(linkExistingReferrerCode());
    },
  };
};

const ReferrerLandingPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferrerLandingPage);

export default ReferrerLandingPageContainer;

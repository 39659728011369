import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { reset } from '../../actions/startup';
import { ROUTES } from '../../config/constants';

class BackToPolicies extends PureComponent {
  render() {
    const { hasPolicies, goBack } = this.props;
    if (hasPolicies) {
      return (
        <p className="text-center">
          Click{' '}
          <a className="pointer" onClick={goBack}>
            here
          </a>{' '}
          to view your policy
        </p>
      );
    }
    return null;
  }
}

BackToPolicies.propTypes = {
  hasPolicies: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  hasPolicies: !state.getIn(['policyInformation', 'policies']).isEmpty(),
});

const mapDispatchToProps = dispatch => ({
  goBack: () => {
    dispatch(push(ROUTES.THANK_YOU));
    dispatch(reset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackToPolicies);

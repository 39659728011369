import React from 'react';
import PropTypes from 'prop-types';
import LoadingRow from '../common/LoadingRow';
import CompleteSaleErrors from '../thankYou/CompleteSaleErrors';
import ChoosePaymentType from './ChoosePaymentType';

function BankingDetailsPage(props) {
  const { isSaving, next, updateCorrectionComments, sendForCorrections, rejectSale} = props;
  return (
    <div>
      <ChoosePaymentType isSaving={isSaving} next={next} updateCorrectionComments={updateCorrectionComments} sendForCorrections={sendForCorrections} rejectSale={rejectSale}/>

      {isSaving && <LoadingRow />}

      <CompleteSaleErrors />
    </div>
  );
}

BankingDetailsPage.propTypes = {
  next: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default BankingDetailsPage;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import QuoteContainer from './QuoteContainer';
import { PageDescriptionHtml } from '../common/PageDescription';
import PageSeo from '../common/PageSeo';

export default class PackageQuotePage extends PureComponent {
  componentDidMount() {
    if (this.props.packageUrlSlug) {
      this.props.updateSelectedPackage(this.props.packageUrlSlug);
    }
  }

  render() {
    const { packageUrlSlug, description, packageQuoteDescription } = this.props;
    return (
      <div>
        <PageSeo
          image="https://storage.googleapis.com/simply-public/cloud_home.jpg"
          description="Awesome insurance this way everyone! Great Value! Instant
        signup! No Blood tests! Simple Combos: Life, Disability, and FamilyFuneral Cover."
          title="Simply"
        />
        {description && <PageDescriptionHtml description={description} />}
        {packageQuoteDescription && (
          <div className="row">
            <div className="col-xs-12 text-center">
              <p> {packageQuoteDescription} </p>
            </div>
          </div>
        )}
        <QuoteContainer packageSlug={packageUrlSlug} />
      </div>
    );
  }
}

PackageQuotePage.propTypes = {
  packageUrlSlug: PropTypes.string.isRequired,
  updateSelectedPackage: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  packageQuoteDescription: PropTypes.string.isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { answerUnderwritingQuestion as answerQuestionActionCreator } from '../../actions/answers';
import { submitUnderwriting } from '../../actions/detailsCapture';
import { stateToQuestionnaireProps } from '../questionnaire/toQuestionPageProps';
import { QuestionPageQuestions } from '../questionnaire/QuestionPage';
import PageActionButtons from '../common/PageActionButtons';
import BackToPolicies from '../thankYou/BackToPolicies';
import {
  eligibilitySummary, // eslint-disable-line
} from '../../.shared/validators/questionnaire/validateQuestionPageEligibility';
import * as R from 'ramda';

class EligibilityHelper extends PureComponent {
  render() {
    const { isEligible, exclusions, declinedProducts } = this.props;
    return (
      <div
        style={{
          position: 'fixed',
          left: '0',
          top: '83px',
          width: '100%',
          height: '100px',
          'background-color': 'salmon',
          color: 'white',
          'font-weight': 'bold',
          padding: '20px',
          zIndex: '9999',
        }}
      >
        Eligible? {isEligible ? 'YES' : 'NO'}
        <br />
        Declined Products:{' '}
        {R.join(
          ',',
          R.map(
            p => `${p.productId} -> ${p.replacingProductId}`,
            declinedProducts
          )
        )}
        <br />
        Exclusions:{' '}
        {R.join(
          ',',
          R.map(p => p.exclusionId, exclusions)
        )}
      </div>
    );
  }
}

class Underwriting extends PureComponent {
  render() {
    const { next, isValid, eligibilityResult } = this.props;
    return (
      <div className="row">
        <div className="col-xs-12">
          <BackToPolicies />
          <h2 className="text-center">Please tell us more about yourself.</h2>
          {eligibilityResult && <EligibilityHelper {...eligibilityResult} />}
          <QuestionPageQuestions {...this.props} />
          <PageActionButtons
            onClick={next}
            disabled={!isValid}
            text="Next"
            fineprint="We still need beneficiary and family member details"
          />
        </div>
      </div>
    );
  }
}

Underwriting.propTypes = {
  next: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export const mapStateToProps = state => {
  const { selectedPackage } = state.get('detailsCaptureProcess').toJS();
  const selectedPackageDetail = state
    .get('packages')
    .filter(p => p.get('packageId') === selectedPackage)
    .first();
  const pageQuestions = selectedPackageDetail
    .get('questionnairePagesThatSuggestPackage')
    .map(pageId => state.getIn(['questionPages', pageId, 'questions']))
    .reduce((r, v) => r.concat(v), fromJS([]));
  const optionalPageQuestions = fromJS([]);
  const { walkedPage, isValid, questions, answers } = stateToQuestionnaireProps(
    state,
    pageQuestions,
    optionalPageQuestions
  );
  // TODO COMMENT ME
  const eligibilityResult = null;
  // const eligibilityResult = eligibilitySummary(walkedPage, answers);
  // TO HERE

  return {
    walkedPage,
    isValid,
    questions,
    answers,
    pageQuestions,
    selectedPackage,
    eligibilityResult,
  };
};

export const answerQuestion = R.curry(
  (dispatch, packageId, questionId, answer) =>
    dispatch(answerQuestionActionCreator(packageId, questionId, answer))
);

export const mapDispatchToProps = dispatch => ({
  answerQuestion: answerQuestion(dispatch),
  next: () => dispatch(submitUnderwriting()),
});

const mergeProps = (stateProps, dispatchProps) =>
  Object.assign({}, stateProps, dispatchProps, {
    answerQuestion: dispatchProps.answerQuestion(stateProps.selectedPackage),
  });

const UnderwritingContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Underwriting);

export default UnderwritingContainer;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RadioSelectQuestion from '../../questions/RadioSelectQuestion';
import GeneralTerms from './GeneralTerms';
import ReplacePolicyTerms, {
  WaivePeriodConditions,
} from '../ReplacePolicyTerms';
import WaivePeriodQuestionnaire from '../WaivePeriodQuestionnaire';
import AcceptCheckboxQuestion from '../../questions/AcceptCheckboxQuestion';
import PageActionButtons from '../../common/PageActionButtons';
import LoadingRow from '../../common/LoadingRow';
import {
  hasAgreedToTerms,
  POLICY_REPLACES_OTHER_OPTIONS,
  YES,
  NO,
} from '../../../.shared/validators/agreedToTerms';
import { PageDescriptionHtml } from '../../common/PageDescription';
import { WallOfTextContainer } from '@simply-fin-services/astronomix3';

const CheckboxContainer = styled.div`
  @media (max-width: 960px) {
    div {
      padding-top: 0px !important;
    }
    label {
      span {
        &.children {
          max-width: 100% !important;
        }
      }
    }
  }
`;
/* eslint-disable max-len */

const allTermsAccepted = (details) => {
  const { replacePolicyWaivePeriodTermsAccepted, waiveQuestionnaireIsValid } =
    details.toJS();
  return replacePolicyWaivePeriodTermsAccepted
    ? hasAgreedToTerms(details) && waiveQuestionnaireIsValid
    : hasAgreedToTerms(details);
};

export default class SimplifiedTerms extends PureComponent {
  constructor() {
    super();
    this.acceptGeneralTerms = this.acceptGeneralTerms.bind(this);
    this.acceptReplacePolicyTerms = this.acceptReplacePolicyTerms.bind(this);
    this.acceptReplacePolicyWaivePeriodTerms =
      this.acceptReplacePolicyWaivePeriodTerms.bind(this);
    this.acceptReplacePolicyInformationCorrect =
      this.acceptReplacePolicyInformationCorrect.bind(this);
    this.setReplacesPolicy = this.setReplacesPolicy.bind(this);
    this.setShowWaivePeriodForm = this.setShowWaivePeriodForm.bind(this);
    this.continue = this.continue.bind(this);
    this.continueWithoutWaiving = this.continueWithoutWaiving.bind(this);
    this.state = {
      replacePolicyInformationCorrect: null,
    };
  }

  acceptGeneralTerms(event) {
    this.props.acceptGeneralTermsAndConditions(event.target.checked);
  }

  acceptReplacePolicyTerms(event) {
    this.props.acceptPolicyReplacementTermsAndConditions(event.target.checked);
  }

  acceptReplacePolicyWaivePeriodTerms(event) {
    this.props.acceptPolicyReplacementWaivePeriodTerms(event.target.checked);
  }

  acceptReplacePolicyInformationCorrect(event) {
    this.props.acceptPolicyReplacementWaivePeriodTerms(event.target.checked);
  }

  setReplacesPolicy(answer) {
    this.props.choosePolicyReplaced(answer);
    if (answer === NO) {
      this.props.acceptPolicyReplacementTermsAndConditions(false);
    }
    this.setState({
      policyReplacesOther: {
        answer,
        isValid: true,
      },
    });
  }

  continue() {
    const { next, canSkipWaiveQuestionnaire, details } = this.props;
    const { waiveQuestionnaireIsValid } = details.toJS();
    if (
      waiveQuestionnaireIsValid === false &&
      canSkipWaiveQuestionnaire === true
    ) {
      next(details.set('replacePolicyWaivePeriodTermsAccepted', false).toJS());
    } else {
      next(details.toJS());
    }
  }

  continueWithoutWaiving() {
    const { next, details } = this.props;
    next(details.set('replacePolicyWaivePeriodTermsAccepted', false).toJS());
  }

  componentWillMount() {
    const { policyReplacesOther } = this.props.details.toJS();
    const alreadySelected = policyReplacesOther.length > 0;

    this.setState({
      policyReplacesOther: {
        answer: policyReplacesOther,
        isValid: alreadySelected,
      },
      isWaivePolicy: {
        answer: '',
        isValid: true,
      },
      showWaivePeriodForm: false,
    });
  }

  setShowWaivePeriodForm(answer) {
    if (answer === NO) {
      this.setState({ showWaivePeriodForm: false, isWaivePolicy: { answer } });
      this.props.acceptPolicyReplacementWaivePeriodTerms(false);
    } else {
      this.setState({ showWaivePeriodForm: true, isWaivePolicy: { answer } });
    }
  }

  render() {
    const {
      generalTermsAccepted,
      replacePolicyTermsAccepted,
      replacePolicyWaivePeriodTermsAccepted,
    } = this.props.details.toJS();
    const { policyReplacesOther, isWaivePolicy, showWaivePeriodForm } =
      this.state;
    const {
      isSaving,
      termsAndConditionsUrl,
      waivePeriodDetailsShouldBeRequested,
      waiveQuestionnaireErrors,
      walkedWaiveQuestionnaire,
      waiveQuestionnaireIsValid,
    } = this.props;
    const allWaiveQuestionnaireQuestionsAnswered =
      walkedWaiveQuestionnaire.get('allNodesAnswered');
    const showExtraTerms = policyReplacesOther.answer === YES;
    const canGoToNextPage = allTermsAccepted(this.props.details);
    const waiveQuestion =
      'Was the policy a funeral policy or did it include a funeral benefit?';
    return (
      <React.Fragment>
        <PageDescriptionHtml description="As the insured person, these are most important T&C's for you to be aware of." />
        <WallOfTextContainer className="space-below-2">
          <p>
            (It’s important that you also read the full T&Cs, which you can
            access below, and which we’ll send you once you’ve completed the
            sign-up process.)
          </p>

          {showExtraTerms && (
            <ReplacePolicyTerms>
              <AcceptCheckboxQuestion
                onChange={this.acceptReplacePolicyTerms}
                checked={replacePolicyTermsAccepted}
                id="replacement"
              >
                I have considered these important points
              </AcceptCheckboxQuestion>
              <br />
              {waivePeriodDetailsShouldBeRequested &&
                replacePolicyTermsAccepted && (
                  <RadioSelectQuestion
                    title={waiveQuestion}
                    possibleAnswers={POLICY_REPLACES_OTHER_OPTIONS}
                    answerQuestion={this.setShowWaivePeriodForm}
                    {...isWaivePolicy}
                  />
                )}
            </ReplacePolicyTerms>
          )}

          {waivePeriodDetailsShouldBeRequested &&
            replacePolicyTermsAccepted &&
            showWaivePeriodForm && (
              <div>
                <WaivePeriodConditions />
                <AcceptCheckboxQuestion
                  onChange={this.acceptReplacePolicyWaivePeriodTerms}
                  checked={replacePolicyWaivePeriodTermsAccepted}
                  id="replacement-waive-period-conditions"
                >
                  I accept these conditions and request that the funeral waiting
                  period be waived.
                </AcceptCheckboxQuestion>
                <br />
                {replacePolicyWaivePeriodTermsAccepted && (
                  <div>
                    <label>
                      <strong>Details of policy being replaced</strong>
                    </label>
                    <br />
                    <br />
                    <WaivePeriodQuestionnaire />
                    <br />
                    <AcceptCheckboxQuestion
                      onChange={this.acceptReplacePolicyInformationCorrect}
                      checked={this.state.replacePolicyInformationCorrect}
                      id="replacement-policy-terms"
                    >
                      I confirm that all this information is correct, and
                      understand that if it is not, or if I do not meet the
                      conditions set out above, the waiting period on my Simply
                      policy may not be waived
                    </AcceptCheckboxQuestion>
                    <br />
                  </div>
                )}
              </div>
            )}
          <GeneralTerms>
            <hr />
            <div className="space-below" />
            <CheckboxContainer>
              <div>
                To see Simply’s full T&Cs click{' '}
                <a
                  href={termsAndConditionsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                {'. '}
              </div>
              <div className="space-below" />
              <AcceptCheckboxQuestion
                onChange={this.acceptGeneralTerms}
                checked={generalTermsAccepted}
                id="final"
              >
                <div>
                  I accept Simply’s full T&Cs and confirm I’ve read and
                  understood all information and disclosures provided in
                  relation to this product
                </div>
              </AcceptCheckboxQuestion>
            </CheckboxContainer>
          </GeneralTerms>
          {isSaving && <LoadingRow />}
        </WallOfTextContainer>
        <WallOfTextContainer>
          <br />
          <h5 className='text-danger'>The last section must be reviewed and completed by the premium payer.</h5>
        </WallOfTextContainer>
        {!isSaving && (
          <PageActionButtons
            onClick={this.continue}
            disabled={!canGoToNextPage}
            fineprint=""
            text="Next"
            canGoBack
          />
        )}
        {(!waiveQuestionnaireIsValid ||
          !allWaiveQuestionnaireQuestionsAnswered) &&
          replacePolicyWaivePeriodTermsAccepted && (
            <div className="space-above space-below-3 indent">
              <p className="small bold">
                You have not filled in your replacement policy form correctly,
                please fix the following
              </p>
              {waiveQuestionnaireErrors.map((answer) => {
                const { errors, title } = answer;
                return (
                  <div className="space-below-tight">
                    <h6 className="small">{title}</h6>
                    {errors.map((err) => (
                      <p className="text-danger small"> {err} </p>
                    ))}
                  </div>
                );
              })}
              {!allWaiveQuestionnaireQuestionsAnswered && (
                <p className="space-above-tight small">
                  * Please answer all questions
                </p>
              )}
              {hasAgreedToTerms(this.props.details) && (
                <p className="small">
                  Or you can{' '}
                  <a onClick={this.continueWithoutWaiving}>
                    continue without waiving the waiting period.
                  </a>
                </p>
              )}
            </div>
          )}
      </React.Fragment>
    );
  }
}

SimplifiedTerms.propTypes = {
  next: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  acceptGeneralTermsAndConditions: PropTypes.func.isRequired,
  acceptPolicyReplacementTermsAndConditions: PropTypes.func.isRequired,
  choosePolicyReplaced: PropTypes.func.isRequired,
  currentTheme: PropTypes.string.isRequired,
  termsAndConditionsUrl: PropTypes.string.isRequired,
  waiveQuestionnaireIsValid: PropTypes.bool.isRequired,
  allowsPolicyreplacement: PropTypes.bool.isRequired,
  waivePeriodDetailsShouldBeRequested: PropTypes.bool,
  canSkipWaiveQuestionnaire: PropTypes.bool,
};

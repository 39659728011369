import { connect } from 'react-redux';
import { setCollectionDates } from '../../../actions/persal';
import { isReplaceAndSaveEnabled } from '../../../stateStore/replaceAndSave';
import PersalInceptionOptions from './PersalInceptionOptions';
import {
  inceptionDate as persalInceptionDate,
  firstSalaryDeductionCollectionMonth as persalFirstSalaryDeductionCollectionMonth,
} from '../../../stateStore/persal';

export const mapStateToProps = (state) => {
  return {
    isReplaceAndSaveEnabled: isReplaceAndSaveEnabled(state),
    inceptionDate: persalInceptionDate(state),
    firstSalaryDeductionCollectionMonth:
      persalFirstSalaryDeductionCollectionMonth(state),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  setCollectionDates: (options) => dispatch(setCollectionDates(options)),
});

const PersalInceptionOptionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PersalInceptionOptions);

export default PersalInceptionOptionsContainer;

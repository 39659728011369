import * as types from './types';
import { dispatchNewInteractionId } from './startup';
import { updateSelectedPackage } from './selectedPackageSlug';
import getPackageIdFromSlug, {
  getSelectedPackageIdFromState,
  getPackageSlugFromId,
  getSelectedPackageIdOrDefault,
} from '../common/getPackageIdFromSlug';
import { rehydrateInteractionPeeps } from './peeps';
import { getRecommendationFromAnswers } from './recommendation';
import {
  saveSelections,
  toggleRop,
  selectProducts,
  sendSelectionQuotes,
  toggleExtendedFamilySelectable,
} from './productSelection';
import calculatePricing, {
  getPricingConfig,
  getSelectedProductPricing,
} from '../views/customization/calculatePricing';
import { createProductsToCustomize } from '../views/customization/CustomizationPageContainer';
import { push } from 'connected-react-router';
import request from 'axios';
import { API_ROUTES, ROUTES } from '../config/constants';
import * as R from 'ramda';
import { linkBroker } from './brokerLinking';
import { saveExtendedFamilyToProductSelection } from './extendedFamilyInsuredsActions';
import { reportError } from '../config/configureErrorHandling';

import { setBankingDetails } from './bankingDetails';
import { setTermsAndConditions } from './terms';
import { setPaymentType } from './paymentType';

export const blocklist = [
  '/welcome-back',
  '/',
  '/error',
  '/prefilled-quote',
  '/sale-requirements',
  ROUTES.REPLACE_AND_SAVE_DASHBOARD
];

export const isBlockedRehydrationRoute = route =>
  R.includes(route.location.pathname, blocklist);

const reportErrorAndRoute = (dispatch, getState, err, type) => {
  reportError(new Error(err), { getState }, { type });
  return dispatch(push(ROUTES.ERROR));
};

export const rehydrationComplete = () => {
  const type = types.REHYDRATION_COMPLETE;
  const payload = '';
  return {
    type,
    payload,
  };
};

const rehydrateInteractionSuccess = interactionId => {
  const type = types.REHYDRATE_INTERACTION_SUCCESS;
  const payload = { interactionId };
  return {
    type,
    payload,
  };
};

export const rehydrateInteractionError = (interactionId, err) => {
  const type = types.REHYDRATE_INTERACTION_FAILURE;
  const payload = (dispatch, getState) =>
    reportErrorAndRoute(dispatch, getState, err, type);
  return {
    type,
    payload,
    meta: {
      interactionId,
      err,
    },
  };
};

const sendRehydrationLinkSuccess = url => {
  const type = types.SEND_REHYDRATE_LINK_SUCCESS;
  const payload = { linkSent: true, url };
  return {
    type,
    payload,
  };
};

export const sendRehydrationLinkFailure = err => {
  const type = types.SEND_REHYDRATE_LINK_FAILURE;
  const payload = (dispatch, getState) =>
    reportErrorAndRoute(dispatch, getState, err, type);
  return {
    type,
    payload,
  };
};

const saveLastKnownLocation = (location, isRehydrating) => {
  const type = types.SAVE_LAST_ROUTE;
  const payload = location;
  const meta = isRehydrating;
  return {
    type,
    payload,
    meta,
  };
};

const getInteraction = (route, interactionId) =>
  request.get(route, {
    params: {
      interactionId,
    },
  });

const getRehydrationUrl = (route, packageId) =>
  request.get(route, {
    params: {
      packageId,
    },
  });

  const getPaymentTypeFromBankingDetails = (bankingDetails) => {
    const paymentType = bankingDetails.paymentType;

    if(paymentType === 'salary-deduction') {
      const provider = bankingDetails.payrollProvider;

      return `${paymentType}|${provider}`;
    }

    return paymentType || 'debit-order';
  };

const getAndRehydrateInteraction = (route, packageInfo, interactionId) => (
  dispatch,
  getState
) =>
  getInteraction(route, interactionId)
    .then(interaction => {


      const packageId =
        packageInfo.packageId ||
        R.path(['data', 'packageId', 'packageId'], interaction) ||
        getSelectedPackageIdOrDefault(getState());
      const packageUrlSlug =
        packageInfo.packageUrlSlug ||
        getPackageSlugFromId(getState(), packageId);
      dispatchNewInteractionId(dispatch, interactionId);
      if (R.has('productSelection', interaction.data)) {
        const productSelection = interaction.data.productSelection;
        dispatch(updateSelectedPackage(packageUrlSlug));
        const selections = R.keys(
          R.filter(selection => selection.selected, productSelection)
        );
        if (R.has('withRop', interaction.data)) {
          dispatch(toggleRop(interaction.data.withRop.withRop));
        }
        dispatch(saveSelections(selections));
        dispatch(selectProducts(productSelection));
        toggleExtendedFamilySelectable(
          dispatch,
          getState,
          R.values(productSelection)
        );
        const withExtendedFamilyFuneral = R.path(
          ['data', 'withExtendedFamilyFuneral', 'withExtendedFamilyFuneral'],
          interaction
        );
        const extendedFamilyInsureds = R.pipe(
          R.values,
          R.find(R.propEq('isExtendedFamily', true)),
          R.prop('insureds')
        )(productSelection);
        if (extendedFamilyInsureds) {
          const extendedFamilyProductId = R.pipe(
            R.head,
            R.prop('prices'),
            R.keys,
            R.head
          )(extendedFamilyInsureds);
          saveExtendedFamilyToProductSelection(
            dispatch,
            getState,
            extendedFamilyInsureds,
            extendedFamilyProductId
          );
          dispatch({
            type: types.REHYDRATE_EXTENDED_FAMILY_INSUREDS,
            payload: extendedFamilyInsureds,
          });
          const isSelected = R.path(
            [extendedFamilyProductId, 'selected'],
            productSelection
          );
          dispatch({
            type: types.TOGGLE_EFF_SUCCESS,
            payload: withExtendedFamilyFuneral || isSelected,
          });
        }
      }

      const isQaSale = getState().getIn(['brokerQa', 'isQaSale']);

      if(R.hasPath(['data', 'bankingDetails'], interaction) && isQaSale) {
        const bankingDetails = R.path(['data', 'bankingDetails'], interaction);
        dispatch(setBankingDetails(bankingDetails));
        dispatch(setPaymentType(getPaymentTypeFromBankingDetails(bankingDetails)));
      };

      if(R.hasPath(['data', 'termsAndConditions'], interaction) && isQaSale)
        dispatch(setTermsAndConditions(R.path(['data', 'termsAndConditions'], interaction)));

      if (R.hasPath(['data', 'broker', 'brokerCode'], interaction)) {
        dispatch(
          linkBroker(
            R.path(['data', 'broker', 'brokerCode'], interaction),
            R.path(['data', 'broker', 'senderReference'], interaction)
          )
        );
      }

      rehydrateInteractionPeeps(dispatch, R.prop('data', interaction));

      dispatch(rehydrateInteractionSuccess(interactionId));
      if (
        R.hasPath(['data', 'lastKnownRoute', 'lastRouterLocation'], interaction)
      ) {
        dispatch(
          saveLastKnownLocation(
            interaction.data.lastKnownRoute.lastRouterLocation,
            true
          )
        );
      }
      return dispatch(
        getRecommendationFromAnswers(
          packageId,
          packageUrlSlug,
          interaction.data.answers
        )
      );
    })
    .catch(err => dispatch(rehydrateInteractionError(interactionId, err)));

const rehydrateInteractionBase = R.curry(
  (route, packageInfo, interactionId) => {
    const type = types.REHYDRATE_INTERACTION;
    const payload = getAndRehydrateInteraction(
      route,
      packageInfo,
      interactionId
    );
    return {
      type,
      payload,
    };
  }
);

export const setRehydrationUrlSuccess = url => {
  const type = types.SET_REHYDRATION_URL_SUCCESS;
  const payload = { url };
  return {
    type,
    payload,
  };
};

export const setRehydrationUrlFailure = (interactionId, err) => {
  const type = types.SET_REHYDRATION_URL_FAILURE;
  const payload = { interactionId, error: err };
  return {
    type,
    payload,
  };
};

const fetchAndSetRehydrationUrl = (route, packageUrlSlug) => (
  dispatch,
  getState
) => {
  const state = getState();
  const interactionId = state.get('interactionId');
  const packageId = getPackageIdFromSlug(state, packageUrlSlug);
  getRehydrationUrl(route, packageId)
    .then(url => dispatch(setRehydrationUrlSuccess(url.data)))
    .catch(err => dispatch(setRehydrationUrlFailure(interactionId, err)));
};

const setRehydrationUrlBase = R.curry((route, packageUrlSlug) => {
  const type = types.SET_REHYDRATION_URL;
  const payload = fetchAndSetRehydrationUrl(route, packageUrlSlug);
  return {
    type,
    payload,
  };
});

const sendRehydration = (route, url) => (dispatch, getState) => {
  const state = getState();
  const customizationProducts = createProductsToCustomize(state);
  const selections = customizationProducts.map(p =>
    p.merge(getSelectedProductPricing(p))
  );
  const packageId = getSelectedPackageIdFromState(state);
  const pricingConfig = getPricingConfig(
    state,
    state.get('selectedPackageSlug')
  );
  const pricing = calculatePricing(selections, pricingConfig);
  dispatch(sendSelectionQuotes(packageId, selections.toList().toJS(), pricing));
  return dispatch(sendRehydrationLinkSuccess(url));
};

const sendRehydrationLinkBase = R.curry((route, url) => {
  const type = types.SEND_REHYDRATION_LINK;
  const payload = sendRehydration(route, url);
  return {
    type,
    payload,
  };
});

export const sendRehydrationLink = sendRehydrationLinkBase(
  API_ROUTES.SEND_REHYDRATION_NOTIFICATION
);

export const rehydrateInteraction = rehydrateInteractionBase(
  API_ROUTES.GET_INTERACTION
);

export const setRehydrationUrl = setRehydrationUrlBase(
  API_ROUTES.GET_REHYDRATION_URL
);


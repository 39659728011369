import React, { PureComponent } from 'react';
import { PageButtons, Form } from '@simply-fin-services/astronomix3';
import PageDescription from '../common/PageDescription';
import AddPolicyForm from './policies/AddContainer';
import AddInsuredPerson from './insured/AddContainer';
import ReplacementPolicyListing from './policies/ListContainer';
import { centeredSpinner } from './sharedComponents';

export default class PolicyReplacementPage extends PureComponent {
  constructor() {
    super();
    this.state = { loading: true };
  }

  componentDidMount() {
    this.props.fetchLatestPolicyReplacementData().then(() => {
      this.setState({ loading: false });
    });
  }

  nextButtonDisabled() {
    return this.props.hasReplacementPolicies === false;
  }

  loadingData() {
    return this.state.loading;
  }

  canShowPageElements() {
    return !this.state.loading;
  }

  showMainPageDescription() {
    return !this.props.isAddingInsuredPerson;
  }

  render() {
    return (
      <Form>
        {this.showMainPageDescription() && (
          <PageDescription
            description="Please add the policies you plan to replace"
          />
        )}
        {this.loadingData() && centeredSpinner()}

        {this.canShowPageElements() && (
          <React.Fragment>
            {this.props.shouldShowPolicyListing && (
              <ReplacementPolicyListing
                replacementPolicies={this.props.replacementPolicies}
              />
            )}

            {this.props.isAddingReplacementPolicy && <AddPolicyForm />}

            {this.props.isAddingInsuredPerson && <AddInsuredPerson />}

            {this.props.shouldShowPolicyListing && (
              <PageButtons
                disabled={this.nextButtonDisabled()}
                label="Next"
                onClick={() => this.props.goToNextPage()}
              />
            )}
          </React.Fragment>
        )}
      </Form>
    );
  }
}

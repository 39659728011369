import { connect } from 'react-redux';
import {
  isReplaceAndSaveEnabled,
  replaceAndSaveInteractionId,
} from '../../../stateStore/replaceAndSave';
import { listCancellationLetters } from '../httpApi';
import ThankYouCancellation from './ThankYouCancellation';

export const mapStateToProps = (state, ownProps) => {
  return {
    isReplaceAndSaveEnabled: isReplaceAndSaveEnabled(state),
    listCancellationLetters: () =>
      listCancellationLetters(
        ownProps.contractId,
        replaceAndSaveInteractionId(state)
      ),
  };
};

export const mapDispatchToProps = () => ({});

export const ThankYouCancellationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYouCancellation);

export default ThankYouCancellationContainer;

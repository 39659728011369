import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import QuestionContainer from './QuestionContainer';
import { getLabel } from '../../common/alternativeLabels';
import { InputSelect } from '@simply-fin-services/astronomix3';
import { CHECKBOX_QUESTION_ANSWER_SPLITTER } from '../../.shared/validators';

export class SearchableMultiSelectListQuestion extends PureComponent {
  render() {
    const { answer, possibleAnswers, questionId } = this.props;
    const answerAsAnswerObject = (a) => ({
      value: a,
      label: getLabel(this.props, a),
    });
    const options = possibleAnswers.map(answerAsAnswerObject).toJS();
    const answerQuestion = (selected) => {
      if (selected) {
        const values = selected
          .map(({ value }) => value)
          .join(CHECKBOX_QUESTION_ANSWER_SPLITTER);
        this.props.answerQuestion(values);
      }
    };
    const answers = answer
      ? answer
          .split(CHECKBOX_QUESTION_ANSWER_SPLITTER)
          .map(answerAsAnswerObject)
      : [];
    return (
      <QuestionContainer {...this.props}>
        <InputSelect
          id={questionId}
          options={options}
          onChange={answerQuestion}
          value={answers}
          isMulti
          closeMenuOnSelect={false}
          isClearable={false}
        />
      </QuestionContainer>
    );
  }
}

SearchableMultiSelectListQuestion.propTypes = {
  possibleAnswers: PropTypes.arrayOf(PropTypes.string).isRequired,
  answer: PropTypes.string,
  answerQuestion: PropTypes.func.isRequired,
  label: PropTypes.string,
};

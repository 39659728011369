import { connect } from 'react-redux';
import {
  isReplaceAndSaveEnabled,
  totalReplacementPolicies,
  totalMonthlyPremium,
} from '../../../stateStore/replaceAndSave';
import { savingsInformationForComponent } from '../httpApi';
import CustomisationPageSummary from './CustomisationPageSummary';

const maxFuneralCoverAmount = (state) => {
  const funeralProductId = state.getIn([
    'packages',
    state.get('selectedPackageSlug'),
    'funeralProduct',
  ]);
  const funeralProcutPriceMap = state.getIn([
    'recommendation',
    'productPriceMap',
    funeralProductId,
  ]);
  const maxCoverAmount = funeralProcutPriceMap.entrySeq().last()[0];
  return maxCoverAmount;
};

const replacementPolicyTotalCoverAmount = (state) => {
  const totalCover = state.getIn(['replaceAndSave', 'totalCover']);
  const totalCoverAmount = Number(totalCover) / 100;
  return totalCoverAmount;
};

const hasMaxCoverWarning = (state) => {
  const maxCover = maxFuneralCoverAmount(state);
  const replacementPolicyTotalCover = replacementPolicyTotalCoverAmount(state);
  return replacementPolicyTotalCover > maxCover;
};

export const mapStateToProps = (state) => {
  const replaceAndSaveEnabled = isReplaceAndSaveEnabled(state);

  if (!replaceAndSaveEnabled) {
    return { isReplaceAndSaveEnabled: replaceAndSaveEnabled };
  }

  return {
    isReplaceAndSaveEnabled: replaceAndSaveEnabled,
    totalReplacementPolicies: totalReplacementPolicies(state),
    totalMonthlyPremium: totalMonthlyPremium(state),
    maxCoverAmount: maxFuneralCoverAmount(state),
    replacementPolicyTotalCoverAmount: replacementPolicyTotalCoverAmount(state),
    hasMaxCoverWarning: hasMaxCoverWarning(state),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  savingsInformationForComponent: (quoteMonthlyPremium) =>
    savingsInformationForComponent(quoteMonthlyPremium, dispatch),
});

export const CustomisationPageSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomisationPageSummary);

export default CustomisationPageSummaryContainer;

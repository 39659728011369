import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  personWithRelationshipValidator,
  MAX_AGE,
  MIN_AGE,
} from '../../.shared/validators/validateNomination';
import * as R from 'ramda';
import { fromJS } from 'immutable';
import ProperCaseQuestion from '../questions/ProperCaseQuestion';
import InputQuestion from '../questions/InputQuestion';
import DateQuestion from '../questions/DateQuestion';
import RadioSelectQuestion from '../questions/RadioSelectQuestion';
import {
  PageButtons,
  ShadowedContainer,
  Form,
} from '@simply-fin-services/astronomix3';
import QuestionContainer from '../questions/QuestionContainer';

const toAgeByRelationship = (ageRanges) =>
  R.pipe(
    R.map((r) => R.objOf(r.relationship, r)),
    R.reduce(R.merge, {})
  )(ageRanges);

const getAgeRange = (ageRanges, peep) => {
  const ageRangeByRelationship = toAgeByRelationship(ageRanges);
  if (R.has(peep.relationship, ageRangeByRelationship)) {
    return ageRangeByRelationship[peep.relationship];
  }
  return { min: MIN_AGE, max: MAX_AGE };
};

const getQuestions = (relationshipTypes, detailType) => {
  /* const idQuestionConfig = {
    id: 'idNumber',
    control: InputQuestion,
    title: 'ID Number',
    optional: true,
  }; */
  const idPassportQuestionConfig = {
    id: 'idPasportNumber',
    control: InputQuestion,
    title: 'ID/Passport Number',
    optional: true,
  };
  const detailQuestions = [
    {
      id: 'name',
      control: ProperCaseQuestion,
      title: 'Name',
      settings: {},
    },
    {
      id: 'surname',
      control: ProperCaseQuestion,
      title: 'Surname',
    },
    {
      id: 'dateOfBirth',
      control: DateQuestion,
      title: 'Date of Birth',
    },
    idPassportQuestionConfig,
    {
      id: 'relationship',
      control: RadioSelectQuestion,
      title: 'Relationship',
      possibleAnswers: relationshipTypes,
    },
  ];

  if (detailType !== 'beneficiary') {
    return R.without([idPassportQuestionConfig], detailQuestions);
  }
  return detailQuestions;
};

const getInitialState = (relationshipTypes) =>
  R.pipe(
    R.map(R.prop('id')),
    R.reduce((a, v) => R.assoc(v, { answer: '' }, a), {})
  )(getQuestions(relationshipTypes));

const getAnswers = (state) => R.map(R.prop('answer'), state);

const validatePeeps = R.curry((relationshipTypes, ageRanges, answers) => {
  const { min, max } = getAgeRange(ageRanges, answers);
  return personWithRelationshipValidator(
    fromJS(answers),
    max,
    min,
    relationshipTypes
  ).toJS();
});

const associateValidationsWithAnswers = (validations) =>
  R.map((v) => R.assoc('answer', v.value, v), validations);

const clearValidationForEmptyAnswers = (validations) =>
  R.map((v) => (v.value ? v : { answer: v.answer }), validations);

const submitPeep = R.curry((f, state, e) => {
  e.preventDefault();
  f(getAnswers(state));
});

const canAddPeep = (validations) =>
  R.all(R.propEq('isValid', true), R.values(validations));

export default class AddPeep extends PureComponent {
  constructor() {
    super();
    this.answerQuestion = this.answerQuestion.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.state = getInitialState();
  }

  scrollTo(id) {
    const elem = document.getElementById(id);
    if (elem) {
      setTimeout(
        () =>
          elem.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          }),
        500
      );
    }
  }

  answerQuestion(id, a) {
    const { relationshipTypes, ageRanges } = this.props;
    const newState = R.pipe(
      getAnswers,
      R.assoc(id, a),
      validatePeeps(relationshipTypes, ageRanges),
      associateValidationsWithAnswers,
      clearValidationForEmptyAnswers
    )(this.state);

    this.setState(newState);
  }

  render() {
    const { relationshipTypes, hide, type, containerId } = this.props;
    const questions = getQuestions(relationshipTypes, type);
    const addPeep = (e) => {
      this.scrollTo(containerId);
      submitPeep(this.props.addPeep, this.state, e);
    };
    const cols = R.map((q) => {
      const Control = q.control;
      const answerQ = (a) => this.answerQuestion(q.id, a);
      const options = R.mergeRight(R.dissoc('control', q), this.state[q.id]);
      return (
        <QuestionContainer>
          <Control
            buttonSize="small"
            {...options}
            answerQuestion={answerQ}
            key={q.id}
          />
        </QuestionContainer>
      );
    })(questions);

    // Fields display side by side for larger displays
    const fields = [];
    for (let i = 0; i < cols.length; i += 2) {
      fields.push(
        <Fragment>
          {cols[i]}
          {cols[i + 1] ? cols[i + 1] : <></>}
        </Fragment>
      );
    }

    return (
      <ShadowedContainer>
        <form onSubmit={(e) => addPeep(e)} className="pop-up add-peep-pop-up">
          <Form>{fields}</Form>
          <PageButtons
            backAction={hide}
            backText="Cancel"
            type="submit"
            label="Add"
            disabled={!canAddPeep(this.state)}
          />
        </form>
      </ShadowedContainer>
    );
  }
}

AddPeep.propTypes = {
  addPeep: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  relationshipTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  ageRanges: PropTypes.arrayOf(
    PropTypes.shape({
      relationship: PropTypes.string.isRequired,
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    })
  ).isRequired,
};

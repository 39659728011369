// This file gets copied from app/config to server/config
// To make changes make them in app/config otherwise they will get overwritten
import * as R from 'ramda';
export const SUFFIX_URL = process.env.SUFFIX_URL || 'http://localhost:3467';
export const TENANDSIX_URL =
  process.env.TENANDSIX_URL || 'http://localhost:3451';
// DO NOT ADD SENSITIVE CONFIG HERE AS IT WILL BE PASSED TO THE BROWSER
export const VERSION = process.env.CI_VERSION || 'not-set-s';
export const HOSTNAME = process.env.HOSTNAME || 'localhost';
export const PORT = process.env.PORT || '3000';
export const CLIENT_PORT = process.env.CLIENT_PORT || '3000';
export const ENV = process.env.NODE_ENV || 'development';
export const GOOGLE_TAG_MANAGER_ID = process.env.GOOGLE_TAG_MANAGER_ID || '';
export const MAUTIC_URL = process.env.MAUTIC_URL;
// this should be copied to serverConfig on back end

export const DISCOUNT_RATE = parseInt(process.env.DISCOUNT_RATE, 10) || 0;
export const WINWIN_DISCOUNT_RATE =
  parseInt(process.env.WINWIN_DISCOUNT_RATE, 10) || 5;
// this should be copied to serverConfig on back end
export const GCP_PROJECT_ID = process.env.GCP_PROJECT_ID || 'dogmatix';
// this should be copied to serverConfig on back en
export const RAYGUN_API_KEY =
  process.env.RAYGUN_API_KEY || 'Z7hUrbCxrmy8PedHmpBU8w==';
export const GOOGLE_MAPS_API_KEY =
  process.env.GOOGLE_MAPS_API_KEY || 'AIzaSyDwqptB9J37LRH0_hZvLL61Nr6WgbVjXYQ';
export const QUESTION_TIMEOUT_DELAY_IN_MILLISECONDS = 400;
export const SALES_SITE = process.env.SALES_SITE || 'http://localhost:3000';
export const WEB_SITE_BASE =
  process.env.WEB_SITE_BASE || 'http://localhost:8000';
export const BROKER_PORTAL_URL =
  process.env.BROKER_PORTAL_URL || 'http://localhost:3470';
export const FB_APP_ID = 1712145419040559;
export const IS_DEV = ENV !== 'production' && ENV !== 'qa';
/* eslint-disable */

export const RISK_SCREENING_ENABLED = process.env.ENABLE_RISK_SCREENING === 'true' || process.env.ENABLE_RISK_SCREENING === true;

export const DEFAULT_PACKAGES = {
  'sim-family': 'flexi-family-standard',
  'sim-family-fmp': 'flexi-family-third-party-standard',
  'sim-flexi-funeral': 'flexi-funeral-category-a',
  'sim-family-accidental': 'flexi-family-accidental',
  'sim-domestic': 'flexi-domestic-standard',
  'sim-domestic-accidental': 'flexi-domestic-accidental',
};

export const GENERIC_PACKAGES = R.invertObj(DEFAULT_PACKAGES);

const DEFAULT_DOMESTIC_PRODUCTS = {
  'sim-funeral': 'flexi-domestic-funeral',
  'sim-life': 'flexi-domestic-life',
  'sim-disability': 'flexi-domestic-disability',
};

const DEFAULT_FAMILY_PRODUCTS = {
  'sim-funeral': 'flexi-individual-funeral',
  'sim-life': 'flexi-individual-life',
  'sim-disability': 'flexi-individual-disability',
};

export const EXTERNAL_MAPPING = {
  married: {
    Yes: 'Married',
    No: 'Not Married',
    Married: 'Married',
    'Not Married': 'Not Married',
  },
  education: {
    'Less than Matric': 'No Matric',
    'Matric or Grade 12': 'Matric',
    Postgraduate: '4yr Degree',
    'Three Year Diploma': '3yr Diploma',
    'Four Year Diploma': '3yr Diploma',
    'Three Year Undergraduate Degree': 'Undergrad',
    'No Matric': 'No Matric',
    Matric: 'Matric',
    '4yr Degree': '4yr Degree',
    '3yr Diploma': '3yr Diploma',
    Undergrad: 'Undergrad',
  },
  packageId: {
    'sim-single-life-combo-package': R.prop('sim-family', DEFAULT_PACKAGES),
    'sim-family-combo-package-cf-ru': R.prop('sim-family', DEFAULT_PACKAGES),
    'sim-family-combo-package-cf': R.prop('sim-family', DEFAULT_PACKAGES),
    'sim-family-combo-package-nrf': R.prop('sim-family', DEFAULT_PACKAGES),
    'sim-family-combo-package-nfl': R.prop('sim-family', DEFAULT_PACKAGES),
    'sim-family-combo-package': R.prop('sim-family', DEFAULT_PACKAGES),
    'sim-family': R.prop('sim-family', DEFAULT_PACKAGES),
    'sim-family-fmp': R.prop('sim-family-fmp', DEFAULT_PACKAGES),
    'sim-flexi-funeral': R.prop('sim-flexi-funeral', DEFAULT_PACKAGES),
    'sim-domestic': R.prop('sim-domestic', DEFAULT_PACKAGES),
    'sim-employee-combo-package': R.prop('sim-domestic', DEFAULT_PACKAGES),
    'sim-employee-combo-package-cf': R.prop('sim-domestic', DEFAULT_PACKAGES),
    // TODO: add packages for HIPPO
  },
  productId: {
    'sim-family': {
      life: R.prop('sim-life', DEFAULT_FAMILY_PRODUCTS),
      funeral: R.prop('sim-funeral', DEFAULT_FAMILY_PRODUCTS),
      disability: R.prop('sim-disability', DEFAULT_FAMILY_PRODUCTS),
      'sim-life-slf': R.prop('sim-life', DEFAULT_FAMILY_PRODUCTS),
      'sim-disability-slf': R.prop('sim-disability', DEFAULT_FAMILY_PRODUCTS),
      'sim-single-funeral': R.prop('sim-funeral', DEFAULT_FAMILY_PRODUCTS),
      'sim-single-funeral-v1': R.prop('sim-funeral', DEFAULT_FAMILY_PRODUCTS),
      'sim-single-funeral-flexi': R.prop('sim-funeral-ff', DEFAULT_FAMILY_PRODUCTS),
      'sim-single-funeral-flexi-v1': R.prop('sim-funeral-ff', DEFAULT_FAMILY_PRODUCTS),
      'sim-life-nrf': R.prop('sim-life', DEFAULT_FAMILY_PRODUCTS),
      'sim-funeral-nrf': R.prop('sim-funeral', DEFAULT_FAMILY_PRODUCTS),
      'sim-disability-nrf': R.prop('sim-disability', DEFAULT_FAMILY_PRODUCTS),
      'sim-life-nfl': R.prop('sim-life', DEFAULT_FAMILY_PRODUCTS),
      'sim-disability-nfl': R.prop('sim-disability', DEFAULT_FAMILY_PRODUCTS),
      'sim-funeral-nfl': R.prop('sim-funeral', DEFAULT_FAMILY_PRODUCTS),
      'sim-funeral': R.prop('sim-funeral', DEFAULT_FAMILY_PRODUCTS),
      'sim-life': R.prop('sim-life', DEFAULT_FAMILY_PRODUCTS),
      'sim-disability': R.prop('sim-disability', DEFAULT_FAMILY_PRODUCTS),
      'flexi-individual-life': R.prop('sim-life', DEFAULT_FAMILY_PRODUCTS),
      'flexi-individual-funeral': R.prop('sim-funeral', DEFAULT_FAMILY_PRODUCTS),
      'flexi-individual-disability': R.prop('sim-disability', DEFAULT_FAMILY_PRODUCTS),
      'flexi-funeral-category-a': R.prop('sim-flexi-funeral', DEFAULT_FAMILY_PRODUCTS),
    },
    'sim-domestic': {
      life: R.prop('sim-life', DEFAULT_DOMESTIC_PRODUCTS),
      funeral: R.prop('sim-funeral', DEFAULT_DOMESTIC_PRODUCTS),
      disability: R.prop('sim-disability', DEFAULT_DOMESTIC_PRODUCTS),
      'sim-life-nrf': R.prop('sim-life', DEFAULT_DOMESTIC_PRODUCTS),
      'sim-funeral-nrf': R.prop('sim-funeral', DEFAULT_DOMESTIC_PRODUCTS),
      'sim-disability-nrf': R.prop('sim-disability', DEFAULT_DOMESTIC_PRODUCTS),
      'sim-funeral': R.prop('sim-funeral', DEFAULT_DOMESTIC_PRODUCTS),
      'flexi-domestic-funeral': R.prop('sim-funeral', DEFAULT_DOMESTIC_PRODUCTS),
      'sim-life': R.prop('sim-life', DEFAULT_DOMESTIC_PRODUCTS),
      'flexi-domestic-life': R.prop('sim-life', DEFAULT_DOMESTIC_PRODUCTS),
      'sim-disability': R.prop('sim-disability', DEFAULT_DOMESTIC_PRODUCTS),
      'flexi-domestic-disability': R.prop('sim-disability', DEFAULT_DOMESTIC_PRODUCTS),
    },
  },
};

export const toGenericPackage = (packageId) =>
  R.prop(packageId, GENERIC_PACKAGES);

export const EXTERNAL_SENDERREFERENCES_WE_MAP_FOR = [
  'HIPPO',
  'PREFIX',
  'BROKER-PORTAL',
  'FACEBOOK',
  'IEMAS',
  'THE-BROKER-GUYS',
  'CORNER-LIFE',
  'VUKHETA',
  'OOBA',
  'SIMPLY',
  'LEADROBOT',
];

export const COUNTRY_CODE = '27';

export const NO_DISCOUNT_SENDER_REFERENCES = ['OOBA'];
/* eslint-disable no-underscore-dangle */
export const readConfigFromState = (key) => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  if (typeof window.__INITIAL_STATE__ === 'undefined') {
    return undefined;
  }
  return R.path(['config', key], window.__INITIAL_STATE__);
};
/* eslint-enable no-underscore-dangle */

export default {
  VERSION,
  HOSTNAME,
  PORT,
  ENV,
  GOOGLE_TAG_MANAGER_ID,
  MAUTIC_URL,
  DISCOUNT_RATE,
  WINWIN_DISCOUNT_RATE,
  RAYGUN_API_KEY,
  GOOGLE_MAPS_API_KEY,
  IS_DEV,
  SALES_SITE,
  WEB_SITE_BASE,
  BROKER_PORTAL_URL,
  APP_ID: GCP_PROJECT_ID,
  EXTERNAL_MAPPING,
  EXTERNAL_SENDERREFERENCES_WE_MAP_FOR,
  NO_DISCOUNT_SENDER_REFERENCES,
  SUFFIX_URL,
  TENANDSIX_URL,
};

export const MAXIMUM_REQUEST_FILE_SIZE = 10485760; // 10MB
export const DEFAULT_EFF_COVER_AMOUNT = 500;

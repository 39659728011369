import { connect } from 'react-redux';
import QuoteContactDetails from './QuoteContactDetails';
import {
  stateToProps,
  dispatchToProps,
  mergeTheProps,
} from '../questionnaire/toQuestionPageProps';

const selectedPackageContactPageId = (state, packageSlug) =>
  state.getIn(['packages', packageSlug, 'contactQuestionPageId']);

export const selectedContactQuestions = (state, packageSlug) =>
  state.getIn([
    'questionPages',
    selectedPackageContactPageId(state, packageSlug),
    'questions',
  ]);

export const selectedOptionalContactQuestions = (state, packageSlug) =>
  state.getIn([
    'questionPages',
    selectedPackageContactPageId(state, packageSlug),
    'optionalQuestions',
  ]);

const toBaseProps = (state, ownProps) =>
  stateToProps(
    state,
    ownProps.packageSlug,
    selectedContactQuestions(state, ownProps.packageSlug),
    selectedOptionalContactQuestions(state, ownProps.packageSlug)
  );

export const mapStateToProps = (state, ownProps) =>
  toBaseProps(state, ownProps);

export const mapDispatchToProps = dispatch =>
  Object.assign({}, dispatchToProps(dispatch), {
    dispatch,
  });

const mergeProps = (stateProps, dispatchProps) =>
  mergeTheProps(stateProps, dispatchProps);

const ContactDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(QuoteContactDetails);

export default ContactDetailsContainer;

import { fromJS } from 'immutable';
import { PROVIDE_INPUT, SET_REFERRER_STATUS } from '../actions/types';
import createActionHandler from './createActionHandler';

const IDENTIFIER = 'referralLogin';

const initialState = fromJS({
  cellphoneNumber: '',
  isReferrer: false,
});

const actionHandlers = {};

const saveFieldData = (state, action) => {
  const { owner, fieldname, value } = action.payload;
  return owner === IDENTIFIER ? state.set(fieldname, value) : state;
};

actionHandlers[PROVIDE_INPUT] = saveFieldData;
actionHandlers[SET_REFERRER_STATUS] = (state, action) =>
  state.set('isReferrer', action.payload);

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

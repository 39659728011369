import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ErrorText } from '@simply-fin-services/astronomix3';

class CompleteSaleErrors extends PureComponent {
  render() {
    const { isSaving, errors } = this.props;
    if (errors && !isSaving) {
      return (
        <ErrorText>
          Unfortunately we cannot complete your sale:
          <br />
          {errors}
        </ErrorText>
      );
    }
    return null;
  }
}

CompleteSaleErrors.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.array,
};

const mapStateToProps = state => ({
  isSaving:
    state.getIn(['bankingDetails', 'isSaving']) ||
    state.getIn(['peeps', 'isSaving']) ||
    state.getIn(['completeSale', 'isSaving']),
  errors: state.getIn(['completeSale', 'error']),
});

export default connect(mapStateToProps)(CompleteSaleErrors);

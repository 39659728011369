import { connect } from 'react-redux';
import {
  addPolicyNextStep,
  showReplacementPoliciesPage,
} from '../../../actions/replaceAndSave';
import AddPolicy from './Add';
import {
  hasReplacementPolicies,
  totalReplacementPolicies,
} from '../../../stateStore/replaceAndSave';
import { getIsEntityValid, getEntity } from '../../../stateStore/entityForm';
import { clearFormEntity } from '../../../actions/entityForm';

const entityType = 'replace-and-save-replacement-policy';

const mapStateToProps = (state) => ({
  hasReplacementPolicies: hasReplacementPolicies(state),
  totalReplacementPolicies: totalReplacementPolicies(state),
  entityType,
  isEntityValid: getIsEntityValid(state),
  entity: getEntity(state),
});

const mapDispatchToProps = (dispatch) => ({
  addPolicyNextStep: (policy) => {
    dispatch(addPolicyNextStep(policy));
    window.scroll(0, 0);
  },
  backToPolicies: () => {
    dispatch(showReplacementPoliciesPage());
    window.scroll(0, 0);
  },
  clearEntity: () => dispatch(clearFormEntity(entityType)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, dispatchProps, {});
};

const AddPolicyContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AddPolicy);

export default AddPolicyContainer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const spin = () => keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerContainer = styled.div`
  display: inline-block;
  width: ${props => props.width};
  height: ${props => props.width};
`;

export const Circle = styled.div`
  font-size: 10px;
  /* margin: 50px auto; */
  /* text-indent: -9999em; */
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #FAFAFB
  background: linear-gradient(to right, #FAFAFB 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: ${spin} 1.4s infinite linear;
  transform: translateZ(0);
  &.stopped {
    animation-play-state: paused;
  }
  &:before {
    width: 50%;
    height: 50%;
    background: #2597D0 ;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  &:after {
    background: #fff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

class Spinner extends Component {
  render() {
    return (
      <SpinnerContainer width={this.props.width ? this.props.width : '200px'}>
        <Circle className={this.props.stop ? 'stopped' : ''}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </Circle>
      </SpinnerContainer>
    );
  }
}

Spinner.propTypes = {
  stop: PropTypes.bool,
  width: PropTypes.string,
};

export default Spinner;

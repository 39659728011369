import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  validateIemasMember,
  validateAndPostIemasMember,
} from '../../actions/iemasMembers';
import PageActionButtons from '../common/PageActionButtons';
import QuestionContainer from '../../views/questions/QuestionContainer';
import PaymentDetailsExtraTermsContainer from '../../views/replaceAndSave/integration/PaymentDetailsExtraTermsContainer';
import { fromJS } from 'immutable';
import * as R from 'ramda';
import OneTimePin, { isOtpValid, isThirdPartyOtpValid } from './Otp';
import { getSelectedPackageIdOrDefault } from '../../common/getPackageIdFromSlug';
import ThirdPartyOtp from './thirdPartyOtp';
import { answerQuestion as answerQuestionActionCreator } from '../../actions/answers';
import {
  InputButton,
  WallOfTextContainer,
  PrimaryButton
} from '@simply-fin-services/astronomix3';
import { DEBIT_ORDER, getFinishButtonText, IEMAS_SALARY_DEDUCTION } from './config';
import BrokerQa from './brokerQa';
import BrokerCompliance from './brokerCompliance';
import { isBrokerSale, isFromBrokerPortal } from '../../common/brokers';
import moment from 'moment';
import {
  setPaymentType as setPaymentTypeAction
} from '../../actions/paymentType';

class IemasSalaryDeduction extends PureComponent {
  constructor(props) {
    super(props);
    this.updateOtpValidation = this.updateOtpValidation.bind(this);
    this.updateThirdPartyOtpValidation =
      this.updateThirdPartyOtpValidation.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setIemasMemberNumber = this.setIemasMemberNumber.bind(this);
    this.setIemasMemberNumberFromField =
      this.setIemasMemberNumberFromField.bind(this);
    this.iemasPaymentDetails = this.iemasPaymentDetails.bind(this);
    this.updateCorrectionComments = this.updateCorrectionComments.bind(this);
    this.state = {
      iemasMemberNumber: props.iemasMembers.get('memberNumber') || '',
      iemasMemberNumberValid: false,
      iemasMemberNumberInvalid: true,
      otp: {
        validateAndAnswerQuestion: this.updateOtpValidation,
        form: 'iemas_salary_deduction',
      },
      thirdPartyOtp: {
        validateAndAnswerQuestion: this.updateThirdPartyOtpValidation,
        form: 'iemas_salary_deduction',
      },
    };
  }

  setIemasMemberNumber(memberNumber) {
    this.props.postMemberValidation(memberNumber);
  }

  updateCorrectionComments(value) {
    this.props.updateCorrectionComments(value);
  }

  setIemasMemberNumberFromField(event) {
    const value = event.target.value;
    this.props.setMemberNumber(value);
  }

  iemasPaymentDetails() {
    return fromJS({
      paymentType: IEMAS_SALARY_DEDUCTION,
      iemasMemberNumber: this.props.iemasMembers.get('memberNumber'),
      inceptionMonth: moment().toISOString(), // Immediate cover start
    });
  }

  updateOtpValidation(validateOtp) {
    const validation = validateOtp();
    const updatedOtp = R.pipe(
      R.assoc('validateAndAnswerQuestion', this.updateOtpValidation),
      R.merge(this.state.otp)
    )(validation);
    this.setState({ otp: updatedOtp });
  }

  updateThirdPartyOtpValidation(validateOtp) {
    const validation = validateOtp();
    const updatedOtp = R.pipe(
      R.assoc('validateAndAnswerQuestion', this.updateThirdPartyOtpValidation),
      R.merge(this.state.thirdPartyOtp)
    )(validation);
    this.setState({ thirdPartyOtp: updatedOtp });
  }

  onSubmit() {
    this.props.next(this.iemasPaymentDetails());
  }

  render() {
    const {
      isSaving,
      ownerFullName,
      requiresOTP,
      iemasMembers,
      validComments,
      requiresThirdPartyOTP,
      otpValid,
      thirdPartyOtpValid,
      brokerComplianceValid,
      currentSaleIsBrokerSale,
      setPaymentType
    } = this.props;
    const otpFieldConfig = this.state.otp;
    const thirdPartyOtpFieldConfig = this.state.thirdPartyOtp;

    const isLoading = iemasMembers.get('isLoading');
    const iemasMemberNumber = iemasMembers.get('memberNumber');
    const iemasMemberNumberValid = iemasMembers.get('canContinue');
    const iemasMemberNumberValidationMessages = R.without(
      [''],
      [iemasMembers.get('status')]
    );
    const canDebitOrder = iemasMembers.get('debitOrderAllowed') && !iemasMembers.get('canContinue');

    const passesSaleConstraints = requiresThirdPartyOTP
      ? iemasMemberNumberValid &&
        otpValid &&
        thirdPartyOtpValid
      : iemasMemberNumberValid && otpValid;

    const canContinueBrokerSale = passesSaleConstraints && brokerComplianceValid;

    const canContinue = currentSaleIsBrokerSale ? canContinueBrokerSale  : passesSaleConstraints;

    const inputProps = {
      placeholder: 'should be 7 digits',
      onChange: this.setIemasMemberNumberFromField,
      value: iemasMemberNumber,
      hasError: this.iemasMemberNumberInvalid,
      validates: iemasMemberNumberValid,
    };

    const submitMemberNumber = () =>
      this.setIemasMemberNumber(iemasMemberNumber);

    const setDebitOrderPaymentMethod = () => setPaymentType(DEBIT_ORDER);

    const buttonProps = {
      onClick: submitMemberNumber,
      disabled: isLoading,
    };

    const buttonText = isLoading ? 'Please wait' : 'Apply';

    const actionButtonText = this.props.toFinishButtonText();
    return (
      <WallOfTextContainer>
        <form onSubmit={(e) => e.preventDefault()}>
          <p>
            In order for the payment of your premium to be made via a deduction
            from your salary you need to acknowledge the following:
          </p>

          <ol>
            <li>
              You, {ownerFullName}, have taken out this policy through iMas
              Insurance Brokers (Pty) Limited. The cover and premium you have
              selected is as agreed during this application process, and will be
              confirmed in the policy schedule provided at its conclusion.
            </li>

            <li>
              You hereby provide your consent and agreement to a salary
              deduction pertaining to the payment of the Insurance Premium due
              under this policy. This premium may from time to time change due
              to factors such as premium increases, or changes in the cover
              selected.
            </li>

            <li>
              From the date of inception of this policy, iMas shall be entitled
              to instruct your Employer on your behalf to deduct the current
              Insurance Premium as well as any increased or reduced future
              Insurance Premium amounts from any remuneration which may derive
              from your employment contract with your Employer, and to pay such
              amounts over to iMas. iMas’ instruction to your Employer shall
              have the same force and effect as should the instruction have been
              given directly by yourself. This document and any information
              relating to it may be provided to your current and future
              employers.
            </li>

            <li>
              iMas shall receive the Insurance Premium amounts on your behalf
              from your Employer and iMas shall receive, hold and pay such
              Insurance Premium amounts to the Insurer in terms of a written
              mandate given by the Insurer to iMas as independent intermediary.
            </li>

            <li>
              iMas shall, within a period of 15 days after the end of every
              month in which the Insurance Premium is received, pay the
              Insurance Premium over to the Insurer, provided such payment may
              be reduced by the amount of any refund premiums then due and
              payable by the Insurer.
            </li>

            <li>
              Should your employment terminate with your Employer, or the salary
              deduction facility be unavailable for any reason whatsoever, you
              are responsible to ensure that proper arrangements are made to
              ensure payment of the Insurance Premium to the Insurer.
            </li>

            <li>
              You accept that iMas shall not unilaterally terminate the salary
              deduction instruction to your Employer without having informed you
              in writing 30 days in advance of its intention to do so.
            </li>

            <li>
              You hereby indemnify iMas against any and all liability of
              whatsoever nature which may arise due to iMas not receiving the
              Insurance Premiums from your Employer as a result of factors not
              attributable to iMas, such as but not limited to the following:
              <br />
              a. Your salary is insufficient to allow for deduction of the
              Insurance Premium; or
              <br />
              b. Your Employer neglects to either deduct the Insurance Premium
              from your salary, or having done so neglects for whatever reason
              to pay such amount over to iMas; or
              <br />
              c. You or any person or entity on your behalf instruct your
              Employer to cease with Insurance Premium deductions to iMas; or
              <br />
              d. The Insurer withdraws the written mandate to iMas to receive
              Insurance Premium amounts on behalf of the Insurer.
              <br />
            </li>

            <li>
              You hereby indemnify Hollard and Simply Financial Services against
              any and all liability which may arise due to Hollard not receiving
              the Insurance Premiums from iMas within the period agreed above.
            </li>
          </ol>

          <PaymentDetailsExtraTermsContainer paymentType="iemas-salary-deduction" />

          <QuestionContainer
            title="My iMas member number is:"
            errors={iemasMemberNumberValidationMessages}
            isValid={iemasMemberNumberValid}
            isLoading={isLoading}
            hasWarning={
              iemasMemberNumberValid &&
              R.not(R.isEmpty(iemasMemberNumberValidationMessages))
            }
            warnings={iemasMemberNumberValidationMessages}
            nudge={iemasMemberNumberValid ? iemasMemberNumberValidationMessages : ''}
          >
            <InputButton input={inputProps} button={buttonProps}>
              {buttonText}
            </InputButton>
          </QuestionContainer>

          {canDebitOrder && (
            <PrimaryButton onClick={setDebitOrderPaymentMethod}>
              Proceed with Debit Order
            </PrimaryButton>
          )}
          
          <p className="text-center" style={{marginTop: '1.25rem'}}>
            By providing this number, you acknowledge these terms and
            conditions, and provide your consent and agreement as stipulated.
          </p>

          <BrokerCompliance type="iemas-salary-deduction" />

          {requiresOTP && (
            <OneTimePin
              validateAndAnswerQuestion={this.updateOtpValidation}
              {...otpFieldConfig}
            />
          )}

          {requiresThirdPartyOTP && (
            <ThirdPartyOtp
              validateAndAnswerQuestion={this.updateThirdPartyOtpValidation}
              {...thirdPartyOtpFieldConfig}
            />
          )}

          {!isSaving && this.props.brokerSaleType === 'qa' && (
            <BrokerQa
              updateCorrectionComments={this.updateCorrectionComments}
              canContinue={canContinue}
              rejectSale={this.props.rejectSale}
              validComments={validComments}
              approveSale={this.onSubmit}
              isSaving={isSaving}
            />
          )}

          {!isSaving && this.props.brokerSaleType !== 'qa' && (
            <PageActionButtons
              onClick={this.onSubmit}
              disabled={!canContinue}
              text={actionButtonText}
              canGoBack
            />
          )}
        </form>
      </WallOfTextContainer>
    );
  }
}

const getOwnerFullName = (state) => {
  const firstName = state.getIn(['answers', 'owner-first-name', 'answer']);
  const surname = state.getIn(['answers', 'owner-surname', 'answer']);

  return `${firstName} ${surname}`;
};

const postMemberValidation = R.curry((dispatch, memberNumber) => {
  dispatch(validateAndPostIemasMember(memberNumber));
});

const setMemberNumber = R.curry((dispatch, memberNumber) => {
  dispatch(validateIemasMember(memberNumber));
});

const setPaymentType = R.curry((dispatch, paymentType) => {
  dispatch(setPaymentTypeAction(paymentType));
});

const mapStateToProps = (state, ownProps) => {
  return {
    ownerFullName: getOwnerFullName(state),
    packageId: getSelectedPackageIdOrDefault(state),
    iemasMembers: state.get('iemasMembers'),
    otpValid: ownProps.requiresOTP ? isOtpValid(state) : true,
    thirdPartyOtpValid: ownProps.requiresThirdPartyOTP
      ? isThirdPartyOtpValid(state)
      : true,
    toFinishButtonText: () => getFinishButtonText(state),
    brokerSaleType: state.getIn(['brokerQa', 'brokerSaleType']),
    validComments: state.getIn(['brokerQa', 'isValidCorrectionComments']),
    brokerComplianceValid: state.getIn(['brokerCompliance', 'isValid']),
    currentSaleIsBrokerSale: isBrokerSale(state) || isFromBrokerPortal(state),
    selectedPaymentType: state.getIn(['paymentType', 'selectedPaymentType']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  postMemberValidation: postMemberValidation(dispatch),
  setMemberNumber: setMemberNumber(dispatch),
  answerQuestion: R.curry((packageId, question, answer) =>
    dispatch(answerQuestionActionCreator(packageId, question, answer))
  ),
  setPaymentType: setPaymentType(dispatch)
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    postMemberValidation: dispatchProps.postMemberValidation,
    setMemberNumber: dispatchProps.setMemberNumber,
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(IemasSalaryDeduction);

import { API_ROUTES } from '../config/constants';
import { 
    SALARY_DEDUCTION_RESERVE_TRANSACTION_NUMBER,
    SALARY_DEDUCTION_TRANSACTION_NUMBER_RESERVED,
    SET_SALARY_DEDUCTION_STAGE,
    SET_SALARY_DEDUCTION_PAYMENT_DETAILS,
    SALARY_DEDUCTION_START_MANDATE_DOWNLOAD,
    SALARY_DEDUCTION_FINISH_MANDATE_DOWNLOAD,
    SALARY_DEDUCTION_ERROR
} from './types';
import request from 'axios';
import { calculatePricingFromState } from '../views/customization/calculatePricing';
import { fileFromBase64 } from '../common/downloadfile';

const toEmployeeData = (data, pricing) => 
    Object.assign(data, { pricing });


const reservedContractNumber = (data) => {
    return {
        type: SALARY_DEDUCTION_TRANSACTION_NUMBER_RESERVED,
        payload: data
    };
};

const setPaymentDetails = (data, provider) => ({
    type: SET_SALARY_DEDUCTION_PAYMENT_DETAILS,
    payload: {
        paymentType: "salary-deduction",
        payrollProvider: provider.get('id'),
        inceptionMonth: "",
        employeeNumber: data.employeeNumber
    }
});

export const onloadRestart = () => ({
    type: SET_SALARY_DEDUCTION_STAGE,
    payload: {
        stage: 'start'
    }
});

export const reserveContractNumber = (provider) => {
    const payload = (dispatch, getState) => {
        const state = getState();
        const slug = state.get('selectedPackageSlug');
        const pricing = calculatePricingFromState(state, slug);

        request
            .get(API_ROUTES.SALARY_DEDUCTION_RESERVE_TRANSACTION_NUMBER, {})
            .then((res) => {
                dispatch(reservedContractNumber(toEmployeeData(res.data, pricing)));
                dispatch(setPaymentDetails(res.data, provider));
            });
    };

    return {
        type: SALARY_DEDUCTION_RESERVE_TRANSACTION_NUMBER,
        payload,
    };
};

export const finishMandateDownload = (data) => ({
    type: SALARY_DEDUCTION_FINISH_MANDATE_DOWNLOAD,
    payload: data,
  });


export const error = () => ({
  type: SALARY_DEDUCTION_ERROR,
  payload: {},
});

export const downloadMandate = (provider) => {
    const payload = (dispatch, getState) => {
      const state = getState();
      const inputs = state.getIn(['salaryDeduction', 'employee']);
      request
        .post(API_ROUTES.SALARY_DEDUCTION_DOWNLOAD_MANDATE, {url: provider.get('mandate-file-path')})
        .then(res => {
          fileFromBase64(`${inputs.transactionNumber}-SALARY-DEDUCTION-MANDATE.pdf`, res.data);
          dispatch(finishMandateDownload(res.data));
        })
        .catch(() => dispatch(error()));
    };
    return {
      type: SALARY_DEDUCTION_START_MANDATE_DOWNLOAD,
      payload,
    };
  };
/* eslint-disable max-len */
import { fromJS } from 'immutable';
import * as R from 'ramda';
import {
  COMPLETE_SALE_SUCCESS,
  SET_MULTI_POLICY_SUPPORT,
  PREP_FOR_MULTIPLE_POLICIES,
  SAVE_BANKING_DETAILS,
  DETAILS_CAPTURE_SUBMIT_UNDERWRITING_SUCCESS,
  DETAILS_CAPTURE_SUBMIT_PEEPS_SUCCESS,
} from '../actions/types';
import createActionHandler from './createActionHandler';

const initialState = fromJS({
  policies: [],
  owner: {},
  supportMultiplePolicies: false,
  bankingDetails: null,
  requiresPeeps: true,
});

const actionHandlers = {};

const setMultiplePoliciesSupport = (state, action) =>
  state.set('supportMultiplePolicies', action.payload.supportMultiplePolicies);

const completeSaleSuccess = (state, action) =>
  state
    .update('policies', v => v.insert(0, fromJS(action.payload)))
    .set('requiresPeeps', action.payload.requiresPeepsCompletion);

const prepForMultiplePolicies = (state, action) =>
  state.merge(fromJS(action.payload));

const setBankingDetails = (state, action) =>
  state.set('bankingDetails', fromJS(action.meta));

const completeDetails = R.curry((field, value, state, action) => {
  const { transactionNumber } = action.payload;
  const policies = state.get('policies').map(p => {
    if (p.get('contractNumber') === transactionNumber) {
      return p.set(field, value);
    }
    return p;
  });
  return state.set('policies', policies);
});

actionHandlers[SET_MULTI_POLICY_SUPPORT] = setMultiplePoliciesSupport;
actionHandlers[COMPLETE_SALE_SUCCESS] = completeSaleSuccess;
actionHandlers[PREP_FOR_MULTIPLE_POLICIES] = prepForMultiplePolicies;
actionHandlers[SAVE_BANKING_DETAILS] = setBankingDetails;
actionHandlers[DETAILS_CAPTURE_SUBMIT_UNDERWRITING_SUCCESS] = completeDetails(
  'underwrite',
  false
);
actionHandlers[DETAILS_CAPTURE_SUBMIT_PEEPS_SUCCESS] = completeDetails(
  'completed',
  true
);

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

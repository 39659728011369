import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Referral from './Referral';
import { getReferralNext } from './helpers';

export default class Referrals extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { referrals: [] };
    this.onReferral = this.onReferral.bind(this);
  }

  onReferral(referral, type) {
    const referrals = [referral].concat(this.state.referrals);
    this.setState({ referrals });
    this.props.submitReferral(referral, type);
  }

  render() {
    const {
      referralCode,
      referrerName,
      referralUrl,
      type,
      button,
    } = this.props;
    const next = getReferralNext(type);
    const formSubmit = {
      onSubmit: this.onReferral,
      next: next(referralUrl, referrerName),
      button,
    };
    return (
      <Referral
        referrerName={referrerName}
        referralCode={referralCode}
        submit={formSubmit}
        type={type}
      />
    );
  }
}

Referrals.propTypes = {
  submitReferral: PropTypes.func.isRequired,
  referralUrl: PropTypes.string,
  referralCode: PropTypes.string.isRequired,
  referrerName: PropTypes.string.isRequired,
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }),
};

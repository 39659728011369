import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import QuestionContainer from './QuestionContainer';
import newId from '../../common/newId';
import * as R from 'ramda';
import { Radio, Radios } from '@simply-fin-services/astronomix3';
import { getLabel } from '../../common/alternativeLabels';

class RadioSelectQuestion extends PureComponent {
  constructor(props) {
    super(props);
    if (props) {
      const keys = R.pipe(
        R.map((a) => ({ answer: a, key: newId() })),
        R.indexBy(R.prop('answer'))
      )(props.possibleAnswers);
      this.state = { keys };
    }
  }

  componentDidMount() {
    const { defaultAnswer, answerQuestion } = this.props;
    if (defaultAnswer) {
      answerQuestion(defaultAnswer);
    }
  }

  render() {
    const { id, possibleAnswers, answer, answerQuestion } = this.props;
    return (
      <QuestionContainer {...this.props}>
        <Radios>
          {possibleAnswers.map((a) => {
            const key = this.state.keys[a].key;
            return (
              <Radio
                key={key}
                id={id ? `${id}_${a}` : key}
                label={getLabel(this.props, a)}
                value={a}
                checked={answer === a}
                onChange={() => answerQuestion(a)}
              />
            );
          })}
        </Radios>
      </QuestionContainer>
    );
  }
}

RadioSelectQuestion.propTypes = {
  answerQuestion: PropTypes.func,
  answer: PropTypes.string,
  possibleAnswers: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export default RadioSelectQuestion;

"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortStateProducts = exports["default"] = void 0;

var R = _interopRequireWildcard(require("ramda"));

var _immutable = require("immutable");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var getSortOrder = function getSortOrder(productConfig) {
  return productConfig.map(function (p) {
    return p.get('order');
  });
};

var sortJsProducts = R.curry(function (productConfig, products) {
  var sortOrder = getSortOrder(productConfig);
  return R.sortBy(function (p) {
    return sortOrder.get(R.prop('productId', p));
  }, products);
});
var sortImmutableProducts = R.curry(function (productConfig, products) {
  var sortOrder = getSortOrder(productConfig);
  return products.sortBy(function (p) {
    return sortOrder.get(p.get('productId'), products);
  });
});
var sortProducts = R.curry(function (productConfig, products) {
  if (_immutable.Iterable.isIterable(products)) {
    return sortImmutableProducts(productConfig, products);
  }

  return sortJsProducts(productConfig, products);
});
var _default = sortProducts;
exports["default"] = _default;
var sortStateProducts = R.curry(function (state, products) {
  return sortProducts(state.get('products'), products);
});
exports.sortStateProducts = sortStateProducts;
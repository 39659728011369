import React from 'react';
import { toPremium } from '../common/numberFormats';
import * as R from 'ramda';

const PREMIUM_PLACEHOLDER = '{{{premium}}}';

const renderBodywithPremium = (premium, text) => {
  const textContainsPremium = R.includes(PREMIUM_PLACEHOLDER, text);
  const textWithPremium = R.replace(PREMIUM_PLACEHOLDER, premium, text);
  return textContainsPremium ? textWithPremium : text;
};

export default (config, finalCost) => {
  const { heading, body } = config;

  return (
    <div className="row shaddowed-container customization space-above ">
      <div className="col-xs-12 shaddowed-content space-below space-above">
        <h5 className="space-above">{heading}</h5>
        <p>{renderBodywithPremium(toPremium(finalCost), body)}</p>
      </div>
    </div>
  );
};

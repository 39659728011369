import { fromJS } from 'immutable';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router/immutable';
import createRootReducer from '../reducers';
import promiseMiddleware from '../middleware/promiseMiddleware';
import createLogger from '../middleware/createLoggerMiddleware';
import createFsaCheck from '../middleware/createFsaCheckMiddleware';
import { catchErrorMiddleware } from '../config/configureErrorHandling';
import createScrollToTopMiddleware from '../middleware/createScrollToTopMiddleware';
import fsaThunkMiddleware from 'redux-fsa-thunk';
import writeToLocalStorage from '../middleware/writeToLocalStorage';
import R from 'ramda';
/*
 * @param {History Object} a history object. We use `createMemoryHistory` for server-side rendering,
 *                          while using browserHistory for client-side
 *                          rendering.
 */

export default function configureStore(wrapMiddleware, initialState, history) {
  // Installs hooks that always keep react-router and redux
  // store in sync
  const defaultMiddleware = R.pipe(
    (m) => wrapMiddleware(initialState, m),
    R.append(createScrollToTopMiddleware())
  )([catchErrorMiddleware,
    promiseMiddleware,
    routerMiddleware(history),
    fsaThunkMiddleware,
    createScrollToTopMiddleware(),
  ]);
  const enableLocalStorageMiddleware = false;
  const exists = (i) => !R.isNil(i) && !R.isEmpty(i);

  const getStateFromLocalStorage = (currentState) => {
    const localStorageState = JSON.parse(localStorage.getItem('state'));
    return exists(localStorageState)
      ? R.assocPath(
          ['devToolsLocalStorage', 'enableLocalStorage'],
          enableLocalStorageMiddleware,
          localStorageState
        )
      : currentState;
  };
  const getRestoredState = (currentState) => {
    return typeof window !== 'undefined' &&
      localStorage &&
      enableLocalStorageMiddleware === true
      ? getStateFromLocalStorage(currentState)
      : currentState;
  };

  const pushDevMiddleware = () =>
    R.pipe(
      R.append(writeToLocalStorage),
      R.append(createLogger()),
      R.append(createFsaCheck())
    )(defaultMiddleware);

  const updatedMiddleware = __DEVCLIENT__
    ? pushDevMiddleware()
    : defaultMiddleware;
  const restoredOrInitialState = __DEVCLIENT__
    ? getRestoredState(initialState)
    : initialState;

  /* eslint-disable no-underscore-dangle */
  const store = createStore(
    createRootReducer(history),
    fromJS(restoredOrInitialState),
    compose(
      applyMiddleware(...updatedMiddleware),
      typeof window === 'object' &&
        typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );
  /* eslint-enable no-underscore-dangle */
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('reducers', () => {
      const nextReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer(history));
    });
  }

  return store;
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FB_APP_ID } from '../../config/appConfig';
import {
  WhatsappButton,
  FacebookButton,
  TwitterButton,
  MessengerButton,
  GhostButton,
} from '@simply-fin-services/astronomix3';

const shareText = '';

const encodeUri = t => {
  if (typeof window === 'undefined') {
    return t;
  }
  return window.encodeURI(t);
};

export class ShareToWhatsapp extends PureComponent {
  render() {
    const { text } = this.props;
    const encodedMessage = encodeUri(text);
    const url = `whatsapp://send?text=${encodedMessage}`;
    const onClick = () => window.open(url, '_blank');
    return <WhatsappButton onClick={onClick}>{shareText} </WhatsappButton>;
  }
}

// <img src={whatsappIcon} alt="share to whatsapp" />
ShareToWhatsapp.propTypes = {
  text: PropTypes.string.isRequired,
};

export class ShareToTwitter extends PureComponent {
  render() {
    const { text, url, hashtags } = this.props;
    const hastagList = encodeUri(hashtags);
    const twitterText = encodeUri(text);
    const twitterUrl = `https://twitter.com/intent/tweet?hashtags=${hastagList}&url=${url}&text=${twitterText}`;
    const onClick = () => window.open(twitterUrl, '_blank');
    return <TwitterButton onClick={onClick}>{shareText} </TwitterButton>;
  }
}

ShareToTwitter.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  hashtags: PropTypes.string,
};

export class ShareToEmail extends PureComponent {
  render() {
    const { subject, body } = this.props;
    const stext = encodeUri(subject);
    const text = encodeUri(body);
    const mailTo = `mailto:?to=&subject=${stext}&cc=referrals@simply.co.za&body=${text}`;
    const onClick = () => window.open(mailTo, '_blank');
    return <GhostButton onClick={onClick}>Email</GhostButton>;
  }
}

ShareToEmail.propTypes = {
  subject: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export class ShareToFacebook extends PureComponent {
  render() {
    const { url } = this.props;
    const facebookSharerUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&display=popup&app_id=${FB_APP_ID}`;
    const onClick = () => window.open(facebookSharerUrl, '_blank');
    return <FacebookButton onClick={onClick}>{shareText} </FacebookButton>;
  }
}

ShareToFacebook.propTypes = {
  url: PropTypes.string.isRequired,
};

export class ShareToMessenger extends PureComponent {
  render() {
    const { url, redirectUri } = this.props;
    const messengerSharerUrl = `https://www.facebook.com/dialog/send?app_id=${FB_APP_ID}&link=${url}&redirect_uri=${redirectUri}`;
    const onClick = () => window.open(messengerSharerUrl, '_blank');
    return <MessengerButton onClick={onClick}>{shareText} </MessengerButton>;
  }
}

ShareToMessenger.propTypes = {
  url: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
};

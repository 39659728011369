import React, { PureComponent } from 'react';
import { ROUTES } from '../config/constants';
export default class NotFound extends PureComponent {
  render() {
    return (
      <div className="row info-page">
        <div className="col-xs-12 text-center space-below-3">
          <p className="space-below-3"></p>
          <p className="space-below-3"></p>
          <h1>Oops!</h1>
          <h3>Something went wrong</h3>
          <h5>
            Click <a href={ROUTES.HOME}> here </a>to go back to the home page
          </h5>
          <p className="space-below-3"></p>
          <p className="space-below-3"></p>
          <p className="space-below-3"></p>
          <p className="space-below-3"></p>
        </div>
      </div>
    );
  }
}

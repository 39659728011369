import { DOMAIN } from '../config/constants';
import * as R from 'ramda';
import { IS_DEV } from '../config/appConfig';
import cookieBakery from 'js-cookie';

export const checkIfCookieExists = cookieKey =>
  !R.isNil(cookieBakery.get(cookieKey)) &&
  !R.isEmpty(cookieBakery.get(cookieKey));

export const getCookie = cookieKey => cookieBakery.get(cookieKey);

export const setCookie = (key, value, expires) => {
  const domain = IS_DEV ? 'localhost' : DOMAIN;
  cookieBakery.set(key, value, { expires, domain });
};

import {
  UPDATE_SELECTED_PACKAGE,
  CONTINUE_WITH_CHANGED_PACKAGE,
  UPDATE_PACKAGE_SUCCESS,
} from './types';
import { setMultiPolicySupport } from './multiplePolicies';
import { setRehydrationUrl } from './rehydration';
import { answerQuestion } from './answers';
import request from 'axios';
import { API_ROUTES } from '../config/constants';
import * as R from 'ramda';
import { fromJS } from 'immutable';

const updatePackageSuccess = (packageId) => {
  const type = UPDATE_PACKAGE_SUCCESS;
  return {
    type,
    payload: { packageId },
  };
};

const getDefaults = (state, packageSlug) => {
  const quotePageId = state.getIn([
    'packages',
    packageSlug,
    'quoteQuestionPageId',
  ]);
  const qPage = state.getIn(['questionPages', quotePageId]);
  if (qPage && qPage.has('defaults')) {
    return qPage.get('defaults');
  }
  return fromJS([]);
};

const answerDefaultQuestions = (state, dispatch, packageSlug) => {
  const packageId = state.getIn(['packages', packageSlug, 'packageId']);
  const defaultAnswers = getDefaults(state, packageSlug);
  if (defaultAnswers) {
    R.forEach((question) => {
      const { answer, questionId } = question;
      return dispatch(answerQuestion(packageId, questionId, answer));
    }, defaultAnswers.toJS());
  }
  return null;
};

const savePackageId = (packageId) =>
  request.post(`${API_ROUTES.SELECTED_PACKAGE}`, { packageId });

const packageChangeActions = (packageSlug) => (dispatch, getState) => {
  const state = getState();
  const packageId = state.getIn(['packages', packageSlug, 'packageId']);
  answerDefaultQuestions(state, dispatch, packageSlug);
  savePackageId(packageId);
  Promise.all([dispatch(setMultiPolicySupport(getState(), packageSlug))])
    .then(() => dispatch(setRehydrationUrl(packageSlug)))
    .catch();
  return dispatch(updatePackageSuccess(packageId));
};

export const updateSelectedPackage = (packageSlug) => {
  const type = UPDATE_SELECTED_PACKAGE;
  const meta = { packageSlug };
  const payload = packageChangeActions(packageSlug);
  return {
    type,
    meta,
    payload,
  };
};

export const continueWithChangedPackage = () => ({
  type: CONTINUE_WITH_CHANGED_PACKAGE,
});

import React from 'react';
import { pipe, append } from 'ramda';
import createRoutes from './routes';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { startup as startupAction } from './actions/startup';
import configurePackageTransitions_ from './config/configurePackageTransitions';
import { createTagManagerMiddleware } from './common/googleTagManager';
import { createMauticMiddleware } from './common/mauticTag';


const App = ({history, store}) => (
  <ConnectedRouter history={history}>{createRoutes(store)}</ConnectedRouter>
);

export default App;

export const startup = startupAction;
export const configurePackageTransitions = configurePackageTransitions_;
export const wrapMiddleware = (initialState, middleware) => {
  return pipe(
    append(createTagManagerMiddleware(initialState)),
    append(createMauticMiddleware(initialState))
  )(middleware);
};

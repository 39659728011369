import * as types from './types';
import { API_ROUTES } from '../config/constants';
import request from 'axios';
import * as R from 'ramda';

export const referTypedContact = (
  referrerName,
  referralCode,
  contactDetails,
  referralType
) => {
  const type = types.REFER_CONTACT;
  const route = `${API_ROUTES.REFER_CONTACT}${referralType}`;
  const payload = request
    .post(route, Object.assign({ referralCode, referrerName }, contactDetails))
    .then(() => {})
    .catch(() => {});
  return {
    type,
    payload,
  };
};

export const referContact = (referrerName, referralCode, contactDetails) => {
  const type = types.REFER_CONTACT;
  const payload = request
    .post(
      API_ROUTES.REFER_CONTACT,
      Object.assign({ referralCode, referrerName }, contactDetails)
    )
    .then(() => {})
    .catch(() => {});
  return {
    type,
    payload,
  };
};

export const referMultipleContacts = R.curry(
  (dispatch, state, referrerName, referralCode, contacts, type) => {
    contacts.map(contact => {
      const contactAnswers = R.head(contact.get('answers').toJS());
      const questions = state
        .getIn(['questionPages', `referrals-${type}`, 'questions'])
        .toJS();
      const answers = R.mergeAll(
        questions.map(qId => R.assoc(qId, R.prop(qId, contactAnswers), {}))
      );
      const structuredAnswers = R.map(
        detail => R.prop('answer', detail),
        answers
      );
      return dispatch(
        referTypedContact(referrerName, referralCode, structuredAnswers, type)
      );
    });
  }
);

import * as types from './types';
import request from 'axios';
import { ROUTES, API_ROUTES } from '../config/constants';
import { completeSale, sendSaleToQa } from './completeSale';
import { push } from 'connected-react-router';
import * as R from 'ramda';
import { shouldSendToQa } from '../common/saleOptions';

export const addPeep = peep => ({
  type: types.ADD_PEEP,
  payload: peep,
});

export const selectBeneficiary = (productId, peep) => ({
  type: types.SELECT_BENEFICIARY,
  payload: {
    productId,
    peep,
  },
});

export const deselectBeneficiary = (productId, peep) => ({
  type: types.DESELECT_BENEFICIARY,
  payload: {
    productId,
    peep,
  },
});

export const selectInsured = (productId, peep) => ({
  type: types.SELECT_INSURED,
  payload: {
    productId,
    peep,
  },
});

export const deselectInsured = (productId, peep) => ({
  type: types.DESELECT_INSURED,
  payload: {
    productId,
    peep,
  },
});

export const savePeepsSuccess = () => ({
  type: types.SAVE_PEEPS_SUCCESS,
});

export const savePeepsFailure = () => ({
  type: types.SAVE_PEEPS_FAILURE,
});

export const savePeepsRequest = (
  insuredsEndpoint,
  beneficiariesEndpoint,
  insureds,
  beneficiaries
) => {
  const insuredsRequest = request.post(insuredsEndpoint, { details: insureds });
  const beneficiariesRequest = request.post(beneficiariesEndpoint, {
    details: beneficiaries,
  });
  return Promise.all([insuredsRequest, beneficiariesRequest]);
};

const postPeeps = (insureds, beneficiaries) => (dispatch, getState) => {
  savePeepsRequest(
    API_ROUTES.INSUREDS,
    API_ROUTES.BENEFICIARIES,
    insureds,
    beneficiaries
  )
    .then(() => {
      const finishAction = shouldSendToQa(getState()) ? sendSaleToQa : completeSale;
      dispatch(savePeepsSuccess());
      dispatch(finishAction());
    })
    .catch(() => {
      dispatch(savePeepsFailure());
      dispatch(push(ROUTES.ERROR));
    });
};

export const savePeeps = (insureds, beneficiaries) => ({
  type: types.SAVE_PEEPS,
  payload: postPeeps(insureds, beneficiaries),
});

const rehydratePeeps = R.curry((dispatch, peepKey, peepFn, interaction) => {
  if (R.has(peepKey, interaction)) {
    R.forEachObjIndexed((peeps, productId) => {
      R.forEach(p => {
        dispatch(addPeep(p));
        dispatch(peepFn(productId, p));
      }, peeps);
    }, interaction[peepKey]);
  }
});

export const rehydrateInteractionPeeps = (dispatch, interaction) => {
  rehydratePeeps(dispatch, 'insureds', selectInsured, interaction);
  rehydratePeeps(dispatch, 'beneficiaries', selectBeneficiary, interaction);
};

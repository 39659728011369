/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  toPremiumNoDecimal,
  toCurrencyNoDecimal,
  toCoveredAmount,
} from '../common/numberFormats';
import { Table as AstroTable } from '@simply-fin-services/astronomix3';

const Table = styled(AstroTable)`
  width: 100%;
  th, td {
    border-top: solid 1px white;
    background-color: #ededed;
    font-size: 1rem !important; /* hack to fix font size for this table */
  }
`;

const RightAlignedCell = styled.td`
  text-align: right;
  padding-left: 1rem;
`;

const TopPaddedCell = styled.td`
  padding-top: 1rem;
`;

export default class SummaryProducts extends PureComponent {
  render() {
    const {
      products,
      hidePremium,
      multiplyCover,
      headerLess,
      hideCover,
      withRop,
      ropPercentage,
      isCompleteSale,
    } = this.props;
    const renderExtendedFamilyInsuredsCover = ({ insureds, title }) => (
      <React.Fragment>
        <tr key={title}>
          <TopPaddedCell colspan="3">
            <strong>
              {title} {!hideCover && <span>cover</span>}
            </strong>
          </TopPaddedCell>
        </tr>
        {insureds.map((i) => (
          <tr key={i.id}>
            <td>
              {`${i.name} ${i.surname}`} {!hideCover && <span>cover</span>}
            </td>
            {multiplyCover && (
              <RightAlignedCell>
                <span>{toCoveredAmount(i.insuredAmount)}</span>
              </RightAlignedCell>
            )}
            {!multiplyCover && (
              <RightAlignedCell>
                <span>{toCurrencyNoDecimal(i.insuredAmount)}</span>
              </RightAlignedCell>
            )}
          </tr>
        ))}
      </React.Fragment>
    );
    return (
      <Table>
        <tbody>
          {!headerLess && (
            <tr>
              <td>Insurance</td>
              <td>Cover Amount</td>
              {!hidePremium && <td>Monthly Premium</td>}
            </tr>
          )}
          {products
            .filter((p) => p.selected)
            .map((p) =>
              p.isExtendedFamily ? (
                renderExtendedFamilyInsuredsCover(p)
              ) : (
                <tr key={p.title}>
                  <td>
                    <strong>
                      {p.title} {!hideCover && <span>cover</span>}
                    </strong>
                  </td>
                  {multiplyCover && (
                    <RightAlignedCell>
                      <span>{toCoveredAmount(p.insuredAmount)}</span>
                    </RightAlignedCell>
                  )}
                  {!multiplyCover && (
                    <RightAlignedCell>
                      <span>{toCurrencyNoDecimal(p.insuredAmount)}</span>
                    </RightAlignedCell>
                  )}
                  {!hidePremium && (
                    <RightAlignedCell>
                      <span>{toPremiumNoDecimal(p.premium)}</span>
                    </RightAlignedCell>
                  )}
                </tr>
              )
            )}
          {withRop && isCompleteSale && (
            <tr key="cashback">
              <TopPaddedCell>
                <strong>Cash Back</strong>
              </TopPaddedCell>
              <RightAlignedCell>
                {ropPercentage}% of premiums every 5 years
              </RightAlignedCell>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}

SummaryProducts.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      insuredAmount: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      premium: PropTypes.number,
    })
  ).isRequired,
  hidePremium: PropTypes.bool,
  multiplyCover: PropTypes.bool,
  headerLess: PropTypes.bool,
  transparent: PropTypes.bool,
  hideCover: PropTypes.bool,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DropdownQuestion from '../questions/DropdownQuestion';
import * as R from 'ramda';

const importantDays = [25, 26, 27, 15];
const lastExcludedDay = 32;
const availableDays = R.pipe(
  R.range(1),
  R.without(importantDays),
  R.concat(importantDays),
  R.map(R.toString)
)(lastExcludedDay);

export default class SelectDebitDay extends PureComponent {
  componentDidMount() {
    const { details } = this.props;
    if(details) {
      const debitDay = details.getIn(['debitDay', 'value']);
      if(debitDay) {
        this.props.answerQuestion(debitDay);
      }
    }
  }

  render() {
    const { answerQuestion } = this.props;
    return (
      <div>
        <DropdownQuestion
          title="Debit day (pay date)"
          possibleAnswers={availableDays}
          answerQuestion={answerQuestion}
          {...this.props}
          fitForm
          placeholder="Select debit day"
        />
      </div>
    );
  }
}

SelectDebitDay.propTypes = {
  answerQuestion: PropTypes.func.isRequired,
};

import React from 'react';
import toQuestionControl from '../questions/toQuestionControl';
import isReflexive, {
  isReflexiveCheckboxes,
} from '../../.shared/validators/isReflexive';
import * as R from 'ramda';
import { Map } from 'immutable';

const isInlineQuestion = question =>
  R.includes('inline', question.get('type').split('-'));
const getQuestion = (qId, questions) => questions.get(qId);
const getAnswer = (qId, answers) => {
  if (answers.has(qId)) {
    return answers.get(qId);
  }
  return new Map();
};

const buildRegularQuestion = (
  QuestionControl,
  question,
  answer,
  qId,
  isOptional,
  answerQuestion,
  possibleAnswers,
  hideUnansweredQuestions
) => (
  <QuestionControl
    key={qId}
    {...question.toJS()}
    {...answer.toJS()}
    optional={isOptional}
    answerQuestion={answerQuestion(qId)}
    possibleAnswers={possibleAnswers}
    hideUnansweredQuestions={hideUnansweredQuestions}
  />
);

const buildRegularInlineQuestion = (
  QuestionControl,
  question,
  answer,
  qId,
  isOptional,
  answerQuestion,
  possibleAnswers,
  hideUnansweredQuestions
) => (
  <QuestionControl
    key={qId}
    {...question.toJS()}
    {...answer.toJS()}
    optional={isOptional}
    answerQuestion={answerQuestion(qId)}
    inline
    possibleAnswers={possibleAnswers}
    hideUnansweredQuestions={hideUnansweredQuestions}
  />
);

const buildReflexiveQuestion = (
  QuestionControl,
  questions,
  answers,
  answerQuestion,
  node,
  qId,
  possibleAnswers,
  hideUnansweredQuestions
) => (
  <QuestionControl
    key={qId}
    questions={questions}
    answers={answers}
    answerQuestion={answerQuestion}
    questionNode={node}
    possibleAnswers={possibleAnswers}
    hideUnansweredQuestions={hideUnansweredQuestions}
  />
);

const buildReflexiveCheckBoxQuestion = (
  QuestionControl,
  question,
  answer,
  questions,
  answers,
  answerQuestion,
  node,
  qId,
  possibleAnswers,
  hideUnansweredQuestions
) => {
  return (
    <QuestionControl
      key={qId}
      {...question.remove('possibleAnswers').toJS()}
      {...answer.toJS()}
      questions={questions}
      answers={answers}
      answerQuestion={answerQuestion}
      questionNode={node}
      possibleAnswers={possibleAnswers}
      hideUnansweredQuestions={hideUnansweredQuestions}
    />
  );
};

const possibleAnswerFilters = {};
possibleAnswerFilters.filterByOtherAnswer = (
  filterConfig,
  answers,
  possibleAnswers
) => {
  return possibleAnswers.filter(possibleAnswer => {
    const possibleAnswersToHideIfRequirementNotMet =
      filterConfig.answersToHideIfRequirementNotMet;
    if (!possibleAnswersToHideIfRequirementNotMet.includes(possibleAnswer)) {
      return true;
    }

    const questionToFilterBy = filterConfig.otherQuestionId;
    const requiredAnswer = filterConfig.answerMustBe.toLowerCase();
    const givenAnswer = answers
      .getIn([questionToFilterBy, 'answer'])
      .toLowerCase();
    const answerIsRequiredAnswerAndOptionShouldBeIncluded =
      requiredAnswer === givenAnswer;
    return answerIsRequiredAnswerAndOptionShouldBeIncluded;
  });
};

const filterPossibleAnswers = (question, answers) => {
  const possibleAnswers = question.get('possibleAnswers');
  if (!question.has('possibleAnswerFilters')) {
    return possibleAnswers;
  }
  const filters = question.get('possibleAnswerFilters').toJS();
  const filterTypes = R.keys(filters);
  return filterTypes.reduce((filteredPossibleAnswers, filterType) => {
    const allFiltersOfType = filters[filterType];
    return allFiltersOfType.reduce((filtered, filterConfig) => {
      const filterFn = possibleAnswerFilters[filterType];
      return filterFn(filterConfig, answers, filtered);
    }, filteredPossibleAnswers);
  }, possibleAnswers);
};

export default R.curry(
  (
    questions,
    optionalQuestions,
    answers,
    answerQuestion,
    hideUnansweredQuestions,
    qId
  ) => {
    const isOptional =
      !!optionalQuestions && optionalQuestions.toSetSeq().has(qId);
    const question = getQuestion(qId, questions);
    const possibleAnswers = filterPossibleAnswers(question, answers);
    const answer = getAnswer(qId, answers);
    const QuestionControl = toQuestionControl(question);
    if (isReflexive(question)) {
      return buildReflexiveQuestion(
        QuestionControl,
        questions,
        answers,
        answerQuestion,
        question,
        qId,
        possibleAnswers,
        hideUnansweredQuestions
      );
    }
    if (isReflexiveCheckboxes(question)) {
      return buildReflexiveCheckBoxQuestion(
        QuestionControl,
        question,
        answer,
        questions,
        answers,
        answerQuestion,
        question,
        qId,
        possibleAnswers,
        hideUnansweredQuestions
      );
    }
    if (isInlineQuestion(question)) {
      return buildRegularInlineQuestion(
        QuestionControl,
        question,
        answer,
        qId,
        isOptional,
        answerQuestion,
        possibleAnswers,
        hideUnansweredQuestions
      );
    }
    return buildRegularQuestion(
      QuestionControl,
      question,
      answer,
      qId,
      isOptional,
      answerQuestion,
      possibleAnswers,
      hideUnansweredQuestions
    );
  }
);

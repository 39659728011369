import React, { PureComponent } from 'react';
import toQueryParams from '../../common/propsToLocationQueryParams';
import { Button, Field, InputText } from '@simply-fin-services/astronomix3';

export default class DetailsCaptureCheckId extends PureComponent {
  render() {
    const queryParams = toQueryParams(this.props);
    const { name, token, error } = queryParams;
    const displayname = name ? `Hi ${name},` : 'Hi,';
    const welcomeMessage = `${displayname} for security purposes please enter your South African ID number below (or passport number if foreign) to continue.`; // eslint-disable-line max-len
    return (
      <div className="row">
        <div className="col-xs-12">
          <form method="post">
            <h4 className="text-center">{welcomeMessage}</h4>
            <Field
              label="Identity / Passport Number"
              errors={error ? ['You have entered invalid details'] : []}
            >
              <InputText id="idnumber" name="idnumber" />
            </Field>
            <input
              type="hidden"
              className="form-control"
              id="token"
              name="token"
              value={token}
            />
            <Button colour="primary" size="medium" type="submit">
              Submit
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

import {
  SET_SHOW_CONTACT_DETAILS_POPUP_FLAG,
  VIEWED_CONTACT_FORM,
} from './types';
import { setCookie, checkIfCookieExists } from '../common/cookieBakery';

const CONTACT_FORM_VIEW_DATE_COOKIE = 'simplyContactFormViewDate';
const SESSION_COOKIE_LIFETIME_IN_DAYS = 30;

export const setContactDetailsPopupFlag = (queryParams, dispatch) => {
  const cookieExists = checkIfCookieExists(CONTACT_FORM_VIEW_DATE_COOKIE);
  const { contactDetailsPopupHidden } = queryParams;
  if (cookieExists || contactDetailsPopupHidden === 'true') {
    dispatch({
      type: SET_SHOW_CONTACT_DETAILS_POPUP_FLAG,
      payload: false,
    });
  }
};

export const setContactFormViewDate = () => ({
  type: VIEWED_CONTACT_FORM,
  payload: () =>
    setCookie(
      CONTACT_FORM_VIEW_DATE_COOKIE,
      new Date(),
      SESSION_COOKIE_LIFETIME_IN_DAYS
    ),
});

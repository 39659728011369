import * as types from './types';
import request from 'axios';
import { API_ROUTES } from '../config/constants';
import * as R from 'ramda';
import Immutable from 'immutable';
import { setFormEntity } from './entityForm';
import { stateToQuestionnaireProps } from '../views/questionnaire/toQuestionPageProps';

const answerQuestionSuccess = (validatedAnswer) => {
  const type = types.ANSWER_QUESTION_SUCCESS;
  const payload = validatedAnswer;
  return {
    type,
    payload,
  };
};

export const answerQuestionError = (questionId, error) => {
  const type = types.ANSWER_QUESTION_ERROR;
  const payload = { questionId, error };
  return {
    type,
    payload,
  };
};

let answerQuestionBase = null;

const getRelatedQuestions = (state, packageId, questionId) =>
  state
    .getIn(['packageRelatedQuestions', packageId, 'questions'])
    .filter((q) => q.get('relatedQuestions').includes(questionId))
    .map((q) => q.get('questionId'))
    .toJS();

const getRelatedQuestionIdsToBeValidated = (state, questionId) => state.getIn(['questions', questionId, 'relatedQuestionIdsToBeValidated']);

const answerRelatedQuestion = R.curry(
  (dispatch, route, packageId, state, questionId) => {
    const answer = state.getIn(['answers', questionId, 'answer']);
    if (answer) {
      dispatch(answerQuestionBase(route, packageId, questionId, answer));
    }
  }
);

const answerAffiliateQuestion = (
  route,
  meta,
  postToServer,
  dispatch,
  getState
) => {
  const { packageId, relatedProduct, questionId } = meta;
  const state = getState();
  const question = state.getIn(['questions', questionId]);
  const affiliateQuestionId = question.get('affiliateQuestionId');
  const affiliateAnswer = state.getIn(['answers', affiliateQuestionId]);
  const affiliatedAnswer = state.getIn(['answers', questionId]);

  const isNil = (i) =>
    i instanceof Immutable.Map ? isNil(i.toJS()) : R.isNil(i);
  const isEmpty = (i) =>
    i instanceof Immutable.Map ? isEmpty(i.toJS()) : R.isEmpty(i);
  if (!isNil(affiliateAnswer) && !isEmpty(affiliateAnswer)) {
    const affiliateMeta = {
      packageId,
      answer: affiliateAnswer.get('answer'),
      relatedProduct,
      questionId: affiliateQuestionId,
      affiliatedAnswer,
    };
    return dispatch({
      type: types.ANSWER_QUESTION,
      meta: affiliateMeta,
      payload: postToServer(route, affiliateMeta),
    });
  }
  return null;
};

const answerRelatedQuestionByDefault = (
  questionId,
  answer,
  route,
  postToServer,
  dispatch
) => {
  const relatedAnswer = R.mergeRight(answer, {
    questionId,
    skipAnsweringOfRelatedQuestionsToBeAnswered: true,
  });

  dispatch({
    type: types.ANSWER_QUESTION,
    meta: relatedAnswer,
    payload: postToServer(route, relatedAnswer),
  });
};

const answerInsuredPersonGender = (
  questionId,
  answerMap,
  route,
  postToServer,
  dispatch
) => {
  const maleRelationships = ['Spouse', 'Father', 'Brother', 'Child'];
  const relationship = answerMap.answer;
  const relatedQuestionAnswer = maleRelationships.includes(relationship)
    ? 'Male'
    : 'Female';
  const relatedAnswer = R.mergeRight(answerMap, {
    questionId,
    answer: relatedQuestionAnswer,
    skipAnsweringOfRelatedQuestionsToBeAnswered: true,
  });

  dispatch({
    type: types.ANSWER_QUESTION,
    meta: relatedAnswer,
    payload: postToServer(route, relatedAnswer),
  });
};

const answerPolicyPayerDOB = (
  questionId,
  answerMap,
  route,
  postToServer,
  dispatch
) => {
  const idNumber = answerMap.answer;
  const unformatedDob = idNumber.slice(0, 6);
  const currentYearDigit = parseInt(
    new Date().getFullYear().toString().slice(2, 4),
    10
  );
  const dobYearDigit = parseInt(unformatedDob.slice(0, 2), 10);
  const dobYear =
    dobYearDigit < currentYearDigit ? `20${dobYearDigit}` : `19${dobYearDigit}`;
  const dateOdBirth = `${unformatedDob.slice(4, 6)}/${unformatedDob.slice(2, 4)}/${dobYear}`;
  const relatedAnswer = R.mergeRight(answerMap, {
    questionId,
    answer: dateOdBirth,
    skipAnsweringOfRelatedQuestionsToBeAnswered: true,
  });
  dispatch({
    type: types.ANSWER_QUESTION,
    meta: relatedAnswer,
    payload: postToServer(route, relatedAnswer),
  });
};

const answerInsuredPersonIncome = (
  questionId,
  answerMap,
  route,
  postToServer,
  dispatch
) => {
  const relatedAnswer = R.mergeRight(answerMap, {
    questionId,
    skipAnsweringOfRelatedQuestionsToBeAnswered: true,
  });
  dispatch({
    type: types.ANSWER_QUESTION,
    meta: relatedAnswer,
    payload: postToServer(route, relatedAnswer),
  });
};

const relatedQuestionAnsweringFunctions = {
  default: answerRelatedQuestionByDefault,
  answerInsuredPersonGender,
  answerPolicyPayerDOB,
  answerInsuredPersonIncome,
};

const getRelatedQuestionIdsToBeAnsweredFromQuestion = (state, questionId) => {
  const question = state.getIn(['questions', questionId]);
  const ids = question.get('relatedQuestionIdsToBeAnswered');
  return R.isNil(ids) ? [] : ids.toJS();
};

const getFunctionToAnswerRelatedQuestionWith = (
  state,
  questionId,
  relatedQuestionId
) => {
  const question = state.getIn(['questions', questionId]);
  const functionName = question.getIn([
    'relatedQuestionAnsweringFunctions',
    relatedQuestionId,
  ]);
  return R.isNil(functionName)
    ? relatedQuestionAnsweringFunctions.default
    : relatedQuestionAnsweringFunctions[functionName];
};

const hasRelatedQuestionIdsToBeAnswered = (ids) => {
  return !R.isNil(ids) && !R.isEmpty(ids);
};

const answerRelatedQuestionsToBeAnswered = (
  route,
  answer,
  postToServer,
  dispatch,
  getState
) => {
  const shouldAnswerRelatedQuestionsToBeAnswered =
    answer.skipAnsweringOfRelatedQuestionsToBeAnswered !== true;

  if (shouldAnswerRelatedQuestionsToBeAnswered) {
    const state = getState();
    const questionId = answer.questionId;
    const questionIds = getRelatedQuestionIdsToBeAnsweredFromQuestion(
      state,
      questionId
    );
    if (hasRelatedQuestionIdsToBeAnswered(questionIds)) {
      questionIds.forEach((id) => {
        const answerRelatedQuestionFunc =
          getFunctionToAnswerRelatedQuestionWith(state, questionId, id);
        answerRelatedQuestionFunc(id, answer, route, postToServer, dispatch);
      });
    }
  }
};

const decorateIncludedAnswers = (state, answer) => {
  const questionId = answer.questionId;

  const includeAnswers = state.getIn([
    "questions",
    questionId,
    "validatorOptions",
    "includeAnswers",
  ]);

  if (includeAnswers) {
    const includedAnswers = Immutable.Map(
       includeAnswers.map((includeQuestionId) => [
         includeQuestionId,
         state.getIn(["answers", includeQuestionId, "answer"]),
       ]));

    return Immutable.fromJS(answer).set("includedAnswers", includedAnswers).toJS();
  }

  return answer;
};

export const validateRelatedQuestionsIds = ({postQuestionAnswer, route, state, answer, entityType, pageQuestions, optionalPageQuestions, dispatch, getState}) => {
  const relatedQuestionIdsToBeValidated = getRelatedQuestionIdsToBeValidated(
    state,
    answer.questionId
  );

  if(relatedQuestionIdsToBeValidated){
    relatedQuestionIdsToBeValidated.forEach((questionIdToValidate => {
      const answerToValidate = state.getIn(['answers',questionIdToValidate]);
      if(answerToValidate){
        const validation = entityType ? postQuestionAnswer(entityType, pageQuestions, optionalPageQuestions, answerToValidate.toJS()) : postQuestionAnswer(route, answerToValidate.toJS());
        validation(dispatch, getState);
      }

    }));
  }
};

const postAnswer = (route, answer) =>
  (dispatch, getState) => {

  const state = getState();

  return request
    .post(route, decorateIncludedAnswers(state, answer))
    .then((res) => {
      dispatch(answerQuestionSuccess(res.data));
      const relatedQuestions = getRelatedQuestions(
        state,
        answer.packageId,
        answer.questionId
      );
      R.forEach(
        answerRelatedQuestion(dispatch, route, answer.packageId, state),
        relatedQuestions
      );
      answerAffiliateQuestion(route, answer, postAnswer, dispatch, getState);
      validateRelatedQuestionsIds({postQuestionAnswer: postAnswer, route, state, answer, dispatch, getState});
      answerRelatedQuestionsToBeAnswered(
        route,
        answer,
        postAnswer,
        dispatch,
        getState
      );
    })
    .catch((error) => {
      dispatch(answerQuestionError(answer.questionId, error));
    });
  };


export const postValidateQuestionAnswer =
  (entityType, pageQuestions, optionalPageQuestions, answer) =>
  (dispatch, getState) => {
    const route = API_ROUTES.VALIDATE_QUESTION_ANSWER;

    const state = getState();

    return request
    .post(route, decorateIncludedAnswers(state, answer))
      .then((res) => {
        dispatch(answerQuestionSuccess(res.data));
        const relatedQuestions = getRelatedQuestions(
          state,
          answer.packageId,
          answer.questionId
        );

        R.forEach(
          answerRelatedQuestion(dispatch, route, answer.packageId, state),
          relatedQuestions
        );
        answerAffiliateQuestion(route, answer, postAnswer, dispatch, getState);
        answerRelatedQuestionsToBeAnswered(
          route,
          answer,
          postAnswer,
          dispatch,
          getState
        );

        validateRelatedQuestionsIds({postQuestionAnswer: postValidateQuestionAnswer, state, answer, entityType, pageQuestions, optionalPageQuestions, dispatch, getState});

        // Entity
        const { isValid, answers } = stateToQuestionnaireProps(
          getState(),
          pageQuestions,
          optionalPageQuestions
        );

        const entity = Immutable.fromJS({
          isValid,
          entity: answers.map((entry) => {
            return entry.get('answer');
          }),
        });

        dispatch(setFormEntity(entityType, entity));
      })
      .catch((error) => {
        dispatch(answerQuestionError(answer.questionId, error));
      });
  };

const postAnswerWithAffiliatedAnswer =
  (route, meta) => (dispatch, getState) => {
    const { questionId } = meta;
    const state = getState();
    const question = state.getIn(['questions', questionId]);

    try {
      const affiliatedQuestionId = question.get('affiliatedQuestionId');
      const affiliatedAnswer = state.getIn(['answers', affiliatedQuestionId]);
      const metaWithAffiliatedAnswer = R.assoc(
        'affiliatedAnswer',
        affiliatedAnswer,
        meta
      );
      return postAnswer(route, metaWithAffiliatedAnswer)(dispatch, getState);
    } catch (error) {
      throw Error(
        `Could not post answer for questionId ${questionId}. Encountered Error: ${error.message}`
      );
    }
  };

const getValidRelatedQuestionsWithAnswer = R.curry(
  (getState, answers, answer, questionId) => {
    const relatedQuestions = getRelatedQuestionIdsToBeAnsweredFromQuestion(
      getState(),
      questionId
    );
    return R.pipe(
      R.filter((relatedQuestionId) => !answers[relatedQuestionId]),
      R.map((relatedQuestionId) => R.objOf(relatedQuestionId, answer)),
      R.reduce(R.mergeRight, {})
    )(relatedQuestions);
  }
);

const getAnswersWithRelatedQuestions = (getState, answers) => {
  return R.pipe(
    R.mapObjIndexed(getValidRelatedQuestionsWithAnswer(getState, answers)),
    R.values,
    R.reduce(R.mergeRight, answers)
  )(answers);
};

const postAnswers =
  (route, packageId, answers, callback, isPrefilledAnswer, prefilledFrom) =>
  (dispatch, getState) => {
    const answersWithRelatedQuestions = getAnswersWithRelatedQuestions(
      getState,
      answers
    );

    Promise.all(
      R.values(
        R.mapObjIndexed((answer, questionId) => {
          const meta = {
            packageId,
            questionId,
            answer,
            isPrefilledAnswer,
            prefilledFrom,
          };
          return request.post(route, meta);
        }, answersWithRelatedQuestions)
      )
    )
      .then((results) => {
        R.map((res) => dispatch(answerQuestionSuccess(res.data)), results);
        return callback(R.map((res) => res.data, results));
      })
      .catch((err) => callback({ error: err }));
  };

answerQuestionBase = R.curry((route, packageId, questionId, answer) => {
  const type = types.ANSWER_QUESTION;
  const meta = { packageId, questionId, answer };
  const payload = postAnswer(route, meta);

  return {
    type,
    payload,
    meta,
  };
});

const answerQuestionsBase = R.curry((route, packageId, answers, callback) => {
  const type = types.ANSWER_QUESTIONS;
  const payload = postAnswers(route, packageId, answers, callback);

  const meta = { packageId, answers };
  return {
    type,
    payload,
    meta,
  };
});

const answerPrefilledQuestionsBase = R.curry(
  (route, packageId, answers, callback, prefilledFrom) => {
    const type = types.ANSWER_QUESTIONS;
    const isPrefilledAnswer = true;
    const payload = postAnswers(
      route,
      packageId,
      answers,
      callback,
      isPrefilledAnswer,
      prefilledFrom
    );
    const meta = { packageId, answers };
    return {
      type,
      payload,
      meta,
    };
  }
);

const answerAffiliatedQuestionBase = R.curry(
  (route, packageId, questionId, answer) => {
    const type = types.ANSWER_QUESTION;
    const meta = { packageId, answer, questionId };
    const payload = postAnswerWithAffiliatedAnswer(route, meta);
    return {
      type,
      payload,
      meta,
    };
  }
);

const answerQuestionsWithRelatedProductBase = R.curry(
  (route, packageId, relatedProduct, questionId, answer) => {
    const type = types.ANSWER_QUESTION;
    const meta = { packageId, answer, relatedProduct, questionId };
    const payload = postAnswerWithAffiliatedAnswer(route, meta);
    return {
      type,
      payload,
      meta,
    };
  }
);

export const answerQuestion = answerQuestionBase(API_ROUTES.ANSWERQUESTION);

export const validateQuestionAnswer = (
  packageId,
  entityType,
  questions,
  optionalQuestions,
  questionId,
  answer
) => {
  const type = types.VALIDATE_QUESTION_ANSWER;
  const meta = { packageId, questionId, answer };
  const payload = postValidateQuestionAnswer(
    entityType,
    questions,
    optionalQuestions,
    meta
  );

  return {
    type,
    payload,
    meta,
  };
};

export const answerQuestionWithRelatedProduct =
  answerQuestionsWithRelatedProductBase(API_ROUTES.ANSWERQUESTION);

export const answerUnderwritingQuestion = answerQuestionBase(
  API_ROUTES.ANSWER_UNDERWRITING_QUESTION
);
export const answerQuestions = answerQuestionsBase(API_ROUTES.ANSWERQUESTION);

export const answerPrefilledQuestions = answerPrefilledQuestionsBase(
  API_ROUTES.ANSWERQUESTION
);

export const answerAffiliatedQuestionActionCreator =
  answerAffiliatedQuestionBase(API_ROUTES.ANSWERQUESTION);

import React, { PureComponent } from 'react';

class FeatureWithoutImage extends PureComponent {
  render() {
    const { title, description, price } = this.props;
    return (
      <div className="vas no-image-vas">
        <div className="vas-body">
          <h3 className="water-blue-text">{title}</h3>
          <p>{description}</p>
          <p className="large-text water-blue-text"> Only R{price}p/m</p>
          <div className="yes-no-button-container">
            <button type="button" className="btn btn-default" disabled="true">
              Yes
            </button>
            <button type="button" className="btn btn-default" disabled="true">
              No
            </button>
          </div>
          <p className=""> More information </p>
        </div>
      </div>
    );
  }
}

export default FeatureWithoutImage;

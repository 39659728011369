import React, { PureComponent } from 'react';
import { PageButtons, Form } from '@simply-fin-services/astronomix3';
import { fromJS } from 'immutable';
import PageDescription from '../../common/PageDescription';
import { toCurrencyNoDecimal } from '../../common/numberFormats';
import { centeredSpinner } from '../sharedComponents';
import CoverAllocationContainer from './CoverAllocationContainer';
import EntityForm from '../../entityForm/form';
import { API_ROUTES } from '../../../config/constants';
import request from 'axios';

class AddInsuredForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      loading: true,
      showAddNewFamilyMemberForm: false,
      familyMembers: fromJS([]),
      hasCoverAllocations: false,
    };
  }

  fetchFamilyMembers() {
    this.setState({ loading: true });

    return this.props
      .fetchFamilyMembersWithCoverAllocations(this.props.policyId)
      .then((familyMembers) => {
        const hasCoverAllocations = familyMembers.size > 0;

        this.setState({ loading: false, hasCoverAllocations, familyMembers });
      });
  }

  componentDidMount() {
    this.fetchFamilyMembers();
  }

  loadingData() {
    return this.state.loading;
  }

  canShowPageElements() {
    return !this.state.loading;
  }

  answerQuestion(questionId, value) {
    const stateToSet = {};

    stateToSet[questionId] = value;
    
    this.setState(stateToSet);
  }

  requestCreate() {
    const insuredPerson = this.props
      .entity(this.props.entityType)
      .set('policy-id', this.props.policyId);

    this.setState({ saving: true });

    request
      .post(
        API_ROUTES.REPLACE_AND_SAVE_ADD_INSURED_COVER_DETAILS,
        insuredPerson
      )
      .then(() => {
        this.setState({ saving: false });
        this.resetForm();
        this.hideAddNewFamilyMemberForm();
        this.props.clearEntity();
        this.fetchFamilyMembers();

        window.scroll(0, 0);
      })
      .catch(() => {
        this.setState({ saving: false });
        // FAILFAST: Error handling is a perfect activity for cool down.
      });
  }

  // Add fields that need to be reset here.
  resetForm() {
    this.setState({ firstName: '' });
  }

  isAddNewFamilyMemberButtonDisabled() {
    if (this.state.saving) return true;

    return !this.props.isEntityValid(this.props.entityType);
  }

  shouldShowAddNewFamilyMemberForm() {
    return this.state.showAddNewFamilyMemberForm;
  }

  shouldShowFamilyMemberCoverAllocation() {
    return (
      !this.shouldShowAddNewFamilyMemberForm() && this.state.hasCoverAllocations
    );
  }

  showAddNewFamilyMemberForm() {
    this.setState({ showAddNewFamilyMemberForm: true });
    window.scroll(0, 0);
  }

  hideAddNewFamilyMemberForm() {
    this.setState({ showAddNewFamilyMemberForm: false });
    this.props.clearEntity();

    window.scroll(0, 0);
  }

  showIntialInsuredsComponent() {
    return (
      !this.state.hasCoverAllocations &&
      !this.shouldShowAddNewFamilyMemberForm()
    );
  }

  addFamilyMemberButton(buttonText) {
    return (
      <PageButtons
        label={buttonText}
        backText="back to replacement policies"
        backAction={() => this.props.insuredPersonCaptured()}
        onClick={() => this.showAddNewFamilyMemberForm()}
      />
    );
  }

  policySummaryLine(policy) {
    return (
      <p>
        This policy costs {toCurrencyNoDecimal(policy.get('monthlyPremium'))} a
        month, covers the main life for{' '}
        {toCurrencyNoDecimal(policy.get('mainLifeCoverAmount'))} and was taken
        out on {policy.get('formattedInceptionDate')}.
      </p>
    );
  }

  render() {
    const policy = this.props.policy;
    const insurer = policy.get('insurer');
    const policyNumber = policy.get('policyNumber');

    return (
      <React.Fragment>
        <Form>
          <PageDescription
            description={`${insurer} Policy: ${policyNumber}`}
          />

          {this.loadingData() && centeredSpinner()}

          {this.canShowPageElements() && (
            <React.Fragment>
              {/* Shows initially when there is no cover allocations. */}
              {this.showIntialInsuredsComponent() && (
                <React.Fragment>
                  <p>
                    Please add the details of any family members who also have funeral cover under this policy
                  </p>
                  {this.addFamilyMemberButton('Add family member')}
                </React.Fragment>
              )}

              {/* Shows when there is one or more cover allocations. */}
              {this.shouldShowFamilyMemberCoverAllocation() && (
                <React.Fragment>
                  {this.policySummaryLine(policy)}

                  <CoverAllocationContainer
                    familyMembers={this.state.familyMembers}
                  />
                  {this.addFamilyMemberButton('Add another family member')}
                </React.Fragment>
              )}

              {this.shouldShowAddNewFamilyMemberForm() && (
                <React.Fragment>
                  <h4>
                    Please add the details of the covered family member
                  </h4>

                  <EntityForm entityType={this.props.entityType} />

                  <PageButtons
                    backText="back to policy details"
                    backAction={() => this.hideAddNewFamilyMemberForm()}
                    disabled={this.isAddNewFamilyMemberButtonDisabled()}
                    label="Add family member"
                    onClick={() => this.requestCreate()}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Form>
      </React.Fragment>
    );
  }
}

export default AddInsuredForm;

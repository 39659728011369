import React, { PureComponent } from 'react';
import peopleHeart from '../../images/people_heart.jpg';
import { connect } from 'react-redux';
import { generateInputField } from '../common/inputHelpers';
import { checkReferrerStatus } from '../../actions/login';
import { REQUIRED_CELLPHONE_NUMBER } from '../common/inputTypes';
import PageSeo from '../common/PageSeo';
import * as R from 'ramda';
import LoadingRow from '../common/LoadingRow';
const referLink = '/refer-now';

const ripErrorsFromControl = field => {
  const { props } = field;
  const { errors } = props;
  return {
    control: R.assocPath(['props', 'errors'], [], field),
    errors,
  };
};

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.checkReferrerStatus = this.checkReferrerStatus.bind(this);
    this.signUp = this.signUp.bind(this);
    this.resetCheck = this.resetCheck.bind(this);
    this.login = this.login.bind(this);
    this.state = { hasCheckedReferrerStatus: false, exists: false };
  }

  checkReferrerStatus(cellphoneNumber) {
    const { isReferrer } = this.props;
    isReferrer(cellphoneNumber).then(res => {
      const { exists, url } = res;
      this.setState({ hasCheckedReferrerStatus: true, exists });
      if (exists) {
        window.location = url;
      }
    });
  }

  login() {
    const { cellphoneNumber } = this.props;
    this.checkReferrerStatus(cellphoneNumber);
  }

  resetCheck() {
    this.setState({ hasCheckedReferrerStatus: false });
  }

  signUp() {
    window.location = referLink;
  }

  render() {
    const { cellphoneNumber, generateInput } = this.props;
    const { hasCheckedReferrerStatus, exists } = this.state;
    const cellphoneInput = generateInput(
      'referralLogin',
      'cellphoneNumber',
      REQUIRED_CELLPHONE_NUMBER,
      'Cell Number',
      cellphoneNumber
    );
    const errorlessCellphoneQuestion = ripErrorsFromControl(
      cellphoneInput.control
    );
    const cellphoneNumberControl = errorlessCellphoneQuestion.control;
    const cellphoneNumberErrors = errorlessCellphoneQuestion.errors;
    const cellphoneNumberInputField = R.assocPath(
      ['props', 'placeholder'],
      'Cellphone Number',
      cellphoneNumberControl
    );
    const loginState = exists ? 'form-area-hidden' : 'form-area-visible';
    return (
      <div className="referrals col-sm-12 col-md-12 col-xs-12">
        <PageSeo
          image="https://storage.googleapis.com/simply-public/simply-referral-programme.png"
          description="You like what Simply does. You refer a friend to Simply.
            Your friend gets a discount. You get cash – for yourself or a charity of your choice.
            It’s a win-win!"
          title="Simply referral programme"
        />
        <section className="screen-1">
          <div className="container">
            <div className="row screen1-intro">
              <h1 className="display-4">Simply referral programme</h1>
              <div className="flex-container col-xs-12">
                <div className="login-form">
                  <div className="story">
                    <p className="col-md-12 col-sm-12">
                      You like what Simply does. You refer a friend to Simply.
                      Your friend gets a discount. You get cash – for yourself
                      or a charity of your choice. It’s a win-win!
                    </p>
                    <div className="col-md-12 col-sm-12 button-cta">
                      <div className={loginState}>
                        <form
                          onChange={this.resetCheck}
                          onSubmit={e => e.preventDefault()}
                        >
                          {cellphoneNumberInputField}
                        </form>
                        <button
                          type="button"
                          className="sign-up btn-finish"
                          onClick={this.login}
                        >
                          Sign In <span>→</span>
                        </button>
                      </div>
                      {exists && (
                        <div className="login-load">
                          <LoadingRow />
                        </div>
                      )}
                    </div>
                    <div className="col-xs-12 login-error">
                      {!R.isEmpty(cellphoneNumberErrors) &&
                        cellphoneNumberErrors.map(err => <p>{err}</p>)}
                      {!exists &&
                        !R.isEmpty(cellphoneNumber) &&
                        R.isEmpty(cellphoneNumberErrors) &&
                        hasCheckedReferrerStatus && (
                          <div className="col-xs-12 text-center">
                            <h4>
                              {' '}
                              Sorry, number does not exist on our system{' '}
                            </h4>
                            <p>
                              would you like to{' '}
                              <a onClick={this.signUp}>signup</a> instead?
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 login-image">
                  <img src={peopleHeart} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export const mapStateToProps = state => ({
  cellphoneNumber: state.getIn(['referralLogin', 'cellphoneNumber']),
});

export const mapDispatchToProps = dispatch => ({
  generateInput: generateInputField(dispatch),
  isReferrer: c => checkReferrerStatus(c, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

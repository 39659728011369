import {
  RESET_WITH_EXISTING_OWNER,
  PREP_FOR_MULTIPLE_POLICIES,
  SET_MULTI_POLICY_SUPPORT,
  SET_BANKING_DETAILS,
} from './types';
import { push } from 'connected-react-router';
import * as R from 'ramda';
import { answerQuestion } from './answers';
import { allPossibleQuestionsForPage } from '../common/selectAllQuestions';
import { linkExistingReferrerCode } from '../actions/referrerLinking';

export const setMultiPolicySupport = (state, selectedPackageSlug) => ({
  type: SET_MULTI_POLICY_SUPPORT,
  payload: {
    supportMultiplePolicies: state.getIn([
      'packages',
      selectedPackageSlug,
      'allowDeferredUnderwriting',
    ]),
  },
});

export const prepForMultiplePolicies = state => {
  const selectedPackageSlug = state.get('selectedPackageSlug');
  const ownerQuestionPageId = state.getIn([
    'packages',
    selectedPackageSlug,
    'ownerIdentificationQuestionPageId',
  ]);
  const allQuestions = allPossibleQuestionsForPage(ownerQuestionPageId, state);
  const owner = R.pick(allQuestions, state.get('answers').toJS());
  return {
    type: PREP_FOR_MULTIPLE_POLICIES,
    payload: { owner },
  };
};

const resetBankingDetails = (dispatch, state) =>
  dispatch({
    type: SET_BANKING_DETAILS,
    payload: state.getIn(['policyInformation', 'bankingDetails']),
  });

const resetAnswers = (dispatch, state) =>
  state
    .getIn(['policyInformation', 'owner'])
    .map(answer =>
      answerQuestion(
        answer.get('packageId'),
        answer.get('questionId'),
        answer.get('answer')
      )
    )
    .forEach(answerAction => dispatch(answerAction));

export const resetWithExistingOwner = () => ({
  type: RESET_WITH_EXISTING_OWNER,
  payload: (dispatch, getState) => {
    const state = getState();
    resetBankingDetails(dispatch, state);
    resetAnswers(dispatch, state);
    dispatch(linkExistingReferrerCode());
  },
});

export const startNewPolicyWithSameOwner = dispatch => {
  dispatch(resetWithExistingOwner());
  dispatch(push('/quote/simply-employer-combo'));
};

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import styled from 'styled-components';

// Note the use of inline CSS to meet requiremnts qucikly,
// the UI team needs to provide a class for type of unnumbered list.

const LI = styled.li`
  list-style-position: outside;
  padding-left: 0 !important;
`;

const OL = styled.ol`
  list-style-position: outside;
  padding-left: 0 !important;
`;

const UL = styled.ul`
  list-style-position: outside;
  padding-left: 0 !important;
`;

const listTypeUnnumbered = 'unnumbered';
const listTypeNumbered = 'numbered';
const listTypeParagraphs = 'paragraphs';

class TermsFromConfig extends PureComponent {
  listItems(points) {
    return R.addIndex(R.map)(
      (listItem, key) => <LI key={key}>{listItem}</LI>,
      points || []
    );
  }

  paragraphElements(points) {
    return (
      <React.Fragment>
        {R.addIndex(R.map)(
          (listItem, key) => (
            <p key={key}>{listItem}</p>
          ),
          points || []
        )}
      </React.Fragment>
    );
  }

  render() {
    const { title, introText, outroText, points } = this.props.termsConfig;
    const listType = this.props.termsConfig.listType;

    return (
      <React.Fragment>
        {title && this.props.showHeading !== false && (
          <h5 className="space-above">{title}</h5>
        )}

        {this.props.showHeading && this.props.showHeading.toString()}

        {introText && <p>{introText}</p>}

        {listType === listTypeUnnumbered && <UL>{this.listItems(points)}</UL>}

        {listType === listTypeNumbered && <OL>{this.listItems(points)}</OL>}

        {listType === listTypeParagraphs && this.paragraphElements(points)}

        {outroText && <p>{outroText}</p>}

        {this.props.children}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state, ownProps) => ({
  termsConfig: state.getIn(['terms', ownProps.termsId]).toJS(),
});

export default connect(mapStateToProps)(TermsFromConfig);

import * as R from 'ramda';

const DEFAULT_BROKER_CONFIG_ID = 'simply';

const generateConfigs = state => {
  const websiteBase = state.getIn(['config', 'WEB_SITE_BASE']);
  const supportsIemasSalaryDeduction = state.getIn([
    'paymentType',
    'supportsIemasSalaryDeduction',
  ]);
  const defaultConfig = {
    paymentType: 'debit-order',
    supportsIemasSalaryDeduction: true,
    otpSmsTemplate: '',
  };
  const iemas = R.assoc(
    'brokerTermsLink',
    `${websiteBase}/iemas/terms`,
    defaultConfig
  );
  const simply = R.pipe(
    R.assoc(
      'brokerTermsLink',
      supportsIemasSalaryDeduction
        ? `${websiteBase}/iemas/terms`
        : `${websiteBase}/terms-conditions`
    ),
    R.assoc(
      'supportsIemasSalaryDeduction',
      supportsIemasSalaryDeduction || false
    )
  )(defaultConfig);
  return { iemas, simply };
};

const getConfig = (state, brokerId) => {
  const configsMap = generateConfigs(state);
  const config = configsMap[brokerId];
  if (R.isNil(config)) {
    return configsMap[DEFAULT_BROKER_CONFIG_ID];
  }
  return configsMap[brokerId];
};

export const isBrokerSale = state => {
  const brokerCode = state.getIn(['broker', 'brokerCode']);
  return R.not(R.or(R.isEmpty(brokerCode), R.isNil(brokerCode)));
};

export const isFromBrokerPortal = state => {
  const fromBrokerPortal = state.getIn(['broker', 'fromBrokerPortal']);
  return !R.isNil(fromBrokerPortal) && fromBrokerPortal === true;
};

export const getBrokerConfig = state => {
  const senderReference = state.get('senderReference');
  const config = getConfig(state, senderReference);
  return config;
};


const getBrokerSaleType = state => state.getIn(['brokerQa', 'brokerSaleType']);
const brokerSaleTypeQA = 'qa';
const brokerSaleTypeCorrection = 'corrections';

export const isQaSale = state => {
  const brokerSaleType =  getBrokerSaleType(state);
  return brokerSaleType === brokerSaleTypeQA || brokerSaleType === brokerSaleTypeCorrection;
};

export const isQaSaleInQaStage = state => {
  const brokerSaleType =  getBrokerSaleType(state);
  return brokerSaleType === brokerSaleTypeQA;
};

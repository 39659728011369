import { connect } from 'react-redux';
import CustomizationPage from './CustomizationPage';
import { fromJS, Map, OrderedMap, List } from 'immutable';
import * as R from 'ramda';
import {
  toggleRop as toggleRopActionCreator,
  toggleExtendedFamilyActionCreator,
  saveProductSelection,
  updateProductSelection,
} from '../../actions/productSelection';
import {
  setCurrentExtendedFamilyInsuredValue as setCurrentExtendedFamilyInsuredValueActionCreator,
  setCurrentExtendedFamilyInsuredValues as setCurrentExtendedFamilyInsuredValuesActionCreator,
  addCurrentExtendedFamilyInsured as addCurrentExtendedFamlyInsuredActionCreation,
  addExtendedFamilyWithSelectedFamilyMembers as addExtendedFamilyWithSelectedFamilyMembersActionCreator,
  insuredRefreshed as insuredRefreshedActionCreator,
  editInsured,
  updateInsured as updateInsuredActionCreator,
  deleteInsured as deleteInsuredActionCreator,
} from '../../actions/extendedFamilyInsuredsActions';

import { fetchPricingForCurrentInsured as fetchPricingForCurrentInsuredActionCreation } from '../../actions/extendedFamilyPricing';

// TODO investigate if getFirstPageOfQuestionnaire should be refactored.
//import { getFirstPageOfQuestionnaire } from '../packages/packageRouter';
import calculatePricing, { getPricingConfig } from './calculatePricing';
import { getPackageDiscountRate } from '../../common/pricing';
import { saveCopyOfPackageProductSelection } from '../../actions/previousPackageSelections';
import { ROUTES } from '../../config/constants';
import { sortStateProducts } from '../../.shared/sortProducts';
import { push } from 'connected-react-router';
import { viewQuote } from '../../actions/recommendation';
import { competitionOptOut } from '../../actions/competition';
import { downloadQuotePDF } from '../../actions/quote';
import { setPrepareQuotePopupFlag } from '../../actions/prepareQuotePopup';
import { isFromBrokerPortal } from '../../common/brokers';
import saveRopPricing from '../../actions/ropPricing';

import getIncome from '../../common/getIncome';
import { getBenefits } from '../peeps/getSelectedProductIds';

import {determineEffectiveFirstUrl} from '../questionnaire/determineNextQuoteStep';

import {
  isReplaceAndSaveEnabled,
} from '../../stateStore/replaceAndSave';

const PARSE_RADIX = 10;

const getPackageUrlSlug = (state) => state.get('selectedPackageSlug');

const sortProductPrices = (productPrices) =>
  productPrices
    .map((v, k) => ({ k, v }))
    .valueSeq()
    .sortBy((i) => parseInt(i.k, 10))
    .reduce((r, i) => r.set(i.k, i.v), new OrderedMap());

const indexProductPrices = (productPrices) => {
  let index = 1;
  const reduced = productPrices.reduce(
    (currentResult, premium, insuredAmount) => {
      const productSelection = fromJS({
        insuredAmount: parseInt(insuredAmount, PARSE_RADIX),
        premium,
      });
      return currentResult.set((index++).toString(), productSelection);
    },
    Map()
  );
  return reduced.merge(fromJS({ 0: { insuredAmount: 0, premium: 0 } }));
};

const indexedProductPrices = R.pipe(sortProductPrices, indexProductPrices);

const getPackageIdBySlug = (slug, state) =>
  state.getIn(['packages', slug, 'packageId']);

export const getIndexedPricingMap = (productPrices) => {
  return indexedProductPrices(productPrices);
};

export const getProductTitlesAndTooltips = (state) => {
  const productIds = R.keys(
    state.getIn(['recommendation', 'productPriceMap']).toJS()
  );

  let productsMap = Map();
  productIds.map((id) => {
    const product = state.getIn(['products', id]);
    productsMap = productsMap.set(
      id,
      Map({
        title: product.get('title'),
        tooltip: product.get('tooltip'),
        productId: id,
      })
    );
    return productsMap;
  });
  return productsMap;
};

export const getProductPrices = (state, productsMap) => {
  const productIds = R.keys(
    state.getIn(['recommendation', 'productPriceMap']).toJS()
  );
  let newProducts = Map();
  productIds.map((productId) => {
    const prices = state.getIn([
      'recommendation',
      'productPriceMap',
      productId,
    ]);
    if (prices) {
      const pricing = getIndexedPricingMap(prices);
      newProducts = newProducts.set(
        productId,
        productsMap.get(productId).merge(Map({ pricing }))
      );
    }
    return newProducts;
  });
  return newProducts;
};

export const getSelectedPrices = (state, productsMap) => {
  let newProductMap = Map();
  productsMap.map((product) => {
    const productId = product.get('productId');
    const pricing = product.get('pricing');
    const productSelection = state.getIn([
      'productSelection',
      'products',
      productId,
    ]);
    const selectedCoverAmount = productSelection
      ? productSelection.get('insuredAmount')
      : pricing.last().get('insuredAmount');
    const selectedIndex =
      pricing.findKey(
        (price) => price.get('insuredAmount') === selectedCoverAmount
      ) || 1;
    const selected = productSelection
      ? productSelection.get('selected')
      : false;
    newProductMap = newProductMap.set(
      productId,
      product.merge(Map({ selectedIndex, selected }))
    );
    return newProductMap;
  });
  return newProductMap;
};

export const createProductsToCustomize = (state) => {
  const productTitles = getProductTitlesAndTooltips(state);

  const mergedMapWithProductPrices = getProductPrices(state, productTitles);
  const mergedMapWithSelectedIndex = getSelectedPrices(
    state,
    mergedMapWithProductPrices
  );
  return mergedMapWithSelectedIndex;
};

const getPackageDiscountDescription = (state) =>
  state.getIn([
    'discounts',
    state.getIn(['packages', getPackageUrlSlug(state), 'packageDiscount']),
    'description',
  ]);

const nextQuestionnaireUrl = (state) =>
  determineEffectiveFirstUrl(getPackageUrlSlug(state), state);

const getCurrentExtendedFamilyInsured = (state) =>
  state.getIn(['extendedFamilyInsureds', 'currentExtenedFamilyInsured']).toJS();

const currentFuneralCoverAmount = (state) => {
  const funeralProductId = state.getIn([
    'packages',
    state.get('selectedPackageSlug'),
    'funeralProduct',
  ]);
  return state.getIn([
    'productSelection',
    'products',
    funeralProductId,
    'insuredAmount',
  ]);
};

const getAllowedEffRelationships = (state) =>
  state.getIn(
    [
      'packages',
      state.get('selectedPackageSlug'),
      'allowedExtendedFamilyRelationships',
    ],
    List()
  );

export const mapStateToProps = (state) => {
  const packageSlug = getPackageUrlSlug(state);
  const pricingConfig = getPricingConfig(state, packageSlug);
  const hasPackageDiscount = getPackageDiscountRate(state) > 0;
  const packageDiscountDescription = getPackageDiscountDescription(state);
  const packageAllowsEffWithoutFuneral = state.getIn([
    'packages',
    packageSlug,
    'supportsExtendedFamilyWithoutFuneralCover',
  ]);
  const benefits = getBenefits(state, packageSlug);

  return {
    currentExtendedFamilyInsured: getCurrentExtendedFamilyInsured(state),
    isReplaceAndSaveEnabled: isReplaceAndSaveEnabled(state),
    insureds: state.getIn(['extendedFamilyInsureds', 'insureds']).toJS(),
    maximumFuneralCoverAmount: state.getIn([
      'extendedFamilyInsureds',
      'maximumFuneralCoverAmount',
    ]),
    hasViewedQuote: state.getIn(['recommendation', 'hasViewedQuote']),
    customizationProducts: createProductsToCustomize(state),
    isSaving: state.getIn(['productSelection', 'isSaving']),
    packageId: getPackageIdBySlug(packageSlug, state),
    packageSlug,
    nextUrl: nextQuestionnaireUrl(state),
    isChangedPackage: state.getIn([
      'previousPackageSelections',
      'packageChanged',
    ]),
    recommendation: state.get('recommendation'),
    pricingConfig,
    sortProducts: sortStateProducts(state),
    hasPackageDiscount,
    packageDiscountDescription,
    showHealthMessage: state.getIn([
      'packages',
      packageSlug,
      'showHealthMessage',
    ]),
    healthMessageConfig: state.getIn([
      'packages',
      packageSlug,
      'healthMessage',
    ]),
    state,
    packageAllowsEffWithoutFuneral,
    extendedFamilySelectable: state.getIn([
      'extendedFamilyInsureds',
      'extendedFamilySelectable',
    ]),
    refreshCurrentInsured: state.getIn([
      'extendedFamilyInsureds',
      'refreshCurrentInsured',
    ]),
    currentFuneralCoverAmount: currentFuneralCoverAmount(state),
    answers: state.get('answers'),
    benefits,
    income: getIncome(state),
    packageUrlSlug: state.get('selectedPackageSlug'),
    packageTips: state.get('packageTips'),
    winWinDetails: state.get('winWinDetails'),
    allowedEffRelationships: getAllowedEffRelationships(state).toJS(),
    selectedPackageSlug: state.get('selectedPackageSlug'),
    summaryContent: state
      .getIn(['packages', state.get('selectedPackageSlug'), 'summaryContent'])
      .toJS(),
    RequestCustomizationCellphone: state
      .getIn([
        'packages',
        state.get('selectedPackageSlug'),
        'RequestCustomizationCellphone',
      ])
      .toJS(),
    isBrokerSale: isFromBrokerPortal(state),
  };
};

const assocByProductId = (m, p) => R.assoc(p.productId, p, m);
const toProductMap = R.pipe(R.reduce(assocByProductId, {}), fromJS);

const next = R.curry(
  (
    dispatch,
    nextUrl,
    packageId,
    isChangedPackage,
    recommendation,
    pricingConfig,
    selections
  ) => {
    if (isChangedPackage === false) {
      dispatch(
        saveCopyOfPackageProductSelection(packageId, selections, recommendation)
      );
    }

    const pricing = calculatePricing(toProductMap(selections), pricingConfig);
    dispatch(saveProductSelection(packageId, selections, nextUrl, pricing));
  }
);

const downloadQuote = R.curry(
  (dispatch, packageId, pricingConfig, selections, idNumber) => {
    const pricing = calculatePricing(toProductMap(selections), pricingConfig);
    dispatch(
      downloadQuotePDF(
        dispatch,
        packageId,
        selections,
        pricing,
        pricingConfig,
        idNumber
      )
    );
  }
);

const toggleRop = R.curry((dispatch, withRop) =>
  dispatch(toggleRopActionCreator(withRop))
);

const toggleExtendedFamily = R.curry((dispatch, withExtendedFamily) =>
  dispatch(toggleExtendedFamilyActionCreator(withExtendedFamily))
);

const setCurrentInsuredValue = R.curry(
  (dispatch, answer, extendedFamilyProductId) =>
    dispatch(
      setCurrentExtendedFamilyInsuredValueActionCreator(
        answer,
        extendedFamilyProductId
      )
    )
);

const setCurrentInsuredValues = R.curry(
  (dispatch, values, extendedFamilyProductId) =>
    dispatch(
      setCurrentExtendedFamilyInsuredValuesActionCreator(
        values,
        extendedFamilyProductId
      )
    )
);

const showQuote = R.curry((dispatch, stateProps, shouldShow) => {
  if (shouldShow) {
    dispatch(viewQuote(stateProps));
  }
  dispatch(push(ROUTES.toCustomisation(stateProps.packageSlug)));
});

const optOut = R.curry((dispatch, hasOptedOut) => {
  if (hasOptedOut) {
    dispatch(competitionOptOut());
  }
});

const addInsured = R.curry((dispatch, insured) => {
  dispatch(addCurrentExtendedFamlyInsuredActionCreation(insured));
});

const fetchPricingForCurrentInsured = R.curry(
  (dispatch, insured, effProductId) => {
    dispatch(
      fetchPricingForCurrentInsuredActionCreation(insured, effProductId)
    );
  }
);

const addExtendedFamilyWithSelectedFamilyMembers = R.curry(
  (dispatch, insureds, effProductId) => {
    dispatch(
      addExtendedFamilyWithSelectedFamilyMembersActionCreator(
        insureds,
        effProductId
      )
    );
  }
);

const setPrepareQuotePopupVisible = R.curry((dispatch, value) => {
  dispatch(setPrepareQuotePopupFlag(value));
});

export const mapDispatchToProps = (dispatch) => ({
  next: next(dispatch),
  toggleRop: toggleRop(dispatch),
  toggleExtendedFamily: toggleExtendedFamily(dispatch),
  setCurrentInsuredValue: setCurrentInsuredValue(dispatch),
  setCurrentInsuredValues: setCurrentInsuredValues(dispatch),
  addInsured: addInsured(dispatch),
  downloadQuote: downloadQuote(dispatch),
  fetchPricingForCurrentInsured: fetchPricingForCurrentInsured(dispatch),
  addExtendedFamilyWithSelectedFamilyMembers:
    addExtendedFamilyWithSelectedFamilyMembers(dispatch),
  showQuote: showQuote(dispatch),
  setPrepareQuotePopupFlag: setPrepareQuotePopupVisible(dispatch),
  optOut: optOut(dispatch),
  insuredRefreshed: () => dispatch(insuredRefreshedActionCreator()),
  updateProductSelection: (selections) =>
    dispatch(updateProductSelection(selections)),
  saveRopDetails: (pricing) => dispatch(saveRopPricing(pricing)),
  editInsured: (insuredId, extendedFamilyProductId) =>
    dispatch(editInsured(insuredId, extendedFamilyProductId)),
  updateInsured: (extendedFamilyProductId) =>
    dispatch(updateInsuredActionCreator(extendedFamilyProductId)),
  deleteInsured: (insuredId, extendedFamilyProductId) =>
    dispatch(deleteInsuredActionCreator(insuredId, extendedFamilyProductId)),
  dispatch,
});

const mergeProps = (stateProps, dispatchProps) =>
  Object.assign({}, stateProps, dispatchProps, {
    next: dispatchProps.next(
      stateProps.nextUrl,
      stateProps.packageId,
      stateProps.isChangedPackage,
      stateProps.recommendation,
      stateProps.pricingConfig
    ),
    downloadQuote: dispatchProps.downloadQuote(
      stateProps.packageId,
      stateProps.pricingConfig
    ),
    showQuote: dispatchProps.showQuote(stateProps),
    compoOptOut: dispatchProps.optOut,
  });

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CustomizationPage);

export default Container;

import React, { PureComponent } from 'react';
import { fromValidateableValue } from '../../.shared/validators';
import validatePaymentDetailInput from '../../.shared/validators/validatePaymentDetailInput';
import PaymentDetails from './PaymentDetails';
import PageActionButtons from '../common/PageActionButtons';
import * as R from 'ramda';
import moment from 'moment';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { isOtpValid, isThirdPartyOtpValid } from './Otp';
import { calculatePricingFromState } from '../customization/calculatePricing';
import { isBrokerSale } from '../../.shared/calculatePricing';
import { isFromBrokerPortal } from '../../common/brokers';
import {QA, CORRECTIONS} from '../../common/saleOptions';
import { getFinishButtonText } from './config';
import { WallOfTextContainer } from '@simply-fin-services/astronomix3';
import BrokerQa from './brokerQa';

const currentDate = new Date();

const answerQuestion = R.curry((a, validator, id, answer) =>
  a(validator, id, answer)
);

const fromValidateable = (v) => fromValidateableValue(v.toJS());

class InlineDebitOrderForm extends PureComponent {
  constructor() {
    super();
    this.setValue = this.setValue.bind(this);
    this.setDetail = this.setDetail.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateCorrectionComments = this.updateCorrectionComments.bind(this);
    this.state = {
      details: fromJS({}),
    };
  }

  componentWillMount() {
    const { details, iemasMembers } = this.props;
    let initDetails;
    const isIemasDebitOrder = iemasMembers.get('debitOrderAllowed');

    if (!details.get('inceptionMonth')) {
      initDetails = details.set(
        'inceptionMonth',
        moment().set('date', 1).toISOString()
      );
    } else {
      initDetails = details;
    }

    if(isIemasDebitOrder) {
      initDetails = initDetails
        .set('iemasMemberNumber', iemasMembers.get('memberNumber'))
        .set('iemasUpfrontCommission', iemasMembers.get('upfrontCommission'))
        .set('iemasDebitOrderAllowed', isIemasDebitOrder);
    }

    const validateableDetails = validatePaymentDetailInput(
      currentDate,
      initDetails
    );
    const result = validateableDetails.get('details').map((d) =>
      d.merge({
        errors: [],
        isValid: !d.get('isValid') ? undefined : d.get('isValid'),
      })
    );
    this.setState({ details: result });
  }

  updateCorrectionComments(value) {
    this.props.updateCorrectionComments(value);
  }

  setValue(validator, id, value) {
    this.setDetail(id, validator(value));
  }

  setDetail(id, detail) {
    const details = this.state.details.set(id, fromJS(detail));
    this.setState({ details });
  }

  onSubmit() {
    this.props.next(this.state.details.map(fromValidateable));
  }

  render() {
    if (!this.state) {
      return null;
    }
    const allValid = this.state.details.reduce(
      (acc, ans) => acc && ans.get('isValid'),
      true
    );

    const answerChild = answerQuestion(this.setValue);
    const {
      isSaving,
      callToAction,
      requiresOTP,
      otpValid,
      premium,
      validComments,
      requiresThirdPartyOTP,
      thirdPartyOtpValid,
      sendForCorrections,
      brokerComplianceValid,
      currentSaleIsBrokerSale
    } = this.props;
    const passesVerification =
      this.props.passesVerification || this.props.brokerSaleType === QA || this.props.brokerSaleType === CORRECTIONS;

    const passesSaleConstraints = requiresThirdPartyOTP
      ? allValid &&
        otpValid &&
        thirdPartyOtpValid &&
        passesVerification
      : allValid && otpValid && passesVerification;

    const canContinueBrokerSale = passesSaleConstraints && brokerComplianceValid;

    const canContinue = currentSaleIsBrokerSale ? canContinueBrokerSale : passesSaleConstraints;
    
    const actionButtonText = this.props.toFinishButtonText();
  
    return (
      <WallOfTextContainer>
        <p>
          By clicking <strong>{actionButtonText}</strong> below, you authorise{' '}
          <strong>Hollard</strong>{' '}
          to deduct the monthly premium of <strong>R{premium}</strong> from your
          primary bank account detailed below, on condition the amount deducted never
          exceeds the amount committed to under this policy. This mandate will
          commence on the debit order date selected below and will continue
          monthly thereafter until it is terminated by giving not less than one
          month’s notice. The reference number for the deduction will contain
          the word SIMPLY.
        </p>

        <p>
          If your bank allows Debicheck, you will be receiving a request from
          them to authorise this collection. Declining this request does not
          cancel the debit order mandate you are authorising here. Simply is
          still authorised to collect this premium via a standard debit order.
        </p>

        <p>
          You agree that cancelling this debit order authority will not cancel
          the agreement you have with Hollard.
        </p>

        <form onSubmit={(e) => e.preventDefault()}>
          <PaymentDetails
            details={this.state.details}
            answerQuestion={answerChild}
            requiresOTP={requiresOTP}
            isBrokerSale={this.props.isBrokerSale}
            requiresThirdPartyOTP={requiresThirdPartyOTP}
          />
        </form>
        <br />
        <p className="text-center space-below-base">
          Go for it. We'll pay back any premiums you've paid if you cancel in
          the next 30 days.
        </p>
        
        {!isSaving && this.props.brokerSaleType === 'qa' && (
          <BrokerQa
            updateCorrectionComments={this.updateCorrectionComments}
            canContinue={canContinue}
            rejectSale={this.props.rejectSale}
            validComments={validComments}
            approveSale={this.onSubmit}
            sendForCorrections={sendForCorrections}
          />
        )}

        {!isSaving && this.props.brokerSaleType !== 'qa' && (
          <PageActionButtons
            onClick={this.onSubmit}
            disabled={!canContinue}
            text={actionButtonText}
            fineprint={callToAction}
            canGoBack
          />
        )}
      </WallOfTextContainer>
    );
  }
}

const getcallToAction = (state) => {
  const ownerQuestionPageId = state.getIn([
    'packages',
    state.get('selectedPackageSlug'),
    'ownerIdentificationQuestionPageId',
  ]);
  return state.getIn(['questionPages', ownerQuestionPageId, 'callToAction']);
};

const calculatePremium = (state) => {
  const slug = state.get('selectedPackageSlug');
  const pricing = calculatePricingFromState(state, slug);
  return pricing.finalCost;
};

const mapStateToProps = (state, ownProps) => {
  return {
    otpValid: ownProps.requiresOTP ? isOtpValid(state) : true,
    thirdPartyOtpValid: ownProps.requiresThirdPartyOTP
      ? isThirdPartyOtpValid(state)
      : true,
    callToAction: getcallToAction(state),
    details: state.getIn(['bankingDetails', 'details']),
    premium: calculatePremium(state),
    isBrokerSale: isBrokerSale(state) || isFromBrokerPortal(state),
    toFinishButtonText: () => getFinishButtonText(state),
    brokerSaleType: state.getIn(['brokerQa', 'brokerSaleType']),
    validComments: state.getIn(['brokerQa', 'isValidCorrectionComments']),
    passesVerification: state.getIn(['avsr', 'canContinueToCheckout']),
    brokerComplianceValid: state.getIn(['brokerCompliance', 'isValid']),
    currentSaleIsBrokerSale: isFromBrokerPortal(state) || isBrokerSale(state),
    iemasMembers: state.get('iemasMembers')
  };
};

export default connect(mapStateToProps)(InlineDebitOrderForm);

/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import * as R from 'ramda';

export default class SummaryContainer extends PureComponent {
  render() {
    const { children, emphasized, transparent, noPadding } = this.props;
    const emphasisClass = emphasized ? 'emphasized' : '';
    const contentType = transparent
      ? 'transparent-content'
      : 'shaddowed-content';
    const contentPadding = noPadding
      ? contentType.concat('  no-padding')
      : contentType;
    const shaddowed = R.concat(
      `col-xs-12 sum-container ${contentPadding}`,
      emphasisClass
    );
    const containerType = transparent
      ? 'transparent-container'
      : 'shaddowed-container';
    return (
      <div>
        <div className={`${containerType} row  summary`}>
          <div className={shaddowed}>{children}</div>
        </div>
      </div>
    );
  }
}

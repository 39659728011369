import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import QuestionContainer from './QuestionContainer';
import { fromJS, Map, OrderedMap } from 'immutable';
import IPropTypes from 'react-immutable-proptypes';
import buildQuestion from './questionBuilder';
import { CHECKBOX_QUESTION_ANSWER_SPLITTER } from '../../.shared/validators';
import { Checkbox } from '@simply-fin-services/astronomix3';
import { getLabel } from '../../common/alternativeLabels';

const makeCheckbox = (config, questionId, label, toggle, isChecked) => {
  const toggleOption = () => toggle(label);
  return (
    <Fragment key={`${questionId}-${label}`}>
      <Checkbox
        onChange={toggleOption}
        checked={isChecked}
        labelText={getLabel(config, label)}
        label={label}
        id={`${questionId}-${label}`}
      />
    </Fragment>
  );
};

const OTHER_KEY = 'Other';
const keysThatBelongAtTheBottom = [
  OTHER_KEY,
  'None of the above',
  'No complications',
  'No, it has not',
];
const getKeyThatBelongsAtTheBottom = possibleAnswers =>
  possibleAnswers.reduce((keyForBottom, possibleAnswer) => {
    if (keyForBottom !== null) {
      return keyForBottom;
    }
    const isAnswerForBottom = keysThatBelongAtTheBottom.reduce(
      (mustBeAtTheBottom, key) =>
        mustBeAtTheBottom || possibleAnswer.indexOf(key) > -1,
      false
    );
    return isAnswerForBottom ? possibleAnswer : null;
  }, null);

const buildAnsweredMap = (possibleAnswers, answer) => {
  const keyForBottom = getKeyThatBelongsAtTheBottom(possibleAnswers.keySeq());
  const hasOther = keyForBottom !== null;
  let answerPosibilities = possibleAnswers;
  if (hasOther) {
    answerPosibilities = possibleAnswers
      .remove(keyForBottom)
      .keySeq()
      .sort()
      .toList()
      .push(keyForBottom)
      .reduce((r, a) => r.set(a, false), new OrderedMap());
  } else {
    answerPosibilities = possibleAnswers
      .keySeq()
      .sort()
      .toList()
      .reduce((r, a) => r.set(a, false), new OrderedMap());
  }
  let answers;
  if (answer) {
    answers = fromJS(answer.split(CHECKBOX_QUESTION_ANSWER_SPLITTER))
      .sort()
      .reduce((r, a) => r.set(a, true), new OrderedMap());
  } else {
    answers = new Map();
  }
  return answerPosibilities.merge(answers);
};

const getAnswerFromSelection = selection =>
  selection
    .filter(v => v)
    .keySeq()
    .join(CHECKBOX_QUESTION_ANSWER_SPLITTER);

class ReflexiveCheckboxListQuestion extends PureComponent {
  constructor() {
    super();
    this.toggleOption = this.toggleOption.bind(this);
  }

  componentWillMount() {
    const { possibleAnswers, answer } = this.props;
    const selections = buildAnsweredMap(possibleAnswers, answer);
    this.setState({ selections });
  }

  toggleOption(option) {
    const { selections } = this.state;
    const { answerQuestion, questionId } = this.props;
    const updatedSelections = selections.set(option, !selections.get(option));
    this.setState({ selections: updatedSelections });
    const answer = getAnswerFromSelection(updatedSelections);
    answerQuestion(questionId, answer);
  }

  render() {
    const { selections } = this.state;
    const {
      possibleAnswers,
      questions,
      answers,
      answerQuestion,
      questionId,
      hideUnansweredQuestions
    } = this.props;
    return (
      <QuestionContainer
        {...this.props}
      >
        {selections
          .map((isChecked, answer) => (
            <div key={answer}>
              {makeCheckbox(this.props, questionId, answer, this.toggleOption, isChecked)}
              {isChecked &&
                possibleAnswers.has(answer) &&
                possibleAnswers.get(answer) !== null && (
                  <div
                    // TODO: revise this decision
                    // Hard code it because it's not a component in Astro and there are no stylesheets.
                    // This is a risk for dark themes.
                    style={{
                      borderLeft: "solid 1px #cccccc",
                      paddingLeft: "1.65rem",
                    }}
                  >
                    {buildQuestion(
                      questions,
                      fromJS([]),
                      answers,
                      answerQuestion,
                      hideUnansweredQuestions,
                      possibleAnswers.get(answer)
                    )}
                  </div>
                )}
            </div>
          ))
          .valueSeq()}
      </QuestionContainer>
    );
  }
}

ReflexiveCheckboxListQuestion.propTypes = {
  questionId: PropTypes.string.isRequired,
  possibleAnswers: IPropTypes.map.isRequired,
  answer: PropTypes.string,
  answerQuestion: PropTypes.func.isRequired,
  questions: IPropTypes.map.isRequired,
  answers: IPropTypes.map.isRequired,
};

export default ReflexiveCheckboxListQuestion;

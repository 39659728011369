import { LOCATION_CHANGE } from 'connected-react-router';
import { fromJS } from 'immutable';
import {
  DEFAULT_THEME,
  DEFAULT_SENDER_REFERENCE,
  ROUTES,
  DOMAIN,
} from '../config/constants';
import cookieBakery from 'js-cookie';
import { IS_DEV } from '../config/appConfig';
import { ThemeNames } from '@simply-fin-services/astronomix3';

const THEME_COOKIE = 'simplyThemeCookie';

const initialState = fromJS({
  theme: DEFAULT_THEME,
  senderReference: DEFAULT_SENDER_REFERENCE,
});

const cookieAttribs = { expires: 0.04, domain: IS_DEV ? 'localhost' : DOMAIN };

const setTheme = (theme) => {
  cookieBakery.set(THEME_COOKIE, theme, cookieAttribs);
};

const isValidTheme = (theme) => {
  if (!theme) return false;
  return ThemeNames.indexOf(theme) > -1;
};

const isOnLandingPage = (action) => {
  const route = action.payload?.location?.pathname;
  if (!route) return false;
  // note
  // we need to do an exact check here because / exists in all routes so this would always
  // pass if we left it as indexOf > -1
  // as this is literally just the pathname from the location, no manipulation to the
  // string is required as the qs has already been taken out
  return (
    route.toUpperCase() === ROUTES.QUOTE_LANDING.toUpperCase() ||
    route.toUpperCase() === ROUTES.WELCOME_BACK.toUpperCase() ||
    route.toUpperCase() === ROUTES.HOME.toUpperCase()
  );
};

const shouldAttemptThemeChange = (action, theme) => {
  return (
    action.type === LOCATION_CHANGE &&
    isOnLandingPage(action) &&
    isValidTheme(theme)
  );
};

/**
 * Scenarios:
 * - The app does not set the theme on startup because it depends on the url
 *   if the user is coming from panoramix, the theme could be co-branded which means
 *   the theme would initially be simply and then flicker to the co-branded theme.
 * - If the user lands on a landing page of sorts, then the theme should be set.
 * - Then can click through to prefix at any time and when they come back,
 *   their theme needs to be retained. (footer link, pricing overlay in header during sale)
 * Note that there is a sender reference cookie and it exists in "campaign-source".
 */
export default (state = initialState, action) => {
  // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/get
  const query = new URLSearchParams(action.payload?.location?.search);
  const theme = query.get('t');
  const senderReference = query.get('senderReference');

  if (shouldAttemptThemeChange(action, theme)) {
    setTheme(theme);
    return fromJS({
      theme,
      senderReference,
    });
  }
  return state;
};

import * as types from './types';
import request from 'axios';
import { API_ROUTES } from '../config/constants';
import * as R from 'ramda';
import { setCookie, getCookie } from '../common/cookieBakery';
import { clearUTMProps } from './campaign';
const BROKER_COOKIE = 'brokercode';
const BROKER_COOKIE_LIFETIME_IN_DAYS = 30;

// This regext test is from https://github.com/prototypejs/prototype/blob/560bb59414fc9343ce85429b91b1e1b82fdc6812/src/prototype/lang/string.js#L699

const isJSON = str => {
  if (R.or(R.isNil(str), R.isEmpty(str))) {
    return false;
  }
  return R.pipe(
    R.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@'),
    R.replace(
      /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
      ']'
    ),
    R.replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
    R.test(/^[\],:{}\s]*$/)
  )(str);
};


export const setFromBrokerPortal = queryParams => {
  /* eslint-disable */
  if (window._chatlio) {
    _chatlio.hide();
  }
  /* eslint-enable */
  const type = types.SET_BROKER_PORTAL_FLAG;
  const fromBrokerPortal = queryParams.fromBrokerPortal === 'true';
  const payload = { fromBrokerPortal };
  return {
    type,
    payload,
  };
};


const checkBrokerContextIsCorrect = () => {
  return {
    type: types.CHECK_BROKER_CONTEXT_IS_CORRECT,
    payload: (dispatch, getState) => {
      const state = getState();
      const isFromBrokerPortal = state.getIn(["broker", "fromBrokerPortal"]);
      const brokerPortalUrl = state.getIn(['config', 'BROKER_PORTAL_URL']);
      if (isFromBrokerPortal !== true) {
        /* eslint-disable */
        if (window.confirm('The current sale will not be linked to your profile on the broker portal. Please be sure to always continue sales from your broker portal dashboard. Click "Ok" to return to your dashboard.')) {
          window.location.href = brokerPortalUrl;
        }
        /* eslint-enable */
      }
    }
  };
};


const linkBrokerSuccess = brokerCode => ({
  type: types.LINK_BROKER_CODE_SUCCESS,
  payload: { brokerCode },
});

const linkBrokerToInteraction = (
  dispatch,
  interactionId,
  brokerCode,
  senderReference
) => {
  const requestData = {
    interactionId,
    brokerCode,
    senderReference,
  };
  dispatch(clearUTMProps());
  dispatch(checkBrokerContextIsCorrect());
  request
    .post(`${API_ROUTES.BROKER_LINK_TO_INTERACTION}`, requestData)
    .then(() => dispatch(linkBrokerSuccess(brokerCode)));
};

const setBrokerCookie = (brokerCode, senderReference) => {
  const brokerCookie = {
    brokerCode,
    senderReference,
  };
  return setCookie(BROKER_COOKIE, brokerCookie, BROKER_COOKIE_LIFETIME_IN_DAYS);
};

export const linkBroker = (brokerCode, senderReference) => {
  const type = types.LINK_BROKER_CODE;
  const payload = R.not(R.isEmpty(brokerCode))
    ? (dispatch, getState) => {
        const state = getState();
        const interactionId = state.get('interactionId');
        setBrokerCookie(brokerCode, senderReference);
        linkBrokerToInteraction(
          dispatch,
          interactionId,
          brokerCode,
          senderReference
        );
      }
    : '';
  return {
    type,
    payload,
  };
};

export const linkExistingBrokerCode = () => {
  const type = types.LINK_EXISTING_BROKER_CODE;
  const payload = (dispatch, getState) => {
    const state = getState();
    const interactionId = state.get('interactionId');
    const tempCookie = getCookie(BROKER_COOKIE);
    const brokerCookieObject = isJSON(tempCookie)
      ? JSON.parse(tempCookie)
      : null;
    const brokerCode = brokerCookieObject
      ? brokerCookieObject.brokerCode
      : tempCookie;
    const senderReference = brokerCookieObject
      ? brokerCookieObject.senderReference
      : null;
    if (brokerCode && R.not(R.isEmpty(brokerCode))) {
      setBrokerCookie(brokerCode, senderReference);
      linkBrokerToInteraction(
        dispatch,
        interactionId,
        brokerCode,
        senderReference
      );
    }
  };
  return {
    type,
    payload,
  };
};

export const setBrokerConfig = config => ({
  type: types.SET_BROKER_CONFIG,
  payload: config,
});

import {
  REPLACE_AND_SAVE_INSURED_PERSON_ADDED,
  REPLACE_AND_SAVE_REPLACEMENT_POLICY_ADDED,
  REPLACE_AND_SAVE_ADD_REPLACEMENT_POLICY,
  REPLACE_AND_SAVE_REMOVE_REPLACEMENT_POLICY,
  REPLACE_AND_SAVE_SET_ENABLED,
  REPLACE_AND_SAVE_ADD_POLICY_NEXT_STEP,
  REPLACE_AND_SAVE_REMOVE_POLICY_NEXT_STEP,
  REPLACE_AND_SAVE_ADDING_INSURED_PERSON,
  REPLACE_AND_SAVE_SET_REPLACEMENT_POLICY_DATA,
  REPLACE_AND_SAVE_SET_CANCELLATION_LETTER_DATA,
  REPLACE_AND_SAVE_SHOW_REPLACEMENT_POLICIES_PAGE,
  REPLACE_AND_SAVE_SET_REPLACEMENT_POLICIES_SAVINGS_INFORMATION,
} from '../actions/types';
import * as R from 'ramda';
import { fromJS } from 'immutable';
import createActionHandler from './createActionHandler';

const initialState = fromJS({
  enabled: false,
  isAddingReplacementPolicy: false,
  isAddingInsured: false,
});

const setReplaceAndSaveEnabled = (state, { payload }) =>
  state.merge(
    initialState.merge({
      interactionId: payload.interactionId,
      enabled: true,
    })
  );

const replacementPolicyAdded = (state) =>
  state.set('isAddingReplacementPolicy', false);

const addReplacementPolicy = (state) =>
  state.set('isAddingReplacementPolicy', true);

const replacementPolicyRemoved = (state) =>
  state.set('isRemovingReplacementPolicy', false);

const removeReplacementPolicy = (state) =>
  state.set('isRemovingReplacementPolicy', true);

const addingInsuredPerson = (state) => state.set('isAddingInsuredPerson', true);

const hideAddingReplacementPolicy = (state) =>
  state.set('isAddingReplacementPolicy', false);

const addPolicyNextStep = (state, { payload }) => {
  return R.pipe(
    replacementPolicyAdded,
    addingInsuredPerson,
    hideAddingReplacementPolicy
  )(state).set('currentPolicy', fromJS(payload.policy));
};

const removePolicyNextStep = (state, { payload }) => {
  return R.pipe(
    replacementPolicyRemoved,
  )(state).set('currentPolicy', fromJS(payload.policy));
};

const insuredPersonAdded = (state) => state.set('isAddingInsuredPerson', false);

const setReplacementPoliciesData = (state, { payload }) => {
  const data = fromJS(payload);
  const showInitialAddReplacementPolicy =
    data.get('replacementPolicies').size === 0;

  return state
    .merge(data)
    .set('isAddingReplacementPolicy', showInitialAddReplacementPolicy);
};

const setCancellationLetterData = (state, {payload}) => {
  const data = fromJS(payload);
  return state.set('cancellationLetters', data);
};

const hideAddingInsuredPerson = state =>
  state.set('isAddingInsuredPerson', false);

const showReplacementPoliciesPage = (state) =>
  R.pipe(hideAddingReplacementPolicy, hideAddingInsuredPerson)(state);

const setReplacementPolciesSavingsInformation = (state, { payload }) => {
  const savingsInformation = fromJS(payload);

  return state.set('savingsInformation', savingsInformation);
};

// Action handler config

const actionHandlers = {};

actionHandlers[REPLACE_AND_SAVE_SET_ENABLED] = setReplaceAndSaveEnabled;
actionHandlers[REPLACE_AND_SAVE_ADD_REPLACEMENT_POLICY] = addReplacementPolicy;
actionHandlers[REPLACE_AND_SAVE_REMOVE_REPLACEMENT_POLICY] = removeReplacementPolicy;
actionHandlers[REPLACE_AND_SAVE_ADD_POLICY_NEXT_STEP] = addPolicyNextStep;
actionHandlers[REPLACE_AND_SAVE_REMOVE_POLICY_NEXT_STEP] = removePolicyNextStep;
actionHandlers[REPLACE_AND_SAVE_REPLACEMENT_POLICY_ADDED] = replacementPolicyAdded;
actionHandlers[REPLACE_AND_SAVE_SET_REPLACEMENT_POLICY_DATA] = setReplacementPoliciesData;
actionHandlers[REPLACE_AND_SAVE_SET_CANCELLATION_LETTER_DATA] = setCancellationLetterData;
actionHandlers[REPLACE_AND_SAVE_SHOW_REPLACEMENT_POLICIES_PAGE] = showReplacementPoliciesPage;
actionHandlers[REPLACE_AND_SAVE_ADDING_INSURED_PERSON] = addingInsuredPerson;
actionHandlers[REPLACE_AND_SAVE_INSURED_PERSON_ADDED] = insuredPersonAdded;
actionHandlers[REPLACE_AND_SAVE_SET_REPLACEMENT_POLICIES_SAVINGS_INFORMATION] =
  setReplacementPolciesSavingsInformation;

const handle = createActionHandler(actionHandlers);
export default (state = initialState, action) => handle(state, action);

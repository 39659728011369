import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FullWidthButtonWrapper from '../common/FullWidthButtonWrapper';
import { Link } from 'react-router-dom';
import FeatureWithoutImage from './FeatureWithoutImage';
import FeatureWithImage from './FeatureWithImage';
import { ROUTES } from '../../config/constants';
import PageHeader from '../common/PageHeader';

class FeaturesPage extends PureComponent {
  render() {
    const { extras, packageSlug } = this.props;
    return (
      <div>
        <PageHeader heading="Would you like to add any of the following?" />

        <div className="row centered">
          {extras.map((service, key) => {
            if (service.hasImage) {
              return (
                <div className="col-xs-12" key={`extra-${key}`}>
                  <FeatureWithImage
                    title={service.title}
                    description={service.description}
                    price={service.price}
                    imageClass={service['image-class']}
                  />
                </div>
              );
            }
            return (
              <div className="col-xs-12" key={`extra-${key}`}>
                <FeatureWithoutImage
                  title={service.title}
                  description={service.description}
                  price={service.price}
                />
              </div>
            );
          })}
        </div>

        <div className="row">
          <FullWidthButtonWrapper>
            <Link
              className="btn btn-primary long btn-block m-t-15 m-b-10"
              to={ROUTES.toCustomisation(packageSlug)}
            >
              Continue
            </Link>
          </FullWidthButtonWrapper>
        </div>
      </div>
    );
  }
}

const extrasShape = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  hasImage: PropTypes.bool.isRequired,
  'image-class': PropTypes.string,
};

FeaturesPage.propTypes = {
  extras: PropTypes.arrayOf(PropTypes.shape(extrasShape)).isRequired,
  packageSlug: PropTypes.string.isRequired,
};

export default FeaturesPage;

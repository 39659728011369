import { connect } from 'react-redux';
import PackageQuotePage from './PackageQuotePage';
import { updateSelectedPackage } from '../../actions/selectedPackageSlug';
import getPackageIdFromSlug from '../../common/getPackageIdFromSlug';

const getPackageUrlSlug = ownProps => ownProps.match.params.packageUrlSlug;

const getPackageQuoteDescription = (state, ownProps) => {
  const slug = getPackageUrlSlug(ownProps);
  const packageId = getPackageIdFromSlug(state, slug);
  return state.getIn(['packageDetails', packageId, 'quotePageDescription']);
};

const selectPackageTitle = (state, ownProps) =>
  state.getIn(['packages', getPackageUrlSlug(ownProps), 'title']);

const selectQuotePageId = (state, ownProps) =>
  state.getIn(['packages', getPackageUrlSlug(ownProps), 'quoteQuestionPageId']);

const selectDescription = (state, ownProps) =>
  state.getIn([
    'questionPages',
    selectQuotePageId(state, ownProps),
    'description',
  ]);

export const mapStateToProps = (state, ownProps) => ({
  packageUrlSlug: getPackageUrlSlug(ownProps),
  heading: `${selectPackageTitle(state, ownProps)} Quote`,
  description: selectDescription(state, ownProps),
  packageQuoteDescription: getPackageQuoteDescription(state, ownProps),
});

const mapDispatchToProps = dispatch => ({
  updateSelectedPackage: slug => dispatch(updateSelectedPackage(slug)),
});

const PackageQuoteContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageQuotePage);

export default PackageQuoteContainer;

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import InputButtonQuestion from '../questions/InputButtonQuestion';
import request from 'axios';
import { API_ROUTES } from '../../config/constants';
import {
  sendOtpActionCreator,
  otpValidActionCreator,
  otpInvalidActionCreator,
} from '../../actions/Otp';

import { calculatePricingFromState } from '../customization/calculatePricing';
import { toPremium } from '../common/numberFormats';
import { submittableDiscounts } from '../../actions/completeSale';
import { isBrokerSale } from '../../.shared/calculatePricing';
import { isFromBrokerPortal } from '../../common/brokers';
import { getPackageSlugFromId } from '../../common/getPackageIdFromSlug';
import { fromJS } from 'immutable';

const paymentMethods = {
  debit_order: 'debit order',
  iemas_salary_deduction: 'salary deduction',
  persal_salary_deduction: 'salary deduction', // TODO: I HATE THIS
  salary_deduction: 'salary deduction'
};

const getPaymentMethod = (id) => {
  if (!R.has(id, paymentMethods)) {
    throw new Error(`unknown payment method: ${id}`);
  }
  return paymentMethods[id];
};

const bankingOtp = 'BANKING_DETAILS';

const getNodeQuestions = R.curry((questions, questionNodes, nodeId) => {
  const getNextNode = getNodeQuestions(questions, questionNodes);
  const question = R.prop(nodeId, questions);
  const questionNode = R.prop(nodeId, questionNodes);
  if (questionNode) {
    return R.pipe(
      R.prop('children'),
      R.values,
      R.unnest,
      R.map(getNextNode),
      R.unnest,
      R.prepend(R.prop('questionId', questionNode))
    )(questionNode);
  }
  if (R.equals(R.prop('type', question), 'reflexive-checkbox')) {
    return R.pipe(
      R.prop('possibleAnswers'),
      R.values,
      R.map(getNextNode),
      R.unnest,
      R.prepend(nodeId)
    )(question);
  }
  if (question) {
    return [nodeId];
  }
  return [];
});

const getPossibleQuestions = R.curry((state, questionIds) => {
  const { questions, questionNodes } = state;
  return R.pipe(
    R.map((questionId) =>
      getNodeQuestions(questions, questionNodes, questionId)
    ),
    R.unnest
  )(questionIds);
});

const getQuestionairePage = (catalogue, questionPageId) =>
  R.path(['questionPages', questionPageId], catalogue);

const isMainQuestionareQuestion = (state, medicalQuestionPage, question) => {
  const { questionNodes } = state;
  const medicalQuestions = R.prop(
    'questions',
    getQuestionairePage(state, medicalQuestionPage)
  );
  const relevantNodes = R.filter(
    (v) => R.any(R.equals(v.nodeId), medicalQuestions),
    questionNodes
  );
  const isNode = R.filter(
    (v) => R.trim(v.questionId) === R.trim(question.questionId),
    relevantNodes
  );
  return (
    R.not(R.isEmpty(isNode)) ||
    R.not(
      R.isNil(R.find(R.equals(R.trim(question.questionId)), medicalQuestions))
    )
  );
};

const combinePossibleQuestionsWithAnswers = (
  catalogue,
  medicalQuestionPage,
  questionAnswerMap,
  questionIds
) => {
  return R.pipe(
    getPossibleQuestions(catalogue),
    R.map((questionId) => [questionId, R.prop(questionId, questionAnswerMap)]),
    R.filter((pair) => R.not(R.isNil(R.last(pair)))),
    R.map((pair) =>
      R.merge(R.path(['questions', R.head(pair)], catalogue), {
        answer: R.last(pair),
        isMainQuestion: isMainQuestionareQuestion(
          catalogue,
          medicalQuestionPage,
          R.path(['questions', R.head(pair)], catalogue)
        ),
      })
    )
  )(questionIds);
};

const medicalQuestions = (state) => {
  const currentPackageSlug = R.prop('selectedPackageSlug', state);
  const currentPackageId = R.path(
    ['packages', currentPackageSlug, 'packageId'],
    state
  );

  const isSuggestedPackage = R.pipe(
    R.find(
      (p) =>
        R.equals(currentPackageId, R.prop('suggestPackageId', p)) ||
        R.has(currentPackageId, R.prop('packageSuggestionOrder', p))
    ),
    R.prop('packageId')
  )(R.values(R.prop('packages', state)));

  const packageSlug = isSuggestedPackage
    ? getPackageSlugFromId(fromJS(state), isSuggestedPackage)
    : R.prop('selectedPackageSlug', state);
  const medicalQuestionPage = R.path(
    ['packages', packageSlug, 'questionnairePagesThatSuggestPackage', 0],
    state
  );
  return R.pipe(
    R.filter((answer) => answer.questionId !== 'owner-rsa-citizen'),
    R.uniq
  )(
    combinePossibleQuestionsWithAnswers(
      state,
      medicalQuestionPage,
      R.prop('answers', state),
      R.prop('questions', getQuestionairePage(state, medicalQuestionPage))
    )
  );
};

const addLineForMainQuestion = (question) => {
  if (question.isMainQuestion) {
    return '\n';
  }
  return '';
};

const medicalQuestionAnswersString = (state) =>
  R.replace(
    '|',
    ', ',
    R.join(
      '',
      R.map(
        (answer) =>
          `${addLineForMainQuestion(answer)}- ${R.prop(
            'title',
            answer
          )} ${R.path(['answer', 'answer'], answer)}\n`,
        medicalQuestions(state)
      )
    )
  );

const willSendRecordOfAdviceWithOtp = (state) =>
  state.getIn(['broker', 'willSendRecordOfAdviceWithOtp']);

const recordOfAdviceOptions = (state) => ({
  sendRoa: willSendRecordOfAdviceWithOtp(state),
  discounts: submittableDiscounts(state),
});

const recordOfAdviceWording = (state) =>
  willSendRecordOfAdviceWithOtp(state)
    ? 'confirm that the details in the record of advice are correct ({{recordOfAdviceUrl}}), '
    : '';

const isThirdParty = (state) => {
  const slug = state.get('selectedPackageSlug');
  return state.getIn(['packages', slug, 'isThirdPartyPackage']) === true;
};

const generateSmstext = (state, data) => {
  const { premium, paymentMethod } = data;
  const isThirdPartyPackage = isThirdParty(state);
  const insuredPersonsName = `${state.getIn([
    'answers',
    'insured-person-first-name',
    'answer',
  ])} ${state.getIn(['answers', 'insured-person-surname', 'answer'])}`;
  const standardPolicyText = `By providing the OTP below, you agree to your premium of ${toPremium(
    premium
  )}, acknowledge Simply's full T&Cs (${state.getIn([
    'broker',
    'brokerTermsLink',
  ])}), ${recordOfAdviceWording(
    state
  )}and have chosen ${paymentMethod} as your payment method${
    R.isEmpty(medicalQuestionAnswersString(state.toJS()))
      ? '.'
      : ` and confirm you answered our underwriting questions as follows:\n${medicalQuestionAnswersString(
          state.toJS()
        )}`
  }\nOTP: {{OTP}}`;

  const thirdPartyPolicyText = `By providing the OTP below, you agree to your premium of ${toPremium(
    premium
  )} payable on the Simply Family Cover policy covering ${insuredPersonsName}, and have chosen ${paymentMethod}
   as your payment method and acknowledge Simply's full T&Cs (${state.getIn([
     'broker',
     'brokerTermsLink',
   ])}) \nOTP: {{OTP}}`;

  return isThirdPartyPackage ? thirdPartyPolicyText : standardPolicyText;
};

const validateOtpEndpoint = API_ROUTES.VALIDATE_OTP;

const exists = (i) => !R.isNil(i) && !R.isEmpty(i);
class OneTimePin extends PureComponent {
  constructor() {
    super();
    this.answerQuestion = this.answerQuestion.bind(this);
    this.submit = this.submit.bind(this);
    this.state = {
      answer: '',
      hasSentOtp: false,
      timeLapsed: 0,
      resendIsDisabled: false,
    };
    this.sendOTP = this.sendOTP.bind(this);
    this.incrementTimeLapsed = this.incrementTimeLapsed.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.interval = null;
  }

  incrementTimeLapsed() {
    this.setState({ timeLapsed: this.state.timeLapsed + 1 });
  }

  startTimer() {
    this.interval = setInterval(this.incrementTimeLapsed, 1000);
    this.setState({
      resendIsDisabled: true,
    });
  }

  stopTimer() {
    clearInterval(this.interval);
    this.interval = null;
    this.setState({
      resendIsDisabled: false,
      timeLapsed: 0,
    });
  }

  submit() {
    const { answer } = this.state;
    const {
      validateAndAnswerQuestion,
      questionId,
      setOtpValid,
      setOtpInvalid,
      cellphoneNumber,
    } = this.props;
    const { id } = this.props.otpState;
    request
      .post(validateOtpEndpoint, {
        id,
        value: answer,
        cellphoneNumber,
        type: `${bankingOtp}_${this.props.form.toUpperCase()}`,
      })
      .then((res) => {
        const validation = res.data;
        validateAndAnswerQuestion(() => validation, questionId, answer);
        if (validation.isValid) {
          setOtpValid();
          this.stopTimer();
        } else {
          setOtpInvalid();
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  answerQuestion(value) {
    this.setState({ answer: value });
  }

  componentDidMount() {
    this.props.setOtpInvalid();
  }

  componentDidUpdate() {
    if (this.state.timeLapsed === 30) {
      this.stopTimer();
    }
  }

  sendOTP() {
    this.startTimer();
    const { dispatch, sendOTP, cellphoneNumber, form, state, pricing } =
      this.props;
    const premium = pricing.finalCost;
    const type = `${bankingOtp}_${form.toUpperCase()}`;
    const paymentMethod = getPaymentMethod(form);
    const sms = generateSmstext(state, { premium, paymentMethod });

    const roaOptions = recordOfAdviceOptions(state);
    dispatch(sendOTP(cellphoneNumber, type, sms, roaOptions));
    this.setState({ hasSentOtp: true });
  }

  render() {
    // AYA is what valid?
    const { isValid } = this.props;
    const { cellphoneNumber } = this.props;
    const { sent } = !R.isNil(this.props.otpState)
      ? this.props.otpState.toJS().status
      : { sent: null };
    const buttonProps = {
      onClick: isValid !== true ? this.submit : () => {},
      disabled:
        isValid === true ||
        (isValid === undefined && exists(this.props.otpState) === false),
    };
    const buttonText = 'Validate';
    const isThirdPartyPackage = isThirdParty(this.props.state);
    const Resend = isValid ? (
      ''
    ) : (
      <Fragment>
        Click here to <a onClick={this.sendOTP}>resend</a>
      </Fragment>
    );

    const ResendDisabledMessage = (
      <Fragment>
        Resend in
        <strong>
          {' '}
          <span className="text-primary">{30 - this.state.timeLapsed}s</span>
        </strong>
      </Fragment>
    );

    const getSendMessage = () => {
      const standardPolicySendMessage = (
        <span>          
          <a onClick={this.sendOTP}>Click here</a> to send a confirmation SMS containing your policy summary and OTP to {cellphoneNumber}
        </span>
      );
      const thirdPartyPolicySendMessage = (
        <span>
          Please click <a onClick={this.sendOTP}>here</a> to send a summary of
          the application and an OTP to {cellphoneNumber}. This OTP must be
          provided by the premium payer once they have reviewed the message and
          input here.
        </span>
      );
      return isThirdPartyPackage
        ? thirdPartyPolicySendMessage
        : standardPolicySendMessage;
    };

    const Hint = !this.state.hasSentOtp ? (
      getSendMessage()
    ) : (
      <span>
        An OTP has been sent to {cellphoneNumber}.{' '}
        {this.state.resendIsDisabled ? ResendDisabledMessage : Resend}
      </span>
    );

    return (
      <Fragment>
        <InputButtonQuestion
          title="Validate OTP to complete process:"
          hint={Hint}
          isValid={isValid}
          className="otp"
          {...this.props}
          answerQuestion={this.answerQuestion}
          buttonProps={buttonProps}
          icon={!R.isNil(isValid) ? null : 'lock'}
          disable={isValid}
        >
          {buttonText}
        </InputButtonQuestion>
        {sent === false && (
          <p className="small text-danger">
            hmm...something's not right, couldn't send otp
          </p>
        )}
      </Fragment>
    );
  }
}

OneTimePin.propTypes = {
  answerQuestion: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  setOtpValid: PropTypes.func.isRequired,
  setOtpInvalid: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    interactionId: state.get('interactionId'),
    cellphoneNumber: state.getIn(['answers', 'owner-contact-number', 'answer']),
    packageUrlSlug: state.get('selectedPackageSlug'),
    isBrokerSale: isBrokerSale(state) || isFromBrokerPortal(state),
    state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendOTP: (c, otpType, smsTemplate, roaOptions) =>
    sendOtpActionCreator(c, otpType, smsTemplate, roaOptions),
  setOtpValid: () => dispatch(otpValidActionCreator()),
  setOtpInvalid: () => dispatch(otpInvalidActionCreator()),
  dispatch,
});

const validateAndAnswer = (ownProps) => {
  if (ownProps.validateAndAnswerQuestion) {
    return ownProps.validateAndAnswerQuestion;
  }
  return (validate) => validate();
};

export const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    otpState: stateProps.state.getIn([
      'authentication',
      'otp',
      `${bankingOtp}_${ownProps.form.toUpperCase()}`,
    ]),
    state: stateProps.state,
    pricing: calculatePricingFromState(
      stateProps.state,
      stateProps.packageUrlSlug
    ),
    validateAndAnswerQuestion: validateAndAnswer(ownProps),
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(OneTimePin);

export const isOtpValid = (state) =>
  state.getIn(['authentication', 'otp', 'isValid']) === true;

export const isThirdPartyOtpValid = (state) =>
  state.getIn(['authentication', 'otp', 'thirdPartyOtpIsValid']) === true;

import { connect } from 'react-redux';
import * as R from 'ramda';
import PeepsPage from './Peeps';
import { Set, List } from 'immutable';
import {
  selectInsured,
  deselectInsured,
  addPeep,
  selectBeneficiary,
} from '../../actions/peeps';
import getSelectedProductIds from './getSelectedProductIds';
import getSelectedPackageDetail from '../common/getSelectedPackageDetail';
import { validateRequiredDateOfBirth } from '../../.shared/validators';

const AGE_RANGES = [
  { relationship: 'Spouse', min: 18, max: 65 },
  { relationship: 'Child', min: 0, max: 21 },
];

export const CHILD_MAX_AGE = 21;
export const CHILD_MIN_AGE = 0;

const AGE_RANGES_M = R.pipe(
  R.map(r => R.objOf(r.relationship, r)),
  R.reduce(R.merge, {})
)(AGE_RANGES);

const mergeProductConfig = R.curry(product =>
  R.merge(product, {
    max: product.maxInsureds,
    maxPerType: product.insureds,
  })
);

const getDescription = state =>
  getSelectedPackageDetail(state).get('insuredsPageDescription');

const getSelectedProductConfig = state => {
  const selectedProductIds = getSelectedProductIds(state);
  return R.map(
    productId => state.getIn(['products', productId]).toJS(),
    selectedProductIds
  );
};

const getInsuredsConfig = selectedProductConfig =>
  R.pipe(
    R.filter(R.prop('canAddInsureds')),
    R.map(mergeProductConfig)
  )(selectedProductConfig);

const isAllowedToAdd = (insuredTypeLimits, ageRangesMap, peep) => {
  const relationship = peep.get('relationship');
  const dob = peep.get('dateOfBirth');
  const range = ageRangesMap[relationship];
  return (
    (insuredTypeLimits[relationship] || false) &&
    !!range &&
    validateRequiredDateOfBirth(dob, range.max, range.min).isValid
  );
};

const buildInsuredsSelection = (
  allPeeps,
  selectedPeeps,
  insuredTypeLimits,
  ageRangesMap
) =>
  new Set(allPeeps)
    .map(p =>
      p
        .set('isSelected', selectedPeeps.includes(p))
        .set('canAdd', isAllowedToAdd(insuredTypeLimits, ageRangesMap, p))
    )
    .toJS();

const getInsuredTypeLimits = (selectedPeeps, insuredRelationshipConfig) =>
  R.map(
    r =>
      R.filter(R.propEq('relationship', r.relationship), selectedPeeps).length <
      r.max,
    insuredRelationshipConfig
  );

const getProductPeeps = (allPeeps, insuredProductConfig, selectedInsureds) =>
  R.map(p => {
    const selectedPeeps = selectedInsureds.get(p.productId) || new List();
    const insuredTypeLimits = getInsuredTypeLimits(
      selectedPeeps.toJS(),
      p.insureds
    );
    return R.merge(p, {
      peeps: buildInsuredsSelection(
        allPeeps,
        selectedPeeps,
        insuredTypeLimits,
        AGE_RANGES_M
      ),
      canAdd: R.any(R.equals(true), R.values(insuredTypeLimits)),
      maxPerType: R.values(p.insureds),
      relationships: R.keys(R.filter(R.equals(true), insuredTypeLimits)),
      ageRanges: AGE_RANGES,
      type: 'insureds',
    });
  }, insuredProductConfig);

export const mapStateToProps = (state, ownProps) => {
  const selectedProductConfig = getSelectedProductConfig(state);
  const insuredProductConfig = getInsuredsConfig(selectedProductConfig);
  const selectedInsureds = state.getIn(['peeps', 'insureds']);
  const allPeeps = state.getIn(['peeps', 'peeps']);
  return {
    productPeeps: getProductPeeps(
      allPeeps,
      insuredProductConfig,
      selectedInsureds
    ),
    heading: 'Covered dependants',
    description: ownProps.description || getDescription(state),
    selectedProductConfig,
  };
};

export const mapDispatchToProps = dispatch => ({
  select: productId => peep => dispatch(selectInsured(productId, peep)),
  deselect: productId => peep => dispatch(deselectInsured(productId, peep)),
  dispatch,
  addPeep: productId => peep => {
    dispatch(addPeep(peep));
    dispatch(selectInsured(productId, peep));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { selectedProductConfig } = stateProps;
  const { dispatch } = dispatchProps;
  const addPeepDeets = productId => peep => {
    dispatch(addPeep(peep));
    dispatch(selectInsured(productId, peep));
    const spouse = peep.relationship === 'Spouse';
    if (spouse) {
      selectedProductConfig.map(p =>
        dispatch(selectBeneficiary(p.productId, peep))
      );
    }
  };
  return Object.assign({}, stateProps, dispatchProps, {
    addPeepButtonText: 'Add family members',
    addPeep: addPeepDeets,
    children: ownProps.children,
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PeepsPage);

import {
  VALIDATE_BROKER_COMPLIANCE,
  COMPLIANCE_DOC_UPLOAD_ERROR,
  FINISH_COMPLIANCE_DOC_UPLOAD,
  COMPLIANCE_DOC_UPLOAD,
  SAVE_COMPLIANCE_REQUIREMENTS,
  SAVE_COMPLIANCE_PAYMENT_TYPE,
  SET_FILE_UPLOAD_PROGRESS,
} from './types';
import request from 'axios';
import { API_ROUTES } from '../config/constants';
import { MAXIMUM_REQUEST_FILE_SIZE } from '../config/appConfig';

export const validateBrokerCompliance = () => ({
  type: VALIDATE_BROKER_COMPLIANCE,
  payload: null,
});

export const saveRequiredComplianceFiles = (files) => ({
  type: SAVE_COMPLIANCE_REQUIREMENTS,
  payload: files,
});

export const saveCompliancePaymentType = (type) => ({
  type: SAVE_COMPLIANCE_PAYMENT_TYPE,
  payload: type,
});

export const errorUploadingFile = (fileId, message) => ({
  type: COMPLIANCE_DOC_UPLOAD_ERROR,
  payload: { fileId, message },
});

export const finishComplianceDocUpload = (fileId) => ({
  type: FINISH_COMPLIANCE_DOC_UPLOAD,
  payload: fileId,
});

export const setFileUploadProgress = (isUploading, fileId) => ({
  type: SET_FILE_UPLOAD_PROGRESS,
  payload: { isUploading, fileId },
});

export const uploadComplianceDoc = (fileId, file) => {
  const formData = new FormData();
  formData.append('compliance-file', file);
  formData.append('fileId', fileId);
  const payload = (dispatch) => {
    dispatch(setFileUploadProgress(true, fileId));
    request
      .post(API_ROUTES.COMPLIANCE_DOC_UPLOAD, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        maxContentLength: MAXIMUM_REQUEST_FILE_SIZE,
        maxBodyLength: MAXIMUM_REQUEST_FILE_SIZE,
      })
      .then(() => {
        dispatch(setFileUploadProgress(false, fileId));
        dispatch(finishComplianceDocUpload(fileId));
        dispatch(validateBrokerCompliance());
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data
        ) {
          dispatch(errorUploadingFile(fileId, error.response.data.message));
        } else {
          dispatch(errorUploadingFile(fileId));
        }
      });
  };
  return {
    type: COMPLIANCE_DOC_UPLOAD,
    payload,
  };
};

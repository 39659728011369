import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { isFromBrokerPortal, isQaSaleInQaStage } from '../../common/brokers';
import { PageButtons } from '@simply-fin-services/astronomix3';
import logo from '../../images/debicheck.png';

const Background = styled.div`
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: #eeeeeeee;
`;

const Container = styled.div`
  background-color: #fff;
  border-radius: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 3rem;
  p {
    line-height: 1.6rem;
    text-align: center;
  }

  @media screen and (max-height: 600px) {
    position: relative;
    top: 10px;
    left: 10px;
    right: 10px;
    width: 100%;
    max-width: 100%;
    transform: none;
  }
`;

const Logo = styled.img`
  max-width: 450px;
  width: 100%;
`;

const Modal = ({ children, onToggle }) => {
  document.body.style.overflow = 'hidden';

  return (
    <Background>
      <Container>
        <div>
          <Logo src={logo} alt="DebiCheck Logo" />
        </div>
        {children}
        <div className="action">
          <PageButtons
            colour="primary"
            label="Got it"
            onClick={() => {
              onToggle();
              document.body.style.overflow = '';
            }}
          />
        </div>
      </Container>
    </Background>
  );
};

const DirectSaleDebiCheckPopup = ({ onToggle }) => {
  return (
    <Modal onToggle={onToggle}>
      <p>
        DebiCheck is{' '}
        <strong>
          a secure debit order payment system that allows you to electronically
          approve your debit order details
        </strong>
        . This prevents fraudulent debit orders from being processed, giving you
        more control over your finances.
      </p>
      <p>
        You should have just received an SMS from your bank asking you to
        approve this debit order. Please do so now via USSD to ensure your
        payment is secure (you have 2 mins). If you’re not able to do so before
        the USSD option expires, please log into your banking app or online
        banking and do it there. It’s important.
      </p>
    </Modal>
  );
};

const BrokerSaleDebiCheckPopup = ({ onToggle }) => {
  return (
    <Modal onToggle={onToggle}>
      <p>
        DebiCheck is{' '}
        <strong>
          a secure debit order payment system that allows you to electronically
          approve your debit order details
        </strong>
        . This prevents fraudulent debit orders from being processed, giving you
        more control over your finances.
      </p>
      <p>
        You should have just received an SMS from your bank asking you to
        approve this debit order. Please do so now via USSD to ensure your
        payment is secure (you have 2 mins). If you’re not able to do so before
        the USSD option expires, please log into your banking app or online
        banking and do it there. It’s important.
      </p>
    </Modal>
  );
};

const DebiCheckPopup = ({ isDirectSale, isBrokerSale }) => {
  const [showPopup, setShowPopup] = useState(true);

  if (!showPopup) return <></>;

  if (isDirectSale) {
    return <DirectSaleDebiCheckPopup onToggle={setShowPopup} />;
  }

  if (isBrokerSale) {
    return <BrokerSaleDebiCheckPopup onToggle={setShowPopup} />;
  }

  return <></>;
};

const isDebitOrder = (state) => {
  return state.getIn(['paymentType', 'selectedPaymentType']) === 'debit-order';
};

const mapStateToProps = (state) => {
  return {
    isDirectSale: !isFromBrokerPortal(state) && isDebitOrder(state),
    isBrokerSale:
      isFromBrokerPortal(state) &&
      isDebitOrder(state) &&
      !isQaSaleInQaStage(state),
  };
};

export default connect(mapStateToProps)(DebiCheckPopup);

import request from 'axios';
import { API_ROUTES } from '../../config/constants';
import {
  removePolicyNextStep,
  setReplacementPolicyData,
  setReplacementPolicySavingsInformation,
} from '../../actions/replaceAndSave';
import { fromJS } from 'immutable';

export const fetchLatestPolicyReplacementData = (dispatch) =>
  request
    .get(API_ROUTES.REPLACE_AND_SAVE_LIST_REPLACEMENT_POLICIES)
    .then(({ data }) => {
      dispatch(setReplacementPolicyData(data));
      return data;
    })
    .catch((error) => {
      // FAILFAST: Error handling is a perfect activity for cool down.
      throw error;
    });

export const fetchLatestPolicyReplacementForInteractionId = (
  interactionId,
  dispatch
) =>
  request
    .get(API_ROUTES.REPLACE_AND_SAVE_LIST_REPLACEMENT_POLICIES, {
      params: { interactionId },
    })
    .then(({ data }) => {
      dispatch(setReplacementPolicyData(data));
      return data;
    })
    .catch((error) => {
      // FAILFAST: Error handling is a perfect activity for cool down.
      throw error;
    });

export const fetchFamilyMembersWithCoverAllocations = (policyId) =>
  request
    .get(API_ROUTES.REPLACE_AND_SAVE_FETCH_FAMILY_MEMBERS, {
      params: { policyId },
    })
    .then(({ data }) => {
      return fromJS(data);
    })
    .catch(() => {
      // FAILFAST: Error handling is a perfect activity for cool down.
    });

export const allocateCover = (params) =>
  request
    .post(API_ROUTES.REPLACE_AND_SAVE_ALLOCATE_COVER, params)
    .then(({ data }) => {
      return fromJS(data);
    })
    .catch(() => {
      // FAILFAST: Error handling is a perfect activity for cool down.
    });

export const savingsInformationForComponent = (quoteMonthlyPremium) =>
  request
    .get(API_ROUTES.REPLACE_AND_SAVE_REPLACEMENT_POLICIES_SAVINGS_INFORMATION, {
      params: {
        quoteMonthlyPremium,
      },
    })
    .then(({ data }) => {
      return fromJS(data);
    })
    .catch(() => {
      // FAILFAST: Error handling is a perfect activity for cool down.
    });

export const savingsInformation = (quoteMonthlyPremium, dispatch) =>
  savingsInformationForComponent(quoteMonthlyPremium).then((data) => {
    dispatch(setReplacementPolicySavingsInformation(data));
    return fromJS(data);
  });

export const savingsInformationForInteractionId = (
  interactionId,
  quoteMonthlyPremium,
  dispatch
) =>
  request
    .get(API_ROUTES.REPLACE_AND_SAVE_REPLACEMENT_POLICIES_SAVINGS_INFORMATION, {
      params: { interactionId, quoteMonthlyPremium },
    })
    .then(({ data }) => {
      dispatch(setReplacementPolicySavingsInformation(data));
      return fromJS(data);
    })
    .catch(() => {
      // FAILFAST: Error handling is a perfect activity for cool down.
    });

export const populateExtendedFamilyFuneralMembers = (packageId) =>
  request
    .post(API_ROUTES.REPLACE_AND_SAVE_POPULATE_EXTENDED_FAMILY_FUNERAL_MEMBERS, {
      params: { packageId },
    })
    .then(({ data }) => {
      return fromJS(data);
    })
    .catch(() => {
      // FAILFAST: Error handling is a perfect activity for cool down.
    });

export const listCancellationLetters = (contractId, interactionId) =>
  request
    .get(API_ROUTES.REPLACE_AND_SAVE_LIST_CANCELLATION_LETTERS, {
      params: { contractId, interactionId },
    })
    .then(({ data }) => {
      return fromJS(data);
    })
    .catch(() => {
      // FAILFAST: Error handling is a perfect activity for cool down.
    });

export const downloadCancellationLetter = () =>
  request
    .get(API_ROUTES.REPLACE_AND_SAVE_DOWNLOAD_CANCELLATION_LETTER)
    .then(({ data }) => {
      return fromJS(data);
    })
    .catch(() => {
      // FAILFAST: Error handling is a perfect activity for cool down.
    });

export const removeReplacementPolicy = (policyId) =>
  request
    .post(API_ROUTES.REPLACE_AND_SAVE_REMOVE_REPLACEMENT_POLICY, {
      policyId,
    })
    .then(({ data }) => {
      removePolicyNextStep(data);
      return fromJS(data);
    })
    .catch(() => {
      // FAILFAST: Error handling is a perfect activity for cool down.
    });

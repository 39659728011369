import React, { PureComponent } from 'react';
import copyToClipboard from 'copy-to-clipboard';
import ShareButtons from './ShareButtons';
import { WEB_SITE_BASE } from '../../config/appConfig';
import {
  InputButton,
  Form,
  FieldContainer,
} from '@simply-fin-services/astronomix3';
export default class ShareReferralLink extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { copied: false };
    this.onCopy = this.onCopy.bind(this);
  }

  onCopy() {
    copyToClipboard(this.props.referralUrl);
    this.setState({ copied: true });
  }

  render() {
    const { referralUrl, referrerName, referralCode } = this.props;
    const { copied } = this.state;
    const buttonText = copied ? 'Copied' : 'Copy Link';
    const buttonColour = copied ? 'secondary' : 'primary';
    const redirectBase = 'refer-your-friends-and-family';
    const redirectUri = `${WEB_SITE_BASE}/${redirectBase}/${referralCode}/${referrerName}/referralUrl=${referralUrl}`;
    return (
      <Form>
        <FieldContainer fieldClass="no-max-width">
          <InputButton
            button={{ colour: buttonColour, onClick: this.onCopy }}
            input={{ value: referralUrl, onChange: () => null }}
          >
            {buttonText}
          </InputButton>
        </FieldContainer>
        <ShareButtons referralUrl={referralUrl} redirectUri={redirectUri} />
      </Form>
    );
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class PageHeader extends PureComponent {
  render() {
    const { heading } = this.props;
    return <h3>{heading}</h3>;
  }
}

PageHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default PageHeader;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DropdownQuestion from './DropdownQuestion';
import { fromJS } from 'immutable';

const cleanTrailingPlus = value => value.replace('+', '');

const addTrailingPlus = value => `${value}+`;

const addTrailingPlusToOptions = possibleAnswers => {
  const listAnswers = fromJS(possibleAnswers);
  const last = addTrailingPlus(listAnswers.last());
  return listAnswers
    .pop()
    .push(last)
    .toJS();
};

const addTrailingPlusToAnswer = (possibleAnswers, answer) => {
  const lastOption = fromJS(possibleAnswers).last();
  if (lastOption === answer) {
    return addTrailingPlus(answer);
  }
  return answer;
};

class TrailingPlusQuestion extends PureComponent {
  constructor() {
    super();
    this.cleanThenAnswer = this.cleanThenAnswer.bind(this);
  }

  cleanThenAnswer(value) {
    const { answerQuestion } = this.props;
    answerQuestion(cleanTrailingPlus(value));
  }

  render() {
    const { possibleAnswers, answer } = this.props;
    const plussedAnswers = addTrailingPlusToOptions(possibleAnswers);
    const formattedAnswer = addTrailingPlusToAnswer(possibleAnswers, answer);
    return (
      <DropdownQuestion
        {...this.props}
        answerQuestion={this.cleanThenAnswer}
        possibleAnswers={plussedAnswers}
        answer={formattedAnswer}
      />
    );
  }
}

TrailingPlusQuestion.propTypes = {
  possibleAnswers: PropTypes.arrayOf(PropTypes.string).isRequired,
  answer: PropTypes.string,
  answerQuestion: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default TrailingPlusQuestion;

/*
  Prefer answer labels directly on the question config.
*/

const answersLabels = {
  'sim-family-combo-package-nrf': 'Simply Family',
  'sim-family-combo-package-cf': 'Simply Family',
  'sim-family-combo-package': 'Simply Family',
  'sim-employee-combo-package': 'Simply Domestic',
  'sim-sme': 'Simply Group',
  Undergrad: '3yr Degree',
  '4yr Degree': '4yr+ Degree',
  Cash: 'Get cash',
  'life-mf': 'Life Matters Foundation',
  'angels-bc': 'Angels Baby Sanctuary',
  wessa: 'Wessa',
  nsri: 'NSRI',
  choc: 'Choc',
  paedespal: 'Paedespal',
  ffl: 'Food For Life',
  tears: 'Tears',
  wwf: 'WWF',
};

const getDeprecatedAnswerLabel = (answer) => answersLabels[answer];

const getLabelFromConfig = (questionConfig, answer) => {
  if (questionConfig.answerLabels) {
    return questionConfig.answerLabels[answer];
  }
  return null;
};

export const getLabel = (questionConfig, answer) => getLabelFromConfig(questionConfig, answer) || getDeprecatedAnswerLabel(answer) || answer;

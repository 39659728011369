"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAffiliateMaxCover = void 0;

var R = _interopRequireWildcard(require("ramda"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var halfOfFuneralCoverAmount = R.pipe(R.prop('funeralCoverAmount'), R.multiply(0.5));
/* const maxOfFuneralCoverAmount = R.prop('funeralCoverAmount'); */

var halfOfFuneralCoverAmountLessThan10k = R.ifElse(R.pipe(halfOfFuneralCoverAmount, R.lte(100)), halfOfFuneralCoverAmount, R.always(100));
/* const defaultMaxCover = R.ifElse(
  (options) => options.funeralCoverAmount <= 500,
  R.prop('funeralCoverAmount'),
  R.always(500)
); */

var checkField = function checkField(field, checkFn) {
  return R.pipe(R.prop(field), checkFn);
};

var isRelationship = function isRelationship(match) {
  return checkField('relationship', R.equals(match));
};

var isChildUnder21 = isRelationship('Child Under 21');
var isSpouse = isRelationship('Spouse');

var isBetweenAge = function isBetweenAge(min, max) {
  return R.allPass([function (age) {
    return age >= min;
  }, function (age) {
    return age < max;
  }]);
};

var isChildBetween14and21 = R.allPass([isChildUnder21, checkField('age', isBetweenAge(14, 21))]);
var isChildBetween6and14 = R.allPass([isChildUnder21, checkField('age', isBetweenAge(6, 14))]);
var isChildBetween0and6 = R.allPass([isChildUnder21, checkField('age', isBetweenAge(0, 6))]);
var applyMaximumCover = R.prop('maximumCover');
var getAffiliateMaxCover = R.cond([[isSpouse, applyMaximumCover], [isChildBetween14and21, applyMaximumCover], [isChildBetween6and14, halfOfFuneralCoverAmount], [isChildBetween0and6, halfOfFuneralCoverAmountLessThan10k], [R.T, applyMaximumCover]]);
exports.getAffiliateMaxCover = getAffiliateMaxCover;
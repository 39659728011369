/* eslint-disable */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Icon,
  InputFile,
  Table,
  WallOfTextContainer,
  Spinner,
} from '@simply-fin-services/astronomix3';
import styled from 'styled-components';
import {
  uploadComplianceDoc,
  saveRequiredComplianceFiles,
  validateBrokerCompliance,
  saveCompliancePaymentType,
} from '../../actions/brokerCompliance';
import Immutable from 'immutable';
import { isEmpty, uniqWith } from 'ramda';
import { isQaSaleInQaStage } from '../../common/brokers';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const BrokerComplianceContainer = styled.div`
  margin-top: 25px;
`;

const completionMesages = (filesRequired) => {
  return (
    <div>
      {filesRequired.length > 0 && (
        <p>
          <small>
            <span className="text-danger">
              {filesRequired.length} documents required.{' '}
            </span>{' '}
          </small>
        </p>
      )}
    </div>
  );
};

const TextLeftTableData = styled.td`
  text-align: left;
  width: 30% !important ;
`;

const TextCenterTableData = styled.td`
  text-align: center;
  width: 30% !important ;
`;

const TextCenterTableHead = styled.th`
  text-align: center !important;
`;

const TextRightTableHead = styled.th`
  text-align: right
   !important;
`;

const TextRightTableData = styled.td`
  text-align: right;
  width: 30% !important ;
`;

const renderFileUpload = (file, uploadFile, isQaSale) => (
  <tr key={file.id}>
    <TextLeftTableData colSpan="3">{file.description}</TextLeftTableData>
    <TextCenterTableData>
      {file.uploaded ? (
        <Icon icon="check" size="20px" />
      ) : file.failedToUpload ? (
        <span className="text-danger">{file.reasonFailedToUpload ? file.reasonFailedToUpload : "Error Uploading"}</span>
      ) : file.isUploading === true? (
        <Spinner width="20px" margin="5px" />
      ) : (
        <Icon icon="close" size="20px" />
      )}
    </TextCenterTableData>

    <TextRightTableData>
      <InputFile
        id={file.id}
        onClick={() => {}}
        buttonConfig={{ colour: 'primary' , disabled: isQaSale}}
        inputConfig={{
          onChange: (e) => uploadFile(file.id, e.target.files[0]),
        }}
      >
        Upload
      </InputFile>
    </TextRightTableData>
  </tr>
);

const paymentTypeRequiresFile = (file, props) =>
  file['required-by'] === props.type ||
  file['required-by'] === props.selectedPackage;
class BrokerCompliance extends React.Component {
  componentWillMount() {
    const { saveComplianceFiles, requiredComplianceFiles, validate } =
      this.props;
    if (
      isEmpty(this.props.files) ||
      this.props.selectedPaymentType !== this.props.type
    ) {
      const files = requiredComplianceFiles.filter((file) =>
        paymentTypeRequiresFile(file, this.props)
      );
      const hasSameId = (a, b) => a.id === b.id;
      saveComplianceFiles(uniqWith(hasSameId)(files), this.props.type);
    }
    validate();
  }

  render() {
    const hasFilesToUpload = this.props.files.length > 0;
    const { postUploadComplianceDoc, isQaSale } = this.props;
    const headerStyles = {
      fontWeight: 600,
      fontSize: '1rem',
      color: '#091A23',
      display: 'block',
      textAlign: 'left',
      WebkitLetterSpacing: 'normal',
      MozLetterSpacing: 'normal',
      MsLetterSpacing: 'normal',
      letterSpacing: 'normal',
      marginBottom: '0.25rem',
      opacity: 0.7
    };

    return (
      <Fragment>
        {hasFilesToUpload && (
          <BrokerComplianceContainer>
            <WallOfTextContainer>
              <h4 style={headerStyles}>Compliance requirements</h4>
              
            </WallOfTextContainer>
            <Container>
              {completionMesages(this.props.filesRequired)}
              <Table>
                <thead>
                  <tr>
                    <th colSpan="3">Document description</th>
                    <TextCenterTableHead>Uploaded?</TextCenterTableHead>
                    <TextRightTableHead>Action</TextRightTableHead>
                  </tr>
                </thead>
                <tbody>
                  {this.props.files.map((file) =>
                    renderFileUpload(file, postUploadComplianceDoc, isQaSale)
                  )}
                </tbody>
              </Table>
            </Container>
          </BrokerComplianceContainer>
        )}
      </Fragment>
    );
  }
}

const getFromState = (state, stateKeys) =>
  state.getIn(stateKeys) instanceof Immutable.List
    ? state.getIn(stateKeys).toJS()
    : state.getIn(stateKeys);

const mapStateToProps = (state) => {
  const files = getFromState(state, ['brokerCompliance', 'files']);
  const selectedPaymentType = getFromState(state, [
    'brokerCompliance',
    'selectedPaymentType',
  ]);
  const filesRequired = files.filter((f) => !f.uploaded);
  const filesFailedToUpload = files.filter((f) => f.failedToUpload);
  const requiredComplianceFiles = getFromState(state, [
    'saleOptions',
    'compliance-documents',
  ]);
  const selectedPackageSlug = state.get('selectedPackageSlug');
  const selectedPackage = state.getIn([
    'packages',
    selectedPackageSlug,
    'basePackageId',
  ]);
  const isQaSale = isQaSaleInQaStage(state);
  return {
    requiredComplianceFiles,
    files,
    filesRequired,
    filesFailedToUpload,
    selectedPackage,
    selectedPaymentType,
    isQaSale
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  validate: () => dispatch(validateBrokerCompliance()),
  postUploadComplianceDoc: (fileId, file) =>
    dispatch(uploadComplianceDoc(fileId, file)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    saveComplianceFiles: (files, paymentType) => {
      const { dispatch } = dispatchProps;
      dispatch(saveRequiredComplianceFiles(files));
      dispatch(saveCompliancePaymentType(paymentType));
    },
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(BrokerCompliance);

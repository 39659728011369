import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BorderedContainer, Block } from '@simply-fin-services/astronomix3';
import { toPremium } from '../common/numberFormats';

// Remove this override once we have conformed to the new style guide
const HighlightedText = styled.h4`
  margin: 0;
  padding-top: 0.25rem;
`;

const PromotedText = ({ children }) => {
  return <HighlightedText>{children}</HighlightedText>;
};

const BoringText = ({ children }) => {
  return <div>{children}</div>;
};

const DangerText = ({ children }) => {
  return <div className="text-danger">{children}</div>;
};

const PrimaryText = ({ children }) => {
  return <div className="text-primary">{children}</div>;
};

const PromotedPrimaryText = ({ children }) => {
  return <HighlightedText className="text-primary">{children}</HighlightedText>;
};

const Summary = ({
  promote,
  danger,
  primary,
  promotePrimary,
  description,
  cost,
  discount,
  formatCost,
}) => {
  const NormalText = danger ? DangerText : primary ? PrimaryText : BoringText;

  const Text = promotePrimary
    ? PromotedPrimaryText
    : promote
      ? PromotedText
      : NormalText;

  const amount = formatCost === false ? cost : toPremium(cost);

  return (
    <Block display="flex" justifyContent="space-between" alignItems="center">
      <Block>
        <Text>{description}</Text>
      </Block>
      <Block>
        <Text>
          {discount ? '- ' : ''}
          {amount}
        </Text>
      </Block>
    </Block>
  );
};

Summary.propTypes = {
  promote: PropTypes.bool,
  promotePrimary: PropTypes.bool,
  bordered: PropTypes.bool,
  danger: PropTypes.bool,
  primary: PropTypes.bool,
  cost: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  discount: PropTypes.bool,
};

export default class SummaryCost extends PureComponent {
  render() {
    const {
      cost = '0',
      description,
      promote,
      danger,
      promotePrimary,
      primary,
      discount,
      formatCost,
      alwaysShow,
      bordered,
    } = this.props;

    if (cost === '0' && alwaysShow !== false) return <React.Fragment />;

    if (promote || promotePrimary) {
      if (bordered) {
        return (
          <BorderedContainer
            borderStyle="none"
            shade="neutral"
            hue="base"
            bottom="1px"
            padding="0.5rem 0 0.25rem 0"
          >
            <Summary
              promote={promote}
              promotePrimary={promotePrimary}
              description={description}
              formatCost={formatCost}
              cost={cost}
            />
          </BorderedContainer>
        );
      }
      return (
        <BorderedContainer
          padding="0.5rem 0 0.25rem 0"
        >
          <Summary
            promote={promote}
            promotePrimary={promotePrimary}
            description={description}
            formatCost={formatCost}
            cost={cost}
          />
        </BorderedContainer>
      );
    }

    if (bordered) {
      return (
        <BorderedContainer
          borderStyle="dashed"
          shade="neutral"
          hue="base"
          bottom="1px"
          padding="0.5rem 0 0.25rem 0"
        >
          <Summary
            danger={danger}
            primary={primary}
            promotePrimary={promotePrimary}
            description={description}
            cost={cost}
            discount={discount}
          />
        </BorderedContainer>
      );
    }

    return (
      <BorderedContainer
        shade="neutral"
        hue="base"
        padding="0.5rem 0 0.25rem 0"
      >
        <Summary
          danger={danger}
          primary={primary}
          promotePrimary={promotePrimary}
          description={description}
          cost={cost}
          discount={discount}
        />
      </BorderedContainer>
    );
  }
}

SummaryCost.propTypes = {
  cost: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  promote: PropTypes.bool,
  promotePrimary: PropTypes.bool,
  danger: PropTypes.bool,
  primary: PropTypes.bool,
  discount: PropTypes.bool,
};

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toEnoughDigitsResponse = exports.toNotEnoughDigitsResponse = exports.iemasMemberNumberFormatValid = exports["default"] = void 0;

var _immutable = require("immutable");

var _paymentDetails = require("../paymentDetails");

var _default = function _default(currentDate, details) {
  var paymentType = details.get('paymentType');
  return (0, _paymentDetails.validatePaymentDetails)(paymentType, currentDate, details).reduce(function (reduction, value, key) {
    return reduction.setIn(['details', key], value).set('isValid', reduction.get('isValid') && value.get('isValid'));
  }, new _immutable.Map({
    isValid: true
  }));
}; // An Iemas member number must be 7 digits, no more, no less.


exports["default"] = _default;

var iemasMemberNumberFormatValid = function iemasMemberNumberFormatValid(iemasMemberNumber) {
  return /^\d{7}$/.test(iemasMemberNumber);
};

exports.iemasMemberNumberFormatValid = iemasMemberNumberFormatValid;

var toNotEnoughDigitsResponse = function toNotEnoughDigitsResponse(memberNumber) {
  return {
    memberNumber: memberNumber,
    found: false,
    status: 'Must contain 7 digits',
    canContinue: false
  };
};

exports.toNotEnoughDigitsResponse = toNotEnoughDigitsResponse;

var toEnoughDigitsResponse = function toEnoughDigitsResponse(memberNumber) {
  return {
    memberNumber: memberNumber,
    found: false,
    status: '',
    canContinue: false
  };
};

exports.toEnoughDigitsResponse = toEnoughDigitsResponse;
import * as types from './types';
import request from 'axios';
import { API_ROUTES } from '../config/constants';

const setAccountVerificationStatus = (status) => {
  return {
    type: types.SET_BANK_ACCOUNT_VERIFICATION,
    payload: status,
  };
};

const postAvsrVerification = (details) => (dispatch) => {
  request
    .post(API_ROUTES.DO_AVSR_ACCOUNT_VALIDATION, {
      details,
    })
    .then((response) => {
      dispatch(setAccountVerificationStatus(response.data));
    })
    .catch(() => {
      dispatch(
        setAccountVerificationStatus({
          status: 'retry-required',
          data: ['Verification failed'],
        })
      );
    });
};

const postAvsrVerificationPoll = (details) => (dispatch) => {
  request
    .post(API_ROUTES.POLL_AVSR_ACCOUNT_VALIDATION, {
      details,
    })
    .then((response) => {
      dispatch(setAccountVerificationStatus(response.data));
    })
    .catch(() => {
      dispatch(
        setAccountVerificationStatus({
          status: 'retry-required',
          data: ['Verification failed polling bank'],
        })
      );
    });
};

export const getAccountVerfification = (details) => ({
  type: types.GET_BANK_ACCOUNT_VERIFICATION,
  payload: postAvsrVerification(details),
});

export const pollAccountVerfification = (details) => ({
  type: types.POLL_BANK_ACCOUNT_VERIFICATION,
  payload: postAvsrVerificationPoll(details),
});

import * as types from './types';

export const insuredPersonAdded = () => {
  return {
    type: types.REPLACE_AND_SAVE_INSURED_PERSON_ADDED,
    payload: {},
  };
};

export const doneAddingInsureds = () => {
  return {
    type: types.REPLACE_AND_SAVE_INSURED_PERSON_ADDED,
    payload: {},
  };
};

export const replacementPolicyAdded = () => {
  return {
    type: types.REPLACE_AND_SAVE_REPLACEMENT_POLICY_ADDED,
    payload: {},
  };
};

export const addPolicyNextStep = (policy) => {
  return {
    type: types.REPLACE_AND_SAVE_ADD_POLICY_NEXT_STEP,
    payload: {policy},
  };
};

export const addReplacementPolicy = () => {
  return {
    type: types.REPLACE_AND_SAVE_ADD_REPLACEMENT_POLICY,
    payload: {},
  };
};

export const removePolicyNextStep = (policy) => {
  return {
    type: types.REPLACE_AND_SAVE_REMOVE_POLICY_NEXT_STEP,
    payload: {policy},
  };
};

export const removeReplacementPolicy = (policy) => {
  return {
    type: types.REPLACE_AND_SAVE_REMOVE_REPLACEMENT_POLICY,
    payload: { policy },
  };
};

export const setReplaceAndSaveEnabled = (interactionId) => {
  return {type: types.REPLACE_AND_SAVE_SET_ENABLED, payload: {interactionId}};
};

export const setReplacementPolicyData = (data) => {
  return {type: types.REPLACE_AND_SAVE_SET_REPLACEMENT_POLICY_DATA,
          payload: data};
};

export const showReplacementPoliciesPage = () => {
  return {type: types.REPLACE_AND_SAVE_SHOW_REPLACEMENT_POLICIES_PAGE,
          payload: {}};
};

export const setReplacementPolicySavingsInformation = (data) => {
  return {type: types.REPLACE_AND_SAVE_SET_REPLACEMENT_POLICIES_SAVINGS_INFORMATION,
          payload: data};
};
export const fileFromBase64 = (fileName, base64encodedStringData) => {
  const byteCharacters = atob(base64encodedStringData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const url = window.URL.createObjectURL(
    new Blob([new Uint8Array(byteNumbers)])
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

import { connect } from 'react-redux';
import { getSelectedPackageIdOrDefault } from '../../common/getPackageIdFromSlug';
import Progress from './Progress';

export const mapStateToProps = state => {
  const path =
    state.getIn(['routing', 'locationBeforeTransitions', 'pathname']) ||
    'not set yet';
  const rehydrationLink = state.getIn(['rehydration', 'url']);
  const packageId = getSelectedPackageIdOrDefault(state);
  return {
    path,
    packageId,
    rehydrationLink,
  };
};

const ProgressContainer = connect(mapStateToProps)(Progress);

export default ProgressContainer;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FullWidthButtonWrapper from '../common/FullWidthButtonWrapper';

export default class ButtonText extends PureComponent {
  render() {
    const { text } = this.props;
    return (
      <FullWidthButtonWrapper>
        <button
          type="button"
          className="btn btn-primary m-b-10 disabled button-response"
          disabled
        >
          {text}
        </button>
      </FullWidthButtonWrapper>
    );
  }
}

ButtonText.propTypes = {
  text: PropTypes.string.isRequired,
};

import React, { PureComponent } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import PolicyInformation from './PolicyInformation';
import PageDescription from '../common/PageDescription';
import DebiCheckPopup from './DebiCheckPopup';

export default class ThankYouPage extends PureComponent {
  render() {
    const {
      canSignupMultiplePolicies,
      policies,
      sortProducts,
      initDetailsCaptureFromPolicy,
      SUFFIX_URL,
      discountRate,
      winWinDiscountRate,
      hasWinWin,
      productConfig,
      ropPricing,
      paymentType,
      isFromBrokerPortal,
      requiresPeeps,
      isDomesticPartialSale,
      brokerQaThankYouMessage,
      isQaActive,
      isThirdPartyPackage,
      firstSalaryDeductionCollectionMonth
    } = this.props;
    const isDebiOrderPayment = paymentType === 'debit-order';

    const description = (
      <span>
        {!canSignupMultiplePolicies && isDebiOrderPayment && !isQaActive && (
          <span>Well done! Your purchase is complete.</span>
        )}
        {(canSignupMultiplePolicies || !isDebiOrderPayment) && !isQaActive && (
          <span>Congratulations. Your purchase is complete.</span>
        )}
        {isQaActive && <span>{brokerQaThankYouMessage}</span>}
      </span>
    );

    return (
      <div>
        <DebiCheckPopup />
        <PageDescription description={description} />
        {R.map(
          (p) => (
            <PolicyInformation
              key={p.identityNumber}
              policy={p}
              canSignupMultiplePolicies={canSignupMultiplePolicies}
              sortProducts={sortProducts}
              initDetailsCaptureFromPolicy={initDetailsCaptureFromPolicy}
              policyDocumentHome={SUFFIX_URL}
              discountRate={discountRate}
              winWinDiscountRate={winWinDiscountRate}
              hasWinWin={hasWinWin}
              productConfig={productConfig}
              ropPricing={ropPricing}
              isFromBrokerPortal={isFromBrokerPortal}
              requiresPeeps={requiresPeeps}
              isDomesticPartialSale={isDomesticPartialSale}
              isThirdPartyPackage={isThirdPartyPackage}
              firstSalaryDeductionCollectionMonth={firstSalaryDeductionCollectionMonth}
            />
          ),

          policies
        )}
      </div>
    );
  }
}

ThankYouPage.propTypes = {
  canSignupMultiplePolicies: PropTypes.bool.isRequired,
  isDomesticPartialSale: PropTypes.bool.isRequired,
  policies: PropTypes.array.isRequired,
  sortProducts: PropTypes.func.isRequired,
  initDetailsCaptureFromPolicy: PropTypes.func.isRequired,
  SUFFIX_URL: PropTypes.string,
  discountRate: PropTypes.string,
  winWinDiscountRate: PropTypes.string,
  productConfig: PropTypes.object,
  ropPricing: PropTypes.object,
  a: PropTypes.string,
  requiresPeeps: PropTypes.bool.isRequired,
  firstSalaryDeductionCollectionMonth: PropTypes.string,
};

export const getPackageDetailGivenUrlSlug = (state, packageUrlSlug) => {
  const selectedPackage = state.getIn(['packages', packageUrlSlug]);
  const packageDetailId = selectedPackage.get('packageId');
  return state.getIn(['packageDetails', packageDetailId]);
};

export default state => {
  const selectedPackageUrlSlug = state.get('selectedPackageSlug');
  return getPackageDetailGivenUrlSlug(state, selectedPackageUrlSlug);
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Head } from '@simply-fin-services/astronomix3';

class PageSEO extends PureComponent {
  render() {
    const { theme, title, description, image } = this.props;
    return (
      <Head themeName={theme} title={title}>
        <meta name="description" content={description} />
        <meta name="og:type" content={description} />
        <meta name="og:title" content={title} />
        <meta name="og:image" content={image} />
        <meta name="fb:app_id" content="1712145419040559" />
      </Head>
    );
  }
}

PageSEO.propTypes = {
  theme: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

const mapStateToProps = (state) => ({
  theme: state.getIn(['currentTheme', 'theme']),
});

export default connect(mapStateToProps)(PageSEO);

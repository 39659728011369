import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { mapReducer } from './unchangedStateReducer';
import routing from './routing';
import authentication from './authentication';
import interactionId from './interactionId';
import answers from './answers';
import recommendation from './recommendation';
import bankingDetails from './bankingDetails';
import productSelection from './productSelection';
import selectedPackageSlug from './selectedPackageSlug';
import termsAndConditions from './termsAndConditions';
import referenceNumber from './referenceNumber';
import completeSale from './completeSale';
import callMeBack from './callMeBack';
import moreCover from './moreCover';
import referral from './referral';
import referralLogin from './referralLogin';
import referralSignup from './referralSignup';
import referrerCode from './referrerCode';
import winWinDetails from './winWinDetails';
import inSale from './inSale';
import campaign from './campaign';
import previousPackageSelections from './previousPackageSelections';
import peeps from './peeps';
import policyInformation from './policyInformation';
import detailsCaptureProcess from './detailsCaptureProcess';
import rehydration from './rehydration';
import currentTheme from './currentTheme';
import broker from './broker';
import contactDetailsPopup from './contactDetailsPopup';
import prepareQuotePopup from './prepareQuotePopup';
import analytics from './analytics';
import paymentType from './paymentType';
import ropPricing from './ropPricing';
import extendedFamilyInsureds from './extendedFamilyInsureds';
import devToolsLocalStorage from './devToolsLocalStorage';
import iemasMembers from './iemasMembers';
import persal from './persal';
import salaryDeduction from './salaryDeduction';
import brokerQa from './brokerQA';
import avsr from './avsr';
import brokerCompliance from './brokerCompliance';
import replaceAndSave from './replaceAndSave';
import entityForm from './entityForm';

// Combine reducers with routeReducer which keeps track of
// router state

/*
        If you add reducers to this list, you need to check if the
        state is needed by the re-hydrator, and dispatch the relevant
        actions from the getAndRehydrateInteraction method in the
        rehydration.js file in ../actions/
*/

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    interactionId,
    routing,
    selectedPackageSlug,
    authentication,
    packages: mapReducer,
    discounts: mapReducer,
    extras: mapReducer,
    packageDetails: mapReducer,
    products: mapReducer,
    questions: mapReducer,
    questionNodes: mapReducer,
    questionPages: mapReducer,
    terms: mapReducer,
    packageTips: mapReducer,
    packageReferrals: mapReducer,
    packageConstituents: mapReducer,
    productLimitCombos: mapReducer,
    packageRelatedQuestions: mapReducer,
    extendedFamilyQuestions: mapReducer,
    answers,
    recommendation,
    productSelection,
    bankingDetails,
    fileManifest: mapReducer,
    termsAndConditions,
    referenceNumber,
    config: mapReducer,
    completeSale,
    callMeBack,
    moreCover,
    referralLogin,
    referralSignup,
    referral,
    referrerCode,
    winWinDetails,
    inSale,
    campaign,
    previousPackageSelections,
    peeps,
    policyInformation,
    detailsCapture: mapReducer,
    detailsCaptureProcess,
    rehydration,
    currentTheme,
    broker,
    iemasMembers,
    contactDetailsPopup,
    prepareQuotePopup,
    analytics,
    paymentType,
    ropPricing,
    extendedFamilyInsureds,
    devToolsLocalStorage,
    persal,
    brokerQa,
    brokerCompliance,
    avsr,
    salaryDeduction,
    saleOptions: mapReducer,
    replaceAndSave,
    entityForm,
  });

export default rootReducer;

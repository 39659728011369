import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormHeading } from '@simply-fin-services/astronomix3';
import * as R from 'ramda';

const rawMarkup = (description) => ({ __html: description });

const Header = styled.div`
  margin-bottom: ${(props) => (props.hasSubheading ? '1rem' : '2rem')};
  text-align: center;
  br {
    @media (max-width: 960px) {
      display: none;
    }
  }
`;

export default class PageDescription extends PureComponent {
  render() {
    const { description, subHeading } = this.props;
    return (
      <Header>
        <FormHeading className="no-margins">{description}</FormHeading>
        {subHeading && <p className="text-center">{subHeading}</p>}
      </Header>
    );
  }
}

PageDescription.propTypes = {
  description: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export class PageDescriptionHtml extends PureComponent {
  render() {
    const { description, subHeading } = this.props;
    const hasSubheading = !R.isNil(subHeading) && !R.isEmpty(subHeading);
    return (
      <Header hasSubheading={hasSubheading}>
        <h3
          className="text-center space-below-2"
          dangerouslySetInnerHTML={rawMarkup(description)} // eslint-disable-line
        />
        {subHeading && <p className="text-center">{subHeading}</p>}
      </Header>
    );
  }
}

PageDescriptionHtml.propTypes = {
  description: PropTypes.string.isRequired,
};

import * as types from './types';
import request from 'axios';
import * as R from 'ramda';
import { ROUTES, API_ROUTES } from '../config/constants';
import { WINWIN_DISCOUNT_RATE } from '../config/appConfig';
import { push } from 'connected-react-router';
import getPackageIdFromSlug, {
  getPackageSlugFromId,
} from '../common/getPackageIdFromSlug';
import { reset } from './startup';
import { linkExistingReferrerToSale } from './referrerLinking';
import { autoPopulateReferrerDetails } from './referralSignup';
import { prepForMultiplePolicies } from './multiplePolicies';
import { getPackageDiscountRate } from '../common/pricing';
import { isBrokerSale, isFromBrokerPortal } from '../common/brokers';

const completeSaleFailure = err => {
  const type = types.COMPLETE_SALE_FAILURE;
  return {
    type,
    payload: err,
  };
};

const completeSaleValidationFailure = err => {
  const type = types.COMPLETE_SALE_VALIDATION_FAILURE;
  return {
    type,
    payload: err,
  };
};

const saleComplete = () => {
  const type = types.SET_SALE_COMPLETE;
  return {
    type,
    payload: '',
  };
};

export const dispatchResetActionAfterCompletedSale = (slug, dispatch) => {
  dispatch(push(ROUTES.toThankYou(slug)));
  dispatch(reset());
  dispatch(saleComplete());
};

const completeSaleSuccess = newPolicy => {
  const type = types.COMPLETE_SALE_SUCCESS;
  return {
    type,
    payload: newPolicy,
  };
};

const submitCompleteSaleBusy = () => (dispatch, getstate) => {
  const state = getstate();
  return state;
};

const preventDoubleSaleCompletion = () => {
  const type = types.COMPLETE_SALE_BUSY;
  const payload = submitCompleteSaleBusy();
  return {
    type,
    payload,
  };
};

const prepCompletedPolicy = (state, packageId, policy) =>
  R.pipe(
    R.assoc('packageId', packageId),
    R.assoc(
      'packageName',
      state.getIn(['packages', getPackageSlugFromId(state, packageId), 'title'])
    ),
    R.assoc('interactionId', state.get('interactionId')),
    R.assoc('completed', false),
    p => R.assoc('underwrite', R.prop('deferredUnderwriting', p), p)
  )(policy);

const commonActions = (dispatch, state, packageId, res) => {
  const slug = state.get('selectedPackageSlug');
  dispatch(autoPopulateReferrerDetails(res.data.owner));
  dispatch(
    completeSaleSuccess(prepCompletedPolicy(state, packageId, res.data))
  );
  const referrerData = {
    cellNumber: res.data.owner.cellphone,
    referrerCode: state.get('referrerCode'),
    referenceNumber: res.data.contractNumber,
  };
  linkExistingReferrerToSale(dispatch, referrerData);
  dispatchResetActionAfterCompletedSale(slug, dispatch);
};

const sendToQaActions = (dispatch, state, packageId, res) => {
  const slug = state.get('selectedPackageSlug');
  dispatch({
    type: types.SET_SEND_TO_QA_THANK_YOU_MESSAGE
  });
  dispatch(
    completeSaleSuccess(prepCompletedPolicy(state, packageId, res.data))
  );
  dispatchResetActionAfterCompletedSale(slug, dispatch);
};

export const submittableDiscounts = state => {
  const winWinDiscountRate = state.getIn(['winWinDetails', 'hasWinWin'])
    ? WINWIN_DISCOUNT_RATE
    : 0;
  const interactionDiscountRate = getPackageDiscountRate(state);
  return {
    winWinDiscountRate,
    interactionDiscountRate,
  };
};

export const handleError = R.curry((dispatch, error) => {
  if (error.response && error.response.status === 400) {
    dispatch(completeSaleValidationFailure(error.response.data));
  } else {
    dispatch(completeSaleFailure(error));
    dispatch(push(ROUTES.ERROR));
  }
});

// TODO: Create similar function for SEND TO QA
const submitCompleteSale = () => (dispatch, getState) => {
  const state = getState();
  const slug = state.get('selectedPackageSlug');
  if (state.getIn(['completeSale', 'isSubmitted']) === false) {
    dispatch(preventDoubleSaleCompletion());
    const packageId = getPackageIdFromSlug(state, slug);
    const discounts = submittableDiscounts(state);
    const params = R.pipe(
      R.assoc('packageId', packageId),
      R.assoc("isBrokerSale", isBrokerSale(state) || isFromBrokerPortal(state)),
    )(discounts);
    request
      .post(API_ROUTES.COMPLETE_SALE, params)
      .then(res => {
        dispatch(prepForMultiplePolicies(state));
        return commonActions(dispatch, state, packageId, res);
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          dispatch(completeSaleValidationFailure(error.response.data));
        } else {
          dispatch(completeSaleFailure(error));
          dispatch(push(ROUTES.ERROR));
        }
      });
  }
};

export const completeSale = () => {
  const type = types.COMPLETE_SALE;
  const payload = submitCompleteSale();
  return {
    type,
    payload,
  };
};

const submitSendToQa = () => (dispatch, getState) => {
  const state = getState();
  const slug = state.get('selectedPackageSlug');
  if (state.getIn(['completeSale', 'isSubmitted']) === false) {
    dispatch(preventDoubleSaleCompletion());
    const packageId = getPackageIdFromSlug(state, slug);
    const discounts = submittableDiscounts(state);
    const params = R.assoc('packageId', packageId, discounts);
    request
      .post(API_ROUTES.SEND_SALE_TO_QA, params)
      .then((res) => {
        return sendToQaActions(dispatch, state, packageId, res);
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
           dispatch(completeSaleValidationFailure(error.response.data));
        } else {
          dispatch(push(ROUTES.ERROR));
        }
      });
  }
};

export const sendSaleToQa = () => {
  const type = types.SEND_SALE_TO_QA;
  const payload = submitSendToQa();
  return {
    type,
    payload
  };
};

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import ProductPeeps from './ProductPeeps';
import { WallOfTextContainer } from '@simply-fin-services/astronomix3';
import Cession from './policyCession';
export default class Peeps extends PureComponent {
  render() {
    const {
      productPeeps,
      select,
      deselect,
      addPeep,
      heading,
      description,
      addPeepButtonText,
    } = this.props;
    if (R.isEmpty(productPeeps)) {
      return null;
    }
    return (
      <WallOfTextContainer>
        <h4 className="">{heading}</h4>
        {!R.isNil(description) && !R.isEmpty(description) && (
          <p className="space-below">{description}</p>
        )}
        {R.map(
          (p) => (
            <Fragment>
              <ProductPeeps
                {...p}
                key={p.title}
                select={select(p.productId)}
                deselect={deselect(p.productId)}
                addPeep={addPeep(p.productId)}
                warnIfUnder18={this.props.warnIfUnder18}
                addPeepButtonText={addPeepButtonText}
              >
                {this.props.children}
              </ProductPeeps>
              {p.canBeCeeded && <Cession />}
            </Fragment>
          ),
          productPeeps
        )}
      </WallOfTextContainer>
    );
  }
}

Peeps.propTypes = {
  select: PropTypes.func.isRequired,
  deselect: PropTypes.func.isRequired,
  addPeep: PropTypes.func.isRequired,
  productPeeps: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  warnIfUnder18: PropTypes.bool,
  addPeepButtonText: PropTypes.string,
};

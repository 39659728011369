import * as types from './types';
import * as R from 'ramda';
import { API_ROUTES } from '../config/constants';
import request from 'axios';
import { setPrepareQuotePopupFlag, setPrepareQuoteLoading } from './prepareQuotePopup';

const postDownloadQuotePDF = (
  dispatch,
  packageId,
  productSelection,
  pricingConfig,
  idNumber
) => (_dispatch, getState) => {
  const state = getState();

  // Include EFF if it exists.
  const withExtendedFamily = state.getIn([
    'productSelection',
    'withExtendedFamilyFuneral',
  ]);
  const extendedFamilyPricing = state.getIn([
    'productSelection',
    'extendedFamilyProductAndPricing',
  ]);
  let withEffSelection = productSelection;
  if (withExtendedFamily && extendedFamilyPricing) {
    withEffSelection = [...productSelection, extendedFamilyPricing.toJS()];
  }

  dispatch(setPrepareQuoteLoading(true));

  return request
    .post(API_ROUTES.DOWNLOAD_QUOTE, {
      productSelection: withEffSelection,
      pricingConfig,
      packageId,
      idNumber,
    })
    .then(response => {
      const byteCharacters = atob(response.data.base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(byteNumbers)])
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'quote.pdf');
      document.body.appendChild(link);
      link.click();
      dispatch(setPrepareQuotePopupFlag(false));
      dispatch(setPrepareQuoteLoading(false));
    }).catch(() => dispatch(setPrepareQuoteLoading(false)));
};

export const downloadQuotePDF = (
  dispatch,
  packageId,
  selections,
  pricing,
  pricingConfig,
  idNumber
) => {
  const productSelection = R.map(
    R.omit(['pricing', 'selectedIndex']),
    selections,
    pricing
  );

  const type = types.DOWNLOAD_QUOTE;
  const meta = { productSelection };

  return {
    type,
    meta,
    payload: postDownloadQuotePDF(
      dispatch,
      packageId,
      productSelection,
      pricingConfig,
      idNumber
    ),
  };
};

/* eslint-disable */
import React, { Fragment, PureComponent, useState } from 'react';
import * as R from 'ramda';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { stateToQuestionnaireProps } from '../questionnaire/toQuestionPageProps';
import { getSelectedPackageIdOrDefault } from '../../common/getPackageIdFromSlug';
import { answerQuestion as answerQuestionActionCreator } from '../../actions/answers';
import { QuestionPageQuestions } from '../questionnaire/QuestionPage';
import {
  Buttons,
  Button,
  WallOfTextContainer,
  TextArea,
  PageButtons,
  Errors,
} from '@simply-fin-services/astronomix3';
import styled from 'styled-components';
import PageActionButtons from '../common/PageActionButtons';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const BrokerQaContainer = styled.div`
  margin-top: 25px;
`;

const ErrorContainer = styled.div`
  margin-top: 20px;
`;

const ContentContainer = styled.div`
  margin-top: 15px;
  width: 100%;
`;

const ContentContainerCentre = styled.div`
  margin-top: 15px;
  width: 100%;
  text-align:center;
`;
class BrokerQa extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedTab: '',
    };
    this.selectTab = this.selectTab.bind(this);
    this.updateCorrectionComments = this.updateCorrectionComments.bind(this);
  }

  selectTab(selectedTab) {
    this.setState({ selectedTab });
  }

  updateCorrectionComments(e) {
    this.props.updateCorrectionComments(e.target.value);
  }

  render() {
    const {
      canContinue,
      validComments,
      brokerQuestionPageProps,
      rejectSale,
      sendForCorrections,
      approveSale,
    } = this.props;
    const questionnaireIsValid = this.props.brokerQuestionPageProps.isValid;
    return (
      <BrokerQaContainer>
        <Buttons>
          {this.state.selectedTab === 'approve' && (
            <Button
              type="ghost-primary"
              onClick={() => this.selectTab('approve')}
            >
              Approve
            </Button>
          )}
          {this.state.selectedTab !== 'approve' && (
            <Button onClick={() => this.selectTab('approve')}>Approve</Button>
          )}
          {this.state.selectedTab === 'correct' && (
            <Button
              type="ghost-primary"
              onClick={() => this.selectTab('correct')}
            >
              Send for Corrections
            </Button>
          )}
          {this.state.selectedTab !== 'correct' && (
            <Button onClick={() => this.selectTab('correct')}>
              Send for Corrections
            </Button>
          )}
          {this.state.selectedTab === 'reject' && (
            <Button
              type="ghost-primary"
              onClick={() => this.selectTab('reject')}
            >
              Reject
            </Button>
          )}
          {this.state.selectedTab !== 'reject' && (
            <Button onClick={() => this.selectTab('reject')}>Reject</Button>
          )}
        </Buttons>
        <WallOfTextContainer>
          <Container>
            {this.state.selectedTab === 'approve' && (
              <ContentContainer>
                <QuestionPageQuestions {...brokerQuestionPageProps} />
                <PageActionButtons
                  onClick={approveSale}
                  disabled={!(questionnaireIsValid && canContinue)}
                  text="Submit"
                  canGoBack
                />
              </ContentContainer>
            )}
            {this.state.selectedTab === 'reject' && (
              <ContentContainerCentre>
                {canContinue && (
                  <Fragment>
                    <p>Are you sure you want to reject this policy?</p>
                    <Buttons>
                      <Button type="danger" onClick={rejectSale}>Yes</Button>
                      <Button onClick={() => this.selectTab('')}>No</Button>
                    </Buttons>
                  </Fragment>
                )}
                {!canContinue && (
                  <Fragment>
                    <Errors
                      errors={[
                        'Cannot reject sale, please complete all page requirements.',
                      ]}
                    />
                  </Fragment>
                )}
              </ContentContainerCentre>
            )}
            {this.state.selectedTab === 'correct' && (
              <ContentContainer>
                <TextArea
                  label="What are the corrections that are required?"
                  placeholder="Corrections. Must be at least 20 characters."
                  onChange={(v) => this.updateCorrectionComments(v)}
                />
                <Button
                  disabled={!(canContinue && validComments)}
                  onClick={sendForCorrections}
                >
                  Send
                </Button>
                <br />
                <ErrorContainer>
                  {!(canContinue && validComments) && (
                    <Errors
                      errors={[
                        'Cannot send corrections, please complete all page requirements.',
                      ]}
                    />
                  )}
                </ErrorContainer>
              </ContentContainer>
            )}
          </Container>
        </WallOfTextContainer>
      </BrokerQaContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const brokerQaQuestions = state.getIn([
    'questionPages',
    'broker-qa-check',
    'questions',
  ]);
  const optionalPageQuestions = fromJS([]);
  const { walkedPage, isValid, questions, answers } = stateToQuestionnaireProps(
    state,
    brokerQaQuestions,
    optionalPageQuestions
  );
  return {
    packageId: getSelectedPackageIdOrDefault(state),
    brokerQuestionPageProps: {
      walkedPage,
      isValid,
      questions,
      answers,
      pageQuestions: brokerQaQuestions,
      optionalPageQuestions,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  answerQuestion: R.curry((packageId, question, answer) => {
    dispatch(answerQuestionActionCreator(packageId, question, answer));
  }),
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    brokerQuestionPageProps: Object.assign(stateProps.brokerQuestionPageProps, {
      answerQuestion: dispatchProps.answerQuestion(stateProps.packageId),
    }),
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(BrokerQa);

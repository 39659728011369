import { SET_WIN_WIN_DETAILS } from '../actions/types';
import { fromJS } from 'immutable';

const initialState = '';

export default (state = initialState, action) => {
  if (action.type === SET_WIN_WIN_DETAILS) {
    return fromJS(action.payload);
  }
  return state;
};

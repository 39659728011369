import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Field } from '@simply-fin-services/astronomix3';

export const isNotEligible = (eligibility, packageId) =>
  eligibility &&
  eligibility.isEligible === false &&
  eligibility.eligibilityCheckedForPackageId === packageId;
export const isNotValid = (props) => props.isValid === false;
export const isOptional = (props) => props.optional === true;
export const isNotAnswered = (props) =>
  R.isNil(props.answer) || R.isEmpty(props.answer);
export const showError = (props) =>
  (isNotValid(props) || isNotEligible(props)) &&
  !(isOptional(props) && isNotAnswered(props));

class QuestionContainer extends PureComponent {
  render() {
    const {
      id,
      children,
      title,
      hint,
      nudge,
      isLoading,
      eligibility,
      packageId,
      errors,
      hasWarning,
      warnings,
    } = this.props;
    const label = <span>{title}</span>;
    const eligibilityErrors =
      isNotEligible(eligibility, packageId) && eligibility.failureMessage
        ? [eligibility.failureMessage]
        : [];
    const errorMessages = showError(this.props) ? errors || [] : [];
    const warningMessages = hasWarning ? warnings : [];
    const allErrors = R.concat(
      R.concat(errorMessages, warningMessages),
      eligibilityErrors
    );
    return (
      <Field
        id={id || title}
        nudge={nudge}
        label={label}
        hint={hint}
        isLoading={isLoading}
        errors={allErrors}
        dontUseContainer
      >
        {children}
      </Field>
    );
  }
}

QuestionContainer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  hint: PropTypes.any,
  isValid: PropTypes.bool,
  isLoading: PropTypes.bool,
  hasWarning: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  warnings: PropTypes.arrayOf(PropTypes.string),
  packageId: PropTypes.string,
  eligibility: PropTypes.shape({
    isEligible: PropTypes.bool.isRequired,
    eligibilityCheckedForPackageId: PropTypes.string.isRequired,
    failureMessage: PropTypes.string,
  }),
  optional: PropTypes.bool,
  noMargin: PropTypes.bool,
};

export default QuestionContainer;

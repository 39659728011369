import { DELAYED_SCROLL_TOP } from './types';

export const delayedScrollToTop = () => ({
  type: DELAYED_SCROLL_TOP,
  payload: () => {
    setTimeout(() => {
      if (window && window.scrollY !== 0) {
        if (window.scroll) {
          window.scroll(0, 0);
        } else if (window.scrollTo) {
          window.scrollTo(0, 0);
        } else if (window.scrollY) {
          window.scrollY(0, 0);
        }
      }
    },500);
  }});

import { SET_INTERACTION_ID } from '../actions/types';

const initialState = null;

export default (state = initialState, action) => {
  if (action.type === SET_INTERACTION_ID) {
    return action.payload;
  }
  return state;
};

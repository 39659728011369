import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { ReferralBannerHome } from '../referralSignup/ReferralBanner';
import Referrals from './Referrals';
import { referContact } from '../../actions/referrals';
import ThankYouSharing from '../thankYou/ThankYouSharing';

class ConnectFriendsPage extends PureComponent {
  render() {
    const { submitReferral } = this.props;
    return (
      <div>
        <div className="refer-friend">
          <Referrals submitReferral={submitReferral} />
        </div>
        <ReferralBannerHome />
        <div className="share-experience-header">
          <ThankYouSharing />
        </div>
      </div>
    );
  }
}

ConnectFriendsPage.propTypes = {
  referralCode: PropTypes.string.isRequired,
  submitReferral: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  referralCode: state.getIn(['referralSignup', 'cellphoneNumber']),
  referrerName: state.getIn(['referralSignup', 'referrer-name']),
});

const submitReferral = R.curry(
  (dispatch, referrerName, referralCode, contactDetails) =>
    dispatch(referContact(referrerName, referralCode, contactDetails))
);

export const mapDispatchToProps = dispatch => ({
  submitReferral: submitReferral(dispatch),
});

export const mergeProps = (stateProps, dispatchProps) =>
  Object.assign({}, stateProps, dispatchProps, {
    submitReferral: dispatchProps.submitReferral(
      stateProps.referrerName,
      stateProps.referralCode
    ),
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ConnectFriendsPage);

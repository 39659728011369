import {
  REQUEST_MORE,
  REQUEST_MORE_SUCCESS,
  REQUEST_MORE_FAILURE,
} from './types';
import { API_ROUTES, ROUTES } from '../config/constants';
import request from 'axios';
import { push } from 'connected-react-router';

export const requestForMoreSuccess = () => {
  const type = REQUEST_MORE_SUCCESS;
  return {
    type,
  };
};

export const requestForMoreFailure = () => {
  const type = REQUEST_MORE_FAILURE;
  return {
    type,
  };
};

const postRequestForMore = () => dispatch => {
  request
    .post(API_ROUTES.REQUEST_MORE_COVER, {})
    .then(() => {
      dispatch(requestForMoreSuccess());
    })
    .catch(() => {
      dispatch(requestForMoreFailure());
      dispatch(push(ROUTES.ERROR));
    });
};

export const requestForMore = () => {
  const type = REQUEST_MORE;
  const payload = postRequestForMore();
  return {
    type,
    payload,
  };
};

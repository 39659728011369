import { fromJS } from 'immutable';
import { SAVE_ROP_PRICING } from '../actions/types';
import createActionHandler from './createActionHandler';

const initialState = fromJS({});

const actionHandlers = {};

const saveRopPricing = (state, action) => state.merge(action.payload);

actionHandlers[SAVE_ROP_PRICING] = saveRopPricing;

const handle = createActionHandler(actionHandlers);

export default (state = initialState, action) => handle(state, action);

import {
  SET_BROKER_QA_CONFIG,
  UPDATE_CORRECTION_COMMENTS,
  SEND_FOR_CORRECTIONS,
  SET_SEND_TO_QA_THANK_YOU_MESSAGE,
  REJECT_SALE
} from '../actions/types';
import createActionHandler from './createActionHandler';

const actionHandlers = {};

const setBrokerQaConfig = (state, action) => {
  return state.merge({
    brokerSaleType: action.payload.brokerSaleType,
    isQaActive: action.payload.isQaActive,
  });
};

const sendToQaMessage = "Congratulations. Your purchase is complete and awaits approval.";

const correctionsMessage = "Thank you. The purchase has been sent back for corrections.";

const rejectionMessage = "Thank you. The sale has been successfully rejected";

const setCorrectionsThankYouMessage = (state) => state.merge({brokerQaThankYouMessage: correctionsMessage});

const setSendToQaThankYouMessage = (state) => state.merge({brokerQaThankYouMessage: sendToQaMessage});

const setRejectionThankYouMessage = (state) => state.merge({brokerQaThankYouMessage: rejectionMessage});

const updateCorrectionComments = (state, action) => {
  const comments = action.payload.comments;
  return state.merge({
    correctionComments: comments,
    isValidCorrectionComments: comments.length >= 20
  });
};

actionHandlers[SET_BROKER_QA_CONFIG] = setBrokerQaConfig;
actionHandlers[UPDATE_CORRECTION_COMMENTS] = updateCorrectionComments;
actionHandlers[SEND_FOR_CORRECTIONS] = setCorrectionsThankYouMessage;
actionHandlers[SET_SEND_TO_QA_THANK_YOU_MESSAGE] = setSendToQaThankYouMessage;
actionHandlers[REJECT_SALE] = setRejectionThankYouMessage;

const handle = createActionHandler(actionHandlers);

const initialState = {
  isQaActive: false,
  brokerQaThankYouMessage: "Congratulations. Your purchase is complete.",
  correctionComments: "",
  isValidCorrectionComments: false
};

export default (state = initialState, action) => handle(state, action);

import * as types from './types';
import { ROUTES, API_ROUTES } from '../config/constants';
import { push } from 'connected-react-router';
import * as R from 'ramda';
import { dispatchNewInteractionId, reset } from './startup';
import { updateSelectedPackage } from './selectedPackageSlug';
import { reportError } from '../config/configureErrorHandling';
import request from 'axios';
import { savePeepsRequest, savePeepsSuccess } from './peeps';
import { isBrokerSale } from '../.shared/calculatePricing';
import { isFromBrokerPortal } from '../common/brokers';

const invalidConfig = errorMessage => ({
  type: types.INVALID_DETAILS_CAPTURE,
  payload: (dispatch, getState) => {
    reportError(
      new Error(errorMessage),
      { getState },
      { type: types.INVALID_DETAILS_CAPTURE }
    );
    dispatch(push(ROUTES.NOT_FOUND));
  },
});

const validConfig = R.allPass([
  R.compose(R.not, R.isEmpty),
  R.has('products'),
  R.has('parent'),
  R.has('reference'),
]);

const setProducts = (packageProducts, selectedProducts) => ({
  type: types.SET_DETAILS_CAPTURE_PRODUCTS,
  payload: R.pipe(
    R.map(p =>
      R.objOf(p, { productId: p, selected: R.includes(p, selectedProducts) })
    ),
    R.reduce(R.merge, {})
  )(packageProducts),
});

const constructInteractionId = (parent, reference) => `${parent}-${reference}`;
const referenceFromInteractionId = interactionId =>
  R.last(R.split('-', interactionId));

const prepCover = cover => {
  if (cover) {
    try {
      return R.pipe(
        R.split(','),
        R.map(R.split(';')),
        R.map(
          R.applySpec({
            title: R.nth(0),
            cover: R.compose(parseInt, R.nth(1)),
            failedUnderwriting: R.compose(v => v === 'true', R.nth(2)),
          })
        )
      )(cover);
    } catch (err) {
      // We just ignore the Exception
    }
  }
  return [];
};

export const initDetailsCapture = (config, packageId) => {
  const valid = validConfig(config);
  if (!valid) {
    return invalidConfig('Detail Capture Query Config Invalid');
  }

  const {
    parent,
    reference,
    underwrite,
    products,
    completed,
    packageName,
    cover,
  } = config;
  const interactionId = constructInteractionId(parent, reference);
  const alreadyCompleted = completed === 'true' || completed === true;
  const shouldUnderwrite = underwrite === 'true' || underwrite === true;
  const chosenProducts = R.split(',', products);
  const parentDisplayName = R.split('-', packageName || parent);
  const displayCover = prepCover(cover);
  return {
    type: types.INIT_DETAILS_CAPTURE,
    meta: {
      parent,
      reference,
      shouldUnderwrite,
      selectedPackage: packageId,
      parentDisplayName,
      products: chosenProducts,
      cover: displayCover,
    },
    payload: (dispatch, getState) => {
      if (alreadyCompleted) {
        dispatch(push(ROUTES.DETAILS_CAPTURE_THANK_YOU));
        return;
      }
      const packages = getState()
        .get('packages')
        .filter(p => p.get('packageId') === packageId);
      const hasSelectedPackage = packages.count() === 1;
      if (!hasSelectedPackage) {
        dispatch(
          invalidConfig(`Details capture packageId invalid: ${packageId}`)
        );
        return;
      }

      const chosedPackages = packages.first();
      const packageProducts = R.pipe(
        () => chosedPackages.get('products').toJS(),
        pp =>
          R.isNil(chosedPackages.get('extendedFamilyFuneralProduct'))
            ? pp
            : R.append(chosedPackages.get('extendedFamilyFuneralProduct'), pp)
      )();
      const validProductSelection = R.pipe(
        R.map(p =>
          R.or(R.includes(p, packageProducts), R.prop('isExtendedFamily', p))
        ),
        R.reduce(R.and, true)
      )(chosenProducts);

      if (!validProductSelection) {
        dispatch(
          invalidConfig(`Details capture products invalid: ${products}`)
        );
      } else {
        dispatchNewInteractionId(dispatch, interactionId);
        dispatch(setProducts(packageProducts, chosenProducts));
        dispatch(updateSelectedPackage(chosedPackages.get('urlSlug')));
        if (shouldUnderwrite) {
          dispatch(push(ROUTES.DETAILS_CAPTURE));
        } else {
          dispatch(push(ROUTES.DETAILS_CAPTURE_PEEPS));
        }
      }
    },
  };
};

export const initExternalDetailsCapture = (config, defaultPackageId) => {
  const { packageId } = config;
  const selectedPackage = packageId || defaultPackageId;
  return initDetailsCapture(config, selectedPackage);
};

export const initDetailsCaptureFromPolicy = policy => {
  const {
    underwrite,
    packageId,
    packageName,
    completed,
    contractNumber,
    parent,
    productSelection,
  } = policy;

  return initDetailsCapture(
    {
      parent,
      reference: contractNumber,
      underwrite,
      products: R.pipe(
        R.map(R.prop('productId')),
        R.join(',')
      )(productSelection),
      completed,
      packageName,
    },
    packageId
  );
};

const submitDetails = (type, successType, url, next) => () => ({
  type,
  payload: (dispatch, getState) => {
    const state = getState();
    const { parent, reference, selectedPackage } = state
      .get('detailsCaptureProcess')
      .toJS();
    request
      .post(url, { parent, reference, packageId: selectedPackage })
      .then(data => {
        dispatch({
          type: successType,
          payload: {
            data,
            transactionNumber: referenceFromInteractionId(
              state.get('interactionId')
            ),
          },
        });
        next(dispatch, state);
      })
      .catch(() => {
        dispatch(reset());
        dispatch(push(ROUTES.ERROR));
      });
  },
});

export const submitUnderwriting = submitDetails(
  types.DETAILS_CAPTURE_SUBMIT_UNDERWRITING,
  types.DETAILS_CAPTURE_SUBMIT_UNDERWRITING_SUCCESS,
  API_ROUTES.DETAILS_CAPTURE_UNDERWRITING,
  dispatch => dispatch(push(ROUTES.DETAILS_CAPTURE_PEEPS))
);

const submitPeepsCompleted = submitDetails(
  types.DETAILS_CAPTURE_SUBMIT_PEEPS,
  types.DETAILS_CAPTURE_SUBMIT_PEEPS_SUCCESS,
  API_ROUTES.DETAILS_CAPTURE_PEEPS,
  (dispatch, state) => {
    const isUnderwriting = state.getIn([
      'detailsCaptureProcess',
      'shouldUnderwrite',
    ]);
    if (isUnderwriting) {
      return dispatch(push(ROUTES.DETAILS_CAPTURE_THANK_YOU));
    }
    return dispatch(push(ROUTES.THANK_YOU));
  }
);

const completePeepsProcessAndGoToTerms = (dispatch, state) => {
  dispatch(savePeepsSuccess());
  const packageUrlSlug = state.get('selectedPackageSlug');
  const packageCanDoInsuredTerms = state.getIn(["packages", packageUrlSlug, "allowBrokerToDisplayInsuredTerms"]);
  if((isBrokerSale(state) || isFromBrokerPortal(state)) && packageCanDoInsuredTerms) {
    return dispatch(push(ROUTES.toSimplifiedInsuredTerms(packageUrlSlug)));
  }
  return dispatch(push(ROUTES.toSimplifiedTerms(packageUrlSlug)));
};

export const submitPeeps = (insureds, beneficiaries) => ({
  type: types.SAVE_PEEPS,
  payload: (dispatch, getState) => {
    savePeepsRequest(
      API_ROUTES.UNDERWRITING_INSUREDS,
      API_ROUTES.UNDERWRITING_BENEFICIARIES,
      insureds,
      beneficiaries
    )
      .then(() => {
        const state = getState();
        const peepsWereCompletedInProcess = state.get('inSale');
        if (peepsWereCompletedInProcess) {
          return completePeepsProcessAndGoToTerms(dispatch, state);
        }
        return dispatch(submitPeepsCompleted());
      })
      .catch(() => {
        dispatch(reset());
        dispatch(push(ROUTES.ERROR));
      });
  },
});

export const toggleHasNoSpouseOrChildrenUnder21 = hasNoSpouseOrChildrenUnder21 => ({
  type: types.TOGGLE_HAS_NO_SPOURSE_OR_CHILDREN_UNDER_21,
  payload: { hasNoSpouseOrChildrenUnder21 },
});

import * as R from 'ramda';
import { ROUTES } from '../../config/constants';
import { isBrokerSale, isFromBrokerPortal } from '../../common/brokers';
import { fromJS } from 'immutable';

const selectedProductsIds = R.pipe(
  R.values,
  R.filter((product) => product.selected),
  R.map((product) => product.productId)
);

const selectedProducts = R.curry((products, productSelection) =>
  R.values(R.pick(selectedProductsIds(productSelection), products))
);

const shouldNotShowMedicalQuestions = (products, productSelection) =>
  R.all(R.prop('doesNotRequireUnderwriting'))(
    selectedProducts(products, productSelection)
  );

// Used to extract key params for `shouldNotShowMedicalQuestions` from `state`
export const doNotShowMedicalQuestions = (state) =>
  shouldNotShowMedicalQuestions(state.get('products').toJS(),
                                state.getIn(['productSelection', 'products']).toJS());

// Removes medical questions from the given `questions` depending on if medical underwriting applies based on the given `state`.
export const filterMedicalQuestions = (selectedPackage, questions, state) => {
  const medicalQuestionnaire =
        selectedPackage.get('medicalQuestionnaire') || fromJS([]);

  return doNotShowMedicalQuestions(state)
    ? questions.filterNot((q) => medicalQuestionnaire.includes(q))
    : questions;
};


// Takes into account selected package, broker flows and if
// medical questions apply or not based on the given `state`;
export const determineEffectiveQuestionnaire = (packaeUrlSlug, state) => {
  const selectedPackage = state.getIn(['packages', packaeUrlSlug]);

  const questionPages = selectedPackage.get('questionnaire');

  const questionnaire = filterMedicalQuestions(selectedPackage, questionPages, state);

  const shouldNotDeferUnderwriting = !selectedPackage.get('allowDeferredUnderwriting');

  // The sale does not allow deferring underwriting (broker) and the questionare is returned here, terminating further logic.
  if (shouldNotDeferUnderwriting) {
    return questionnaire;
  }

  const brokerCanUnderwrite =
    (isFromBrokerPortal(state) || isBrokerSale(state)) &&
    selectedPackage.get('allowBrokerUnderwriting');

  if (brokerCanUnderwrite) {
    const brokerQuestionnaire = selectedPackage.get('brokerQuestionnaire');

    if (brokerQuestionnaire) {
      return filterMedicalQuestions(selectedPackage, brokerQuestionnaire, state);
    }

    return questionnaire;
  }

  const pagesToRemove = selectedPackage.get(
    'questionnairePagesThatSuggestPackage'
  );

  return questionnaire.filterNot((page) => pagesToRemove.includes(page));
};

// Returns the first quote url in the list, taking into account selected package,
// broker and medical questions based on the given `state`.
export const determineEffectiveFirstUrl = (packaeUrlSlug, state) => {
  const questionnaire = determineEffectiveQuestionnaire(packaeUrlSlug, state);

  const nextQuestionId = questionnaire.first();

  return ROUTES.toDetail(packaeUrlSlug, nextQuestionId);
};

import * as types from './types';
import cookieBakery from 'js-cookie';
import request from 'axios';
import * as R from 'ramda';
import { API_ROUTES, DOMAIN } from '../config/constants';
import { IS_DEV } from '../config/appConfig';
import { isBrokerSale } from '../common/brokers';

const REFERRER_COOKIE = 'referralcode';
const REFERRER_COOKIE_LIFETIME_IN_DAYS = 30;

const setReferrer = data => ({
  type: types.SET_REFERRER_CODE,
  payload: data,
});

const setwinWinDetails = data => ({
  type: types.SET_WIN_WIN_DETAILS,
  payload: data,
});

const linkReferrerToInteraction = (interactionId, referrerCode, hasWinWin) => {
  const requestData = {
    interactionId,
    referrerCode,
    hasWinWin,
  };
  request.post(`${API_ROUTES.REFERRER_LINK_TO_INTERACTION}`, requestData);
};

export const linkReferrerCode = referralCode => {
  const type = types.LINK_REFERRER_CODE;
  const domain = IS_DEV ? 'localhost' : DOMAIN;
  const payload = dispatch => {
    cookieBakery.set(REFERRER_COOKIE, referralCode, {
      expires: REFERRER_COOKIE_LIFETIME_IN_DAYS,
      domain,
    });
    dispatch(setReferrer(referralCode));
  };
  return {
    type,
    payload,
  };
};

export const linkExistingReferrerCode = () => {
  const domain = IS_DEV ? 'localhost' : DOMAIN;
  const type = types.LINK_EXISTING_REFERRER_CODE;
  const payload = (dispatch, getState) => {
    const state = getState();
    const interactionId = state.get('interactionId');
    const referralCode = cookieBakery.get(REFERRER_COOKIE);
    if (referralCode && !isBrokerSale(state)) {
      // This is a temporary measure to migrate existing cookies to domain cookies.
      cookieBakery.set(REFERRER_COOKIE, referralCode, {
        expires: REFERRER_COOKIE_LIFETIME_IN_DAYS,
        domain,
      });
      request
        .get(`${API_ROUTES.GET_REFERRER_INFO}`, {
          params: {
            referralCode,
          },
        })
        .then(data => {
          if (data) {
            const referralData = R.pick(
              ['fullname', 'referralCode'],
              data.data
            );
            dispatch(setReferrer(referralData.referralCode));
            dispatch(
              setwinWinDetails({
                fullname: referralData.fullname,
                hasWinWin: true,
              })
            );
            dispatch(
              linkReferrerToInteraction(
                interactionId,
                referralData.referralCode,
                true
              )
            );
          }
        })
        .catch(() => dispatch(setReferrer(referralCode)));
    }
  };
  return {
    type,
    payload,
  };
};

export const linkReferrerToSale = (referenceNumber, referrerCode) => {
  const type = types.LINK_REFERRER_TO_SALE;
  const payload = (dispatch, getState) => {
    if (!isBrokerSale(getState())) {
      request
        .post(API_ROUTES.toReferrerLinkToSale(referrerCode), {
          referenceNumber,
        })
        .then(() => {})
        .catch(() => {});
    }
  };
  return {
    type,
    payload,
  };
};

export const linkExistingReferrerToSale = (dispatch, referrerData) => {
  const { referrerCode, cellNumber, referenceNumber } = referrerData;
  if (referrerCode && referrerCode !== cellNumber) {
    dispatch(linkReferrerToSale(referenceNumber, referrerCode));
  }
};

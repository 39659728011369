// This reducer is required to make react-router-redux work with immutable.js
import { COMPLETE_SALE_SUCCESS } from '../actions/types';

const initialState = '';

export default (state = initialState, action) => {
  if (action.type === COMPLETE_SALE_SUCCESS) {
    return action.payload.contractNumber;
  }

  return state;
};

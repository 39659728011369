import { connect } from 'react-redux';
import { doneAddingInsureds } from '../../../actions/replaceAndSave';
import {
  currentPolicy,
  currentPolicyId,
} from '../../../stateStore/replaceAndSave';
import {
  fetchLatestPolicyReplacementData,
  fetchFamilyMembersWithCoverAllocations,
  allocateCover,
} from './../httpApi';
import AddInsured from './Add';
import { getIsEntityValid, getEntity } from '../../../stateStore/entityForm';
import { clearFormEntity } from '../../../actions/entityForm';

const entityType = 'replace-and-save-replacement-insured';

const mapStateToProps = (state) => ({
  policy: currentPolicy(state),
  policyId: currentPolicyId(state),
  entityType,
  isEntityValid: getIsEntityValid(state),
  entity: getEntity(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchLatestPolicyReplacementData: () =>
    fetchLatestPolicyReplacementData(dispatch),

  fetchFamilyMembersWithCoverAllocations: (policyId) =>
    fetchFamilyMembersWithCoverAllocations(policyId),

  allocateCover: (params) => allocateCover(params),

  // Alias for allocateCover where coverAmount is null
  clearAllocatedCover: (params) => allocateCover(params),

  insuredPersonCaptured: () => {
    fetchLatestPolicyReplacementData(dispatch);
    dispatch(doneAddingInsureds());

    window.scroll(0, 0);
  },
  clearEntity: () => dispatch(clearFormEntity(entityType)),
});

const AddInsuredContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInsured);

export default AddInsuredContainer;

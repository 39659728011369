import {
  RELOAD_OLD_PACKAGE_SELECTED_PRODUCTS,
  COPY_PACKAGE_SELECTED_PRODUCTS,
  RESET_STATE_TO_PREVIOUS_PACKAGE,
  CLEAR_OLD_PACKAGE_SELECTIONS,
} from './types';
import { saveProductSelection } from './productSelection';
import { updateSelectedPackage } from './selectedPackageSlug';
import calculatePricing, {
  getPricingConfig,
} from './../views/customization/calculatePricing';

const clearOldPackage = () => ({
  type: CLEAR_OLD_PACKAGE_SELECTIONS,
  payload: {},
});

export const goBackToAnotherPackage = (previousSelections, packageSlug) => ({
  type: RELOAD_OLD_PACKAGE_SELECTED_PRODUCTS,
  payload: {
    products: previousSelections.get('products').toJS(),
    packageSlug,
    recommendation: previousSelections.get('recommendation').toJS(),
  },
});

export const resetStateToPreviousPackage = (packageSlug) => ({
  type: RESET_STATE_TO_PREVIOUS_PACKAGE,
  payload: (dispatch, getState) => {
    const previousPackageSelections = getState().get(
      'previousPackageSelections'
    );

    if (previousPackageSelections.get('products')) {
      dispatch(goBackToAnotherPackage(previousPackageSelections, packageSlug));
      const selection = previousPackageSelections
        .get('products')
        .valueSeq()
        .toJS();
      const packageId = getState().getIn([
        'packages',
        packageSlug,
        'packageId',
      ]);
      const pricingConfig = getPricingConfig(getState(), packageSlug);
      const pricing = calculatePricing(
        previousPackageSelections.get('products'),
        pricingConfig
      );
      dispatch(updateSelectedPackage(packageSlug));
      dispatch(saveProductSelection(packageId, selection, null, pricing));
      dispatch(clearOldPackage());
    }
  },
});

export const saveCopyOfPackageProductSelection = (
  packageId,
  productSelection,
  recommendation
) => ({
  type: COPY_PACKAGE_SELECTED_PRODUCTS,
  payload: {
    products: productSelection,
    recommendation: recommendation.toJS(),
    packageId,
  },
});

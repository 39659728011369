import { LOCATION_CHANGE } from 'connected-react-router';
import { ROUTES } from '../config/constants';
import { GET_RECOMMENDATION, UPDATE_SELECTED_PACKAGE } from '../actions/types';

const route = ROUTES.QUOTE;
const routeLength = route.length;

const selectPathName = action => action.payload.location.pathname;
const isCorrectLength = path =>
  path && path.length && path.length > routeLength;
const isCorrectPath = path => path.substring(0, routeLength) === route;
const isCorrectRoute = path => isCorrectLength(path) && isCorrectPath(path);

const getSlug = path => {
  const packageSlug = path.substring(routeLength);
  const indexOfSlash = packageSlug.indexOf('/');
  if (indexOfSlash === -1) {
    return packageSlug;
  }
  return '';
};

const initialState = '';

export default (state = initialState, action) => {
  if (action.type === LOCATION_CHANGE) {
    const path = selectPathName(action);
    if (isCorrectRoute(path)) {
      return getSlug(path);
    }
  }
  if (action.type === GET_RECOMMENDATION) {
    return action.meta.packageUrlSlug;
  }

  if (action.type === UPDATE_SELECTED_PACKAGE) {
    return action.meta.packageSlug;
  }
  return state;
};
